import {
  FETCH_AUCTION_ENTRY_FAILURE,
  FETCH_AUCTION_ENTRY_REQUEST,
  FETCH_AUCTION_ENTRY_RESPONSE,
  FETCH_AUCTION_ENTRIES_FAILURE,
  FETCH_AUCTION_ENTRIES_REQUEST,
  FETCH_AUCTION_ENTRIES_RESPONSE,
  POST_AUCTION_ENTRY_FAILURE,
  POST_AUCTION_ENTRY_RESPONSE,
  AuctionEntryActionTypes,
  AuctionEntryState,
  POST_AUCTION_ENTRY_REQUEST,
} from '../types/AuctionEntry';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AuctionEntryState = {
  auctionEntries: null,
  activeAuctionEntry: null,
  isFetchingAuctionEntries: false,
  fetchingAuctionEntriesFailed: false,
  isPostingAuctionEntry: false,
  postingAuctionEntryFailed: false,
  postingAuctionEntryError: null,
  postingAuctionEntryValidationErrors: null,
};

const auctionEntryReducer = (
  state = initialState,
  action: AuctionEntryActionTypes,
): AuctionEntryState => {
  switch (action.type) {
    case FETCH_AUCTION_ENTRIES_REQUEST:
      return {
        ...state,
        auctionEntries: null,
        isFetchingAuctionEntries: true,
        fetchingAuctionEntriesFailed: false,
      };
    case FETCH_AUCTION_ENTRIES_RESPONSE:
      return {
        ...state,
        auctionEntries: action.payload || [],
        isFetchingAuctionEntries: false,
        fetchingAuctionEntriesFailed: false,
      };
    case FETCH_AUCTION_ENTRIES_FAILURE:
      return {
        ...state,
        auctionEntries: null,
        isFetchingAuctionEntries: false,
        fetchingAuctionEntriesFailed: true,
      };
    case FETCH_AUCTION_ENTRY_REQUEST:
      return {
        ...state,
        auctionEntries: null,
        isFetchingAuctionEntries: true,
        fetchingAuctionEntriesFailed: false,
      };
    case FETCH_AUCTION_ENTRY_RESPONSE:
      return {
        ...state,
        activeAuctionEntry: action.payload,
        isFetchingAuctionEntries: false,
        fetchingAuctionEntriesFailed: false,
      };
    case FETCH_AUCTION_ENTRY_FAILURE:
      return {
        ...state,
        auctionEntries: null,
        isFetchingAuctionEntries: false,
        fetchingAuctionEntriesFailed: true,
      };
    case POST_AUCTION_ENTRY_REQUEST:
      return {
        ...state,
        isPostingAuctionEntry: true,
        postingAuctionEntryError: null,
        postingAuctionEntryValidationErrors: null,
        postingAuctionEntryFailed: false,
      };
    case POST_AUCTION_ENTRY_RESPONSE:
      return {
        ...state,
        isPostingAuctionEntry: false,
        postingAuctionEntryError: null,
        postingAuctionEntryValidationErrors: null,
        postingAuctionEntryFailed: false,
      };
    case POST_AUCTION_ENTRY_FAILURE:
      return {
        ...state,
        isPostingAuctionEntry: false,
        postingAuctionEntryError: action.payload.error || 'An error occurred',
        postingAuctionEntryValidationErrors: parseValidationErrors(
          action.payload.validationErrors,
        ),
        postingAuctionEntryFailed: true,
      };

    default:
      return state;
  }
};

export default auctionEntryReducer;
