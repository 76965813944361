import { RootState } from '../store/configureStore';

export const getAuctions = (state: RootState) => state?.auction?.auctions;

export const getActiveAuction = (state: RootState) => state?.auction?.activeAuction;

export const isFetchingAuctions = (state: RootState) => state?.auction?.isFetchingAuctions;

export const isPostingAuction = (state: RootState) => state?.auction?.isPostingAuction;
export const postingAuctionFailed = (state: RootState) => state?.auction?.postingAuctionFailed;
export const postingAuctionError = (state: RootState) => state?.auction?.postingAuctionError;
export const postingAuctionValidationErrors = (state: RootState) => state?.auction?.postingAuctionValidationErrors;
export const fetchingAuctionsFailed = (state: RootState) => state?.auction?.fetchingAuctionsFailed;