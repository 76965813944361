import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_WIZARDS_REQUEST,
  FETCH_WIZARDS_RESPONSE,
  FETCH_WIZARDS_TEXT_RESPONSE,
  FETCH_WIZARDS_IMAGE_RESPONSE,
  FETCH_VALUATION_INFO_RESPONSE,
  FETCH_XY_GENERATION_RESPONSE,
  FETCH_WIZARDS_FAILURE,
  FETCH_WIZARD_REQUEST,
  FETCH_WIZARD_RESPONSE,
  FETCH_WIZARD_FAILURE,
  POST_WIZARD_FAILURE,
  POST_WIZARD_REQUEST,
  POST_WIZARD_RESPONSE,
  WizardActionTypes,
  WizardDataModel,
  WizardTag,
  TextInfo,
  FETCH_WIZARDS_TEXT_REQUEST, FETCH_WIZARDS_IMAGE_REQUEST, FETCH_VALUATION_INFO_REQUEST, FETCH_XY_GENERATION_REQUEST,
  ValuationInfo, XYGeneration,

} from '../types/Wizard';

import { RootState } from '../store/configureStore';

import { getInfoByImage, getInfoByText, getValuationInfo, getXYGeneration } from '../api/Wizard';
import { fetchMatchesByEntry } from './Match';

export function fetchWizardsRequest(): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_REQUEST,
    payload: null,
  };
}

export function fetchWizardsResponse(
  wizards: WizardDataModel,
): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_RESPONSE,
    payload: wizards,
  };
}

export function fetchWizardsFailure(): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_FAILURE,
    payload: null,
  };
}

export function fetchWizardRequest(): WizardActionTypes {
  return {
    type: FETCH_WIZARD_REQUEST,
    payload: null,
  };
}

export function fetchWizardResponse(
  wizards: WizardTag,
): WizardActionTypes {
  return {
    type: FETCH_WIZARD_RESPONSE,
    payload: wizards,
  };
}

export function fetchWizardTextRequest(): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_TEXT_REQUEST,
    payload: null,
  };
}

export function fetchWizardTextResponse(
  textInfo: TextInfo,
): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_TEXT_RESPONSE,
    payload: textInfo,
  };
}

export function fetchWizardImageRequest(): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_IMAGE_REQUEST,
    payload: null,
  };
}

export function fetchWizardImageResponse(
  imageInfo: [],
): WizardActionTypes {
  return {
    type: FETCH_WIZARDS_IMAGE_RESPONSE,
    payload: imageInfo,
  };
}

export function fetchValuationInfoRequest(): WizardActionTypes {
  return {
    type: FETCH_VALUATION_INFO_REQUEST,
    payload: null,
  };
}

export function fetchValuationInfoResponse(
  valuationInfo: ValuationInfo,
): WizardActionTypes {
  return {
    type: FETCH_VALUATION_INFO_RESPONSE,
    payload: valuationInfo,
  };
}

export function fetchXYGenerationRequest(): WizardActionTypes {
  return {
    type: FETCH_XY_GENERATION_REQUEST,
    payload: null,
  };
}

export function fetchXYGenerationResponse(
  xyGeneration: XYGeneration,
): WizardActionTypes {
  return {
    type: FETCH_XY_GENERATION_RESPONSE,
    payload: xyGeneration,
  };
}

export function fetchWizardFailure(): WizardActionTypes {
  return {
    type: FETCH_WIZARD_FAILURE,
    payload: null,
  };
}


export function postWizardRequest(): WizardActionTypes {
  return {
    type: POST_WIZARD_REQUEST,
    payload: null,
  };
}

export function postWizardResponse(): WizardActionTypes {
  return {
    type: POST_WIZARD_RESPONSE,
    payload: null,
  };
}

export function postWizardFailure(error: string, validationErrors: any): WizardActionTypes {
  return {
    type: POST_WIZARD_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchInfoByText = (
  title: string, description: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchWizardTextRequest());
    const asyncResp: any = await getInfoByText(title, description);
    if (asyncResp?.success) {
      await dispatch(fetchWizardTextResponse(asyncResp));
      // await dispatch(fetchWizards());
    } else {
      await dispatch(postWizardFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchInfoByImage = (
  assetId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchWizardImageRequest());
    const asyncResp: any = await getInfoByImage(assetId);
    if (asyncResp?.success) {
      await dispatch(fetchWizardImageResponse(asyncResp.data));
      // await dispatch(fetchWizards());
    } else {
      await dispatch(postWizardFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchValuationInfo = (
  matchId: string, solutionTitle: string, solutionDescription: string, entryTitle: string, entryDescription: string, solutionLocation?: string, entryLocation?: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchValuationInfoRequest());
    const asyncResp: any = await getValuationInfo(matchId, solutionTitle, solutionDescription, entryTitle, entryDescription, solutionLocation, entryLocation);
    if (asyncResp?.success) {
      await dispatch(fetchValuationInfoResponse(asyncResp));
      // await dispatch(fetchActiveMatch(matchId));
      // await dispatch(fetchWizards());
    } else {
      await dispatch(postWizardFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchXYGeneration = (
  // matchId: string, solutionTitle: string, solutionDescription: string, entryTitle: string, entryDescription: string,
  matches: { matchId: string, entryTitle?: string, entryDescription?: string, solutionTitle?: string, solutionDescription?: string }[],
  entryId?: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchXYGenerationRequest());
    const asyncResp: any = await getXYGeneration(matches);
    if (asyncResp?.success) {
      await dispatch(fetchXYGenerationResponse(asyncResp));
      await dispatch(fetchMatchesByEntry({ searchField: 'entryId', searchWord : entryId, paging: '6' }));
      // await dispatch(fetchActiveMatch(matchId));
      // await dispatch(fetchWizards());
    } else {
      await dispatch(postWizardFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

