import axios from 'axios';
import c from '../helpers/constants';
import { AuctionEntry } from '../types/AuctionEntry';
import { DataTableParamsModel } from '../types/Common';

export function getAuctionEntry(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.AUCTION_ENTRY}/${id}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function getAuctionEntriesByAuctionId(dataTableParams?: DataTableParamsModel) {
  const fetchParams = dataTableParams?.fetchParams;
  return axios({
    url: `${c.API_ENDPOINTS.AUCTION_ENTRIES_BY_AUCTION_ID}/${fetchParams?.id}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params:{
      dataTableParams,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function getAuctionEntries(
  dataTableParams?: DataTableParamsModel,
  groupShortcode?: string,
  clientShortcode?: string,
) {
  const baseUrl = `${c.API_ENDPOINTS.AUCTION_ENTRY}`;

  const url = groupShortcode && clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return axios({
    url,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params: {
      dataTableParams,
      groupShortcode,
      clientShortcode,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}


export function archiveAuctionEntry(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.AUCTION_ENTRY}/${id}`,
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function postAuctionEntry(
  auctionId: string, 
  entryId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
) {
  return axios({
    url: c.API_ENDPOINTS.AUCTION_ENTRY,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      auctionId,
      entryId,
      startingBid,
      startDateTime,
      endDateTime,
      bidIncrement,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function postBatchAuctionEntries(
  auctionId: string,
  entryIds: string[],
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
) {
  return axios({
    url: c.API_ENDPOINTS.AUCTION_BATCH_ENTRY,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      auctionId,
      entryIds,
      startingBid,
      startDateTime,
      endDateTime,
      bidIncrement,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function postEditBatchAuctionEntries(
  batch :AuctionEntry[],
) {
  return axios({
    url: c.API_ENDPOINTS.AUCTION_EDIT_BATCH_ENTRY,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      batch,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function putAuctionEntry(
  id: string,
  auctionId: string,
  entryId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION_ENTRY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        id,
        auctionId,
        entryId,
        startingBid,
        startDateTime,
        endDateTime,
        bidIncrement,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


