import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c  from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getGroups, isFetchingGroups } from '../../../selectors/Group';
import { fetchGroups, storeGroup } from '../../../actions/Group';
import { Group } from '../../../types/Group';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const GroupDataTable = () => {

  const groups = useSelector(getGroups);
  const isLoading = useSelector(isFetchingGroups);
  const [toggle, setToggle] = useState(false);
  const [group, setActiveGroup] = useState<Group | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.GROUP_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.GROUP_UPDATE, row.id));
  };

  const onArchive = (row: Group) => {
    setActiveGroup(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (group?.id) {
      await storeGroup(group?.id);
      setRefetch(!refetch);
    }
    setActiveGroup(null);
  };

  const config = {
    columns: [
      { key: 'shortcode', label: 'Shortcode', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };


  return  <View title="Groups" onAddNew={onCreate}>
    <DataTable fetchFunction={fetchGroups} data={groups?.data || []} isLoading={isLoading}
               paging={groups?.paging || []} config={config} dependencies={refetch} />
    <Modal show={toggle} title="Archive Group" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to archive this group?
            </Modal>
    </View>;
};

export default GroupDataTable;
