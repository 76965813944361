import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getCategories, isFetchingCategories } from '../../../selectors/Category';
import { fetchCategories, storeCategory } from '../../../actions/Category';
import { Category } from '../../../types/Category';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';



const CategoryDataTable = () => {

  const categories = useSelector(getCategories);
  const isLoading = useSelector(isFetchingCategories);
  const [toggle, setToggle] = useState(false);
  const [category, setActiveCategory] = useState<Category | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CATEGORY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CATEGORY_UPDATE, row.id));
  };

  const onArchive = (row: Category) => {
    setActiveCategory(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (category?.id) {
      await storeCategory(category?.id);
      setRefetch(!refetch);
    }
    setActiveCategory(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'title', label: 'Category Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'order', label: 'Order', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Categories" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchCategories} data={categories?.data || []}
                 isLoading={isLoading} paging={categories?.paging || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Category" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this category?
      </Modal>
    </View>
  );
};

export default CategoryDataTable;

