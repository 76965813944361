import React, { useState, useEffect } from 'react';
import FileUpload from '../FileUpload/FileUpload';
import Tabs from '../Tabs';
import FilePicker from '../FilePicker/FilePicker';
import { Tab } from '../Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssets,
  getUploadedAssets,
  getUploadedAssetsIdentifier,
  isPostingAsset,
} from '../../selectors/Asset';
import assetGallery from '../AssetGallery';
import c from '../../helpers/constants';
import noImage from '../../assets/images/no-preview.png';
import Button from '../Button';
import { Asset } from '../../types/Asset';
import Loading from '../Loading';
import { useMobile } from '../../helpers/functions';
import { clearUpload } from '../../actions/Asset';


type FileSelectorProps = {
  type: 'images' | 'files' | 'custom';
  groupId?: string;
  clientId?: string;
  onPick: (id: string) => void;
  onUnPick: (id: string) => void;
  picked?: string;
  fileTypes?: string[];
  dynamicId?: string;
};

const FileSelector: React.FC<FileSelectorProps> = ({
  type,
  groupId,
  clientId,
  onPick,
  onUnPick,
  picked,
  fileTypes,
  dynamicId,
}) => {
  const dispatch = useDispatch();
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const [assets, setAssets] = useState<string[]>([]);
  const allAssets = useSelector(getAssets);
  const uploadedAssets = useSelector(getUploadedAssets);
  const identifier = useSelector(getUploadedAssetsIdentifier);
  const isPosting = useSelector(isPostingAsset);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (picked) {
      const pickedArray = picked.split(',').filter(Boolean);
      setAssets(pickedArray);
    } else {
      setAssets([]);
    }
  }, [picked]);

  useEffect(() => {
    if (uploadedAssets && dynamicId) {
      const ids = uploadedAssets.data.map(obj => obj.id);
      if (dynamicId === identifier){
        onPick(ids[0]);
        dispatch(clearUpload());
        if (isMobile) {
          setIsExpanded(!isExpanded);
        }
      }
    }
  }, [uploadedAssets, dynamicId]);

  useEffect(() => {
    if (assets && allAssets) {
      setSelectedAssets(allAssets?.data.filter((asset: any) => assets.includes(asset.id)));
    }
  }, [assets, allAssets]);

  const handleFilePick = (id: string) => {
    if (!assets.includes(id)) {
      onPick(id);
    }
  };

  const handleFileUnPick = (id: string) => {
    onUnPick(id);
  };


  const tabs: Tab[] = [
    {
      label: type === 'files' ? 'Upload a new File' : 'Upload a new Image',
      content: (
          <FileUpload
              type={type}
              groupId={groupId || ''}
              clientId={clientId || ''}
              fileTypes={fileTypes}
              dynamicId={dynamicId ?? ''}
          />
      ),
    },
    {
      label: 'Select from Gallery',
      content: (
          <FilePicker
              type={type}
              picked={picked}
              clientId={clientId}
              dynamicId={dynamicId ?? ''}
              onPick={handleFilePick}
              onUnPick={handleFileUnPick}
              fileTypes={fileTypes}
          />
      ),
    },
  ];

  const isImg = (mime: string) =>{
    return !(mime.includes('application/pdf') || mime.includes('text/plain'));
  };

  return (
      <div>
        {selectedAssets && selectedAssets.length > 0 &&
            <div className="file-picker__img-selected">
              <div className="row mx-2 mt-4 file-picker__img-selected">
                {(isPosting) ?
                    <div className="col-xl-2 col-lg-3 col-6 mb-4">
                <Loading/>
                    </div> :
                    <>
                {selectedAssets?.map((p: any, i: number) => (
                    <>
                      {!(isMobile && p.title === 'placeholder') && <div key={i} className="col-xl-2 col-lg-3 col-6 mb-4" onClick={() => onUnPick(p.id)}>
                        <div className="file-picker__img-delete">
                          <img
                              alt="deselect"
                              src={assetGallery.xImgDark}
                              style={!isImg(p.mime) ? { transform: 'translateY(15px)' } : {}}
                          />
                        </div>
                        {type === 'images' && <img
                            src={`${c.API_ENDPOINTS.ASSET_FILE}/${p.id}`}
                            className="file-picker__img-preview"
                            onError={(event) => {
                              event.currentTarget.src = noImage;
                            }}
                            alt="selected"
                        />}
                        {!isImg(p.mime) && <div style={{ display: 'inline-block' }}>
                          <iframe
                              src={`${c.API_ENDPOINTS.ASSET_FILE}/${p.id}`}
                              className="img-preview img-thumbnail"
                              onError={(event) => {
                                event.currentTarget.src = noImage;
                              }}
                          />
                        </div>}
                        <div className="file-picker__img-title">
                          {p.title}.{p.extension}
                        </div>
                      </div>}
                    </>
                ))}
                    </>}

              </div>
            </div>}
        <Button disabled={(isPosting)} title={(picked && !isExpanded) ? 'Change Selected Files'
          : (!picked && !isExpanded) ? 'Select Files'
            : 'Close Gallery'
        } onClick={() => setIsExpanded(!isExpanded)}/>
        {isExpanded && <>
          <Tabs tabs={tabs} activeTabIndex={0} />
        </>}
      </div>
  );
};

export default FileSelector;
