import React from 'react';
import img from '../assets/images/white-recycling-icon.png';
import '../styles/error404.scss';
import { FaLongArrowAltDown } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import c from '../helpers/constants';



function Error404() {
  const history = useHistory();
  const onSubmit = () => {
    history.push(c.APP_ROUTES.DASHBOARD);
  };
  return (

                <div className="error-404">
                    <div className="row align-content-center error-404-container">
                        <div className="col-12 d-flex justify-content-center text-center">
                            <img src={img} alt='' className='image'/>
                        </div>
                        <div className="col-12 my-2 d-flex justify-content-center text-center e404">
                            <p>404</p>
                        </div>
                        <div className="col-12 my-2 d-flex justify-content-center text-center arrow">
                            <p><FaLongArrowAltDown  size={40} /> </p>
                        </div>
                        <div className="col-12 my-2 d-flex justify-content-center text-center message">
                            <p>The page you are looking for has been recycled! </p>
                        </div>
                        <div className="col-12 my-2 d-flex justify-content-center text-center ">
                            <button type='button' className="back-button btn" onClick={onSubmit}>BACK HOME</button>
                        </div>
                    </div>
                </div>

  );
}

export default Error404;
