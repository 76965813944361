import React from 'react';
import Card from '../../components/Card/Card';

import c from '../../helpers/constants';
import { formatUrl } from '../../helpers/functions';
import { useHistory } from 'react-router-dom';

type ProductsCardProps = {
  type?: string;
  recentEntries?: any;
  reusedEntries?: any;
  popularEntries?: any;
};

const ProductsCard = ({ type, recentEntries, reusedEntries, popularEntries }: ProductsCardProps) => {
  const history = useHistory();

  const onNavigate = (theEntryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, theEntryId));
  };

  let entries = [];

  switch (type) {
    case 'recently_added':
      entries = recentEntries?.data.slice(0, 4) || [];
      break;
    case 'recently_reused':
      entries = reusedEntries?.data.slice(0, 4) || [];
      break;
    case 'popular_items':
      entries = popularEntries?.data.slice(0, 4) || [];
      break;
    default:
      break;
  }

  return (
                <div className="row g-4">
                    {entries.map((entry: any, idx: any) => (
                        <div className="col-lg-6 col-md-6 col-sm-12" key={idx}>
                            <Card
                                cardId={idx}
                                title={entry.title}
                                description={entry.description}
                                onClick={() => onNavigate(entry.id)}
                                imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${entry.assetId}`}
                            />
                        </div>
                    ))}
                </div>
  );
};

export default ProductsCard;
