import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getEntryFieldHistory(id: string, visibilityPublic: boolean) {
  
  let url = '';  
  if (visibilityPublic) {
    url = `${c.API_ENDPOINTS.ENTRY_FIELD_HISTORY_PUBLIC}/${id}`;
  } else {
    url = `${c.API_ENDPOINTS.ENTRY_FIELD_HISTORY}/${id}`;
  } 
  return (
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryFieldHistoryByShortcode(groupShortcode: string, clientShortcode: string, id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD_HISTORY}/${groupShortcode}/${clientShortcode}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntryFieldsHistory(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_FIELD_HISTORY,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

/*export function postEntryFieldHistory(groupId: string, clientId: string, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_FIELD_HISTORY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryFieldHistory(
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string | null,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD_HISTORY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntryFieldHistory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD_HISTORY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}*/

