import { RootState } from '../store/configureStore';

export const getSolutionBadges = (state: RootState) => state?.solutionBadge?.solutionBadges;

export const getActiveSolutionBadge = (state: RootState) => state?.solutionBadge?.activeSolutionBadge;

export const isFetchingSolutionBadges = (state: RootState) => state?.solutionBadge?.isFetchingSolutionBadges;

export const isPostingSolutionBadge = (state: RootState) => state?.solutionBadge?.isPostingSolutionBadge;
export const postingSolutionBadgeFailed = (state: RootState) => state?.solutionBadge?.postingSolutionBadgeFailed;
export const postingSolutionBadgeError = (state: RootState) => state?.solutionBadge?.postingSolutionBadgeError;
export const postingSolutionBadgeValidationErrors = (state: RootState) => state?.solutionBadge?.postingSolutionBadgeValidationErrors;
export const fetchingSolutionBadgesFailed = (state: RootState) => state?.solutionBadge?.fetchingSolutionBadgesFailed;