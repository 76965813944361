import axios from 'axios';
import c from '../helpers/constants';
import { DataTableParamsModel, Row } from '../types/Common';

export function getOffers(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getOffer(id: string, groupShortcode?: string, clientShortcode?: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        groupShortcode,
        clientShortcode,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postOffer(
  donorClientId: string,
  projectManagerIds: string | string[] | object | object[],
  salesRepUserId: string,
  shipmentContactId: string,
  solutionId: string,
  subject: string,
  details: string,
  date: string,
  clientNumber: string,
  currency: string,
  pickupLocation: string,
  collectionDate: string,
  notesToEME: string,
  notesToShippingProvider: string,
  statusCode?: string,
  offerItems?: Row[],
) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        donorClientId,
        projectManagerIds,
        salesRepUserId,
        shipmentContactId,
        solutionId,
        subject,
        details,
        date,
        clientNumber,
        currency,
        pickupLocation,
        collectionDate,
        notesToEME,
        notesToShippingProvider,
        statusCode,
        offerItems,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putOffer(
  id: string,
  donorClientId: string,
  projectManagerIds: string | string[] | object | object[],
  salesRepUserId: string,
  shipmentContactId: string,
  solutionId: string,
  subject: string,
  details: string,
  date: string,
  clientNumber: string,
  currency: string,
  pickupLocation: string,
  collectionDate: string,
  notesToEME: string,
  notesToShippingProvider: string,
  statusCode?: string,
  reEstimationNotes?: string,
  offerItems?: Row[],
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        donorClientId,
        projectManagerIds,
        salesRepUserId,
        shipmentContactId,
        solutionId,
        subject,
        details,
        date,
        clientNumber,
        currency,
        pickupLocation,
        collectionDate,
        notesToEME,
        notesToShippingProvider,
        statusCode,
        reEstimationNotes,
        offerItems,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function storeOffer(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
