import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getFieldTypeOption(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE_OPTION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getFieldTypeOptions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD_TYPE_OPTION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postFieldTypeOption(fieldTypeId: string, key: string, defaultValue: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD_TYPE_OPTION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        fieldTypeId,
        key,
        defaultValue,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putFieldTypeOption(
  id: string,
  fieldTypeId: string,
  key: string,
  defaultValue: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE_OPTION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        fieldTypeId,
        key,
        defaultValue,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveFieldTypeOption(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE_OPTION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

