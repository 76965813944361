import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './Accordion.scss';


type Props = {
  title: string;
  children?: React.ReactNode;
  active?: string;
  navLinks?: string[]; // Array of NavLink paths to check for activity
  icon?: string;
  customClassname?: string;
};

const AccordionTab = (props: Props) => {
  const { title, children, active = '1', navLinks = [], icon, customClassname } = props;
  const location = useLocation();

  // Function to check if a NavLink is active based on its path
  const isNavLinkActive = () => {
    return navLinks.some((link) => location.pathname.includes(link));
  };

  return (
        <Accordion defaultActiveKey={isNavLinkActive() ? '0' : active} id="action-header" flush style={navLinks.length > 0 ? { marginBottom: '10px' } : undefined}>
            <Accordion.Item eventKey="0">
                <Accordion.Header className={customClassname}>
                    {icon && <img src={icon} alt="Icon" width={25} height={25} className="accordion-icon" />}
                    {title}</Accordion.Header>
                <Accordion.Body>
                    {children}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
  );
};

export default AccordionTab;
