import React from 'react';
import View from '../../../components/View';
import { useSelector } from 'react-redux';
import { getEntriesHistory, isFetchingEntriesHistory } from '../../../selectors/EntryHistory';
import { fetchEntriesHistory/*, fetchEntryHistory */ } from '../../../actions/EntryHistory';

import DataTable from '../../../components/DataTable';


const EntryHistoryDataTable = () => {

  const entriesHistory = useSelector(getEntriesHistory);
  const isLoading = useSelector(isFetchingEntriesHistory);

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'formTitle', label: 'Form', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'assetTitle', label: 'Asset', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'notes', label: 'Notes', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'createdBy', label: 'Created By', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    hideControls: true,
    hideButton: true,
    actions: [],
  };

  return <View title="EntriesHistory">
        <DataTable fetchFunction={fetchEntriesHistory} isLoading={isLoading} data={entriesHistory?.data || []}
                   paging={entriesHistory?.paging || []} config={config}/>
    </View>;
};

export default EntryHistoryDataTable;
