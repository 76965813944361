import React from 'react';
import View from '../../../components/View';
import { useSelector } from 'react-redux';
import { getEntryFieldsHistory, isFetchingEntryFieldsHistory } from '../../../selectors/EntryFieldHistory';
import { fetchEntryFieldsHistory/*, fetchEntryFieldHistory */ } from '../../../actions/EntryFieldHistory';

import DataTable from '../../../components/DataTable';


const EntryFieldHistoryDataTable = () => {

  const entryFieldsHistory = useSelector(getEntryFieldsHistory);
  const isLoading = useSelector(isFetchingEntryFieldsHistory);

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'fieldTitle', label: 'Form', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'notes', label: 'Notes', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'createdBy', label: 'Created By', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    hideControls: true,
    hideButton: true,
    actions: [],
  };

  return <View title="EntryFieldsHistory">
        <DataTable fetchFunction={fetchEntryFieldsHistory} isLoading={isLoading} paging={entryFieldsHistory?.paging || []}
                   data={entryFieldsHistory?.data || []} config={config}/>
    </View>;
};

export default EntryFieldHistoryDataTable;
