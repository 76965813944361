import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StaticView from './StaticView';
import DynamicView from './DynamicView';
import '../../../components/View/View.scss';
import c from '../../../helpers/constants';
import { fetchActiveView, fetchViewByShortcodes } from '../../../actions/View';
import { getActiveView } from '../../../selectors/View';
import useShortcodes from '../../../hooks/ShortcodeHook';
import View from '../../../components/View';
import { isFetchingEntryFields } from '../../../selectors/EntryField';
import { isFetchingForms } from '../../../selectors/Form';
import { formatUrl, getUserToken } from '../../../helpers/functions';
import { isFetchingEntries, isPostingEntry } from '../../../selectors/Entry';

function ViewRenderer() {
  const dispatch = useDispatch();
  const {
    routeId,
    isUsingShortcodes,
    groupShortcode,
    clientShortcode,
  } = useShortcodes(c.APP_ROUTES.VIEW_RENDERER_SHORTCODE, c.APP_ROUTES.VIEW_RENDERER);
  const activeView = useSelector(getActiveView);
  const history = useHistory();
  const isFetchingEnt = useSelector(isFetchingEntries);
  const isFetchingEntF = useSelector(isFetchingEntryFields);
  const isFetchingForm = useSelector(isFetchingForms);
  const isPostingEnt = useSelector(isPostingEntry);

  useEffect(() => {
    if (routeId && routeId !== activeView?.id) {
      if (isUsingShortcodes) {
        dispatch(fetchViewByShortcodes(groupShortcode, clientShortcode, routeId));
      } else {
        dispatch(fetchActiveView(routeId));
      }
    }
  }, [dispatch, routeId]);

  const isDynamicView = activeView?.formId && activeView?.formId?.length > 0;
  const isStaticView = activeView?.contents && activeView?.contents.length > 0;
  const isLoadingDatatable = isFetchingEntF || isFetchingForm || isFetchingEnt || isPostingEnt ;
  const isLoadingView = isFetchingForm ;

  const onAddNewRecord = () => {
    if (activeView?.formId) {
      history.push(formatUrl(c.APP_ROUTES.FORM_RENDERER, activeView?.formId));
    }
  };
  return <View title={activeView?.title} isLoading={isLoadingView}  onAddNew={isDynamicView && getUserToken() && !isUsingShortcodes ? onAddNewRecord : undefined}>
        {isDynamicView && !isStaticView && <DynamicView isLoadingDatatable={isLoadingDatatable} view={activeView}/>}
        {isStaticView && !isDynamicView && <StaticView view={activeView}/>}
    </View>;
}

export default ViewRenderer;
