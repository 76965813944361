import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  ViewRole,
  ViewRoleActionTypes,
  ViewRoleDataModel, FETCH_VIEW_ROLE_FAILURE,
  FETCH_VIEW_ROLE_REQUEST,
  FETCH_VIEW_ROLE_RESPONSE,
  FETCH_VIEW_ROLES_FAILURE,
  FETCH_VIEW_ROLES_REQUEST,
  FETCH_VIEW_ROLES_RESPONSE,
  POST_VIEW_ROLE_FAILURE,
  POST_VIEW_ROLE_REQUEST,
  POST_VIEW_ROLE_RESPONSE,
} from '../types/ViewRole';

import { RootState } from '../store/configureStore';

import { deleteViewRole, getViewRoles, postViewRole } from '../api/ViewRole';
import { DataTableParamsModel } from '../types/Common';



export function fetchViewRolesRequest(): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLES_REQUEST,
    payload: null,
  };
}

export function fetchViewRolesResponse(
  viewRoles: ViewRoleDataModel,
): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLES_RESPONSE,
    payload: viewRoles,
  };
}

export function fetchViewRolesFailure(): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLES_FAILURE,
    payload: null,
  };
}

export function fetchViewRoleRequest(): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLE_REQUEST,
    payload: null,
  };
}

export function fetchViewRoleResponse(
  user: ViewRole,
): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLE_RESPONSE,
    payload: user,
  };
}

export function fetchViewRoleFailure(): ViewRoleActionTypes {
  return {
    type: FETCH_VIEW_ROLE_FAILURE,
    payload: null,
  };
}

export function postViewRoleRequest(): ViewRoleActionTypes {
  return {
    type: POST_VIEW_ROLE_REQUEST,
    payload: null,
  };
}

export function postViewRoleResponse(): ViewRoleActionTypes {
  return {
    type: POST_VIEW_ROLE_RESPONSE,
    payload: null,
  };
}

export function postViewRoleFailure(error: string, validationErrors: any): ViewRoleActionTypes {
  return {
    type: POST_VIEW_ROLE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchViewRoles = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewRolesRequest());
    const asyncResp: any = await getViewRoles(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchViewRolesResponse(asyncResp));
    } else {
      await dispatch(fetchViewRolesFailure());
    }
  };

export const destroyViewRole = async (id: string) => {
  try {
    await deleteViewRole(id);
  } catch (error) {
    throw error;
  }
};


export const createViewRole = (
  viewId: string, roleId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postViewRoleRequest());
    const asyncResp: any = await postViewRole(viewId, roleId);
    if (asyncResp?.success) {
      await dispatch(postViewRoleResponse());
    } else {
      await dispatch(postViewRoleFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
