import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getOfferProjectManager(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_PROJECT_MANAGER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getOfferProjectManagers(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER_PROJECT_MANAGER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteOfferProjectManager(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_PROJECT_MANAGER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postOfferProjectManager(offerId: string, projectManagerId: string ) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER_PROJECT_MANAGER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        offerId,
        projectManagerId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postOfferProjectManagers(offerId: string, projectManagerIds: string[] ) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_PROJECT_MANAGER}/batch`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        offerId,
        projectManagerIds,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

