import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  OfferProjectManager,
  OfferProjectManagerActionTypes,
  OfferProjectManagerDataModel, FETCH_OFFER_PROJECT_MANAGER_FAILURE,
  FETCH_OFFER_PROJECT_MANAGER_REQUEST,
  FETCH_OFFER_PROJECT_MANAGER_RESPONSE,
  FETCH_OFFER_PROJECT_MANAGERS_FAILURE,
  FETCH_OFFER_PROJECT_MANAGERS_REQUEST,
  FETCH_OFFER_PROJECT_MANAGERS_RESPONSE,
  POST_OFFER_PROJECT_MANAGER_FAILURE,
  POST_OFFER_PROJECT_MANAGER_REQUEST,
  POST_OFFER_PROJECT_MANAGER_RESPONSE,
  POST_OFFER_PROJECT_MANAGERS_FAILURE,
  POST_OFFER_PROJECT_MANAGERS_REQUEST,
  POST_OFFER_PROJECT_MANAGERS_RESPONSE,
} from '../types/OfferProjectManager';

import { RootState } from '../store/configureStore';

import {
  deleteOfferProjectManager,
  getOfferProjectManagers,
  postOfferProjectManager,
  postOfferProjectManagers,
} from '../api/OfferProjectManager';
import { DataTableParamsModel } from '../types/Common';

export function fetchOfferProjectManagersRequest(): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGERS_REQUEST,
    payload: null,
  };
}

export function fetchOfferProjectManagersResponse(
  offerProjectManagers: OfferProjectManagerDataModel,
): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGERS_RESPONSE,
    payload: offerProjectManagers,
  };
}

export function fetchOfferProjectManagersFailure(): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGERS_FAILURE,
    payload: null,
  };
}

export function fetchOfferProjectManagerRequest(): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGER_REQUEST,
    payload: null,
  };
}

export function fetchOfferProjectManagerResponse(
  user: OfferProjectManager,
): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGER_RESPONSE,
    payload: user,
  };
}

export function fetchOfferProjectManagerFailure(): OfferProjectManagerActionTypes {
  return {
    type: FETCH_OFFER_PROJECT_MANAGER_FAILURE,
    payload: null,
  };
}

export function postOfferProjectManagerRequest(): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGER_REQUEST,
    payload: null,
  };
}

export function postOfferProjectManagerResponse(): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGER_RESPONSE,
    payload: null,
  };
}

export function postOfferProjectManagerFailure(error: string, validationErrors: any): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGER_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export function postOfferProjectManagersRequest(): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGERS_REQUEST,
    payload: null,
  };
}

export function postOfferProjectManagersResponse(): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGERS_RESPONSE,
    payload: null,
  };
}

export function postOfferProjectManagersFailure(error: string, validationErrors: any): OfferProjectManagerActionTypes {
  return {
    type: POST_OFFER_PROJECT_MANAGERS_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchOfferProjectManagers = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferProjectManagersRequest());
    const asyncResp: any = await getOfferProjectManagers(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOfferProjectManagersResponse(asyncResp));
    } else {
      await dispatch(fetchOfferProjectManagersFailure());
    }
  };

export const destroyOfferProjectManager = async (id: string) => {
  try {
    await deleteOfferProjectManager(id);
  } catch (error) {
    throw error;
  }
};


export const createOfferProjectManager = (
  offerId: string, projectManagerId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferProjectManagerRequest());
    const asyncResp: any = await postOfferProjectManager(offerId, projectManagerId);
    if (asyncResp?.success) {
      await dispatch(postOfferProjectManagerResponse());
    } else {
      await dispatch(postOfferProjectManagerFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createOfferProjectManagers = (
  offerId: string, projectManagerIds: string[],
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferProjectManagersRequest());
    const asyncResp: any = await postOfferProjectManagers(offerId, projectManagerIds);
    if (asyncResp?.success) {
      await dispatch(postOfferProjectManagersResponse());
    } else {
      await dispatch(postOfferProjectManagersFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
