import { ApiDataModel, FormError } from './Common';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_RESPONSE = 'FETCH_CATEGORIES_RESPONSE';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_RESPONSE = 'FETCH_CATEGORY_RESPONSE';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const POST_CATEGORY_REQUEST = 'POST_CATEGORY_REQUEST';
export const POST_CATEGORY_RESPONSE = 'POST_CATEGORY_RESPONSE';
export const POST_CATEGORY_FAILURE = 'POST_CATEGORY_FAILURE';

export interface Category {
  id: string;
  groupId: string;
  clientId: string;
  order: number;
  title: string;
  description: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface CategoryDataModel extends Omit<ApiDataModel, 'data'> {
  data: Category[];
}

export interface CategoryState {
  categories: CategoryDataModel | null,
  activeCategory: Category | null,
  isFetchingCategories: boolean;
  fetchingCategoriesFailed: boolean;
  postingCategoryFailed?: boolean;
  isPostingCategory: boolean;
  postingCategoryValidationErrors: FormError[] | null;
  postingCategoryError: string | null;
}

interface FetchCategoriesRequestAction {
  type: typeof FETCH_CATEGORIES_REQUEST;
  payload: null;
}

interface FetchCategoriesResponseAction {
  type: typeof FETCH_CATEGORIES_RESPONSE;
  payload: CategoryDataModel;
}

interface FetchCategoriesFailureAction {
  type: typeof FETCH_CATEGORIES_FAILURE;
  payload: null;
}

interface FetchCategoryRequestAction {
  type: typeof FETCH_CATEGORY_REQUEST;
  payload: null;
}

interface FetchCategoryResponseAction {
  type: typeof FETCH_CATEGORY_RESPONSE;
  payload: Category;
}

interface FetchCategoryFailureAction {
  type: typeof FETCH_CATEGORY_FAILURE;
  payload: null;
}

interface PostCategoryRequestAction {
  type: typeof POST_CATEGORY_REQUEST;
  payload: null;
}

interface PostCategoryResponseAction {
  type: typeof POST_CATEGORY_RESPONSE;
  payload: null;
}

interface PostCategoryFailureAction {
  type: typeof POST_CATEGORY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type CategoryActionTypes =
    | FetchCategoriesRequestAction
    | FetchCategoriesResponseAction
    | FetchCategoriesFailureAction
    | PostCategoryRequestAction
    | PostCategoryResponseAction
    | PostCategoryFailureAction
    | FetchCategoryRequestAction
    | FetchCategoryResponseAction
    | FetchCategoryFailureAction;
