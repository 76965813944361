import { RootState } from '../store/configureStore';

export const getLeads = (state: RootState) => state?.lead?.leads;

export const getActiveLead = (state: RootState) => state?.lead?.activeLead;

export const isFetchingLeads = (state: RootState) => state?.lead?.isFetchingLeads;

export const isPostingLead = (state: RootState) => state?.lead?.isPostingLead;
export const postingLeadFailed = (state: RootState) => state?.lead?.postingLeadFailed;
export const postingLeadError = (state: RootState) => state?.lead?.postingLeadError;
export const postingLeadValidationErrors = (state: RootState) => state?.lead?.postingLeadValidationErrors;
export const fetchingLeadsFailed = (state: RootState) => state?.lead?.fetchingLeadsFailed;