import { ApiDataModel, FormError } from './Common';

export const FETCH_FIELD_PROPERTIES_REQUEST = 'FETCH_FIELD_PROPERTIES_REQUEST';
export const FETCH_FIELD_PROPERTIES_RESPONSE = 'FETCH_FIELD_PROPERTIES_RESPONSE';
export const FETCH_FIELD_PROPERTIES_FAILURE = 'FETCH_FIELD_PROPERTIES_FAILURE';

export const FETCH_FIELD_PROPERTY_REQUEST = 'FETCH_FIELD_PROPERTY_REQUEST';
export const FETCH_FIELD_PROPERTY_RESPONSE = 'FETCH_FIELD_PROPERTY_RESPONSE';
export const FETCH_FIELD_PROPERTY_FAILURE = 'FETCH_FIELD_PROPERTY_FAILURE';

export const POST_FIELD_PROPERTY_REQUEST = 'POST_FIELD_PROPERTY_REQUEST';
export const POST_FIELD_PROPERTY_RESPONSE = 'POST_FIELD_PROPERTY_RESPONSE';
export const POST_FIELD_PROPERTY_FAILURE = 'POST_FIELD_PROPERTY_FAILURE';

export interface FieldProperty {
  id: string;
  groupId: string;
  clientId: string;
  fieldTypeId: string;
  fieldId: string;
  fieldTypeOptionId: string;
  fieldTitle: string;
  formId: string;
  value: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
  fieldTypeTitle?: string;
  categoryTitle?: string;
  fieldTypeOptionKey?: string;
  fieldTypeOption?: string;
}

export interface FieldPropertyDataModel extends Omit<ApiDataModel, 'data'> {
  data: FieldProperty[];
}

export interface FieldPropertyState {
  fieldProperties: FieldPropertyDataModel | null,
  activeFieldProperty: FieldProperty | null,
  isFetchingFieldProperties: boolean;
  fetchingFieldPropertiesFailed: boolean;
  postingFieldPropertyFailed?: boolean;
  isPostingFieldProperty: boolean;
  postingFieldPropertyValidationErrors: FormError[] | null;
  postingFieldPropertyError: string | null;
}

interface FetchFieldPropertiesRequestAction {
  type: typeof FETCH_FIELD_PROPERTIES_REQUEST;
  payload: null;
}

interface FetchFieldPropertiesResponseAction {
  type: typeof FETCH_FIELD_PROPERTIES_RESPONSE;
  payload: FieldPropertyDataModel;
}

interface FetchFieldPropertiesFailureAction {
  type: typeof FETCH_FIELD_PROPERTIES_FAILURE;
  payload: null;
}

interface FetchFieldPropertyRequestAction {
  type: typeof FETCH_FIELD_PROPERTY_REQUEST;
  payload: null;
}

interface FetchFieldPropertyResponseAction {
  type: typeof FETCH_FIELD_PROPERTY_RESPONSE;
  payload: FieldProperty;
}

interface FetchFieldPropertyFailureAction {
  type: typeof FETCH_FIELD_PROPERTY_FAILURE;
  payload: null;
}

interface PostFieldPropertyRequestAction {
  type: typeof POST_FIELD_PROPERTY_REQUEST;
  payload: null;
}

interface PostFieldPropertyResponseAction {
  type: typeof POST_FIELD_PROPERTY_RESPONSE;
  payload: null;
}

interface PostFieldPropertyFailureAction {
  type: typeof POST_FIELD_PROPERTY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FieldPropertyActionTypes =
    | FetchFieldPropertiesRequestAction
    | FetchFieldPropertiesResponseAction
    | FetchFieldPropertiesFailureAction
    | PostFieldPropertyRequestAction
    | PostFieldPropertyResponseAction
    | PostFieldPropertyFailureAction
    | FetchFieldPropertyRequestAction
    | FetchFieldPropertyResponseAction
    | FetchFieldPropertyFailureAction;
