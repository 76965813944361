import React from 'react';
import PasswordEdit from '../../../components/PasswordEdit';

const ChangePassword = () => {
  return <PasswordEdit />;
};

export default ChangePassword;



