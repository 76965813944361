import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMarketplacesPlusPublic, updateMarketplaceClientAdmin } from '../../actions/Marketplace';
import { fetchingMarketplacesFailed, getMarketplaces, isFetchingMarketplaces } from '../../selectors/Marketplace';
import View from '../../components/View';
import '../../components/View/View.scss';
import { belongsToMyClient, formatUrl, getToken, getUserToken } from '../../helpers/functions';
import { fetchFormFields } from '../../actions/FormField';
import c, { CardType, marketplaceCardTypes } from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { fetchAllEntryFields } from '../../actions/EntryField';
import { fetchMarketplaceEntries } from '../../actions/MarketplaceEntry';
import { fetchRoleClients } from '../../actions/RoleClient';
import { fetchEntryTags } from '../../actions/EntryTag';
import Card from '../../components/Card/Card';
import { useEditMode } from '../../hooks/EditModeHook';
import DataTable from '../../components/DataTable';
import useShortcodes from '../../hooks/ShortcodeHook';
import { toNumber } from 'lodash';
import DropDown from '../../components/DropDown';
import Modal from '../../components/Modal';
import { Alert } from 'react-bootstrap';

function MarketplaceRenderer() {
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.VIEW_MARKETPLACES_SHORTCODE, c.APP_ROUTES.VIEW_MARKETPLACES);
  const dispatch = useDispatch();
  const marketplaces = useSelector(getMarketplaces);
  const fetchFailed = useSelector(fetchingMarketplacesFailed);
  const isLoading = useSelector(isFetchingMarketplaces);
  const history = useHistory();
  const userRole = useSelector(getLoggedInRole);
  const canCreateMarketplaces = (getToken() && userRole && userRole.accessLevel < 2000);
  const user = useSelector(getLoggedInUser);

  const [searchMode, searchWord] = isUsingShortcodes ? useEditMode(c.APP_ROUTES.SEARCH_MARKETPLACES_SHORTCODE) : useEditMode(c.APP_ROUTES.SEARCH_MARKETPLACES);
  const [accessLevelModal, setAccessLevel] = useState(2000);
  const [statusModal, setStatus] = useState('Private');
  const [isMadeByMeModal, setIsMadeByMe] = useState(false);
  const [marketplaceIdModal, setMarketplaceIdModal] = useState('');
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const itemsStatus = marketplaceCardTypes.map(r => ({ label: r.label, value: r.value }));
  const queryParams = new URLSearchParams(location.search);
  const clientParam = queryParams.get('client');
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (getUserToken() && !isUsingShortcodes && searchMode ) {
      dispatch(fetchFormFields());
      dispatch(fetchAllEntryFields());
      dispatch(fetchMarketplaceEntries());
      dispatch(fetchEntryTags());
      if (getToken()) {
        dispatch(fetchRoleClients({ searchField: 'roleId', searchWord: userRole?.id }));
      }
    } else if (getUserToken() && !isUsingShortcodes && !searchMode){
      dispatch(fetchFormFields());
      dispatch(fetchAllEntryFields());
      dispatch(fetchMarketplaceEntries());
      dispatch(fetchEntryTags());
    }
    if (!getUserToken() && groupShortcode && clientShortcode && isUsingShortcodes){
      {
        dispatch(fetchFormFields( { fetchParams:{ 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
        dispatch(fetchAllEntryFields( { fetchParams:{ 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
        dispatch(fetchMarketplaceEntries({ fetchParams:{ 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
        dispatch(fetchEntryTags( { fetchParams:{ 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      }
    }
  }, [dispatch, searchWord]);

  const toClientProfile = (id: string) => {
    if (isUsingShortcodes){
      history.push(formatUrl(c.APP_ROUTES.CLIENT_PROFILE_SHORTCODE, groupShortcode, clientShortcode, id));
    } else {
      history.push(formatUrl(c.APP_ROUTES.CLIENT_PROFILE, id));
    }
  };

  const onNavigate = (entryId: string) => {
    let route;

    if (searchWord) {
      if (isUsingShortcodes) {
        route = `${formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE, groupShortcode, clientShortcode, entryId)}?search=${searchWord}`;
      } else {
        route = `${formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE, entryId)}?search=${searchWord}`;
      }
    } else {
      if (isUsingShortcodes) {
        route = formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE, groupShortcode, clientShortcode, entryId);
      } else {
        route = formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE, entryId);
      }
    }

    history.push(route);
  };

  const onCreateNewMarketplace = () => {
    history.push(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_ONE);
  };

  const onDismissStatusModal = () => {
    setOpenStatusModal(false);
  };

  const setStatusModal = (accessLevel: number, status: string, isMadeByMe: boolean, marketplaceId: string) => {
    setAccessLevel(accessLevel);
    setStatus(status);
    setIsMadeByMe(isMadeByMe);
    setMarketplaceIdModal(marketplaceId);
    setOpenStatusModal(true);
  };

  const onStatusSelect = (obj: any, marketplaceId: string) => {
    dispatch(updateMarketplaceClientAdmin(marketplaceId, '', '', '', '', '', true, obj, searchWord));
    setOpenStatusModal(false);
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
  };

  const onShare = (row: any) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE, row.groupShortcode, row.clientShortcode, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };

  return (
      <View title='MARKETPLACES' onAddNew={canCreateMarketplaces ? onCreateNewMarketplace : undefined}>
        {showCopied && <Alert className="mt-3" variant="success">
          Guest link copied to clipboard successfully!
        </Alert>}
        <DataTable paging={marketplaces?.paging || []} data={marketplaces?.data || []} config={config}
                   fetchFunction={fetchMarketplacesPlusPublic}
                   baseSearch={ clientParam ? { 'clientTitle': searchWord } : { 'all': searchWord }}
                   fetchParams={isUsingShortcodes && { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode }}
                   isTable={false}
                   isLoading={isLoading}
                   dependencies={searchWord}
                   lineItemTemplate={(row: any) =>
                      <div key={row.id} className="col-md-6 col-sm-6 mb-4">
                        <Card
                            cardId={row.index}
                            linkTitle={row.clientTitle}
                            onLink={()=> toClientProfile(row.clientId)}
                            onShare={() => onShare(row)}
                            title={row.title}
                            description={row.description}
                            imageUrl={row.asset ? `${c.API_ENDPOINTS.ASSET_FILE}/${row.asset}` : `${c.API_ENDPOINTS.ASSET_FILE}/${row.primaryLogo}`}
                            secondaryLogo={row.secondaryLogo ? `${c.API_ENDPOINTS.ASSET_FILE}/${row.secondaryLogo}` : `${c.API_ENDPOINTS.ASSET_FILE}/${row.primaryLogo}`}
                            onClick={() => onNavigate(row.id)}
                            onStatusClick={() => setStatusModal(toNumber(userRole?.accessLevel), row.status, row.createdBy == user?.id, row.id)}
                            status={(user && userRole && userRole?.accessLevel <= 1000 && belongsToMyClient(user, row.clientId)) && row.visibility}
                            cardType={CardType.MARKETPLACE}
                            // metaText={`This entry has this number of matches
                            //  ${countMatches(row, fieldsToMatch, searchWord)}`}
                        />
                      </div>
          }
        />
        <Modal show={openStatusModal} title={'Choose Status'} onHide={onDismissStatusModal}>
          {
              (accessLevelModal < 2000 || isMadeByMeModal) &&
              <div><DropDown placeholder="Marketplace Status" type="default" items={itemsStatus}
                             value={statusModal} onSelect={(e) => onStatusSelect(e.value, marketplaceIdModal)}/>
              </div>
          }
        </Modal>
          {fetchFailed && (
              <p>Failed to fetch marketplaces.</p>
          )}
      </View>
  );


}

export default MarketplaceRenderer;
