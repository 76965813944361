import { ApiDataModel, FormError } from './Common';
import { LeadStatus } from '../helpers/constants';

export const FETCH_LEADS_REQUEST = 'FETCH_LEADS_REQUEST';
export const FETCH_LEADS_RESPONSE = 'FETCH_LEADS_RESPONSE';
export const FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE';

export const FETCH_LEAD_REQUEST = 'FETCH_LEAD_REQUEST';
export const FETCH_LEAD_RESPONSE = 'FETCH_LEAD_RESPONSE';
export const FETCH_LEAD_FAILURE = 'FETCH_LEAD_FAILURE';

export const POST_LEAD_REQUEST = 'POST_LEAD_REQUEST';
export const POST_LEAD_RESPONSE = 'POST_LEAD_RESPONSE';
export const POST_LEAD_FAILURE = 'POST_LEAD_FAILURE';

export interface Lead {
  id: string;
  groupId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  companyCommercialNumber: string; // or chamberOfCommerceNumber
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  roleInCompany: string;
  city: string;
  country: string;
  termsAccepted: boolean;
  verification: LeadStatus,
  transaction: LeadStatus,
  agreement: LeadStatus,
  onboarded: LeadStatus,
  created: Date;
  updated: Date;
  updatedBy: string;
  archived: Date;
  archivedBy: string;
  converted: Date;
  convertedBy: string;
}

export interface LeadDataModel extends Omit<ApiDataModel, 'data'> {
  data: Lead[];
}

export interface LeadState {
  leads: LeadDataModel | null,
  activeLead: Lead | null,
  isFetchingLeads: boolean;
  fetchingLeadsFailed: boolean;
  postingLeadFailed?: boolean;
  isPostingLead: boolean;
  postingLeadValidationErrors: FormError[] | null;
  postingLeadError: string | null;
}

interface FetchLeadsRequestAction {
  type: typeof FETCH_LEADS_REQUEST;
  payload: null;
}

interface FetchLeadsResponseAction {
  type: typeof FETCH_LEADS_RESPONSE;
  payload: LeadDataModel;
}

interface FetchLeadsFailureAction {
  type: typeof FETCH_LEADS_FAILURE;
  payload: null;
}

interface FetchLeadRequestAction {
  type: typeof FETCH_LEAD_REQUEST;
  payload: null;
}

interface FetchLeadResponseAction {
  type: typeof FETCH_LEAD_RESPONSE;
  payload: Lead;
}

interface FetchLeadFailureAction {
  type: typeof FETCH_LEAD_FAILURE;
  payload: null;
}

interface PostLeadRequestAction {
  type: typeof POST_LEAD_REQUEST;
  payload: null;
}

interface PostLeadResponseAction {
  type: typeof POST_LEAD_RESPONSE;
  payload: null;
}

interface PostLeadFailureAction {
  type: typeof POST_LEAD_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type LeadActionTypes =
    | FetchLeadsRequestAction
    | FetchLeadsResponseAction
    | FetchLeadsFailureAction
    | PostLeadRequestAction
    | PostLeadResponseAction
    | PostLeadFailureAction
    | FetchLeadRequestAction
    | FetchLeadResponseAction
    | FetchLeadFailureAction;
