import {
  FETCH_LEAD_FAILURE,
  FETCH_LEAD_REQUEST,
  FETCH_LEAD_RESPONSE,
  FETCH_LEADS_FAILURE,
  FETCH_LEADS_REQUEST,
  FETCH_LEADS_RESPONSE,
  POST_LEAD_FAILURE,
  POST_LEAD_RESPONSE,
  LeadActionTypes,
  LeadState,
  POST_LEAD_REQUEST,
} from '../types/Lead';
import { parseValidationErrors } from '../helpers/functions';

const initialState: LeadState = {
  leads: null,
  activeLead: null,
  isFetchingLeads: false,
  fetchingLeadsFailed: false,
  isPostingLead: false,
  postingLeadFailed: false,
  postingLeadError: null,
  postingLeadValidationErrors: null,
};

const leadsReducer = (
  state = initialState,
  action: LeadActionTypes,
): LeadState => {
  switch (action.type) {
    case FETCH_LEADS_REQUEST:
      return {
        ...state,
        leads: null,
        isFetchingLeads: true,
        fetchingLeadsFailed: false,
      };
    case FETCH_LEADS_RESPONSE:
      return {
        ...state,
        leads: action.payload || [],
        isFetchingLeads: false,
        fetchingLeadsFailed: false,
      };
    case FETCH_LEADS_FAILURE:
      return {
        ...state,
        leads: null,
        isFetchingLeads: false,
        fetchingLeadsFailed: true,
      };
    case FETCH_LEAD_REQUEST:
      return {
        ...state,
        leads: null,
        isFetchingLeads: true,
        fetchingLeadsFailed: false,
      };
    case FETCH_LEAD_RESPONSE:
      return {
        ...state,
        activeLead: action.payload,
        isFetchingLeads: false,
        fetchingLeadsFailed: false,
      };
    case FETCH_LEAD_FAILURE:
      return {
        ...state,
        leads: null,
        isFetchingLeads: false,
        fetchingLeadsFailed: true,
      };
    case POST_LEAD_REQUEST:
      return {
        ...state,
        isPostingLead: true,
        postingLeadError: null,
        postingLeadValidationErrors: null,
        postingLeadFailed: false,
      };
    case POST_LEAD_RESPONSE:
      return {
        ...state,
        isPostingLead: false,
        postingLeadError: null,
        postingLeadValidationErrors: null,
        postingLeadFailed: false,
      };
    case POST_LEAD_FAILURE:
      return {
        ...state,
        isPostingLead: false,
        postingLeadError: action.payload.error || 'An error occurred',
        postingLeadValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingLeadFailed: true,
      };
    default:
      return state;
  }
};

export default leadsReducer;
