import { useRouteMatch } from 'react-router-dom';

interface MatchParams {
  groupShortcode: string;
  clientShortcode: string;
  id: string;
}

const useShortcodes = (shortcodeRoute: string, fallbackRoute: string) => {
  let matchUpdate = useRouteMatch<MatchParams>(shortcodeRoute);
  const { groupShortcode = '', clientShortcode = '' } = matchUpdate?.params || {};

  const isValidShortcode = (shortcode?: string) => {
    return shortcode && shortcode.length === 12;
  };

  const isUsingShortcodes = (isValidShortcode(groupShortcode) && isValidShortcode(clientShortcode)) || false;

  if (!isUsingShortcodes) {
    matchUpdate = useRouteMatch(fallbackRoute);
  }

  const { id = '' } = matchUpdate?.params || {};
  const routeId = id;

  const isExact = matchUpdate?.isExact || false;

  return {
    routeId,
    isExact,
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } as const;
};

export default useShortcodes;
