import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getSolutionOutputTags, isFetchingSolutionOutputTags } from '../../../selectors/SolutionOutputTag';
import { fetchSolutionOutputTags, storeSolutionOutputTag } from '../../../actions/SolutionOutputTag';
import { SolutionOutputTag } from '../../../types/SolutionOutputTag';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const SolutionOutputTagDataTable = () => {

  const solutionOutputTags = useSelector(getSolutionOutputTags);
  const isLoading = useSelector(isFetchingSolutionOutputTags);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [solutionOutputTag, setActiveSolutionOutputTag] = useState<SolutionOutputTag | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SOLUTION_OUTPUT_TAG_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SOLUTION_OUTPUT_TAG_UPDATE, row.id));
  };

  const onArchive = (row: SolutionOutputTag) => {
    setActiveSolutionOutputTag(row);
    setToggle(true);
  };

  const onConfirmArchive = () => {
    setToggle(false);
    if (solutionOutputTag?.id) {
      dispatch(storeSolutionOutputTag(solutionOutputTag?.id));
      setRefetch(!refetch);
    }
    setActiveSolutionOutputTag(null);
  };

  const config = {
    columns: [
      { key: 'solutionTitle', label: 'Solution', mobileFriendly: true },
      { key: 'tagValue', label: 'Tag', mobileFriendly: true },
      { key: 'tagType', label: 'Tag Type', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="SolutionOutputTags" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchSolutionOutputTags} paging={solutionOutputTags?.paging || []}
                   isLoading={isLoading} data={solutionOutputTags?.data || []} config={config}
                   dependencies={refetch} />
        <Modal show={toggle} title="Archive SolutionOutputTag" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this solutionOutputTag?
        </Modal>
      </View>
  );
};

export default SolutionOutputTagDataTable;

