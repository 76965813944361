import React, { useEffect, useState } from 'react';
import View from '../../../components/View/View';
import Card from '../../../components/Card/Card';
import c, { marketplaceCreateSteps, marketplaceEditSteps } from '../../../helpers/constants';
import { fetchEntries, fetchEntryIdsByMarketplace } from '../../../actions/Entry';
import { useDispatch, useSelector } from 'react-redux';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { formatUrl, getActiveClient } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { Alert } from 'react-bootstrap';
import Stepper from '../../../components/Stepper';
import {
  getMarketplaceEntries,
  isFetchingMarketplaceEntries,
  isPostingMarketplaceEntry,
  postingMarketplaceEntryError,
  postingMarketplaceEntryFailed,
} from '../../../selectors/MarketplaceEntry';
import { getActiveMarketplace } from '../../../selectors/Marketplace';
import { createMarketplaceWithItems, fetchActiveMarketplace } from '../../../actions/Marketplace';
import { useEditMode } from '../../../hooks/EditModeHook';
import Button from '../../../components/Button';
import { createOrUpdateMarketplaceEntry } from '../../../actions/MarketplaceEntry';
import './MarketplaceWizard.scss';
import DataTable from '../../../components/DataTable';
import { useContextProvider } from '../../../providers/ContextProvider';
import { Entry } from '../../../types/Entry';

const StepTwo = () => {

  const dispatch = useDispatch();
  const entries = useSelector(getEntries);
  const history = useHistory();
  const isPosting = useSelector(isPostingMarketplaceEntry);
  const isLoadingMarketplaceEntries = useSelector(isFetchingMarketplaceEntries);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const activeMarketplace = useSelector(getActiveMarketplace);
  const postingFailed = useSelector(postingMarketplaceEntryFailed);
  const postingError = useSelector(postingMarketplaceEntryError);
  const marketplaceEntries = useSelector(getMarketplaceEntries);
  const isFetching = isLoadingMarketplaceEntries || isLoadingEntries;

  const [selectedEntries, setSelectedEntries] = useState<Entry[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [createMode] = useEditMode(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_TWO);
  const [editMode, activeEditId ] = useEditMode(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_TWO);
  const steps = createMode ? marketplaceCreateSteps : marketplaceEditSteps;
  const clientId = getActiveClient().id;
  const { marketplaceData } = useContextProvider();

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'viewTitle', label: 'View', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  useEffect(() => {
    if (editMode && activeEditId){
      dispatch(fetchActiveMarketplace(activeEditId));
      dispatch(fetchEntryIdsByMarketplace({ fetchParams:{ 'id' : activeEditId } }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (createMode && (!marketplaceData.marketplaceDetails || Object.keys(marketplaceData.marketplaceDetails).length === 0)) {
      history.push(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_ONE);
    }
  }, [marketplaceData.marketplaceDetails]);

  useEffect(() => {
    if (editMode && activeEditId) {
      const selectedEntryObjects = entries?.data?.filter(entry =>
        marketplaceEntries?.data.some(marketplaceEntry => marketplaceEntry.entryId === entry.id),
      ) || [];
      setSelectedEntries(selectedEntryObjects);
    }
  }, [marketplaceEntries, entries, editMode, activeEditId]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      if (createMode){
        history.push(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_THREE);
      } else if (editMode && activeEditId) {
        history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_THREE, activeEditId));
      }
    }
  }, [hasSubmitted, isPosting]);

  const onNavigate = (entryId: string) => {
    const url = formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId);
    window.open(url, '_blank'); // Opens the URL in a new tab
  };

  const handleCardSelect = (entry: Entry) => {
    const isSelected = selectedEntries.some(selectedEntry => selectedEntry.id === entry.id);

    if (isSelected) {
      // Remove the selected entry by filtering it out based on its id
      setSelectedEntries(selectedEntries.filter(selectedEntry => selectedEntry.id !== entry.id));
    } else {
      // Add the full Entry object to selectedEntryIds
      setSelectedEntries([...selectedEntries, entry]);
    }
  };

  const handleSelectAll = (select: boolean) => {
    if (select && entries?.data) {
      setSelectedEntries(entries.data);
      setSelectAll(true);
    } else {
      setSelectedEntries([]);
      setSelectAll(false);
    }
  };


  const onSubmit = async () => {
    if (activeMarketplace && editMode && activeEditId) {
      setHasSubmitted(true);
      const selectedEntryIds = selectedEntries.map(entry => entry.id);
      dispatch(createOrUpdateMarketplaceEntry(activeMarketplace.id, selectedEntryIds));
    } else {
      setHasSubmitted(true);
      dispatch(createMarketplaceWithItems(marketplaceData.marketplaceDetails, selectedEntries));
    }
  };

  return (
        <View title='Select Items For Marketplace'>
            <div className="container-fluid">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                <div className="row justify-content-center mb-4">
                    <div className="col-8">
                        <Stepper steps={steps} activeStep={createMode ? 1 : 0} hideMobileLabels={true}/>
                    </div>
                </div>

                <div className="mx-4">
                    <div className='row'>
                        <div className="col-md-12 marketplace-wizard__box--grey">
                            <div className='row'>
                                <div className='row my-4 justify-content-end'>
                                    <div className="col-10 col-md-3 align-items-center justify-content-center">
                                        <CheckBox label='Select All Items' type='checkbox' checked={selectAll} onChange={handleSelectAll}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <DataTable
                                        paging={entries?.paging}
                                        isLoading={isFetching}
                                        data={entries?.data}
                                        config={config}
                                        fetchFunction={fetchEntries}
                                        baseSearch={{ 'clientId': clientId }}
                                        isTable={false}
                                        lineItemTemplate={(row: any) => (
                                            <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                                                <Card
                                                    itemId={row.id}
                                                    cardId={row.index}
                                                    title={row.title}
                                                    description={row.description}
                                                    imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                                                    onClick={() => onNavigate(row.id)}
                                                    selectable={true}
                                                    onSelect={() => handleCardSelect(row)}
                                                    isSelected={selectedEntries.some(selectedEntry => selectedEntry.id === row.id)}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <Button disabled={selectedEntries.length < 1} isLoading={isPosting || isFetching}
                                onClick={() =>  onSubmit()}
                                title={ 'Next'}/>
                    </div>
                </div>
            </div>
        </View>
  );
};

export default StepTwo;

