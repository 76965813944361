import { RootState } from '../store/configureStore';

export const getFields = (state: RootState) => state?.field?.fields;

export const getActiveField = (state: RootState) => state?.field?.activeField;

export const isFetchingFields = (state: RootState) => state?.field?.isFetchingFields;

export const isPostingField = (state: RootState) => state?.field?.isPostingField;
export const postingFieldFailed = (state: RootState) => state?.field?.postingFieldFailed;
export const postingFieldError = (state: RootState) => state?.field?.postingFieldError;
export const postingFieldValidationErrors = (state: RootState) => state?.field?.postingFieldValidationErrors;
export const fetchingFieldsFailed = (state: RootState) => state?.field?.fetchingFieldsFailed;
