import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getSolutionInputTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_INPUT_TAG}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getSolutionInputTags(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION_INPUT_TAG,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postSolutionInputTag(solutionId: string, tagId: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION_INPUT_TAG,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        solutionId,
        tagId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putSolutionInputTag(
  id: string,
  solutionId: string,
  tagId: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_INPUT_TAG}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        solutionId,
        tagId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveSolutionInputTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_INPUT_TAG}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

