import { ApiDataModel, FormError } from './Common';

export const FETCH_OFFER_PROJECT_MANAGERS_REQUEST = 'FETCH_OFFER_PROJECT_MANAGERS_REQUEST';
export const FETCH_OFFER_PROJECT_MANAGERS_RESPONSE = 'FETCH_OFFER_PROJECT_MANAGERS_RESPONSE';
export const FETCH_OFFER_PROJECT_MANAGERS_FAILURE = 'FETCH_OFFER_PROJECT_MANAGERS_FAILURE';

export const FETCH_OFFER_PROJECT_MANAGER_REQUEST = 'FETCH_OFFER_PROJECT_MANAGER_REQUEST';
export const FETCH_OFFER_PROJECT_MANAGER_RESPONSE = 'FETCH_OFFER_PROJECT_MANAGER_RESPONSE';
export const FETCH_OFFER_PROJECT_MANAGER_FAILURE = 'FETCH_OFFER_PROJECT_MANAGER_FAILURE';

export const POST_OFFER_PROJECT_MANAGER_REQUEST = 'POST_OFFER_PROJECT_MANAGER_REQUEST';
export const POST_OFFER_PROJECT_MANAGER_RESPONSE = 'POST_OFFER_PROJECT_MANAGER_RESPONSE';
export const POST_OFFER_PROJECT_MANAGER_FAILURE = 'POST_OFFER_PROJECT_MANAGER_FAILURE';

export const POST_OFFER_PROJECT_MANAGERS_REQUEST = 'POST_OFFER_PROJECT_MANAGERS_REQUEST';
export const POST_OFFER_PROJECT_MANAGERS_RESPONSE = 'POST_OFFER_PROJECT_MANAGERS_RESPONSE';
export const POST_OFFER_PROJECT_MANAGERS_FAILURE = 'POST_OFFER_PROJECT_MANAGERS_FAILURE';

export interface OfferProjectManager {
  id: string;
  offerId: string;
  projectManagerId: string;
  firstName: string;
  lastName: string;
}

export interface OfferProjectManagerDataModel extends Omit<ApiDataModel, 'data'> {
  data: OfferProjectManager[];
}

export interface OfferProjectManagerState {
  offerProjectManagers: OfferProjectManagerDataModel | null,
  activeOfferProjectManager: OfferProjectManager | null,
  isFetchingOfferProjectManagers: boolean;
  fetchingOfferProjectManagersFailed: boolean;
  postingOfferProjectManagerFailed: boolean;
  isPostingOfferProjectManager: boolean;
  postingOfferProjectManagerValidationErrors: FormError[] | null;
  postingOfferProjectManagerError: string | null;
  postingOfferProjectManagersFailed: boolean;
  isPostingOfferProjectManagers: boolean;
  postingOfferProjectManagersValidationErrors: FormError[] | null;
  postingOfferProjectManagersError: string | null;
}

interface FetchOfferProjectManagersRequestAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGERS_REQUEST;
  payload: null;
}

interface FetchOfferProjectManagersResponseAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGERS_RESPONSE;
  payload: OfferProjectManagerDataModel;
}

interface FetchOfferProjectManagersFailureAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGERS_FAILURE;
  payload: null;
}

interface FetchOfferProjectManagerRequestAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGER_REQUEST;
  payload: null;
}

interface FetchOfferProjectManagerResponseAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGER_RESPONSE;
  payload: OfferProjectManager;
}

interface FetchOfferProjectManagerFailureAction {
  type: typeof FETCH_OFFER_PROJECT_MANAGER_FAILURE;
  payload: null;
}

interface PostOfferProjectManagerRequestAction {
  type: typeof POST_OFFER_PROJECT_MANAGER_REQUEST;
  payload: null;
}

interface PostOfferProjectManagerResponseAction {
  type: typeof POST_OFFER_PROJECT_MANAGER_RESPONSE;
  payload: null;
}

interface PostOfferProjectManagerFailureAction {
  type: typeof POST_OFFER_PROJECT_MANAGER_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

interface PostOfferProjectManagersRequestAction {
  type: typeof POST_OFFER_PROJECT_MANAGERS_REQUEST;
  payload: null;
}

interface PostOfferProjectManagersResponseAction {
  type: typeof POST_OFFER_PROJECT_MANAGERS_RESPONSE;
  payload: null;
}

interface PostOfferProjectManagersFailureAction {
  type: typeof POST_OFFER_PROJECT_MANAGERS_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type OfferProjectManagerActionTypes =
    | FetchOfferProjectManagersRequestAction
    | FetchOfferProjectManagersResponseAction
    | FetchOfferProjectManagersFailureAction
    | PostOfferProjectManagerRequestAction
    | PostOfferProjectManagerResponseAction
    | PostOfferProjectManagerFailureAction
    | PostOfferProjectManagersRequestAction
    | PostOfferProjectManagersResponseAction
    | PostOfferProjectManagersFailureAction
    | FetchOfferProjectManagerRequestAction
    | FetchOfferProjectManagerResponseAction
    | FetchOfferProjectManagerFailureAction;
