import React from 'react';
import DropDown from '../../components/DropDown';
import { Item } from '../DropDown/DropDown';
import Pill from '../../components/Pill';

interface MultiSelectWithPillsProps {
  id: string;
  placeholder: string;
  value: string;
  label: string;
  items: Item[];
  selectedItems: { id: string; label: string }[];
  onSelect: (selectedItem: Item) => void;
  onUnselect: (id: string, label: string) => void;
  disabled?: boolean;
  v?: any;
  err?: any;
}

const MultiSelectWithPills: React.FC<MultiSelectWithPillsProps> = ({
  id,
  placeholder,
  label,
  items,
  selectedItems,
  onSelect,
  onUnselect,
  disabled = false,
  v,
  err,
  value,
}) => {
  const handleSelect = (item: Item) => {
    // Optional chaining to ensure label is defined before using it
    if (item != undefined && item.label) {
      onSelect(item);
    }
  };

  const handleUnselect = (selectedId: string, selectedLabel: string) => {
    onUnselect(selectedId, selectedLabel);
  };

  return (
        <div>
            <DropDown
                v={v}
                err={err}
                id={id}
                placeholder={placeholder}
                label={label}
                items={items}
                disabled={disabled}
                value={value}
                onSelect={handleSelect}
                type="default"
            />
            {selectedItems.length > 0 && (
                <div className="category__container__pill-container">
                    {selectedItems.map(e => (
                        <Pill
                            key={e.id}
                            text={e.label}
                            type="white"
                            cancellable
                            onClick={() => handleUnselect(e.id, e.label)}
                        />
                    ))}
                </div>
            )}
        </div>
  );
};

export default MultiSelectWithPills;
