import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_MATERIALSFUNCTIONS_REQUEST,
  FETCH_MATERIALSFUNCTIONS_RESPONSE,
  FETCH_MATERIALSFUNCTIONS_FAILURE,
  FETCH_MATERIALSFUNCTION_REQUEST,
  FETCH_MATERIALSFUNCTION_RESPONSE,
  FETCH_MATERIALSFUNCTION_FAILURE,
  POST_MATERIALSFUNCTION_FAILURE,
  POST_MATERIALSFUNCTION_REQUEST,
  POST_MATERIALSFUNCTION_RESPONSE,
  MaterialsFunctionActionTypes, MaterialsFunctionDataModel, MaterialsFunction,
} from '../types/MaterialsFunction';

import { RootState } from '../store/configureStore';

import { archiveMaterialsFunction, getMaterialsFunction, getMaterialsFunctions, postMaterialsFunction, putMaterialsFunction } from '../api/MaterialsFunction';
import { DataTableParamsModel } from '../types/Common';

export function fetchMaterialsFunctionsRequest(): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTIONS_REQUEST,
    payload: null,
  };
}

export function fetchMaterialsFunctionsResponse(
  materialsFunctions: MaterialsFunctionDataModel,
): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTIONS_RESPONSE,
    payload: materialsFunctions,
  };
}

export function fetchMaterialsFunctionsFailure(): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTIONS_FAILURE,
    payload: null,
  };
}

export function fetchMaterialsFunctionRequest(): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTION_REQUEST,
    payload: null,
  };
}

export function fetchMaterialsFunctionResponse(
  materialsFunction: MaterialsFunction,
): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTION_RESPONSE,
    payload: materialsFunction,
  };
}

export function fetchMaterialsFunctionFailure(): MaterialsFunctionActionTypes {
  return {
    type: FETCH_MATERIALSFUNCTION_FAILURE,
    payload: null,
  };
}


export function postMaterialsFunctionRequest(): MaterialsFunctionActionTypes {
  return {
    type: POST_MATERIALSFUNCTION_REQUEST,
    payload: null,
  };
}

export function postMaterialsFunctionResponse(): MaterialsFunctionActionTypes {
  return {
    type: POST_MATERIALSFUNCTION_RESPONSE,
    payload: null,
  };
}

export function postMaterialsFunctionFailure(error: string, validationErrors: any): MaterialsFunctionActionTypes {
  return {
    type: POST_MATERIALSFUNCTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveMaterialsFunction = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMaterialsFunctionRequest());
    const asyncResp: any = await getMaterialsFunction(id);
    if (asyncResp?.success) {
      await dispatch(fetchMaterialsFunctionResponse(asyncResp.data));
    } else {
      await dispatch(fetchMaterialsFunctionFailure());
    }
  };

export const fetchMaterialsFunctions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMaterialsFunctionsRequest());
    const asyncResp: any = await getMaterialsFunctions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMaterialsFunctionsResponse(asyncResp));
    } else {
      await dispatch(fetchMaterialsFunctionsFailure());
    }
  };

export const storeMaterialsFunction = async (id: string) => {
  try {
    await archiveMaterialsFunction(id);
  } catch (error) {
    throw error;
  }
};

export const createMaterialsFunction = (
  value:string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMaterialsFunctionRequest());
    const asyncResp: any = await postMaterialsFunction(value, active);
    if (asyncResp?.success) {
      await dispatch(postMaterialsFunctionResponse());
    } else {
      await dispatch(postMaterialsFunctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateMaterialsFunction = (
  id: string,
  value: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMaterialsFunctionRequest());
    const asyncResp: any = await putMaterialsFunction(id, value, active);
    if (asyncResp?.success) {
      await dispatch(postMaterialsFunctionResponse());
    } else {
      await dispatch(postMaterialsFunctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


