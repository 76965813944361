import { RootState } from '../store/configureStore';

export const getMaterialsFunctions = (state: RootState) => state?.materialsFunction?.materialsFunctions;

export const getActiveMaterialsFunction = (state: RootState) => state?.materialsFunction?.activeMaterialsFunction;

export const isFetchingMaterialsFunctions = (state: RootState) => state?.materialsFunction?.isFetchingMaterialsFunctions;

export const isPostingMaterialsFunction = (state: RootState) => state?.materialsFunction?.isPostingMaterialsFunction;
export const postingMaterialsFunctionFailed = (state: RootState) => state?.materialsFunction?.postingMaterialsFunctionFailed;
export const postingMaterialsFunctionError = (state: RootState) => state?.materialsFunction?.postingMaterialsFunctionError;
export const postingMaterialsFunctionValidationErrors = (state: RootState) => state?.materialsFunction?.postingMaterialsFunctionValidationErrors;
export const fetchingMaterialsFunctionsFailed = (state: RootState) => state?.materialsFunction?.fetchingMaterialsFunctionsFailed;