import React from 'react';
import HeadlessView from '../../../components/HeadlessView';


const UserPendingVerification = () => {


  return <HeadlessView title="Register to EXCESS MATERIALS Exchange">
      <div className="container">
          <div className="row">
              <div className="col-12 p-5">
                <h6>Registration complete! Please wait until an administrator verifies your account.</h6>
              </div>
          </div>
      </div>
</HeadlessView>;

};

export default UserPendingVerification;
