import {
  FETCH_WIZARD_FAILURE,
  FETCH_WIZARD_REQUEST,
  FETCH_WIZARD_RESPONSE,
  FETCH_WIZARDS_IMAGE_RESPONSE,
  FETCH_WIZARDS_TEXT_RESPONSE,
  FETCH_WIZARDS_FAILURE,
  FETCH_WIZARDS_REQUEST,
  FETCH_WIZARDS_IMAGE_REQUEST,
  FETCH_WIZARDS_TEXT_REQUEST,
  FETCH_WIZARDS_RESPONSE,
  POST_WIZARD_FAILURE,
  POST_WIZARD_REQUEST,
  POST_WIZARD_RESPONSE,
  WizardActionTypes,
  WizardState, FETCH_VALUATION_INFO_RESPONSE, FETCH_VALUATION_INFO_REQUEST,
  FETCH_XY_GENERATION_RESPONSE, FETCH_XY_GENERATION_REQUEST,
} from '../types/Wizard';
import { parseValidationErrors } from '../helpers/functions';

const initialState: WizardState = {
  wizards: null,
  activeWizard: null,
  textInfo: null,
  imageInfo: null,
  valuationInfo: null,
  xyGeneration: null,
  isFetchingWizards: false,
  fetchingWizardsFailed: false,
  isPostingWizard: false,
  postingWizardFailed: false,
  postingWizardError: null,
  postingWizardValidationErrors: null,
};

const wizardsReducer = (
  state = initialState,
  action: WizardActionTypes,
): WizardState => {
  switch (action.type) {
    case FETCH_WIZARDS_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_IMAGE_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_TEXT_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_VALUATION_INFO_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_XY_GENERATION_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_IMAGE_RESPONSE:
      return {
        ...state,
        imageInfo: action.payload,
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_TEXT_RESPONSE:
      return {
        ...state,
        textInfo: action.payload,
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_VALUATION_INFO_RESPONSE:
      return {
        ...state,
        valuationInfo: action.payload,
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_XY_GENERATION_RESPONSE:
      return {
        ...state,
        xyGeneration: action.payload,
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_RESPONSE:
      return {
        ...state,
        wizards: action.payload || [],
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARDS_FAILURE:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: false,
        fetchingWizardsFailed: true,
      };
    case FETCH_WIZARD_REQUEST:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: true,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARD_RESPONSE:
      return {
        ...state,
        activeWizard: action.payload,
        isFetchingWizards: false,
        fetchingWizardsFailed: false,
      };
    case FETCH_WIZARD_FAILURE:
      return {
        ...state,
        wizards: null,
        isFetchingWizards: false,
        fetchingWizardsFailed: true,
      };
    case POST_WIZARD_REQUEST:
      return {
        ...state,
        isPostingWizard: true,
        postingWizardError: null,
        postingWizardValidationErrors: null,
        postingWizardFailed: false,
      };
    case POST_WIZARD_RESPONSE:
      return {
        ...state,
        isPostingWizard: false,
        postingWizardError: null,
        postingWizardValidationErrors: null,
        postingWizardFailed: false,
      };
    case POST_WIZARD_FAILURE:
      return {
        ...state,
        isPostingWizard: false,
        postingWizardError: action.payload.error || 'An error occurred',
        postingWizardValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingWizardFailed: true,
      };
    default:
      return state;
  }
};

export default wizardsReducer;
