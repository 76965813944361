import React, { useEffect } from 'react';
import View from '../../components/View/View';
import SearchEngineComponent from '../../components/SearchEngine/SearchEngine';
import { formatUrl } from '../../helpers/functions';
import c, { SearchType } from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { useEditMode } from '../../hooks/EditModeHook';
import {
  createOrUpdatePopularSearch,
  fetchPopularSearchesByType,
} from '../../actions/PopularSearch';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularSearches, isFetchingPopularSearches } from '../../selectors/PopularSearch';
import { DataTableParamsModel } from '../../types/Common';
import useShortcodes from '../../hooks/ShortcodeHook';



const SearchEngine = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popularSearches = useSelector(getPopularSearches);
  const isFetchingSearches = useSelector(isFetchingPopularSearches);
  const {
    isUsingShortcodes,
    groupShortcode,
    clientShortcode,
  } = useShortcodes(c.APP_ROUTES.SEARCH_ENGINE_SHORTCODE, c.APP_ROUTES.SEARCH_ENGINE);
  const [hasType, type] = !isUsingShortcodes ? useEditMode(c.APP_ROUTES.SEARCH_ENGINE) : useEditMode(c.APP_ROUTES.SEARCH_ENGINE_SHORTCODE);

  useEffect(() => {
    if (hasType) {
      const dataTableParams: DataTableParamsModel = {
        colKey: 'popularity',
        sortDirection: 'desc',
      };
      if (isUsingShortcodes && groupShortcode && clientShortcode) {
        dataTableParams.fetchParams = {
          groupShortcode: groupShortcode,
          clientShortcode: clientShortcode,
        };
      }
      if (type === SearchType.MARKETPLACE) {
        dispatch(fetchPopularSearchesByType(type, dataTableParams));
      } else if (type === SearchType.AUCTION) {
        dispatch(fetchPopularSearchesByType(type, dataTableParams));
      }
    }
  }, [dispatch, type, groupShortcode, clientShortcode, hasType]);

  const onSearch = (val: string) => {
    if (type === SearchType.MARKETPLACE &&  hasType){
      if (val) {
        dispatch(createOrUpdatePopularSearch(type, val));
      }
      if (isUsingShortcodes && groupShortcode && clientShortcode){
        history.push(formatUrl(c.APP_ROUTES.VIEW_MARKETPLACES_SHORTCODE, groupShortcode, clientShortcode, val));
      } else {
        history.push(formatUrl(c.APP_ROUTES.VIEW_MARKETPLACES, val));
      }
    } else if (type === SearchType.AUCTION && hasType){
      if (val) {
        dispatch(createOrUpdatePopularSearch(type, val));
      }
      history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTIONS, val));
    }
  };

  const returnTitle = () => {
    if (type === SearchType.MARKETPLACE && hasType){
      return 'MARKETPLACE SEARCH ENGINE';
    } else {
      return 'AUCTION SEARCH ENGINE';
    }
  };

  const returnDescription = () => {
    if (type === SearchType.MARKETPLACE && hasType){
      return 'Please use the search bar to look for specific items. Above, select whether you want to search for items in a marketplace.\n';
    } else {
      return 'Please use the search bar to look for specific items. Above, select whether you want to search for items in  an auction.\n';
    }
  };

  return (
        <View isLoading={isFetchingSearches}>
            <div className="search-engine-page">
            <div className="container-fluid">
                <div className="row p-4 justify-content-center align-items-center">
                    <div className="col-12 col-lg-6">
                    <SearchEngineComponent
                        title={returnTitle()}
                        description={returnDescription()}
                        type={type}
                        onSearch={onSearch}
                        popularSearches = {popularSearches?.data}/>
                    </div>
                </div>
            </div>
            </div>
        </View>

  );
};

export default SearchEngine;

