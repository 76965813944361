import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getForms, isFetchingForms } from '../../../selectors/Form';
import { fetchForms, storeForm } from '../../../actions/Form';
import { Form } from '../../../types/Form';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const FormDataTable = () => {

  const forms = useSelector(getForms);
  const isLoading = useSelector(isFetchingForms);
  const [toggle, setToggle] = useState(false);
  const [form, setActiveForm] = useState<Form | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FORM_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FORM_UPDATE, row.id));
  };

  const onArchive = (row: Form) => {
    setActiveForm(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (form?.id) {
      await storeForm(form?.id);
      setRefetch(!refetch);
    }
    setActiveForm(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'title', label: 'Form Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Forms" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchForms} paging={forms?.paging || []}
                 isLoading={isLoading} data={forms?.data || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Form" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this form?
      </Modal>
    </View>
  );
};

export default FormDataTable;

