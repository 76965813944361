import { RootState } from '../store/configureStore';

export const getFieldProperties = (state: RootState) => state?.fieldProperty?.fieldProperties;

export const getActiveFieldProperty = (state: RootState) => state?.fieldProperty?.activeFieldProperty;

export const isFetchingFieldProperties = (state: RootState) => state?.fieldProperty?.isFetchingFieldProperties;

export const isPostingFieldProperty = (state: RootState) => state?.fieldProperty?.isPostingFieldProperty;
export const postingFieldPropertyFailed = (state: RootState) => state?.fieldProperty?.postingFieldPropertyFailed;
export const postingFieldPropertyError = (state: RootState) => state?.fieldProperty?.postingFieldPropertyError;
export const postingFieldPropertyValidationErrors = (state: RootState) => state?.fieldProperty?.postingFieldPropertyValidationErrors;
export const fetchingFieldPropertiesFailed = (state: RootState) => state?.fieldProperty?.fetchingFieldPropertiesFailed;

