import { RootState } from '../store/configureStore';

export const getViewRoles = (state: RootState) => state?.viewRole?.viewRoles;

export const isFetchingViewRoles = (state: RootState) =>
  state?.viewRole?.isFetchingViewRoles;

export const isPostingViewRole = (state: RootState) =>
  state?.viewRole?.isPostingViewRole;
export const postingViewRoleFailed = (state: RootState) =>
  state?.viewRole?.postingViewRoleFailed;
export const postingViewRoleError = (state: RootState) =>
  state?.viewRole?.postingViewRoleError;
export const postingViewRoleValidationErrors = (state: RootState) =>
  state?.viewRole?.postingViewRoleValidationErrors;

export const fetchingViewRolesFailed = (state: RootState) =>
  state?.viewRole?.fetchingViewRolesFailed;
