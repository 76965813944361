import { RootState } from '../store/configureStore';

export const getRoles = (state: RootState) => state?.role?.roles;

export const getActiveRole = (state: RootState) => state?.role?.activeRole;

export const isFetchingRoles = (state: RootState) =>
  state?.role?.isFetchingRoles;

export const isPostingRole = (state: RootState) => state?.role?.isPostingRole;
export const postingRoleFailed = (state: RootState) => state?.role?.postingRoleFailed;
export const postingRoleError = (state: RootState) => state?.role?.postingRoleError;
export const postingRoleValidationErrors = (state: RootState) => state?.role?.postingRoleValidationErrors;


export const fetchingRolesFailed = (state: RootState) =>
  state?.role?.fetchingRolesFailed;
