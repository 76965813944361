import { RootState } from '../store/configureStore';

export const getAuctionBids = (state: RootState) => state?.auctionBid?.auctionBids;

export const getActiveAuctionBid = (state: RootState) => state?.auctionBid?.activeAuctionBid;

export const isFetchingAuctionBids = (state: RootState) => state?.auctionBid?.isFetchingAuctionBids;

export const isPostingAuctionBid = (state: RootState) => state?.auctionBid?.isPostingAuctionBid;
export const postingAuctionBidFailed = (state: RootState) => state?.auctionBid?.postingAuctionBidFailed;
export const postingAuctionBidError = (state: RootState) => state?.auctionBid?.postingAuctionBidError;
export const postingAuctionBidValidationErrors = (state: RootState) => state?.auctionBid?.postingAuctionBidValidationErrors;
export const fetchingAuctionBidsFailed = (state: RootState) => state?.auctionBid?.fetchingAuctionBidsFailed;