import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getSolution(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getSolutions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postSolution(title: string, description: string, location: string, contactNumber: string,
  contactEmail: string, contactPerson: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        location,
        contactNumber,
        contactEmail,
        contactPerson,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putSolution(
  id: string,
  title: string,
  description: string,
  location: string,
  contactNumber: string,
  contactEmail: string,
  contactPerson: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        location,
        contactNumber,
        contactEmail,
        contactPerson,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveSolution(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

