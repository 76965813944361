import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getShadowEntries(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.SHADOW_ENTRY,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getShadowEntry(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SHADOW_ENTRY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postShadowEntry(groupId: string, clientId: string, formId: string, entryId: string, data: string) {
  return (
    axios({
      url: c.API_ENDPOINTS.SHADOW_ENTRY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        entryId,
        data,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putShadowEntry(
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  entryId: string,
  data: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SHADOW_ENTRY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        entryId,
        data,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveShadowEntry(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SHADOW_ENTRY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
