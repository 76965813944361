import React, { useState, useEffect } from 'react';
import './SearchBoxPublic.scss';
import gallery from '../AssetGallery/AssetGallery';
import { SearchType } from '../../helpers/constants';
import { PopularSearch } from '../../types/PopularSearch';
import { removeDuplicates } from '../../helpers/functions';

type Props = {
  type?: any;
  description?: string;
  placeholder?: string;
  onSearch?: (term: string) => void;
  popularSearches?: PopularSearch[] | undefined;
  customStyle?: React.CSSProperties;
  searchQuery?: string;
  isLoadingPopular?:boolean;
};

const SearchBoxPublic = (props: Props) => {
  const {
    placeholder = 'Search',
    type = SearchType.MARKETPLACE,
    onSearch = () => {},
    popularSearches = [],
    customStyle,
    searchQuery,
    isLoadingPopular,
  } = props;

  const [searchText, setSearchText] = useState(searchQuery ? searchQuery : '');

  const uniqueSearchTerms = removeDuplicates(popularSearches?.map(search => search.searchTerm));

  useEffect(() => {
    if (searchQuery) {
      setSearchText(searchQuery);
    }
  }, [searchQuery]);


  const onInnerSearch = (val?: string) => {
    let searchTerm = val ? val : searchText;
    onSearch(searchTerm);
    if (searchTerm) {
      const existingData = JSON.parse(localStorage.getItem('searchData') || '{}');
      const existingListPreFilter = existingData[type] || [];
      const existingList = existingListPreFilter.filter((item: string) => item != searchTerm);
      const updatedList = [searchTerm, ...existingList.slice(0, 3)];
      const updatedData = {
        ...existingData,
        [type]: updatedList,
      };
      localStorage.setItem('searchData', JSON.stringify(updatedData));
    }
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
        <div className="container-fluid search-engine-public">
            <div className="row p-2 justify-content-center align-text-center">
                <div className="col-12 py-2 px-1 text-center custom-input">
                    <input
                        type='text'
                        className='form-control search-input'
                        placeholder={placeholder}
                        value={searchText}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{ ...customStyle }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onInnerSearch();
                          }
                        }}
                    />
                    <img
                        className="search-engine-public__search-icon"
                        src={searchText !== '' ? gallery.closeIcon : gallery.searchImg}
                        alt='search-icon'
                        onClick={() => searchText !== '' ? clearSearchText() : onInnerSearch('')}
                    />
                </div>
                {uniqueSearchTerms.length > 0 && !isLoadingPopular && (
                    <div className="p-1 d-sm-flex d-block">
                        Popular Searches
                        <div className={'d-flex flex-wrap ps-lg-2 popular-search-container '}>
                            {uniqueSearchTerms.map((item, index) => (
                                <div key={index} className="pe-3">
                                    <a
                                        className="link-secondary text-decoration-underline popular_items"
                                        onClick={() => onInnerSearch(item)}
                                    >
                                        {item}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
  );
};

export default SearchBoxPublic;
