import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ASSET}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getAssets(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.ASSET,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postAsset(groupId: string, clientId: string, userId: string, title: string, fileFormData: FormData) {
  fileFormData.append('groupId', groupId);
  fileFormData.append('clientId', clientId);
  fileFormData.append('userId', userId);
  fileFormData.append('title', title);
  return (
    axios({
      url: c.API_ENDPOINTS.ASSET,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
      data: fileFormData,
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ASSET}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
