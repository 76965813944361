import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createOfferProjectManagers } from '../../../actions/OfferProjectManager';
import { getOffers } from '../../../selectors/Offer';
import { fetchOffers } from '../../../actions/Offer';

import {
  postingOfferProjectManagerError,
  postingOfferProjectManagerFailed,
  postingOfferProjectManagerValidationErrors,
  isFetchingOfferProjectManagers, isPostingOfferProjectManagers,
} from '../../../selectors/OfferProjectManager';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { fetchUsers } from '../../../actions/User';
import { fetchContacts } from '../../../actions/Contact';
import { getUsers } from '../../../selectors/User';
import { getContacts } from '../../../selectors/Contact';
import MultiSelectWithPills from '../../../components/DropDownMultiselect/DropDownMultiselect';



const OfferProjectManagerForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const offers = useSelector(getOffers);
  const users = useSelector(getUsers);
  const contacts = useSelector(getContacts);
  const isPosting = useSelector(isPostingOfferProjectManagers);
  const isFetching = useSelector(isFetchingOfferProjectManagers);
  const postingFailed = useSelector(postingOfferProjectManagerFailed);
  const postingError = useSelector(postingOfferProjectManagerError);
  const postingValidationErrors = useSelector(postingOfferProjectManagerValidationErrors);



  const formConfig = [
    { field: 'offerId', validators: [isRequired] },
    { field: 'projectManagerId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [offerId, setOfferId] = useState('');
  const [projectManagerIds, setProjectManagerIds] = useState<any[]>([]);
  const [projectManagerId, setProjectManagerId] = useState('');
  const [itemsUsersContacts, setItemsUsersContacts] = useState<{ label: string; value: string; }[]>([]);

  useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchUsers());
    dispatch(fetchContacts());
  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.OFFER_PROJECT_MANAGER);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      const filteredPMIds = projectManagerIds.map(pm => pm.id);
      dispatch(createOfferProjectManagers(offerId, filteredPMIds));
    }
  };

  const itemsOffers = offers?.data.map(r => ({ label: r.subject, value: r.id })) || [];
  const itemsProjectManagers = [
    ...(users?.data.map(user => ({ label: user.email, value: user.id })) || []),
    ...(contacts?.data.map(contact => ({ label: contact.email, value: contact.id })) || []),
  ];

  const onProjectManagersSelect = (obj: any) => {
    setProjectManagerId(obj.value);
    setItemsUsersContacts(current => current.filter(t => t.label !== obj.label));
    setProjectManagerIds([
      ...projectManagerIds,
      { id: obj.value, email: obj.label },
    ]);
  };
  const onProjectManagersUnselect = (id: any, email: any) => {
    setItemsUsersContacts([...itemsUsersContacts, { label: email, value: id },
    ] );
    setProjectManagerIds([
      ...projectManagerIds.filter(e => e.id != id),
    ]);
  };

  const onOfferSelect = (obj: any) => {
    setOfferId(obj.value);
  };

  return <View>
        <h4>Create OfferProjectManager</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="offerId"
                      placeholder="Please select an Offer"
                      required label="Offer"
                      items={itemsOffers}
                      value={offerId}
                      disabled={isPosting || isFetching}
                      onSelect={onOfferSelect}
                      type="default"
            />
          <MultiSelectWithPills
              v={v} err={err}
              id="projectManagerId"
              placeholder="Please select a ProjectManager"
              label="ProjectManager"
              items={itemsProjectManagers}
              value={projectManagerId}
              selectedItems={projectManagerIds.map(e => ({ id: e.id, label: e.email }))}
              onSelect={onProjectManagersSelect}
              onUnselect={onProjectManagersUnselect}
              disabled={isPosting || isFetching}
          />

            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default OfferProjectManagerForm;
