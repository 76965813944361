import React, { useState, FocusEvent } from 'react';
import './SearchEngine.scss';
import gallery from '../AssetGallery/AssetGallery';
import { SearchType } from '../../helpers/constants';
import Pill from '../Pill/Pill';
import { PopularSearch } from '../../types/PopularSearch';

type Props = {
  title: string;
  type?: any;
  description?: string;
  placeholder?: string;
  onSearch?: (term: string) => void;
  popularSearches?: PopularSearch[] | undefined;
};
const SearchEngineComponent = (props: Props) => {
  const {
    title = '',
    placeholder = 'Search',
    type = SearchType.MARKETPLACE,
    onSearch = () => {},
    description = '',
    popularSearches = [],
  } = props;

  const [searchText, setSearchText] = useState('');

  const onInnerSearch = (val?: string) => {
    let  searchTerm = val ? val : searchText;
    onSearch(searchTerm);
    if (searchTerm) {
      const existingData = JSON.parse(localStorage.getItem('searchData') || '{}');
      const existingListPreFilter = existingData[type] || [];
      const existingList = existingListPreFilter.filter((item: string) => item != searchTerm);
      const updatedList = [searchTerm, ...existingList.slice(0, 3)];
      const updatedData = {
        ...existingData,
        [type]: updatedList,
      };
      localStorage.setItem('searchData', JSON.stringify(updatedData));
    }
  };

  const onChange = (e: FocusEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };


  return (
      <div className="container-fluid search-engine ">
          <div className="row p-2 justify-content-center align-text-center">
              <div className="col-12 p-1 text-center search-engine__title">{title}</div>
              <div className="col-12 p-1 text-start">{description}</div>
              <div className="col-12 py-2 px-1 text-center custom-input">
                  <input type='text'
                         className='form-control search-input'
                         placeholder={placeholder}
                         value={searchText}
                         onChange={onChange}
                         onBlur={onBlur}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                             onInnerSearch();
                           }
                         }}
              />
                  <img className="search-engine__search-icon" src={gallery.searchImg} alt='search-icon' onClick={()=>onInnerSearch('')}/>
              </div>
              <div className="row p-1 d-flex justify-content-start search-engine__pill-box">
                    <div className="col-12 mb-2 search-engine__title-box" >Popular Searches</div>
                    <div className="row justify-content-start align-items-start search-engine__item-box">
                        {popularSearches.map((item, index) =>
                            <div key={index} className="col-auto" ><Pill type={'white'} hoverable={true} onClick={()=>onInnerSearch(item.searchTerm)} icon = {'tag'} text={item.searchTerm}  /></div>,
                        )}
                    </div>
              </div>
          </div>
      </div>
  );
};

export default SearchEngineComponent;

