import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRY_EDGES_REQUEST,
  FETCH_ENTRY_EDGES_RESPONSE,
  FETCH_ENTRY_EDGES_FAILURE,
  FETCH_ENTRY_EDGE_REQUEST,
  FETCH_ENTRY_EDGE_RESPONSE,
  FETCH_ENTRY_EDGE_FAILURE,
  POST_ENTRY_EDGE_FAILURE,
  POST_ENTRY_EDGE_REQUEST,
  POST_ENTRY_EDGE_RESPONSE,
  EntryEdgeActionTypes, EntryEdgeDataModel, EntryEdge,
} from '../types/EntryEdge';

import { RootState } from '../store/configureStore';

import {
  archiveEntryEdge, getConnectionsFrom,
  getConnectionsTo,
  getEntryEdge,
  getEntryEdges, getEntryEdgesByShortcodes,
  postEntryEdge,
  putEntryEdge,
} from '../api/EntryEdge';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntryEdgesRequest(): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGES_REQUEST,
    payload: null,
  };
}

export function fetchEntryEdgesResponse(
  entryEdges: EntryEdgeDataModel,
): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGES_RESPONSE,
    payload: entryEdges,
  };
}

export function fetchEntryEdgesFailure(): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGES_FAILURE,
    payload: null,
  };
}

export function fetchEntryEdgeRequest(): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGE_REQUEST,
    payload: null,
  };
}

export function fetchEntryEdgeResponse(
  entryEdges: EntryEdge,
): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGE_RESPONSE,
    payload: entryEdges,
  };
}

export function fetchEntryEdgeFailure(): EntryEdgeActionTypes {
  return {
    type: FETCH_ENTRY_EDGE_FAILURE,
    payload: null,
  };
}


export function postEntryEdgeRequest(): EntryEdgeActionTypes {
  return {
    type: POST_ENTRY_EDGE_REQUEST,
    payload: null,
  };
}

export function postEntryEdgeResponse(): EntryEdgeActionTypes {
  return {
    type: POST_ENTRY_EDGE_RESPONSE,
    payload: null,
  };
}

export function postEntryEdgeFailure(error: string, validationErrors: any): EntryEdgeActionTypes {
  return {
    type: POST_ENTRY_EDGE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveEntryEdge = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryEdgeRequest());
    const asyncResp: any = await getEntryEdge(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryEdgeResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryEdgeFailure());
    }
  };

export const fetchEntryEdges = (datatableParams?: DataTableParamsModel, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryEdgesRequest());
    const asyncResp: any = await getEntryEdges(datatableParams, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntryEdgesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryEdgesFailure());
    }
  };

export const fetchEntryEdgesByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryEdgesRequest());
    const asyncResp: any = await getEntryEdgesByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchEntryEdgesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryEdgesFailure());
    }
  };

export const fetchConnectionsTo = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryEdgesRequest());
    const asyncResp: any = await getConnectionsTo(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryEdgesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryEdgesFailure());
    }
  };

export const fetchConnectionsFrom = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryEdgesRequest());
    const asyncResp: any = await getConnectionsFrom(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryEdgesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryEdgesFailure());
    }
  };

export const storeEntryEdge = async (id: string) => {
  try {
    await archiveEntryEdge(id);
  } catch (error) {
    throw error;
  }
};

export const createEntryEdge = (
  groupId: string, clientId: string, fromEntryId: string, toEntryId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryEdgeRequest());
    const asyncResp: any = await postEntryEdge(groupId, clientId, fromEntryId, toEntryId, active);
    if (asyncResp?.success) {
      await dispatch(postEntryEdgeResponse());
    } else {
      await dispatch(postEntryEdgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryEdge = (
  id: string,
  groupId: string,
  clientId: string,
  fromEntryId: string,
  toEntryId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryEdgeRequest());
    const asyncResp: any = await putEntryEdge(id, groupId, clientId, fromEntryId, toEntryId, active);
    if (asyncResp?.success) {
      await dispatch(postEntryEdgeResponse());
      await dispatch(fetchEntryEdges());
    } else {
      await dispatch(postEntryEdgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


