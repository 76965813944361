import { RootState } from '../store/configureStore';

export const getClients = (state: RootState) => state?.client?.clients;

export const getClientsData = (state: RootState) => state?.client?.clientsData;

export const getActiveClient = (state: RootState) => state?.client?.activeClient;

export const isFetchingClients = (state: RootState) => state?.client?.isFetchingClients;

export const isPostingClient = (state: RootState) => state?.client?.isPostingClient;
export const postingClientFailed = (state: RootState) => state?.client?.postingClientFailed;
export const postingClientError = (state: RootState) => state?.client?.postingClientError;
export const postingClientValidationErrors = (state: RootState) => state?.client?.postingClientValidationErrors;
export const fetchingClientsFailed = (state: RootState) => state?.client?.fetchingClientsFailed;