import { ApiDataModel, FormError } from './Common';

export const FETCH_WIZARDS_REQUEST = 'FETCH_WIZARDS_REQUEST';
export const FETCH_WIZARDS_IMAGE_REQUEST = 'FETCH_WIZARDS_IMAGE_REQUEST';
export const FETCH_WIZARDS_TEXT_REQUEST = 'FETCH_WIZARDS_TEXT_REQUEST';
export const FETCH_VALUATION_INFO_REQUEST = 'FETCH_WIZARDS_TEXT_REQUEST';
export const FETCH_XY_GENERATION_REQUEST = 'FETCH_XY_GENERATION_REQUEST';


export const FETCH_WIZARDS_RESPONSE = 'FETCH_WIZARDS_RESPONSE';
export const FETCH_WIZARDS_IMAGE_RESPONSE = 'FETCH_WIZARDS_IMAGE_RESPONSE';
export const FETCH_WIZARDS_TEXT_RESPONSE = 'FETCH_WIZARDS_TEXT_RESPONSE';
export const FETCH_VALUATION_INFO_RESPONSE = 'FETCH_VALUATION_INFO_RESPONSE';
export const FETCH_XY_GENERATION_RESPONSE = 'FETCH_XY_GENERATION_RESPONSE';


export const FETCH_WIZARDS_FAILURE = 'FETCH_WIZARDS_FAILURE';

export const FETCH_WIZARD_REQUEST = 'FETCH_WIZARD_REQUEST';
export const FETCH_WIZARD_RESPONSE = 'FETCH_WIZARD_RESPONSE';
export const FETCH_WIZARD_FAILURE = 'FETCH_WIZARD_FAILURE';

export const POST_WIZARD_REQUEST = 'POST_WIZARD_REQUEST';
export const POST_WIZARD_RESPONSE = 'POST_WIZARD_RESPONSE';
export const POST_WIZARD_FAILURE = 'POST_WIZARD_FAILURE';

export interface WizardTag {
  usages: string,
  functionalities: string,
  materials: string,
  components: string,
  imageMaterials: string,
}

export interface TextInfo {
  description: string;
  usages: string[],
  functionalities: string[],
  materials: string[],
  components: string[],
}

export interface ValuationInfo {
  environmental: string,
  financial: string,
  social: string,
  legal: string[],
}

export interface XYGeneration {
  x: number,
  y: number,
}

export interface WizardDataModel extends Omit<ApiDataModel, 'data'> {
  data: WizardTag[];
}

export interface WizardState {
  wizards: WizardDataModel | null,
  activeWizard: WizardTag | null,
  imageInfo: [] | null,
  textInfo: TextInfo | null,
  valuationInfo : ValuationInfo | null,
  xyGeneration: XYGeneration | null,
  isFetchingWizards: boolean;
  fetchingWizardsFailed: boolean;
  postingWizardFailed?: boolean;
  isPostingWizard: boolean;
  postingWizardValidationErrors: FormError[] | null;
  postingWizardError: string | null;
}

interface FetchWizardsRequestAction {
  type: typeof FETCH_WIZARDS_REQUEST;
  payload: null;
}

interface FetchWizardsResponseAction {
  type: typeof FETCH_WIZARDS_RESPONSE;
  payload: WizardDataModel;
}

interface FetchWizardsResponseTextAction {
  type: typeof FETCH_WIZARDS_TEXT_RESPONSE;
  payload: TextInfo;
}

interface FetchWizardsRequestTextAction {
  type: typeof FETCH_WIZARDS_TEXT_REQUEST;
  payload: null;
}

interface FetchValuationInfoResponseTextAction {
  type: typeof FETCH_VALUATION_INFO_RESPONSE;
  payload: ValuationInfo;
}

interface FetchValuationInfoRequestTextAction {
  type: typeof FETCH_VALUATION_INFO_REQUEST;
  payload: null;
}

interface FetchXYGenerationResponseAction {
  type: typeof FETCH_XY_GENERATION_RESPONSE;
  payload: XYGeneration;
}

interface FetchXYGenerationRequestAction {
  type: typeof FETCH_XY_GENERATION_REQUEST;
  payload: null;
}

interface FetchValuationInfoRequestTextAction {
  type: typeof FETCH_VALUATION_INFO_REQUEST;
  payload: null;
}

interface FetchWizardsResponseImageAction {
  type: typeof FETCH_WIZARDS_IMAGE_RESPONSE;
  payload: [];
}

interface FetchWizardsRequestImageAction {
  type: typeof FETCH_WIZARDS_IMAGE_REQUEST;
  payload: null;
}

interface FetchWizardsFailureAction {
  type: typeof FETCH_WIZARDS_FAILURE;
  payload: null;
}

interface FetchWizardRequestAction {
  type: typeof FETCH_WIZARD_REQUEST;
  payload: null;
}

interface FetchWizardResponseAction {
  type: typeof FETCH_WIZARD_RESPONSE;
  payload: WizardTag;
}

interface FetchWizardFailureAction {
  type: typeof FETCH_WIZARD_FAILURE;
  payload: null;
}

interface PostWizardRequestAction {
  type: typeof POST_WIZARD_REQUEST;
  payload: null;
}

interface PostWizardResponseAction {
  type: typeof POST_WIZARD_RESPONSE;
  payload: null;
}

interface PostWizardFailureAction {
  type: typeof POST_WIZARD_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type WizardActionTypes =
    | FetchWizardsRequestAction
    | FetchWizardsRequestTextAction
    | FetchWizardsRequestImageAction
    | FetchValuationInfoRequestTextAction
    | FetchXYGenerationRequestAction
    | FetchWizardsResponseAction
    | FetchWizardsResponseImageAction
    | FetchWizardsResponseTextAction
    | FetchValuationInfoResponseTextAction
    | FetchXYGenerationResponseAction
    | FetchWizardsFailureAction
    | PostWizardRequestAction
    | PostWizardResponseAction
    | PostWizardFailureAction
    | FetchWizardRequestAction
    | FetchWizardResponseAction
    | FetchWizardFailureAction;
