import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_RESPONSE,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_RESPONSE,
  FETCH_GROUP_FAILURE,
  POST_GROUP_FAILURE,
  POST_GROUP_REQUEST,
  POST_GROUP_RESPONSE,
  GroupActionTypes, GroupDataModel, Group,
} from '../types/Group';

import { RootState } from '../store/configureStore';

import { getGroup, getGroups, postGroup, putGroup, archiveGroup } from '../api/Group';
import { DataTableParamsModel } from '../types/Common';

export function fetchGroupsRequest(): GroupActionTypes {
  return {
    type: FETCH_GROUPS_REQUEST,
    payload: null,
  };
}

export function fetchGroupsResponse(
  groups: GroupDataModel,
): GroupActionTypes {
  return {
    type: FETCH_GROUPS_RESPONSE,
    payload: groups,
  };
}

export function fetchGroupsFailure(): GroupActionTypes {
  return {
    type: FETCH_GROUPS_FAILURE,
    payload: null,
  };
}

export function fetchGroupRequest(): GroupActionTypes {
  return {
    type: FETCH_GROUP_REQUEST,
    payload: null,
  };
}

export function fetchGroupResponse(
  group: Group,
): GroupActionTypes {
  return {
    type: FETCH_GROUP_RESPONSE,
    payload: group,
  };
}

export function fetchGroupFailure(): GroupActionTypes {
  return {
    type: FETCH_GROUP_FAILURE,
    payload: null,
  };
}

export const fetchActiveGroup = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchGroupRequest());
    const asyncResp: any = await getGroup(id);
    if (asyncResp?.success) {
      await dispatch(fetchGroupResponse(asyncResp.data));
    } else {
      await dispatch(fetchGroupFailure());
    }
  };

export function postGroupRequest(): GroupActionTypes {
  return {
    type: POST_GROUP_REQUEST,
    payload: null,
  };
}

export function postGroupResponse(): GroupActionTypes {
  return {
    type: POST_GROUP_RESPONSE,
    payload: null,
  };
}

export function postGroupFailure(error: string, validationErrors: any): GroupActionTypes {
  return {
    type: POST_GROUP_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchGroups = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchGroupsRequest());
    const asyncResp: any = await getGroups(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchGroupsResponse(asyncResp));
    } else {
      await dispatch(fetchGroupsFailure());
    }
  };


export const createGroup = (
  title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postGroupRequest());
    const asyncResp: any = await postGroup(title, description, active);
    if (asyncResp?.success) {
      await dispatch(postGroupResponse());
    } else {
      await dispatch(postGroupFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateGroup = (
  id: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postGroupRequest());
    const asyncResp: any = await putGroup(id, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postGroupResponse());
    } else {
      await dispatch(postGroupFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeGroup = async (id: string) => {
  try {
    await archiveGroup(id);
  } catch (error) {
    throw error;
  }
};
