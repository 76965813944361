import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_RESPONSE,
  FETCH_FORMS_FAILURE,
  FETCH_FORM_REQUEST,
  FETCH_FORM_RESPONSE,
  FETCH_FORM_FAILURE,
  POST_FORM_FAILURE,
  POST_FORM_REQUEST,
  POST_FORM_RESPONSE,
  FormActionTypes, FormDataModel, Form,
} from '../types/Form';

import { RootState } from '../store/configureStore';

import { archiveForm, getForm, getForms, postForm, putForm } from '../api/Form';
import { DataTableParamsModel } from '../types/Common';

export function fetchFormsRequest(): FormActionTypes {
  return {
    type: FETCH_FORMS_REQUEST,
    payload: null,
  };
}

export function fetchFormsResponse(
  forms: FormDataModel,
): FormActionTypes {
  return {
    type: FETCH_FORMS_RESPONSE,
    payload: forms,
  };
}

export function fetchFormsFailure(): FormActionTypes {
  return {
    type: FETCH_FORMS_FAILURE,
    payload: null,
  };
}

export function fetchFormRequest(): FormActionTypes {
  return {
    type: FETCH_FORM_REQUEST,
    payload: null,
  };
}

export function fetchFormResponse(
  forms: Form,
): FormActionTypes {
  return {
    type: FETCH_FORM_RESPONSE,
    payload: forms,
  };
}

export function fetchFormFailure(): FormActionTypes {
  return {
    type: FETCH_FORM_FAILURE,
    payload: null,
  };
}


export function postFormRequest(): FormActionTypes {
  return {
    type: POST_FORM_REQUEST,
    payload: null,
  };
}

export function postFormResponse(): FormActionTypes {
  return {
    type: POST_FORM_RESPONSE,
    payload: null,
  };
}

export function postFormFailure(error: string, validationErrors: any): FormActionTypes {
  return {
    type: POST_FORM_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveForm = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormRequest());
    const asyncResp: any = await getForm(id);
    if (asyncResp?.success) {
      await dispatch(fetchFormResponse(asyncResp.data));
    } else {
      await dispatch(fetchFormFailure());
    }
  };

export const fetchForms = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormsRequest());
    const asyncResp: any = await getForms(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFormsResponse(asyncResp));
    } else {
      await dispatch(fetchFormsFailure());
    }
  };

export const storeForm = async (id: string) => {
  try {
    await archiveForm(id);
  } catch (error) {
    throw error;
  }
};

export const createForm = (
  groupId: string, clientId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFormRequest());
    const asyncResp: any = await postForm(groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFormResponse());
    } else {
      await dispatch(postFormFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateForm = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFormRequest());
    const asyncResp: any = await putForm(id, groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFormResponse());
    } else {
      await dispatch(postFormFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


