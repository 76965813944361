import { ApiDataModel, FormError } from './Common';

export const FETCH_POPULAR_SEARCHES_REQUEST = 'FETCH_POPULAR_SEARCHES_REQUEST';
export const FETCH_POPULAR_SEARCHES_RESPONSE = 'FETCH_POPULAR_SEARCHES_RESPONSE';
export const FETCH_POPULAR_SEARCHES_FAILURE = 'FETCH_POPULAR_SEARCHES_FAILURE';

export const FETCH_POPULAR_SEARCH_REQUEST = 'FETCH_POPULAR_SEARCH_REQUEST';
export const FETCH_POPULAR_SEARCH_RESPONSE = 'FETCH_POPULAR_SEARCH_RESPONSE';
export const FETCH_POPULAR_SEARCH_FAILURE = 'FETCH_POPULAR_SEARCH_FAILURE';

export const POST_POPULAR_SEARCH_REQUEST = 'POST_POPULAR_SEARCH_REQUEST';
export const POST_POPULAR_SEARCH_RESPONSE = 'POST_POPULAR_SEARCH_RESPONSE';
export const POST_POPULAR_SEARCH_FAILURE = 'POST_POPULAR_SEARCH_FAILURE';

export const CLEAR_POPULAR_SEARCH = 'CLEAR_POPULAR_SEARCH';

export interface PopularSearch {
  id: string;
  searchTerm: string;
  searchType: string;
  popularity: number;
  created: Date;
}

export interface PopularSearchDataModel extends Omit<ApiDataModel, 'data'> {
  data: PopularSearch[];
}

export interface PopularSearchState {
  popularSearches: PopularSearchDataModel | null,
  activePopularSearch: PopularSearch | null,
  isFetchingPopularSearches: boolean;
  fetchingPopularSearchesFailed: boolean;
  postingPopularSearchFailed: boolean;
  isPostingPopularSearch: boolean;
  postingPopularSearchValidationErrors: FormError[] | null;
  postingPopularSearchError: string | null;
}

interface FetchPopularSearchesRequestAction {
  type: typeof FETCH_POPULAR_SEARCHES_REQUEST;
  payload: null;
}

interface FetchPopularSearchesResponseAction {
  type: typeof FETCH_POPULAR_SEARCHES_RESPONSE;
  payload: PopularSearchDataModel;
}

interface FetchPopularSearchesFailureAction {
  type: typeof FETCH_POPULAR_SEARCHES_FAILURE;
  payload: null;
}

interface FetchPopularSearchRequestAction {
  type: typeof FETCH_POPULAR_SEARCH_REQUEST;
  payload: null;
}

interface FetchPopularSearchResponseAction {
  type: typeof FETCH_POPULAR_SEARCH_RESPONSE;
  payload: PopularSearch;
}

interface FetchPopularSearchFailureAction {
  type: typeof FETCH_POPULAR_SEARCH_FAILURE;
  payload: null;
}

interface PostPopularSearchRequestAction {
  type: typeof POST_POPULAR_SEARCH_REQUEST;
  payload: null;
}

interface PostPopularSearchResponseAction {
  type: typeof POST_POPULAR_SEARCH_RESPONSE;
  payload: null;
}

interface ClearPopularSearchAction {
  type: typeof CLEAR_POPULAR_SEARCH;
  payload: null;
}

interface PostPopularSearchFailureAction {
  type: typeof POST_POPULAR_SEARCH_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type PopularSearchActionTypes =
    | FetchPopularSearchesRequestAction
    | FetchPopularSearchesResponseAction
    | FetchPopularSearchesFailureAction
    | PostPopularSearchRequestAction
    | PostPopularSearchResponseAction
    | PostPopularSearchFailureAction
    | FetchPopularSearchRequestAction
    | FetchPopularSearchResponseAction
    | FetchPopularSearchFailureAction
    | ClearPopularSearchAction;
