import { RootState } from '../store/configureStore';

export const getMarketplaceEntries = (state: RootState) =>
  state?.marketplaceEntry?.marketplaceEntries;

export const isFetchingMarketplaceEntries = (state: RootState) =>
  state?.marketplaceEntry?.isFetchingMarketplaceEntries;

export const isPostingMarketplaceEntry = (state: RootState) =>
  state?.marketplaceEntry?.isPostingMarketplaceEntry;

export const postingMarketplaceEntryFailed = (state: RootState) =>
  state?.marketplaceEntry?.postingMarketplaceEntryFailed;

export const postingMarketplaceEntryError = (state: RootState) =>
  state?.marketplaceEntry?.postingMarketplaceEntryError;

export const postingMarketplaceEntryValidationErrors = (state: RootState) =>
  state?.marketplaceEntry?.postingMarketplaceEntryValidationErrors;

export const fetchingMarketplaceEntriesFailed = (state: RootState) =>
  state?.marketplaceEntry?.fetchingMarketplaceEntriesFailed;
