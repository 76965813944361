import React, { useEffect } from 'react';
import LandingPageView from '../../components/LandingPageView';
import AssetGallery from '../../components/AssetGallery';
import { useParams } from 'react-router-dom';
import { DataTableParamsModel } from '../../types/Common';
import { fetchPopularSearches } from '../../actions/PopularSearch';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularSearches } from '../../selectors/PopularSearch';

const transformContent = () => {
  return (
      <>
        <p>Catalyzing connections in the B2B landscape, our cutting-edge digital matching platform seamlessly transforms
          surplus materials into valuable resources, facilitating an efficient and sustainable exchange. By embracing
          Excess Materials Exchange (EME), you can
          streamline your circular economy goals, significantly diminish your environmental impact, and optimize
          financial gains. This comprehensive approach not only supports responsible business practices but also
          enhances operational efficiency and contributes to a
          more sustainable future.</p>
        <p>Our digital matching platform is designed to find new high-value reuse options for your materials and (waste)
          products, providing a powerful boost to your circular ambitions. We guarantee that a smaller ecological
          footprint and greater financial value can go hand in
          hand.</p>
        <p>Excess Materials Exchange (EME) is a pioneering digital platform dedicated to accelerating the transition to
          a circular economy by facilitating the efficient reuse of materials and products. At its core, EME addresses
          the critical issue of resource inefficiency in the
          global economy, offering a solution that benefits the environment while generating economic value for
          businesses. Through our innovative technology, EME connects companies, enabling them to identify, exchange,
          and repurpose excess materials, transforming
          potential waste into valuable resources.</p>
        <p>With EME, you not only achieve significant environmental benefits but also realize substantial financial
          advantages. On average, companies using our platform see an increase of 110% in the financial value of their
          material flows, while simultaneously reducing their
          ecological footprint by 60%. These impressive results are driven by four key tools integral to our method,
          which meticulously assess, match, and optimize the use of materials, ensuring no resource goes to waste.</p>

        <p>For a closer look at how we operate and the benefits we offer, watch our UK launch video. Discover how Excess
          Materials Exchange can help your business achieve its circular economy goals, reduce environmental impact, and
          enhance profitability. Join us in
          creating a profitable and sustainable future, where responsible business practices and operational efficiency
          go hand in hand.</p>

        <p>For a brief overview of how we operate and the benefits we offer, watch our UK launch video to see Excess
          Materials Exchange in action. Join us in creating a profitable and sustainable future.</p>
      </>
  );
};

const businessContent = () => {
  return (
      <>
  <p>Our digital matching platform revolutionizes the way businesses handle surplus materials by connecting them with potential buyers or partners who can repurpose these resources. This innovative approach transforms the traditional waste management process into a streamlined, efficient operation.</p>

  <h4>Connecting with Buyers and Partners</h4>
  <p>By using our platform, businesses can easily identify and connect with other companies that require the surplus materials they have on hand. This not only facilitates the reuse and recycling of materials but also creates a network of enterprises working together towards sustainability.</p>

  <h4>Reducing Storage Costs</h4>
  <p>Holding onto surplus materials can be costly due to the space and resources required for storage. Our platform helps businesses find immediate outlets for these materials, thereby reducing storage costs. By moving surplus materials out quickly, companies can free up valuable space for more critical operations.</p>

  <h4>Minimizing Waste Disposal Expenses</h4>
  <p>Traditional waste disposal methods can be expensive and environmentally taxing. By repurposing surplus materials through our platform, businesses can significantly cut down on disposal costs. This not only saves money but also reduces the environmental footprint associated with waste disposal.</p>

  <h4>Discovering New Revenue Streams</h4>
  <p>Surplus materials, which were once considered waste, can become valuable assets. Through our platform, businesses can sell these materials to others who need them, thereby creating new revenue streams. This exchange transforms waste into wealth, making every part of the production process potentially profitable.</p>

  <h4>Optimizing Supply Chain Efficiency</h4>
  <p>Integrating our digital matching platform into your supply chain operations enhances overall efficiency. By ensuring that surplus materials are promptly and effectively repurposed or sold, businesses can maintain a more streamlined and responsive supply chain. This optimization leads to better resource management and operational agility, allowing companies to respond swiftly to market demands and changes.</p>
      </>
  );
};

const environmentalImpactContent = () => {
  return (
      <>
          <h4>Platform{"'"}s Role in Reducing Environmental Footprint</h4>
          <p>Our digital matching platform is instrumental in minimizing the environmental impact of businesses. By
              efficiently transforming waste into valuable resources, companies can significantly reduce their carbon
              footprint. This reduction is achieved through the optimization of material reuse, which in turn decreases
              the volume of waste directed to landfills and lowers greenhouse gas emissions associated with waste
              management.</p>

          <h4>Recycling and Repurposing Materials</h4>
          <p>The platform excels in facilitating the recycling and repurposing of various materials. This process not
              only prevents the wastage of usable resources but also reduces the need for new raw materials. By
              decreasing resource extraction, we help preserve natural habitats and reduce the energy consumption
              typically required for the production and transportation of these materials.</p>

          <h4>Corporate Sustainability and Global Alignment</h4>
          <p>Adopting our platform supports corporate sustainability initiatives. Companies can demonstrate their
              commitment to environmentally friendly practices, which enhances their corporate image and can lead to
              competitive advantages. This sustainable approach aligns with global efforts to combat climate change, as
              it supports international environmental goals and regulations aimed at promoting a circular economy and
              reducing global carbon emissions.</p>

          <h4>Promoting Environmental Stewardship</h4>
          <p>Our platform encourages businesses to adopt a proactive stance on environmental stewardship. By integrating
              our solution into their operations, companies are not only able to manage their waste more effectively but
              also contribute to broader environmental conservation efforts. This holistic approach ensures that
              businesses play a vital role in preserving the planet for future generations while simultaneously reaping
              economic benefits from reduced operational costs and increased resource efficiency.</p>

          <h4>Summary</h4>
          <p>By leveraging our digital matching platform, businesses can:</p>
          <ul>
              <li><strong>Reduce their carbon footprint</strong> by optimizing material reuse and minimizing landfill
                  use.
              </li>
              <li><strong>Facilitate recycling and repurposing</strong>, decreasing the need for new raw materials and
                  conserving energy.
              </li>
              <li><strong>Enhance corporate sustainability</strong>, aligning with global environmental goals and
                  improving their market positioning.
              </li>
              <li><strong>Promote environmental stewardship</strong>, contributing to broader conservation efforts and
                  ensuring sustainable business practices.
              </li>
          </ul>
          <p>This comprehensive approach not only supports responsible business operations but also drives significant
              environmental and economic benefits, paving the way for a more sustainable future.</p>
      </>
  );
};

const pageContent: PageContentMap = {
  '9eFVGcJb5W': {
    'title': 'TRANSFORMING WASTE INTO WEALTH',
    'topImgUrl': AssetGallery.smallBanner,
    'content': [
      transformContent(),
    ],
  },
  'TC223m6IhQ': {
    'title': 'ENHANCING BUSINESS EFFICIENCY',
    'topImgUrl': AssetGallery.busEffSmallBanner,
    'content': [
      businessContent(),
    ],
  },
  'zjDrEpLK34': {
    'title': 'DIMINISHING ENVIRONMENTAL IMPACT',
    'topImgUrl': AssetGallery.enImSmallBanner,
    'content': [
      environmentalImpactContent(),
    ],
  },
};

type RouteParams = {
  pageKey: string;
};

type PageContent = {
  title: string;
  topImgUrl: string;
  content: React.ReactNode;
};

type PageContentMap = {
  [key: string]: PageContent;
};

const StaticPageView = () => {
  const dispatch = useDispatch();
  const { pageKey } = useParams<RouteParams>();
  const popularSearches = useSelector(getPopularSearches);
  const pageData = pageContent[pageKey];
  const { title, topImgUrl, content } = pageData;

  useEffect(() => {
    const dataTableParams: DataTableParamsModel = {
      colKey: 'popularity',
      sortDirection: 'desc',
      paging: '10',
    };
    dispatch(fetchPopularSearches(dataTableParams));
  }, [dispatch]);
    
  return (
        <LandingPageView popularSearches={popularSearches}>
            <div className="w-100 overflow-hidden">
                <img src={topImgUrl} alt={'Top Image'} className={'mx-auto d-block'}/>
                <div className="mx-5 my-4 ">
                    <h2 className="">{title}</h2>
                    <div className="mt-4">
                        {content}
                    </div>
                </div>
            </div>
        </LandingPageView>
  );
};

export default StaticPageView;