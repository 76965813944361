import React, { useEffect } from 'react';
import './Timeline.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEntryChildLocationHistory,
  getEntryParentLocationHistory,
  getEntryTimeline,
} from '../../selectors/EntryHistory';
import {
  fetchChildLocationHistory,
  fetchParentLocationHistory,
} from '../../actions/EntryHistory';
import Accordion from '../Accordion';
import { format } from 'date-fns';
import MaterialMap from '../MaterialMap';
import { getEntryFieldsHistory } from '../../selectors/EntryFieldHistory';
import { fetchEntryFieldHistory, fetchEntryFieldHistoryByShortcodes } from '../../actions/EntryFieldHistory';

type Props = {
  entryId?: string;
  groupShortCode?: string;
  clientShortCode?: string;
};

const Timeline = (props: Props) => {
  const {
    entryId = '',
    groupShortCode = '',
    clientShortCode = '',
  } = props;
  const dispatch = useDispatch();
  const entryTimeline = useSelector(getEntryTimeline);
  const entryFieldHistory = useSelector(getEntryFieldsHistory);
  const childLocationHistory = useSelector(getEntryChildLocationHistory);
  const parentLocationHistory = useSelector(getEntryParentLocationHistory);


  useEffect(() => {
    if (entryId && !groupShortCode && !clientShortCode) {
      dispatch(fetchEntryFieldHistory(entryId));
      dispatch(fetchChildLocationHistory(entryId));
      dispatch(fetchParentLocationHistory(entryId));
    } else {
      dispatch(fetchEntryFieldHistoryByShortcodes(groupShortCode, clientShortCode, entryId));
    }
  }, [entryId]);


  const renderMapHistory = () => {
    const locationHistoryPreFilter = entryFieldHistory?.data.filter(item => item.fieldType === 'map').sort((a, b) => new Date(a.updated).getTime() - new Date(b.updated).getTime());
    const locationHistoryCreatedSort = locationHistoryPreFilter?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const firstFound = locationHistoryCreatedSort?.[0]?.fieldId;
    const locationHistory = locationHistoryPreFilter?.filter(item => item.fieldId === firstFound);
    const mapListItems = locationHistory?.map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
    }));

    const parts = locationHistory?.map(item => item.value?.split('~'));

    const childListItems = childLocationHistory?.data.map(e => ({
      'geolocation': e.locationHistory,
      'id': e.childId,
      'title': e.childTitle,
    }));

    const parentListItems = parentLocationHistory?.data.map(e => ({
      'geolocation': e.locationHistory,
      'id': e.parentId,
      'title': e.parentTitle,
    }));

    return (<div className="col-lg-7 col-md-12 mt-3 mt-lg-0">
      <div className="timeline__title">Location History</div>
            {parts && parts.length > 0 ?
                <>
                    <MaterialMap
                        address={parts[parts.length - 1][3]}
                        lat={parseFloat(parts[parts.length - 1][0])}
                        lng={parseFloat(parts[parts.length - 1][1])}
                        zoom={4.5}
                        mapList={mapListItems}
                        //@ts-ignore
                        childList={childListItems}
                        //@ts-ignore
                        parentList={parentListItems}
                        historyMode={true}
                        edgeHistory={true}
                        markersClickable={false}
                    />
                </> :
                <span>No location data found.</span>}
        </div>);
  };

  return (
    <div className="row">
    <div className="col-lg-5 col-md-12">
          <div className="timeline__title">Timeline</div>
              <div className='timeline__container'>
                {entryTimeline?.data.map((e, i, elements) => {
                  return (i == 0 ? true : elements[i - 1] && e.created !== elements[i - 1].created) &&
                            <div key={i} className="row cont my-2">
                              <div className="col-12">
                                <Accordion customClassname={'timeline-accordion'} title={format(new Date(e.created), 'MMMM d, yyyy, pp').toString()}>
                                  {entryTimeline?.data.filter(time => time.created === e.created).map((x, index) =>
                                      <div key={index}>
                                        <span>Field: {x.title}</span>
                                        <div className="changes">
                                          <span className="before">- {x.before}</span>
                                          <span className="after">+ {x.after}</span>
                                          {x.notes && <span className="notes">Notes: {x.notes}</span>}
                                        </div>
                                      </div>)}
                                </Accordion>
                              </div>
                            </div>;
                },
                )}
              </div>
    </div>
                {renderMapHistory()}
            {entryTimeline?.data.length === 0 && <div className={'row my-3'}><span>No data found.</span></div>}
    </div>
  );
};

export default Timeline;
