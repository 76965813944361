import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import './TimelineWidget.scss';

import c from '../../helpers/constants';
import Timeline from '../../components/Timeline';



const TimelineWidget = () => {
  // @ts-ignore
  const matchUpdate = useRouteMatch(c.APP_ROUTES.TIMELINE_ENTRY_RENDERER_SHORTCODE);
  // @ts-ignore
  const groupShortcode = matchUpdate?.params?.groupShortcode;
  // @ts-ignore
  const clientShortcode = matchUpdate?.params?.clientShortcode;


  //@ts-ignore
  const entryId = matchUpdate?.params?.id;



  return (
      <div className='timeline__widget'>
       <Timeline entryId={entryId} groupShortCode={groupShortcode} clientShortCode={clientShortcode} />
      </div>
  );
};

export default TimelineWidget;
