import axios from 'axios';

import c from '../helpers/constants';

export function getInfoByText(title: string, description: string) {
  return (
    axios({
      url: c.API_ENDPOINTS.AI_WIZARD_TEXT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getInfoByImage(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AI_WIZARD_IMAGE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getValuationInfo(matchId: string, entryTitle?: string, entryDescription?: string,
  solutionTitle?: string, solutionDescription?: string, solutionLocation?: string, entryLocation?: string) {
  return (
    axios({
      url: c.API_ENDPOINTS.AI_WIZARD_VALUATION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        matchId,
        entryTitle,
        entryDescription,
        solutionTitle,
        solutionDescription,
        solutionLocation,
        entryLocation,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getXYGeneration(matches: { matchId: string, entryTitle?: string, entryDescription?: string, solutionTitle?: string, solutionDescription?: string }[]) {
  return (
    axios({
      url: c.API_ENDPOINTS.AI_WIZARD_XY_GENERATION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        matches,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}



