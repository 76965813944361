import { RootState } from '../store/configureStore';

export const getConfigurations = (state: RootState) => state?.configuration?.configurations;
export const getConfigurationsData = (state: RootState) => state?.configuration?.configurationsData;
export const getActiveConfiguration = (state: RootState) => state?.configuration?.activeConfiguration;
export const isFetchingConfigurations = (state: RootState) => state?.configuration?.isFetchingConfigurations;
export const isPostingConfiguration = (state: RootState) => state?.configuration?.isPostingConfiguration;
export const postingConfigurationFailed = (state: RootState) => state?.configuration?.postingConfigurationFailed;
export const postingConfigurationError = (state: RootState) => state?.configuration?.postingConfigurationError;
export const postingConfigurationValidationErrors = (state: RootState) => state?.configuration?.postingConfigurationValidationErrors;
export const fetchingConfigurationsFailed = (state: RootState) => state?.configuration?.fetchingConfigurationsFailed;