import React from 'react';
import View from '../../../components/View/View';
import c from '../../../helpers/constants';
import { formatUrl } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import Stepper from '../../../components/Stepper';
import Button from '../../../components/Button';
import TickMark from '../../../assets/images/svgs/tick_mark.svg';
import './AuctionWizard.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';


const StepFour = () => {
  const history = useHistory();

  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];

  const activeAuctionId = useSelector((state: RootState) => state.auction.createdAuction);

  const onNavigate = () => {
    if (activeAuctionId){
      history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION, activeAuctionId));
    }
  };

  return (
      <View>
          <div className="container-fluid">
              <div className="row justify-content-center align-items-center">
                  <div className="col-8 mb-4">
                      <Stepper steps={steps} activeStep={3} hideMobileLabels={true}/>
                  </div>
              </div>

              <div className="row p-4 justify-content-center align-items-center">
                  <div className="row  justify-content-center align-items-center auction-wizard__box">
                      <div className="row justify-content-center align-items-center  p-md-5 p-sm-0">
                      <div className='row mt-5 justify-content-center align-items-center'>
                          <div className='col-12 text-center'><img className="auction-wizard__circle" src={TickMark} alt='checkmark'/></div>
                      </div>
                      <div className='row my-1'>
                          <div className='col-12  auction-wizard__success-title text-center'>Auction Ready: Your Auction and Details are Confirmed!</div>
                      </div>

                      <div className='row my-1'>
                          <div className='col-12  text-center'>All done! Your auction setup is complete.
                          </div>
                      </div>

                      <div className='row my-1'>
                          <div className='col-12  text-center'>
                              <Button onClick={() => onNavigate()} title={'Go To Auction'} />
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </View>

  );
};

export default StepFour;

