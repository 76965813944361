import React from 'react';

type Props = {
  children?: React.ReactNode;
};

const Chart = (props: Props) => {
  return (
      <svg id="impact-svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 681.297 612.612">
          <defs>
              <linearGradient id="linear-gradient" x1="48.83" y1="722.55" x2="48.83" y2="721.55" gradientTransform="translate(-9656.68 -204.82) rotate(90) scale(16.32 -14.28)" gradientUnits="objectBoundingBox">
                  <stop offset="0" stopColor="#3b7fc7"/>
                  <stop offset="1" stopColor="#1f4b86"/>
              </linearGradient>
              <linearGradient id="linear-gradient-2" x1="26.09" y1="726.69" x2="26.09" y2="725.69" gradientTransform="translate(-40.06 425223.74) scale(2.74 -585.14)">
                  <stop offset="0" stopColor="#3b7fc7"/>
                  <stop offset="1" stopColor="#1f4b86"/>
              </linearGradient>
              <linearGradient id="linear-gradient-3" x1="41.7" y1="685.25" x2="41.7" y2="684.25" gradientTransform="translate(-26101.6 2483.3) scale(634.04 -2.76)">
                  <stop offset="0" stopColor="#3b7fc7"/>
                  <stop offset="1" stopColor="#1f4b86"/>
              </linearGradient>
              <linearGradient id="linear-gradient-4" x1="39.26" y1="719.12" x2="39.26" y2="718.12" gradientTransform="translate(-636.49 10637.16) scale(17.01 -14.79)">
                  <stop offset="0" stopColor="#3b7fc7"/>
                  <stop offset="1" stopColor="#1f4b86"/>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradientActive">
                  <stop stopColor="#3B7FC7" offset="0%"></stop>
                  <stop stopColor="#1F4B86" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradientInactive">
                  <stop stopColor="#535353" offset="0%"></stop>
                  <stop stopColor="#888888" offset="100%"></stop>
              </linearGradient>
          </defs>
          <g>
          <polygon id="_Pathl_" data-name="&amp;lt;Pathl&amp;gt;" points="31.74 32.06 628.78 32.06 628.78 592.19 31.26 592.19 31.74 32.06" fill='#D0E7FB'/>
          <rect id="_Pathm_" data-name="&amp;lt;Pathm&amp;gt;" x="31.26" y="200.05" width="401.03" height="391.78" fill='#F5F5F5'/>
          <rect id="_Paths_" data-name="&amp;lt;Paths&amp;gt;" x="31.26" y="378.53" width="220.52" height="213.65" fill='#FFFFFF'/>
              <text transform="translate(10.28 204.07) rotate(-90)" fill='#2C2C2C' fontFamily='HelveticaNeue-Medium, &apos;Helvetica Neue&apos;;' fontSize='15px' fontWeight='500'><tspan x="0" y="0">Materials Reuse Hierarchy</tspan></text>
              <text transform="translate(231.84 609.61)" fill='#2C2C2C' fontFamily='HelveticaNeue-Medium, &apos;Helvetica Neue&apos;;' fontSize='15px' fontWeight='500'><tspan x="0" y="0">Significance of Economic Environmental and Social Impacts</tspan></text>
          <path id="Polygon_4" data-name="Polygon 4" d="m660.58,592.01l-14.28,8.16v-16.32l14.28,8.16Z" fill='#AAAAAA'/>
          <path id="Line_1" data-name="Line 1" d="m32.46,592.9h-2.4L30.4,7.77h2.4l-.34,585.14Z" fill='#AAAAAA'/>
          <path id="Line_2" data-name="Line 2" d="m31.26,593.39v-2.4l614.97-.36v2.4l-614.97.36Z" fill='#AAAAAA'/>
          <path id="Polygon_3" data-name="Polygon 3" d="m31.26,0l8.51,14.79h-17.01L31.26,0Z" fill='#AAAAAA'/>
          <path id="Rectangle_254" data-name="Rectangle 254" d="m498.28,551h52c7.73,0,14,6.27,14,14h0c0,7.73-6.27,14-14,14h-52c-7.73,0-14-6.27-14-14h0c0-7.73,6.27-14,14-14Z" fill='#FFFFFF'/>
          <path id="Rectangle_255" data-name="Rectangle 255" d="m292.28,551h65c7.73,0,14,6.27,14,14h0c0,7.73-6.27,14-14,14h-65c-7.73,0-14-6.27-14-14h0c0-7.73,6.27-14,14-14Z" fill='#D0E7FB'/>
          <path id="Rectangle_256" data-name="Rectangle 256" d="m90.28,551h65c7.73,0,14,6.27,14,14h0c0,7.73-6.27,14-14,14h-65c-7.73,0-14-6.27-14-14h0c0-7.73,6.27-14,14-14Z" fill='#F5F5F5'/>
              <text transform="translate(506.54 570.14)" fill='#2C2C2C'  fontFamily='HelveticaNeue-Medium, &apos;Helvetica Neue&apos;;' fontSize='14px' fontWeight='500'><tspan x="0" y="0">HIGH</tspan></text>
              <text transform="translate(296.44 570.14)" fill='#2C2C2C' fontFamily='HelveticaNeue-Medium, &apos;Helvetica Neue&apos;;' fontSize='14px' fontWeight='500'><tspan x="0" y="0">MEDIUM</tspan></text>
              <text transform="translate(106.73 570.14)" fill='#2C2C2C'   fontFamily='HelveticaNeue-Medium, &apos;Helvetica Neue&apos;;' fontSize='14px' fontWeight='500'><tspan x="0" y="0">LOW</tspan></text>
          </g>
          {props.children}
        </svg>
  );
};

export default Chart;
