import React, { useState, useRef, useEffect } from 'react';
import './ProfileToggle.scss'; // CSS file to style the dropdown
import { User } from '../../types/User';
import ImageButton from '../ImageButton';
import Button from '../Button';
import c from '../../helpers/constants';
import AssetGallery from '../AssetGallery';
import { useHistory } from 'react-router-dom';
import ClientSelector from '../ClientSelector/ClientSelector';
import { getActiveClient } from '../../helpers/functions';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../selectors/Auth';

interface ProfileToggleProps {
  user: User;
}

const ProfileToggle: React.FC<ProfileToggleProps> = ({ user }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [activeClientTitle, setActiveClientTitle] = useState(getActiveClient().title);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown container
  const history = useHistory();
  const loggedInUser = useSelector(getLoggedInUser);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const onProfile = () => {
    history.push(c.APP_ROUTES.PROFILE);
  };

  const toggleClientSelector = () => {
    setShowSelector(!showSelector);
  };

  const handleClientTitleChange = (newTitle: string) => {
    setActiveClientTitle(newTitle); // Update the title when client changes
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
      <>
        <ImageButton
            circularIcon={true}
            iconSrc={user.avatar ? `${c.API_ENDPOINTS.ASSET_FILE}/${user.avatar}` : AssetGallery.profileImg}
            label="Profile"
            onClick={toggleDropdown}
        />
        {showDropdown && (
            <div className="profile-dropdown" ref={dropdownRef}>
              <div className="profile-info" onClick={onProfile}>
                <img
                    src={user.avatar ? `${c.API_ENDPOINTS.ASSET_FILE}/${user.avatar}` : AssetGallery.profileImg}
                    alt="Profile"
                    className="profile-pic-large"
                />
                <div className="profile-details">
                  <strong>{activeClientTitle}</strong>
                  <p>{user.firstName.toUpperCase()} {user.lastName.toUpperCase()}</p>
                </div>
              </div>
              {(loggedInUser && loggedInUser?.clients.length > 1) &&
                  <div style={{ marginTop: '16px' }}>
                    <Button title="Switch Organisation" onClick={toggleClientSelector} />
                  </div>}
              {showSelector && <ClientSelector onClientSelect={handleClientTitleChange} />}
            </div>
        )}
      </>
  );
};

export default ProfileToggle;
