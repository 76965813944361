import React, { useEffect, useState } from 'react';
import View from '../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, isFetchingUsers, isPostingUser } from '../../selectors/User';
import { fetchActiveUser, fetchUsers, updateUser } from '../../actions/User';
import { getUserToken } from '../../helpers/functions';
import Button from '../../components/Button';
import CheckBox from '../../components/CheckBox';
import './Verification.scss';



const Verification = () => {

  const users = useSelector(getUsers);
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const [userArray, setUserArray] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
    dispatch(fetchUsers({
      baseSearch: { 'verified': '0' },
    }));
  }, [dispatch]);

  const handleCheckBoxChange = (val: string) => (checked: boolean) => {
    if (checked) {
      if (!userArray.includes(val)) {
        setUserArray([...userArray, val]);
      }
    } else {
      setUserArray(userArray.filter((user) => user !== val));
    }
  };

  const onEdit = () => {
    const filteredUsers = users?.data.filter(user => userArray.includes(user.id));
    filteredUsers?.map((user) => {
      dispatch(updateUser(user.id, user.roleId, user.email, user.password, user.username, user.firstName, user.lastName, user.location, user.occupation, user.bio, user.avatar, true, true));
    });
    setUserArray([]);
  };


  return (
        <View>
            <div className="page">
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <h1 className="mb-4">Unverified Users</h1>
                            <h1 className="mb-4"></h1>
                        </div>
                    </div>
                    { users?.data.length === 0 ?
                        <div className="row">
                            <div className="white-oswald">All users have been verified.</div>
                            </div> :
                    <div className="row">
                        {users?.data.filter(x=> x.roleId).map((user, index) =>(
                        <div key={user?.email} className="col-12 verify-box">
                            <div className="white-oswald">Username: <b>{user?.username}</b></div>
                            <div className="white-oswald">Email: <b>{user?.email}</b></div>
                            <div className="white-oswald">First Name: <b>{user?.firstName}</b></div>
                            <div className="white-oswald">Last Name: <b>{user?.lastName}</b></div>
                            <div className="white-oswald">Time Created: <b>{user?.created}</b></div>
                            <div className="white-oswald">Role: <b>{user?.roleTitle}</b></div>
                            {user?.clients.length > 0 && <div className="white-oswald">
                                Associated Clients: <b>{user?.clients.map(client => client.clientTitle).join(', ')}</b>
                            </div>}
                            <div className="white-oswald"><CheckBox  key={index} id={`${index}`} onChange={handleCheckBoxChange(user?.id)}></CheckBox></div>
                        </div>))}
                        <div className="col-12">
                            <div className="white-oswald"><Button title='Verify' disabled={userArray.length === 0} isLoading={isPosting || isFetching} onClick={() => {onEdit();}} /></div>
                        </div>
                    </div>}
                </div>
            </div>
        </View>
  );
};

export default Verification;
