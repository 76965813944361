import { RootState } from '../store/configureStore';

export const getGroups = (state: RootState) => state?.group?.groups;

export const getActiveGroup = (state: RootState) => state?.group?.activeGroup;

export const isFetchingGroups = (state: RootState) => state?.group?.isFetchingGroups;

export const isPostingGroup = (state: RootState) => state?.group?.isPostingGroup;
export const postingGroupFailed = (state: RootState) => state?.group?.postingGroupFailed;
export const postingGroupError = (state: RootState) => state?.group?.postingGroupError;
export const postingGroupValidationErrors = (state: RootState) => state?.group?.postingGroupValidationErrors;
export const fetchingGroupsFailed = (state: RootState) => state?.group?.fetchingGroupsFailed;