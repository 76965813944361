import { RootState } from '../store/configureStore';

export const getSolutionInputTags = (state: RootState) => state?.solutionInputTag?.solutionInputTags;

export const getActiveSolutionInputTag = (state: RootState) => state?.solutionInputTag?.activeSolutionInputTag;

export const isFetchingSolutionInputTags = (state: RootState) => state?.solutionInputTag?.isFetchingSolutionInputTags;

export const isPostingSolutionInputTag = (state: RootState) => state?.solutionInputTag?.isPostingSolutionInputTag;
export const postingSolutionInputTagFailed = (state: RootState) => state?.solutionInputTag?.postingSolutionInputTagFailed;
export const postingSolutionInputTagError = (state: RootState) => state?.solutionInputTag?.postingSolutionInputTagError;
export const postingSolutionInputTagValidationErrors = (state: RootState) => state?.solutionInputTag?.postingSolutionInputTagValidationErrors;
export const fetchingSolutionInputTagsFailed = (state: RootState) => state?.solutionInputTag?.fetchingSolutionInputTagsFailed;