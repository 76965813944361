import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SHADOW_ENTRIES_REQUEST,
  FETCH_SHADOW_ENTRIES_RESPONSE,
  FETCH_SHADOW_ENTRIES_FAILURE,
  FETCH_SHADOW_ENTRY_REQUEST,
  FETCH_SHADOW_ENTRY_RESPONSE,
  FETCH_SHADOW_ENTRY_FAILURE,
  POST_SHADOW_ENTRY_FAILURE,
  POST_SHADOW_ENTRY_REQUEST,
  POST_SHADOW_ENTRY_RESPONSE,
  ShadowEntryActionTypes, ShadowEntryDataModel, ShadowEntry,
} from '../types/ShadowEntry';

import { RootState } from '../store/configureStore';

import { getShadowEntry, getShadowEntries, postShadowEntry, putShadowEntry, archiveShadowEntry } from '../api/ShadowEntry';
import { DataTableParamsModel } from '../types/Common';


export function fetchShadowEntriesRequest(): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchShadowEntriesResponse(
  shadowEntries: ShadowEntryDataModel,
): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRIES_RESPONSE,
    payload: shadowEntries,
  };
}

export function fetchShadowEntriesFailure(): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchShadowEntryRequest(): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRY_REQUEST,
    payload: null,
  };
}

export function fetchShadowEntryResponse(
  shadowEntry: ShadowEntry,
): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRY_RESPONSE,
    payload: shadowEntry,
  };
}

export function fetchShadowEntryFailure(): ShadowEntryActionTypes {
  return {
    type: FETCH_SHADOW_ENTRY_FAILURE,
    payload: null,
  };
}

export const fetchActiveShadowEntry = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchShadowEntryRequest());
    const asyncResp: any = await getShadowEntry(id);
    if (asyncResp?.success) {
      await dispatch(fetchShadowEntryResponse(asyncResp.data));
    } else {
      await dispatch(fetchShadowEntryFailure());
    }
  };

export function postShadowEntryRequest(): ShadowEntryActionTypes {
  return {
    type: POST_SHADOW_ENTRY_REQUEST,
    payload: null,
  };
}

export function postShadowEntryResponse(): ShadowEntryActionTypes {
  return {
    type: POST_SHADOW_ENTRY_RESPONSE,
    payload: null,
  };
}

export function postShadowEntryFailure(error: string, validationErrors: any): ShadowEntryActionTypes {
  return {
    type: POST_SHADOW_ENTRY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchShadowEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchShadowEntriesRequest());
    const asyncResp: any = await getShadowEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchShadowEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchShadowEntriesFailure());
    }
  };


export const createShadowEntry = (
  groupId: string, clientId: string, formId: string, entryId: string, data: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postShadowEntryRequest());
    const asyncResp: any = await postShadowEntry(groupId, clientId, formId, entryId, data);
    if (asyncResp?.success) {
      await dispatch(postShadowEntryResponse());
    } else {
      await dispatch(postShadowEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateShadowEntry = (
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  entryId: string,
  data: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postShadowEntryRequest());
    const asyncResp: any = await putShadowEntry(id, groupId, clientId, formId, entryId, data);
    if (asyncResp?.success) {
      await dispatch(postShadowEntryResponse());
    } else {
      await dispatch(postShadowEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeShadowEntry = async (id: string) => {
  try {
    await archiveShadowEntry(id);
  } catch (error) {
    throw error;
  }
};
