import {
  FETCH_OFFER_PROJECT_MANAGER_FAILURE,
  FETCH_OFFER_PROJECT_MANAGER_REQUEST,
  FETCH_OFFER_PROJECT_MANAGER_RESPONSE,
  FETCH_OFFER_PROJECT_MANAGERS_FAILURE,
  FETCH_OFFER_PROJECT_MANAGERS_REQUEST,
  FETCH_OFFER_PROJECT_MANAGERS_RESPONSE,
  POST_OFFER_PROJECT_MANAGER_FAILURE,
  POST_OFFER_PROJECT_MANAGER_RESPONSE,
  OfferProjectManagerActionTypes,
  OfferProjectManagerState,
  POST_OFFER_PROJECT_MANAGER_REQUEST,
  POST_OFFER_PROJECT_MANAGERS_FAILURE,
  POST_OFFER_PROJECT_MANAGERS_RESPONSE,
  POST_OFFER_PROJECT_MANAGERS_REQUEST,

} from '../types/OfferProjectManager';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OfferProjectManagerState = {
  offerProjectManagers: null,
  activeOfferProjectManager: null,
  isFetchingOfferProjectManagers: false,
  fetchingOfferProjectManagersFailed: false,
  isPostingOfferProjectManager: false,
  isPostingOfferProjectManagers: false,
  postingOfferProjectManagerFailed: false,
  postingOfferProjectManagerError: null,
  postingOfferProjectManagerValidationErrors: null,
  postingOfferProjectManagersFailed: false,
  postingOfferProjectManagersError: null,
  postingOfferProjectManagersValidationErrors: null,
};

const offerProjectManagersReducer = (
  state = initialState,
  action: OfferProjectManagerActionTypes,
): OfferProjectManagerState => {
  switch (action.type) {
    case FETCH_OFFER_PROJECT_MANAGERS_REQUEST:
      return {
        ...state,
        offerProjectManagers: null,
        isFetchingOfferProjectManagers: true,
        fetchingOfferProjectManagersFailed: false,
      };
    case FETCH_OFFER_PROJECT_MANAGERS_RESPONSE:
      return {
        ...state,
        offerProjectManagers: action.payload || [],
        isFetchingOfferProjectManagers: false,
        fetchingOfferProjectManagersFailed: false,
      };
    case FETCH_OFFER_PROJECT_MANAGERS_FAILURE:
      return {
        ...state,
        offerProjectManagers: null,
        isFetchingOfferProjectManagers: false,
        fetchingOfferProjectManagersFailed: true,
      };
    case FETCH_OFFER_PROJECT_MANAGER_REQUEST:
      return {
        ...state,
        offerProjectManagers: null,
        isFetchingOfferProjectManagers: true,
        fetchingOfferProjectManagersFailed: false,
      };
    case FETCH_OFFER_PROJECT_MANAGER_RESPONSE:
      return {
        ...state,
        activeOfferProjectManager: action.payload,
        isFetchingOfferProjectManagers: false,
        fetchingOfferProjectManagersFailed: false,
      };
    case FETCH_OFFER_PROJECT_MANAGER_FAILURE:
      return {
        ...state,
        offerProjectManagers: null,
        isFetchingOfferProjectManagers: false,
        fetchingOfferProjectManagersFailed: true,
      };
    case POST_OFFER_PROJECT_MANAGER_REQUEST:
      return {
        ...state,
        isPostingOfferProjectManager: true,
        postingOfferProjectManagerError: null,
        postingOfferProjectManagerValidationErrors: null,
        postingOfferProjectManagerFailed: false,
      };
    case POST_OFFER_PROJECT_MANAGER_RESPONSE:
      return {
        ...state,
        isPostingOfferProjectManager: false,
        postingOfferProjectManagerError: null,
        postingOfferProjectManagerValidationErrors: null,
        postingOfferProjectManagerFailed: false,
      };
    case POST_OFFER_PROJECT_MANAGER_FAILURE:
      return {
        ...state,
        isPostingOfferProjectManager: false,
        postingOfferProjectManagerError: action.payload.error || 'An error occurred',
        postingOfferProjectManagerValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferProjectManagerFailed: true,
      };
    case POST_OFFER_PROJECT_MANAGERS_REQUEST:
      return {
        ...state,
        isPostingOfferProjectManagers: true,
        postingOfferProjectManagersError: null,
        postingOfferProjectManagersValidationErrors: null,
        postingOfferProjectManagersFailed: false,
      };
    case POST_OFFER_PROJECT_MANAGERS_RESPONSE:
      return {
        ...state,
        isPostingOfferProjectManagers: false,
        postingOfferProjectManagersError: null,
        postingOfferProjectManagersValidationErrors: null,
        postingOfferProjectManagersFailed: false,
      };
    case POST_OFFER_PROJECT_MANAGERS_FAILURE:
      return {
        ...state,
        isPostingOfferProjectManagers: false,
        postingOfferProjectManagersError: action.payload.error || 'An error occurred',
        postingOfferProjectManagersValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferProjectManagersFailed: true,
      };

    default:
      return state;
  }
};

export default offerProjectManagersReducer;

