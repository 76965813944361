import React, { useEffect, useState } from 'react';
import './AccountInformation.scss';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { fetchActiveUser, updateUser } from '../../../actions/User';
import { getUserToken } from '../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from '../../../selectors/User';
import TextArea from '../../../components/TextArea';

const AccountInformation = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [location, setLocation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [bio, setBio] = useState('');
  const [editFields, setEditFields] = useState(false);
  const activeUser = useSelector(getActiveUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
  }, [dispatch]);

  useEffect(() => {
    if (activeUser){
      setFirstName(activeUser?.firstName);
      setLastName(activeUser?.lastName);
      setEmail(activeUser?.email);
      setUsername(activeUser?.username);
      setLocation(activeUser?.location);
      setOccupation(activeUser?.occupation);
      setBio(activeUser?.bio);
    }
  }, [activeUser]);
  const onSubmit = () => {
    if (activeUser){
      dispatch(updateUser(activeUser.id, activeUser.roleId, activeUser.email, '', activeUser.username, firstName, lastName, location, occupation, bio, activeUser.avatar, true, true )); // Assuming you have an updateUser action
    }
    // Exit edit mode
    setEditFields(false);
  };

  const backToProfile = () => {
    setEditFields(false);
  };

  return <>
    {editFields ? (
      <form className="user-profile__content">
        <div className={'row'}>
          <div className="col-md-6 col-12">
            <Input
              name="firstName"
              id="firstName"
              label="First Name"
              value={firstName}
              onChange={setFirstName}
              onBlur={setFirstName}
            />
          </div>
          <div className="col-md-6 col-12">
            <Input
              name="lastName"
              id="lastName"
              label="Last Name"
              value={lastName}
              onChange={setLastName}
              onBlur={setLastName}
            />
          </div>
          {/*<div className="col-md-3 col-12">*/}
          {/*  <Input*/}
          {/*    name="username"*/}
          {/*    id="username"*/}
          {/*    label="Username"*/}
          {/*    value={username}*/}
          {/*    onChange={setUsername}*/}
          {/*    onBlur={setUsername}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="col-md-3 col-12">*/}
          {/*  <Input*/}
          {/*    name="email"*/}
          {/*    id="email"*/}
          {/*    label="Email"*/}
          {/*    value={email}*/}
          {/*    onChange={setEmail}*/}
          {/*    onBlur={setEmail}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
          <div className={'row'}>
            <div className="col-md-6 col-12">
              <Input
                name="location"
                id="location"
                label="Location"
                value={location}
                onChange={setLocation}
                onBlur={setLocation}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                name="occupation"
                id="occupation"
                label="Occupation"
                value={occupation}
                onChange={setOccupation}
                onBlur={setOccupation}
              />
            </div>
            <div className="col-md-3 col-12" />
            <div className="col-md-3 col-12" />
          </div>
          <TextArea
            name="bio"
            id="bio"
            label="Bio"
            value={bio}
            onChange={setBio}
            onBlur={setBio}
          />

        <div className={'user-profile__position-button-form user-profile__position--margin-extra'}>
          <Button onClick={() => backToProfile()} title={'Cancel'}></Button>
          <Button onClick={() => onSubmit()}
                  title={'Submit'}/>
        </div>
      </form>
    ) : (
      // View mode: display user information
      <div className="user-profile__content--color">
        <div className="row">
          <div className="col-md-3 col-12">
            <div className={'user-profile__label'}>First Name:</div>
            <div className={'user-profile__field'}>{firstName}</div>
          </div>

          <div className="col-md-3 col-12">
            <div className={'user-profile__label'}>Last Name:</div>
            <div className={'user-profile__field'}>{lastName}</div>
          </div>

          <div className="col-md-3 col-12">
            <div className={'user-profile__label'}>Username:</div>
            <div className={'user-profile__field'}>{username}</div>
          </div>

          <div className="col-md-3 col-12">
            <div className={'user-profile__label'}>Email:</div>
            <div className={'user-profile__field'}>{email}</div>
          </div>
        </div>

        <div className="user-profile__position--margin-extra">
          <div className="row">
            {location && (<div className="col-md-3 col-12">
              <div className="user-profile__label">Location:</div>
              <div className="user-profile__field">{location}</div>
            </div>)}

            {occupation && (<div className="col-md-3 col-12">
              <div className="user-profile__label">Occupation:</div>
              <div className="user-profile__field">{occupation}</div>
            </div>)}
            <div className='col-md-3 col-12' />
            <div className='col-md-3 col-12' />
          </div>
        </div>

        <div className="user-profile__position--start user-profile__position--margin-extra">
          {bio && (
            <div className="mr-3">
              <div className="">
                <div className="user-profile__label">Bio:</div>
              </div>
              <div className={'user-profile__field'} >{bio}</div>
            </div>

          )}
        </div>
        <div className={'user-profile__position--margin-extra'}>
          <Button title="Edit" onClick={() => setEditFields(true)} />
        </div>
      </div>
    )}



  </>;
};

export default AccountInformation;



