import { RootState } from '../store/configureStore';

export const getFormFields = (state: RootState) => state?.formField?.formFields;

export const getActiveFormField = (state: RootState) => state?.formField?.activeFormField;

export const isFetchingFormFields = (state: RootState) => state?.formField?.isFetchingFormFields;

export const isPostingFormField = (state: RootState) => state?.formField?.isPostingFormField;
export const postingFormFieldFailed = (state: RootState) => state?.formField?.postingFormFieldFailed;
export const postingFormFieldError = (state: RootState) => state?.formField?.postingFormFieldError;
export const postingFormFieldValidationErrors = (state: RootState) => state?.formField?.postingFormFieldValidationErrors;
export const fetchingFormFieldsFailed = (state: RootState) => state?.formField?.fetchingFormFieldsFailed;

