import { ApiDataModel, FormError } from './Common';

export const FETCH_SHADOW_ENTRIES_REQUEST = 'FETCH_SHADOW_ENTRIES_REQUEST';
export const FETCH_SHADOW_ENTRIES_RESPONSE = 'FETCH_SHADOW_ENTRIES_RESPONSE';
export const FETCH_SHADOW_ENTRIES_FAILURE = 'FETCH_SHADOW_ENTRIES_FAILURE';

export const FETCH_SHADOW_ENTRY_REQUEST = 'FETCH_SHADOW_ENTRY_REQUEST';
export const FETCH_SHADOW_ENTRY_RESPONSE = 'FETCH_SHADOW_ENTRY_RESPONSE';
export const FETCH_SHADOW_ENTRY_FAILURE = 'FETCH_SHADOW_ENTRY_FAILURE';

export const POST_SHADOW_ENTRY_REQUEST = 'POST_SHADOW_ENTRY_REQUEST';
export const POST_SHADOW_ENTRY_RESPONSE = 'POST_SHADOW_ENTRY_RESPONSE';
export const POST_SHADOW_ENTRY_FAILURE = 'POST_SHADOW_ENTRY_FAILURE';

export interface ShadowEntry {
  id: string;
  groupId: string;
  clientId: string;
  formId: string;
  entryId: string;
  data: string;
}

export interface ShadowEntryDataModel extends Omit<ApiDataModel, 'data'> {
  data: ShadowEntry[];
}

export interface ShadowEntryState {
  shadowEntries: ShadowEntryDataModel | null,
  activeShadowEntry: ShadowEntry | null,
  isFetchingShadowEntries: boolean;
  fetchingShadowEntriesFailed: boolean;
  postingShadowEntryFailed?: boolean;
  isPostingShadowEntry: boolean;
  postingShadowEntryValidationErrors: FormError[] | null;
  postingShadowEntryError: string | null;
}

interface FetchShadowEntriesRequestAction {
  type: typeof FETCH_SHADOW_ENTRIES_REQUEST;
  payload: null;
}

interface FetchShadowEntriesResponseAction {
  type: typeof FETCH_SHADOW_ENTRIES_RESPONSE;
  payload: ShadowEntryDataModel;
}

interface FetchShadowEntriesFailureAction {
  type: typeof FETCH_SHADOW_ENTRIES_FAILURE;
  payload: null;
}

interface FetchShadowEntryRequestAction {
  type: typeof FETCH_SHADOW_ENTRY_REQUEST;
  payload: null;
}

interface FetchShadowEntryResponseAction {
  type: typeof FETCH_SHADOW_ENTRY_RESPONSE;
  payload: ShadowEntry;
}

interface FetchShadowEntryFailureAction {
  type: typeof FETCH_SHADOW_ENTRY_FAILURE;
  payload: null;
}

interface PostShadowEntryRequestAction {
  type: typeof POST_SHADOW_ENTRY_REQUEST;
  payload: null;
}

interface PostShadowEntryResponseAction {
  type: typeof POST_SHADOW_ENTRY_RESPONSE;
  payload: null;
}

interface PostShadowEntryFailureAction {
  type: typeof POST_SHADOW_ENTRY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ShadowEntryActionTypes =
    | FetchShadowEntriesRequestAction
    | FetchShadowEntriesResponseAction
    | FetchShadowEntriesFailureAction
    | PostShadowEntryRequestAction
    | PostShadowEntryResponseAction
    | PostShadowEntryFailureAction
    | FetchShadowEntryRequestAction
    | FetchShadowEntryResponseAction
    | FetchShadowEntryFailureAction;
