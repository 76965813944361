import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getBadges, isFetchingBadges } from '../../../selectors/Badge';
import { fetchBadges, storeBadge } from '../../../actions/Badge';
import { Badge } from '../../../types/Badge';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';



const BadgeDataTable = () => {

  const badges = useSelector(getBadges);
  const isLoading = useSelector(isFetchingBadges);
  const [toggle, setToggle] = useState(false);
  const [badge, setActiveBadge] = useState<Badge | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.BADGE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.BADGE_UPDATE, row.id));
  };

  const onArchive = (row: Badge) => {
    setActiveBadge(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (badge?.id) {
      await storeBadge(badge?.id);
      setRefetch(!refetch);
    }
    setActiveBadge(null);
  };

  const config = {
    columns: [
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Badges" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchBadges} isLoading={isLoading}
                 data={badges?.data || []} paging={badges?.paging || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Badge" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this badge?
      </Modal>
    </View>
  );
};

export default BadgeDataTable;

