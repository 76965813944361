import { RootState } from '../store/configureStore';

export const getMarketplaces = (state: RootState) => state?.marketplace?.marketplaces;
export const getMarketplacesDatatable = (state: RootState) => state?.marketplace?.marketplacesDatatable;

export const getActiveMarketplace = (state: RootState) => state?.marketplace?.activeMarketplace;

export const isFetchingMarketplaces = (state: RootState) => state?.marketplace?.isFetchingMarketplaces;

export const isPostingMarketplace = (state: RootState) => state?.marketplace?.isPostingMarketplace;
export const postingMarketplaceFailed = (state: RootState) => state?.marketplace?.postingMarketplaceFailed;
export const postingMarketplaceError = (state: RootState) => state?.marketplace?.postingMarketplaceError;
export const postingMarketplaceValidationErrors = (state: RootState) => state?.marketplace?.postingMarketplaceValidationErrors;
export const fetchingMarketplacesFailed = (state: RootState) => state?.marketplace?.fetchingMarketplacesFailed;
