import { RootState } from '../store/configureStore';

export const getPopularSearches = (state: RootState) =>
  state?.popularSearch?.popularSearches;

export const getActivePopularSearch = (state: RootState) =>
  state?.popularSearch?.activePopularSearch;

export const isFetchingPopularSearches = (state: RootState) =>
  state?.popularSearch?.isFetchingPopularSearches;

export const isPostingPopularSearch = (state: RootState) =>
  state?.popularSearch?.isPostingPopularSearch;

export const postingPopularSearchFailed = (state: RootState) =>
  state?.popularSearch?.postingPopularSearchFailed;

export const postingPopularSearchError = (state: RootState) =>
  state?.popularSearch?.postingPopularSearchError;

export const postingPopularSearchValidationErrors = (state: RootState) =>
  state?.popularSearch?.postingPopularSearchValidationErrors;

export const fetchingPopularSearchesFailed = (state: RootState) =>
  state?.popularSearch?.fetchingPopularSearchesFailed;
