import React, { useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker, Polyline, InfoWindow } from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import './MaterialMap.scss';

import assetGallery from '../AssetGallery/AssetGallery';
import Card from '../Card/Card';
import { formatUrl } from '../../helpers/functions';
import c from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { Entry } from '../../types/Entry';


const API_KEY = 'AIzaSyDrEEdOGNTPiR_ZBRsyMGMGPFxx-JF4X74';
const latLngTranslateKey = 'AIzaSyBAbdGe9ZZQbuGEAUmbsnAp4g-P4zVm44k';

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

type Props = {
  zoom: number;
  lat?: number;
  view?: string;
  lng?: number;
  enableInfoWindow?:boolean;
  activeCardLocation?: (geo: any) => void;
  address?: string;
  childrenLocations?: string[];
  editMode?: boolean;
  historyMode?: boolean;
  edgeHistory?: boolean;
  showOnlyAddress?: boolean;
  onChange?: (lat: number, lng: number, zoom: number, address: string) => void
  mapList?: { geolocation: string; id: string; title: string, description?:string, assetId?:string }[] | undefined;
  data?: Entry[] | undefined;
  childList?: { geolocation: { value: string }[]; id: string; title: string; }[] | undefined;
  parentList?: { geolocation: { value: string }[]; id: string; title: string; }[] | undefined;
  markersClickable?: boolean;
  isEntryRenderer?:boolean;
  groupShortcode?:string;
  clientShortcode?:string;
  cardsPublicLink?: boolean;
};


const MaterialMap = React.memo((props: Props) => {
  const { activeCardLocation } = props;
  const history = useHistory();
  const [location, setLocation] = useState('');
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [infoParams, setInfoParams] = useState({ id:'', title:'', description:'', assetId:'' });
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);


  useEffect(() => {
    if (activeCardLocation) {
      activeCardLocation(location);
    }
  }, [location]);

  const {
    lat,
    lng,
    zoom,
    mapList,
    childList,
    parentList,
    view,
    enableInfoWindow = false,
    editMode = false,
    historyMode = false,
    showOnlyAddress = false,
    edgeHistory = false,
    isEntryRenderer = false,
    onChange = () => {
    },
    address = '',
    markersClickable = true,
    cardsPublicLink = false,
  } = props;

  useEffect(() => {
    if (lat && lng) {
      setSelectedLocation({ lat, lng });
    }
  }, [lat, lng]);


  const onPlaceSelected = (place: any) => {
    const clickedLat = place.geometry.location.lat();
    const clickedLng = place.geometry.location.lng();

    setSelectedLocation({ lat: clickedLat, lng: clickedLng });

    onChange(clickedLat, clickedLng, zoom, place.formatted_address);
  };
  const onNavigate = (entryId: string) => {
    if (cardsPublicLink){
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, entryId));
    } else if (!props.groupShortcode && !props.clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId));
    } else if (props.groupShortcode && props.clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, props.groupShortcode, props.clientShortcode, entryId));
    }
  };
  const markerClick = (locValue: any, center: any, id: string, title: string, description : string, assetId : string) => {
    setLocation(locValue);
    setSelectedCenter(center);
    setInfoParams({ id:id, title:title, description:description, assetId:assetId });
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    styles: [{
      height: 1000,
      width: '100%',
      stylers: [{
        saturation: -100,
      }],
      zIndex: 9999,
    },
    view == 'viewRenderer' && {
      featureType: 'poi',
      stylers: [
        { visibility: 'off' },
      ],
    }],
  };

  const handleMapClick = async (event: any) => {
    if (editMode) {
      const clickedLat = event.latLng.lat();
      const clickedLng = event.latLng.lng();

      try {
        const res = await Geocode.fromLatLng(clickedLat, clickedLng, latLngTranslateKey);
        const addressComponents = res.results[0].address_components;
        let city = '';
        let region = '';

        addressComponents.forEach((component: { types: string | string[]; long_name: string; }) => {
          if (component.types.includes('postal_town')) {
            city = component.long_name;
          } else if (component.types.includes('locality')) {
            if (!city) {
              city = component.long_name;
            }
          } else if (component.types.includes('administrative_area_level_1')) {
            if (!city) {
              city = component.long_name;
            }
          } else if (component.types.includes('administrative_area_level_3')) {
            if (!city) {
              city = component.long_name;
            }
          } else if (component.types.includes('country')) {
            region = component.long_name;
          }
        });

        let addressToSend = `${city}, ${region}`;
        if (!city || !region) {
          addressToSend = `${clickedLat}, ${clickedLng}`;
        }

        setSelectedLocation({ lat: clickedLat, lng: clickedLng });

        onChange(clickedLat, clickedLng, 80, addressToSend);
      } catch (error) {
        console.error('Error occurred while geocoding:', error);
      }
    }
  };


  const AsyncMap = withScriptjs(
    withGoogleMap(
      mapProps => (
          <>
            {mapList && mapList.length === 0 && !childList && !parentList && (
                <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
                      zIndex: 1000,
                    }}
                >
                  <p style={{ margin: 0 }}>No location data found</p>
                </div>
            )}

                <GoogleMap
                    options={mapOptions}
                    defaultZoom={zoom}
                    defaultCenter={{ lat, lng }}
                    onClick={editMode ? handleMapClick : undefined}
                    {...mapProps}
                >
                    {mapList && historyMode && mapList?.length > 1 && (
                        <Polyline
                            path={mapList.map(e => ({
                              lat: parseFloat(e.geolocation.split('~')[0]),
                              lng: parseFloat(e.geolocation.split('~')[1]),
                            }))}
                            options={{
                              geodesic: true,
                              strokeColor: 'blue',
                              strokeOpacity: 1,
                              strokeWeight: 1,
                              visible: true,
                              icons: [
                                {
                                  icon: {
                                    path: 'M 0,-2 0,2 -2,0 z',
                                    strokeColor: 'blue',
                                    strokeWeight: 2,
                                    scale: '100%',
                                    offset: '0%',
                                    rotation: '90',
                                  },
                                  repeat: '40px',
                                }],
                              zIndex: 0,
                            }}
                        />
                    )}
                  {selectedLocation && isEntryRenderer && (
                      <Marker
                          position={selectedLocation}
                          icon={assetGallery.bluePinImg}
                      />
                  )}
                    {mapList && mapList?.map((e, i) => (
                        <Marker
                            key={i}
                            title={e.title}
                            icon={assetGallery.blackPinImg}
                            onClick={() => markerClick(markersClickable ? e.geolocation : undefined, {
                              'latitude': parseFloat(e.geolocation.split('~')[0]),
                              'longitude': parseFloat(e.geolocation.split('~')[1]),
                            }, e.id, e. title, e.description ?? '', e.assetId ?? '')}
                            position={{
                              lat: parseFloat(e.geolocation.split('~')[0]),
                              lng: parseFloat(e.geolocation.split('~')[1]),
                            }}
                        />
                    ))}

                    {selectedCenter && mapList && enableInfoWindow &&

                        <InfoWindow
                            onCloseClick={() => {
                              setSelectedCenter(null);
                            }}
                            position={{
                            // @ts-ignore
                              lat: selectedCenter.latitude,
                              // @ts-ignore
                              lng: selectedCenter.longitude,
                            }}
                        >
                            <Card
                                cardId={infoParams.id}
                                title={infoParams.title}
                                onClick={() => onNavigate(infoParams.id)}
                                description={infoParams.description}
                                imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${infoParams.assetId}`}
                            >

                            </Card>
                        </InfoWindow>
                    }

                    {/*render child history*/}
                    {childList && historyMode && edgeHistory && childList?.length > 0 && childList?.map((e, i) => (
                        <React.Fragment key={i}>
                            <Polyline
                                path={e.geolocation.map(loc => ({
                                  lat: parseFloat(loc.value.split('~')[0]),
                                  lng: parseFloat(loc.value.split('~')[1]),
                                }))}
                                options={{
                                  geodesic: true,
                                  strokeColor: 'green',
                                  strokeOpacity: 1,
                                  strokeWeight: 1,
                                  visible: true,
                                  icons: [
                                    {
                                      icon: {
                                        path: 'M 0,-2 0,2 -2,0 z',
                                        strokeColor: 'green',
                                        strokeWeight: 2,
                                        scale: '100%',
                                        offset: '0%',
                                        rotation: '90',
                                      },
                                      repeat: '40px',
                                    }],
                                  zIndex: 0,
                                }}
                            />
                            {e.geolocation.map((loc, y) => (<Marker
                                    key={y}
                                    title={e.title}
                                    icon={y === e.geolocation.length - 1 ? assetGallery.bluePinImg : assetGallery.blackPinImg}
                                    position={{
                                      lat: parseFloat(loc.value.split('~')[0]),
                                      lng: parseFloat(loc.value.split('~')[1]),
                                    }}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                    {/*render parent history*/}
                    {parentList && historyMode && edgeHistory && parentList?.length > 0 && parentList?.map((e, i) => (
                        <React.Fragment key={i}>
                            <Polyline
                                path={e.geolocation.map(loc => ({
                                  lat: parseFloat(loc.value.split('~')[0]),
                                  lng: parseFloat(loc.value.split('~')[1]),
                                }))}
                                options={{
                                  geodesic: true,
                                  strokeColor: 'red',
                                  strokeOpacity: 1,
                                  strokeWeight: 1,
                                  visible: true,
                                  icons: [
                                    {
                                      icon: {
                                        path: 'M 0,-2 0,2 -2,0 z',
                                        strokeColor: 'red',
                                        strokeWeight: 2,
                                        scale: '100%',
                                        offset: '0%',
                                        rotation: '90',
                                      },
                                      repeat: '40px',
                                    }],
                                  zIndex: 0,
                                }}
                            />
                            {e.geolocation.map((loc, y) => (<Marker
                                    key={y}
                                    title={e.title}
                                    icon={y === e.geolocation.length - 1 ? assetGallery.bluePinImg : assetGallery.blackPinImg}
                                    position={{
                                      lat: parseFloat(loc.value.split('~')[0]),
                                      lng: parseFloat(loc.value.split('~')[1]),
                                    }}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                    {isEntryRenderer && (
                        <Marker
                            title="Current Location"
                            icon={assetGallery.bluePinImg}
                            zIndex={3000}
                            position={{ lat, lng }}
                        />
                    )}

                    {editMode && (
                        <Autocomplete
                            style={{
                              width: '100%',
                              height: '40px',
                              paddingLeft: '16px',
                              marginTop: '2px',
                              marginBottom: '500px',
                            }}
                            inputAutocompleteValue={address}
                            componentrestrictions={{ country: 'uk' }}
                            onPlaceSelected={onPlaceSelected}
                            options={{
                              types: ['geocode', 'establishment'],
                            }}
                        />
                    )}
                </GoogleMap>
          </>
      ),
    ),
  );


  return <>
    <div
        className={`map-container ${showOnlyAddress ? 'hidden' : 'visible'}`}
        style={{ marginTop: historyMode ? '0' : '5px', marginBottom: editMode ? '30px' : '0' }}
    >
      <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=Function.prototype`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={
            <div style={view === 'viewRenderer' ? { height: 'calc(100vh - 14.188rem)' } : { height: historyMode ? '380px' : '500px' }} />
          }
          mapElement={
            <div
                style={{
                  height: historyMode ? '380px' : '100%',
                  borderRadius: 10,
                  boxShadow: '1px 2px 5px grey',
                }}
            />
          }
      />
    </div>
  </>;
}, (prevProps, nextProps) => {
  return (
    prevProps.lng === nextProps.lng &&
        prevProps.lat === nextProps.lat &&
        prevProps.showOnlyAddress === nextProps.showOnlyAddress
  );
});

MaterialMap.displayName = 'MaterialMap';


export default MaterialMap;

