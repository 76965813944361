import { ApiDataModel, FormError } from './Common';

export const FETCH_CONFIGURATIONS_REQUEST = 'FETCH_CONFIGURATIONS_REQUEST';
export const FETCH_CONFIGURATIONS_RESPONSE = 'FETCH_CONFIGURATIONS_RESPONSE';
export const FETCH_CONFIGURATIONS_FAILURE = 'FETCH_CONFIGURATIONS_FAILURE';

export const FETCH_CONFIGURATION_REQUEST = 'FETCH_CONFIGURATION_REQUEST';
export const FETCH_CONFIGURATION_RESPONSE = 'FETCH_CONFIGURATION_RESPONSE';
export const FETCH_CONFIGURATION_FAILURE = 'FETCH_CONFIGURATION_FAILURE';

export const FETCH_CONFIGURATIONS_DATA_REQUEST = 'FETCH_CONFIGURATIONS_DATA_REQUEST';
export const FETCH_CONFIGURATIONS_DATA_RESPONSE = 'FETCH_CONFIGURATIONS_DATA_RESPONSE';
export const FETCH_CONFIGURATIONS_DATA_FAILURE = 'FETCH_CONFIGURATIONS_DATA_FAILURE';

export const POST_CONFIGURATION_REQUEST = 'POST_CONFIGURATION_REQUEST';
export const POST_CONFIGURATION_RESPONSE = 'POST_CONFIGURATION_RESPONSE';
export const POST_CONFIGURATION_FAILURE = 'POST_CONFIGURATION_FAILURE';

export interface Configuration {
  id: string;
  groupId: string;
  clientId: string;
  clientTitle: string;
  backgroundColor: string;
  foregroundColor: string;
  primaryActionColor: string;
  secondaryActionColor: string;
  successColor: string;
  warningColor: string;
  dropdownColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  primaryLogo: string;
  secondaryLogo: string;
  favicon: string;
  defaultRole: string | null;
  welcomeContent: string | null;
  allowedFileTypes: string | null;
  maxUploadSize: number | null;
  customRedirectUrl: string | null;
  webCommitmentIntegration: boolean;
  enableAI: boolean;
  executeAIWizardOnUpdate: boolean;
  isPremium: boolean;
  enableQR: boolean;
  reservedStatus: boolean;
  notifyOnNewEntry: boolean;
  allowAnonymousViewFetch: boolean;
  allowAnonymousEntryFetch: boolean;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface ConfigurationDataModel extends Omit<ApiDataModel, 'data'> {
  data: Configuration[];
}

export interface ConfigurationState {
  configurations: ConfigurationDataModel | null,
  configurationsData: ConfigurationDataModel | null,
  activeConfiguration: Configuration | null,
  isFetchingConfigurations: boolean;
  fetchingConfigurationsFailed: boolean;
  postingConfigurationFailed: boolean;
  isPostingConfiguration: boolean;
  postingConfigurationValidationErrors: FormError[] | null;
  postingConfigurationError: string | null;
}

interface FetchConfigurationsRequestAction {
  type: typeof FETCH_CONFIGURATIONS_REQUEST;
  payload: null;
}

interface FetchConfigurationsResponseAction {
  type: typeof FETCH_CONFIGURATIONS_RESPONSE;
  payload: ConfigurationDataModel;
}

interface FetchConfigurationsFailureAction {
  type: typeof FETCH_CONFIGURATIONS_FAILURE;
  payload: null;
}

interface FetchConfigurationsDataRequestAction {
  type: typeof FETCH_CONFIGURATIONS_DATA_REQUEST;
  payload: null;
}

interface FetchConfigurationsDataResponseAction {
  type: typeof FETCH_CONFIGURATIONS_DATA_RESPONSE;
  payload: ConfigurationDataModel;
}

interface FetchConfigurationsDataFailureAction {
  type: typeof FETCH_CONFIGURATIONS_DATA_FAILURE;
  payload: null;
}

interface PostConfigurationRequestAction {
  type: typeof POST_CONFIGURATION_REQUEST;
  payload: null;
}

interface FetchConfigurationRequestAction {
  type: typeof FETCH_CONFIGURATION_REQUEST;
  payload: null;
}

interface FetchConfigurationResponseAction {
  type: typeof FETCH_CONFIGURATION_RESPONSE;
  payload: Configuration;
}

interface FetchConfigurationFailureAction {
  type: typeof FETCH_CONFIGURATION_FAILURE;
  payload: null;
}

interface PostConfigurationResponseAction {
  type: typeof POST_CONFIGURATION_RESPONSE;
  payload: null;
}

interface PostConfigurationFailureAction {
  type: typeof POST_CONFIGURATION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ConfigurationActionTypes =
    | FetchConfigurationsRequestAction
    | FetchConfigurationsResponseAction
    | FetchConfigurationsFailureAction
    | FetchConfigurationsDataRequestAction
    | FetchConfigurationsDataResponseAction
    | FetchConfigurationsDataFailureAction
    | FetchConfigurationRequestAction
    | FetchConfigurationResponseAction
    | FetchConfigurationFailureAction
    | PostConfigurationRequestAction
    | PostConfigurationResponseAction
    | PostConfigurationFailureAction;