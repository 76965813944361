import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  MarketplaceEntry,
  MarketplaceEntryActionTypes,
  MarketplaceEntryDataModel,
  FETCH_MARKETPLACE_ENTRY_FAILURE,
  FETCH_MARKETPLACE_ENTRY_REQUEST,
  FETCH_MARKETPLACE_ENTRY_RESPONSE,
  FETCH_MARKETPLACE_ENTRIES_FAILURE,
  FETCH_MARKETPLACE_ENTRIES_REQUEST,
  FETCH_MARKETPLACE_ENTRIES_RESPONSE,
  POST_MARKETPLACE_ENTRY_FAILURE,
  POST_MARKETPLACE_ENTRY_REQUEST,
  POST_MARKETPLACE_ENTRY_RESPONSE,
} from '../types/MarketplaceEntry';

import { RootState } from '../store/configureStore';

import {
  createOrUpdateMarketplaceEntries,
  deleteMarketplaceEntry,
  getMarketplaceEntries,
  postMarketplaceEntry,
} from '../api/MarketplaceEntry';
import { DataTableParamsModel } from '../types/Common';

export function fetchMarketplaceEntriesRequest(): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchMarketplaceEntriesResponse(
  marketplaceEntries: MarketplaceEntryDataModel,
): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRIES_RESPONSE,
    payload: marketplaceEntries,
  };
}

export function fetchMarketplaceEntriesFailure(): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchMarketplaceEntryRequest(): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRY_REQUEST,
    payload: null,
  };
}

export function fetchMarketplaceEntryResponse(
  entry: MarketplaceEntry,
): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRY_RESPONSE,
    payload: entry,
  };
}

export function fetchMarketplaceEntryFailure(): MarketplaceEntryActionTypes {
  return {
    type: FETCH_MARKETPLACE_ENTRY_FAILURE,
    payload: null,
  };
}

export function postMarketplaceEntryRequest(): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_REQUEST,
    payload: null,
  };
}

export function postMarketplaceEntryResponse(): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_RESPONSE,
    payload: null,
  };
}



export function postMarketplaceEntryFailure(
  error: string,
  validationErrors: any,
): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export function createOrUpdateMarketplaceEntriesRequest(): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_REQUEST,
    payload: null,
  };
}

export function createOrUpdateMarketplaceEntriesResponse(): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_RESPONSE,
    payload: null,
  };
}

export function createOrUpdateMarketplaceEntriesFailure(
  error: string,
  validationErrors: any,
): MarketplaceEntryActionTypes {
  return {
    type: POST_MARKETPLACE_ENTRY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchMarketplaceEntries = (
  datatableParams?: DataTableParamsModel,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(fetchMarketplaceEntriesRequest());
  const asyncResp: any = await getMarketplaceEntries(
    datatableParams,
  );
  if (asyncResp?.success) {
    await dispatch(fetchMarketplaceEntriesResponse(asyncResp));
  } else {
    await dispatch(fetchMarketplaceEntriesFailure());
  }
};

export const destroyMarketplaceEntry = async (id: string) => {
  try {
    await deleteMarketplaceEntry(id);
  } catch (error) {
    throw error;
  }
};

export const createMarketplaceEntry = (
  marketplaceId: string,
  entryId: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(postMarketplaceEntryRequest());
  const asyncResp: any = await postMarketplaceEntry(marketplaceId, entryId);
  if (asyncResp?.success) {
    await dispatch(postMarketplaceEntryResponse());
  } else {
    await dispatch(
      postMarketplaceEntryFailure(asyncResp?.error, asyncResp?.validationErrors),
    );
  }
};

export const createOrUpdateMarketplaceEntry = (
  marketplaceId: string,
  entryIds: string[],
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(createOrUpdateMarketplaceEntriesRequest());
  const asyncResp: any = await createOrUpdateMarketplaceEntries(marketplaceId, entryIds);
  if (asyncResp?.success) {
    await dispatch(createOrUpdateMarketplaceEntriesResponse());
  } else {
    await dispatch(
      createOrUpdateMarketplaceEntriesFailure(asyncResp?.error, asyncResp?.validationErrors),
    );
  }
};
