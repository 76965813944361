import { RootState } from '../store/configureStore';


export const getOPPNotifications = (state: RootState) => state?.OPPNotification?.oppNotifications;
export const getActiveOPPNotification = (state: RootState) => state?.OPPNotification?.activeOPPNotification;
export const isFetchingOPPNotifications = (state: RootState) => state?.OPPNotification?.isFetchingOPPNotifications;
export const isPostingOPPNotification = (state: RootState) => state?.OPPNotification?.isPostingOPPNotification;
export const postingOPPNotificationFailed = (state: RootState) => state?.OPPNotification?.postingOPPNotificationFailed;
export const postingOPPNotificationError = (state: RootState) => state?.OPPNotification?.postingOPPNotificationError;
export const postingOPPNotificationValidationErrors = (state: RootState) => state?.OPPNotification?.postingOPPNotificationValidationErrors;
export const fetchingOPPNotificationsFailed = (state: RootState) => state?.OPPNotification?.fetchingOPPNotificationsFailed;
