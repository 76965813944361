import axios from 'axios';

import c from '../helpers/constants';
import { objectToQueryString } from '../helpers/functions';
import { DataTableParamsModel } from '../types/Common';

export function getFormField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM_FIELD}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getFormFieldsByShortcodes(groupShortcode: string, clientShortcode: string, formId: string | null = null) {
  let payload = '';
  if (formId) {
    payload = objectToQueryString({ searching: { key: 'formId', value: formId } });
  }
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM_FIELD}s/${groupShortcode}/${clientShortcode}?${payload}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getFormFields(
  dataTableParams?: DataTableParamsModel,
  visibilityPublic?: boolean,
) {

  let url = '';
  if (visibilityPublic) {
    url = `${c.API_ENDPOINTS.FORM_FIELD_PUBLIC}`;
  } else {
    const baseUrl = `${c.API_ENDPOINTS.FORM_FIELD}`;
    const fetchParams = dataTableParams?.fetchParams;

    url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
      ? `${baseUrl}-shortcode`
      : baseUrl;
  }

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postFormField(groupId: string, clientId: string, formId: string, fieldId: string, order:number, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FORM_FIELD,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        fieldId,
        order,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putFormField(
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  fieldId: string,
  order: number,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM_FIELD}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        fieldId,
        order,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveFormField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM_FIELD}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

