import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FormFieldRole,
  FormFieldRoleActionTypes,
  FormFieldRoleDataModel, FETCH_FORM_FIELD_ROLE_FAILURE,
  FETCH_FORM_FIELD_ROLE_REQUEST,
  FETCH_FORM_FIELD_ROLE_RESPONSE,
  FETCH_FORM_FIELD_ROLES_FAILURE,
  FETCH_FORM_FIELD_ROLES_REQUEST,
  FETCH_FORM_FIELD_ROLES_RESPONSE,
  POST_FORM_FIELD_ROLE_FAILURE,
  POST_FORM_FIELD_ROLE_REQUEST,
  POST_FORM_FIELD_ROLE_RESPONSE,
} from '../types/FormFieldRole';

import { RootState } from '../store/configureStore';

import { deleteFormFieldRole, getFormFieldRoles, postFormFieldRole } from '../api/FormFieldRole';
import { DataTableParamsModel } from '../types/Common';


export function fetchFormFieldRolesRequest(): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLES_REQUEST,
    payload: null,
  };
}

export function fetchFormFieldRolesResponse(
  formFieldRoles: FormFieldRoleDataModel,
): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLES_RESPONSE,
    payload: formFieldRoles,
  };
}

export function fetchFormFieldRolesFailure(): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLES_FAILURE,
    payload: null,
  };
}

export function fetchFormFieldRoleRequest(): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLE_REQUEST,
    payload: null,
  };
}

export function fetchFormFieldRoleResponse(
  user: FormFieldRole,
): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLE_RESPONSE,
    payload: user,
  };
}

export function fetchFormFieldRoleFailure(): FormFieldRoleActionTypes {
  return {
    type: FETCH_FORM_FIELD_ROLE_FAILURE,
    payload: null,
  };
}

export function postFormFieldRoleRequest(): FormFieldRoleActionTypes {
  return {
    type: POST_FORM_FIELD_ROLE_REQUEST,
    payload: null,
  };
}

export function postFormFieldRoleResponse(): FormFieldRoleActionTypes {
  return {
    type: POST_FORM_FIELD_ROLE_RESPONSE,
    payload: null,
  };
}

export function postFormFieldRoleFailure(error: string, validationErrors: any): FormFieldRoleActionTypes {
  return {
    type: POST_FORM_FIELD_ROLE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchFormFieldRoles = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormFieldRolesRequest());
    const asyncResp: any = await getFormFieldRoles(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFormFieldRolesResponse(asyncResp));
    } else {
      await dispatch(fetchFormFieldRolesFailure());
    }
  };

export const destroyFormFieldRole = async (id: string) => {
  try {
    await deleteFormFieldRole(id);
  } catch (error) {
    throw error;
  }
};

export const createFormFieldRole = (
  formFieldId: string, roleId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFormFieldRoleRequest());
    const asyncResp: any = await postFormFieldRole(formFieldId, roleId);
    if (asyncResp?.success) {
      await dispatch(postFormFieldRoleResponse());
    } else {
      await dispatch(postFormFieldRoleFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
