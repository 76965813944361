import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getEntryEdge(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConnectionsTo(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}/to/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConnectionsFrom(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}/from/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryEdgesByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}s/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntryEdges(dataTableParams?: DataTableParamsModel, visibilityPublic?: boolean) {

  let url = ''; 
  
  if (visibilityPublic) {
    url = `${c.API_ENDPOINTS.ENTRY_EDGE_PUBLIC}`;
  } else {
    const baseUrl = `${c.API_ENDPOINTS.ENTRY_EDGE}`;
    const fetchParams = dataTableParams?.fetchParams;

    url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
      ? `${baseUrl}-shortcode`
      : baseUrl;
  }


  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntryEdge(
  groupId: string,
  clientId: string,
  fromEntryId: string,
  toEntryId: string,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_EDGE,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        fromEntryId,
        toEntryId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryEdge(
  id: string,
  groupId: string,
  clientId: string,
  fromEntryId: string,
  toEntryId: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        fromEntryId,
        toEntryId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntryEdge(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_EDGE}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

