import React, { ForceGraph2D } from 'react-force-graph';
import './ForceGraph.scss';
import { EntryEdge } from '../../types/EntryEdge';
import { Entry } from '../../types/Entry';
import { useEffect, useRef } from 'react';

type Props = {
  parentItems :  EntryEdge[] | undefined;
  childItems :  EntryEdge[] | undefined;
  activeEntry :  Entry | null;
};

const ForceGraph = (props: Props) => {
  const {
    parentItems = [],
    childItems = [],
    activeEntry = { id:'', title:'' },
  } = props;

  
  const parentNodes = parentItems.map(e => {return { id:e.id, name:e.fromEntryTitle, val:1 };});
  const childNodes = childItems.map(e => {return { id:e.id, name:e.toEntryTitle, val:1  };});
  const activeNode = [{ id: activeEntry?.id, name: activeEntry?.title, val:5 }];
  const bufferNodes = [...parentNodes, ...childNodes];
  const finalNodes = [...bufferNodes, activeNode[0]];
  const parentLinks = parentNodes.map(e=>{return { source:e.id, target:activeEntry?.id };});
  const childLinks = childNodes.map(e=>{return { source:activeEntry?.id, target: e.id };});
  const links = [...parentLinks, ...childLinks];
  const data = { 'nodes': finalNodes, 'links': links };

  const graphRef = useRef<any>(null);

  useEffect(() => {
    if (graphRef.current) {

      graphRef.current.d3Force('link').distance(150);

      graphRef.current.d3ReheatSimulation();
    }
  }, [data]);

  return (
      <div className='materials-graph'>
       <ForceGraph2D
            graphData={data}
            linkDirectionalArrowLength={4}
            linkDirectionalParticleWidth={20}
            linkWidth={4}
            width={420}
            height={430}
            minZoom={0.1}
            maxZoom={1}
            ref={graphRef}
            nodeRelSize={15}
        />
        </div>

  );
};

export default ForceGraph;