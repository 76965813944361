import React from 'react';


interface BugReportLinkProps {
  clientName: string;
  userName: string;
  appVersion: string;
}

const BugReportLink: React.FC<BugReportLinkProps> = ({ clientName, userName, appVersion }) => {

  const currentPageUrl = encodeURIComponent(window.location.href);


  const subject = encodeURIComponent('Bug Report');
  const body = encodeURIComponent(
    `Client Name: ${clientName}\n\nUser Name: ${userName}\n\nPage URL: ${currentPageUrl}\n\nDescription of the issue that you are facing:\n\n\n\n\n\nv.${appVersion}`,
  );

  const mailtoLink = `mailto:support@excessmaterialsexchange.com?subject=${subject}&body=${body}`;

  return <a href={mailtoLink}>Report a Bug</a>;
};

export default BugReportLink;
