import { RootState } from '../store/configureStore';

export const getEntryFields = (state: RootState) => state?.entryField?.entryFields;

export const getAllEntryFields = (state: RootState) => state?.entryField?.allEntryFields;

export const getActiveEntryField = (state: RootState) => state?.entryField?.activeEntryField;

export const isFetchingEntryFields = (state: RootState) => state?.entryField?.isFetchingEntryFields;

export const isPostingEntryField = (state: RootState) => state?.entryField?.isPostingEntryField;
export const postingEntryFieldFailed = (state: RootState) => state?.entryField?.postingEntryFieldFailed;
export const postingEntryFieldError = (state: RootState) => state?.entryField?.postingEntryFieldError;
export const postingEntryFieldValidationErrors = (state: RootState) => state?.entryField?.postingEntryFieldValidationErrors;
export const fetchingEntryFieldsFailed = (state: RootState) => state?.entryField?.fetchingEntryFieldsFailed;

