import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRY_FIELDS_REQUEST = 'FETCH_ENTRY_FIELDS_REQUEST';
export const FETCH_ENTRY_FIELDS_RESPONSE = 'FETCH_ENTRY_FIELDS_RESPONSE';
export const FETCH_ENTRY_FIELDS_FAILURE = 'FETCH_ENTRY_FIELDS_FAILURE';

export const FETCH_ALL_ENTRY_FIELDS_REQUEST = 'FETCH_ALL_ENTRY_FIELDS_REQUEST';
export const FETCH_ALL_ENTRY_FIELDS_RESPONSE = 'FETCH_ALL_ENTRY_FIELDS_RESPONSE';
export const FETCH_ALL_ENTRY_FIELDS_FAILURE = 'FETCH_ALL_ENTRY_FIELDS_FAILURE';

export const FETCH_ENTRY_FIELD_REQUEST = 'FETCH_ENTRY_FIELD_REQUEST';
export const FETCH_ENTRY_FIELD_RESPONSE = 'FETCH_ENTRY_FIELD_RESPONSE';
export const FETCH_ENTRY_FIELD_FAILURE = 'FETCH_ENTRY_FIELD_FAILURE';

export const POST_ENTRY_FIELD_REQUEST = 'POST_ENTRY_FIELD_REQUEST';
export const POST_ENTRY_FIELD_RESPONSE = 'POST_ENTRY_FIELD_RESPONSE';
export const POST_ENTRY_FIELD_FAILURE = 'POST_ENTRY_FIELD_FAILURE';

export interface EntryField {
  id: string;
  groupId: string;
  clientId: string;
  entryId: string;
  entryTitle: string;
  entryDescription: string;
  entryAssetId: string;
  fieldId: string;
  entryFormId: string;
  categoryId: string;
  categoryTitle: string;
  fieldTitle: string;
  value: string;
  order: number;
  active: boolean;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
  archived: Date;
  archivedBy: string;
  fieldType: string;
  entryStatus: string;
}

export interface EntryFieldDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryField[];
}

export interface EntryFieldState {
  entryFields: EntryFieldDataModel | null,
  allEntryFields: EntryFieldDataModel | null,
  activeEntryField: EntryField | null,
  isFetchingEntryFields: boolean;
  fetchingEntryFieldsFailed: boolean;
  postingEntryFieldFailed?: boolean;
  isPostingEntryField: boolean;
  postingEntryFieldValidationErrors: FormError[] | null;
  postingEntryFieldError: string | null;
}

interface FetchEntryFieldsRequestAction {
  type: typeof FETCH_ENTRY_FIELDS_REQUEST;
  payload: null;
}

interface FetchEntryFieldsResponseAction {
  type: typeof FETCH_ENTRY_FIELDS_RESPONSE;
  payload: EntryFieldDataModel;
}

interface FetchEntryFieldsFailureAction {
  type: typeof FETCH_ENTRY_FIELDS_FAILURE;
  payload: null;
}

//
interface FetchAllEntryFieldsRequestAction {
  type: typeof FETCH_ALL_ENTRY_FIELDS_REQUEST;
  payload: null;
}

interface FetchAllEntryFieldsResponseAction {
  type: typeof FETCH_ALL_ENTRY_FIELDS_RESPONSE;
  payload: EntryFieldDataModel;
}

interface FetchAllEntryFieldsFailureAction {
  type: typeof FETCH_ALL_ENTRY_FIELDS_FAILURE;
  payload: null;
}
//

interface FetchEntryFieldRequestAction {
  type: typeof FETCH_ENTRY_FIELD_REQUEST;
  payload: null;
}

interface FetchEntryFieldResponseAction {
  type: typeof FETCH_ENTRY_FIELD_RESPONSE;
  payload: EntryField;
}

interface FetchEntryFieldFailureAction {
  type: typeof FETCH_ENTRY_FIELD_FAILURE;
  payload: null;
}

interface PostEntryFieldRequestAction {
  type: typeof POST_ENTRY_FIELD_REQUEST;
  payload: null;
}

interface PostEntryFieldResponseAction {
  type: typeof POST_ENTRY_FIELD_RESPONSE;
  payload: null;
}

interface PostEntryFieldFailureAction {
  type: typeof POST_ENTRY_FIELD_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type EntryFieldActionTypes =
    | FetchEntryFieldsRequestAction
    | FetchEntryFieldsResponseAction
    | FetchEntryFieldsFailureAction
    | FetchAllEntryFieldsRequestAction
    | FetchAllEntryFieldsResponseAction
    | FetchAllEntryFieldsFailureAction
    | PostEntryFieldRequestAction
    | PostEntryFieldResponseAction
    | PostEntryFieldFailureAction
    | FetchEntryFieldRequestAction
    | FetchEntryFieldResponseAction
    | FetchEntryFieldFailureAction;
