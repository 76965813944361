import { RootState } from '../store/configureStore';

export const getFieldTypeOptions = (state: RootState) => state?.fieldTypeOption?.fieldTypeOptions;

export const getActiveFieldTypeOption = (state: RootState) => state?.fieldTypeOption?.activeFieldTypeOption;

export const isFetchingFieldTypeOptions = (state: RootState) => state?.fieldTypeOption?.isFetchingFieldTypeOptions;

export const isPostingFieldTypeOption = (state: RootState) => state?.fieldTypeOption?.isPostingFieldTypeOption;
export const postingFieldTypeOptionFailed = (state: RootState) => state?.fieldTypeOption?.postingFieldTypeOptionFailed;
export const postingFieldTypeOptionError = (state: RootState) => state?.fieldTypeOption?.postingFieldTypeOptionError;
export const postingFieldTypeOptionValidationErrors = (state: RootState) => state?.fieldTypeOption?.postingFieldTypeOptionValidationErrors;
export const fetchingFieldTypeOptionsFailed = (state: RootState) => state?.fieldTypeOption?.fetchingFieldTypeOptionsFailed;
