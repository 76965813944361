import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getShadowEntries, isFetchingShadowEntries } from '../../../selectors/ShadowEntry';
import { fetchShadowEntries, storeShadowEntry } from '../../../actions/ShadowEntry';
import { ShadowEntry } from '../../../types/ShadowEntry';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ShadowEntryDataTable = () => {

  const shadowEntries = useSelector(getShadowEntries);
  const isLoading = useSelector(isFetchingShadowEntries);
  const [toggle, setToggle] = useState(false);
  const [shadowEntry, setActiveShadowEntry] = useState<ShadowEntry | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SHADOW_ENTRY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SHADOW_ENTRY_UPDATE, row.id));
  };

  const onArchive = (row: ShadowEntry) => {
    setActiveShadowEntry(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (shadowEntry?.id) {
      await storeShadowEntry(shadowEntry?.id);
      setRefetch(!refetch);
    }
    setActiveShadowEntry(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'formTitle', label: 'Form', mobileFriendly: false },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'data', label: 'data', mobileFriendly: true },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };


  return <View title="ShadowEntries" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchShadowEntries} paging={shadowEntries?.paging || []}
                       isLoading={isLoading} data={shadowEntries?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Archive ShadowEntry" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this shadowEntry?
            </Modal>
    </View>;
};

export default ShadowEntryDataTable;
