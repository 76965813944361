import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SOLUTION_BADGES_REQUEST,
  FETCH_SOLUTION_BADGES_RESPONSE,
  FETCH_SOLUTION_BADGES_FAILURE,
  FETCH_SOLUTION_BADGE_REQUEST,
  FETCH_SOLUTION_BADGE_RESPONSE,
  FETCH_SOLUTION_BADGE_FAILURE,
  POST_SOLUTION_BADGE_FAILURE,
  POST_SOLUTION_BADGE_REQUEST,
  POST_SOLUTION_BADGE_RESPONSE,
  SolutionBadgeActionTypes, SolutionBadgeDataModel, SolutionBadge,
} from '../types/SolutionBadge';

import { RootState } from '../store/configureStore';

import { archiveSolutionBadge, getSolutionBadge, getSolutionBadges, postSolutionBadge, putSolutionBadge } from '../api/SolutionBadge';
import { DataTableParamsModel } from '../types/Common';

export function fetchSolutionBadgesRequest(): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGES_REQUEST,
    payload: null,
  };
}

export function fetchSolutionBadgesResponse(
  solutionBadges: SolutionBadgeDataModel,
): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGES_RESPONSE,
    payload: solutionBadges,
  };
}

export function fetchSolutionBadgesFailure(): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGES_FAILURE,
    payload: null,
  };
}

export function fetchSolutionBadgeRequest(): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGE_REQUEST,
    payload: null,
  };
}

export function fetchSolutionBadgeResponse(
  solutionBadge: SolutionBadge,
): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGE_RESPONSE,
    payload: solutionBadge,
  };
}

export function fetchSolutionBadgeFailure(): SolutionBadgeActionTypes {
  return {
    type: FETCH_SOLUTION_BADGE_FAILURE,
    payload: null,
  };
}


export function postSolutionBadgeRequest(): SolutionBadgeActionTypes {
  return {
    type: POST_SOLUTION_BADGE_REQUEST,
    payload: null,
  };
}

export function postSolutionBadgeResponse(): SolutionBadgeActionTypes {
  return {
    type: POST_SOLUTION_BADGE_RESPONSE,
    payload: null,
  };
}

export function postSolutionBadgeFailure(error: string, validationErrors: any): SolutionBadgeActionTypes {
  return {
    type: POST_SOLUTION_BADGE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveSolutionBadge = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionBadgeRequest());
    const asyncResp: any = await getSolutionBadge(id);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionBadgeResponse(asyncResp.data));
    } else {
      await dispatch(fetchSolutionBadgeFailure());
    }
  };

export const fetchSolutionBadges = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionBadgesRequest());
    const asyncResp: any = await getSolutionBadges(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionBadgesResponse(asyncResp));
    } else {
      await dispatch(fetchSolutionBadgesFailure());
    }
  };

export const storeSolutionBadge = async (id: string) => {
  try {
    await archiveSolutionBadge(id);
  } catch (error) {
    throw error;
  }
};

export const createSolutionBadge = (
  badgeId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionBadgeRequest());
    const asyncResp: any = await postSolutionBadge(badgeId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionBadgeResponse());
    } else {
      await dispatch(postSolutionBadgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSolutionBadge = (
  id: string,
  badgeId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionBadgeRequest());
    const asyncResp: any = await putSolutionBadge(id, badgeId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionBadgeResponse());
    } else {
      await dispatch(postSolutionBadgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


