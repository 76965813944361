import { ApiDataModel, FormError } from './Common';
import { Client } from './Client';

export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export const FETCH_PLANS_RESPONSE = 'FETCH_PLANS_RESPONSE';
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';

export const FETCH_PLAN_REQUEST = 'FETCH_PLAN_REQUEST';
export const FETCH_PLAN_RESPONSE = 'FETCH_PLAN_RESPONSE';
export const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';

export const POST_PLAN_REQUEST = 'POST_PLAN_REQUEST';
export const POST_PLAN_RESPONSE = 'POST_PLAN_RESPONSE';
export const POST_PLAN_FAILURE = 'POST_PLAN_FAILURE';

export interface Plan {
  id: string;
  title: string;
  description: string;
  clientId: string;
  client_title: Client;
  planClientId: string;
  monthlyFee: number;
  createdAt: Date;
  createdBy: Date;
  updatedAt: Date;
  updatedBy: Date;
}

export interface PlanDataModel extends Omit<ApiDataModel, 'data'> {
  data: Plan[];
}

export interface PlanState {
  plans: PlanDataModel | null;
  activePlan: Plan | null;
  isFetchingPlans: boolean;
  fetchingPlansFailed: boolean;
  postingPlanFailed?: boolean;
  isPostingPlan: boolean;
  postingPlanValidationErrors: FormError[] | null;
  postingPlanError: string | null;
}

interface FetchPlansRequestAction {
  type: typeof FETCH_PLANS_REQUEST;
  payload: null;
}

interface FetchPlansResponseAction {
  type: typeof FETCH_PLANS_RESPONSE;
  payload: PlanDataModel;
}

interface FetchPlansFailureAction {
  type: typeof FETCH_PLANS_FAILURE;
  payload: null;
}

interface FetchPlanRequestAction {
  type: typeof FETCH_PLAN_REQUEST;
  payload: null;
}

interface FetchPlanResponseAction {
  type: typeof FETCH_PLAN_RESPONSE;
  payload: Plan;
}

interface FetchPlanFailureAction {
  type: typeof FETCH_PLAN_FAILURE;
  payload: null;
}

interface PostPlanRequestAction {
  type: typeof POST_PLAN_REQUEST;
  payload: null;
}

interface PostPlanResponseAction {
  type: typeof POST_PLAN_RESPONSE;
  payload: null;
}

interface PostPlanFailureAction {
  type: typeof POST_PLAN_FAILURE;
  payload: {
    error?: string;
    validationErrors?: any;
  };
}

export type PlanActionTypes =
    | FetchPlansRequestAction
    | FetchPlansResponseAction
    | FetchPlansFailureAction
    | PostPlanRequestAction
    | PostPlanResponseAction
    | PostPlanFailureAction
    | FetchPlanRequestAction
    | FetchPlanResponseAction
    | FetchPlanFailureAction;
