import React from 'react';

type Props = {
  x: number;
  y: number;
  isActive: boolean;
};

const Pointer = (props: Props) => {
  return (<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform={`translate(${props.x}, ${props.y}) scale(1.5)`} fill={`url(#${props.isActive ? 'linearGradientActive' : 'linearGradientInactive'})`} fillRule="nonzero">
            <path id="Path_255" data-name="Path 255" d="M20.779,9.282a7.282,7.282,0,0,0-14.565,0c0,5.462,7.282,13.351,7.282,13.351S20.779,14.744,20.779,9.282Zm-9.71,0A2.427,2.427,0,1,1,13.5,11.71,2.435,2.435,0,0,1,11.069,9.282ZM5,23.847v2.427H21.992V23.847Z"/>        </g>
    </g>);
};

export default Pointer;
