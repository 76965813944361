import { useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useEditMode = (route: string) => {
  const matchUpdate = useRouteMatch(route);
  const [editMode, setEditMode] = useState<boolean>(matchUpdate?.isExact === true);
  // @ts-ignore
  const [activeId, setActiveId] = useState(matchUpdate?.params?.id);
  useEffect(() => {
    setEditMode(matchUpdate?.isExact === true);
    // @ts-ignore
    setActiveId(matchUpdate?.params?.id);
  }, [matchUpdate]);

  return [editMode, activeId] as const;
};






