import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createShadowEntry, fetchActiveShadowEntry, updateShadowEntry } from '../../../actions/ShadowEntry';
import {
  getActiveShadowEntry,
  isPostingShadowEntry,
  postingShadowEntryError,
  postingShadowEntryFailed,
  postingShadowEntryValidationErrors,
  isFetchingShadowEntries,
} from '../../../selectors/ShadowEntry';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';
import { getGroups } from '../../../selectors/Group';
import { getEntries } from '../../../selectors/Entry';
import { getForms } from '../../../selectors/Form';
import { getClients } from '../../../selectors/Client';
import DropDown from '../../../components/DropDown';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchForms } from '../../../actions/Form';
import { fetchEntries } from '../../../actions/Entry';



const ShadowEntryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeShadowEntry = useSelector(getActiveShadowEntry);
  const isPosting = useSelector(isPostingShadowEntry);
  const isFetching = useSelector(isFetchingShadowEntries);
  const postingFailed = useSelector(postingShadowEntryFailed);
  const postingError = useSelector(postingShadowEntryError);
  const postingValidationErrors = useSelector(postingShadowEntryValidationErrors);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const forms = useSelector(getForms);
  const entries = useSelector(getEntries);


  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SHADOW_ENTRY_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'formId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'data', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [formId, setFormId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [data, setData] = useState('');
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  const [itemsForms, setItemsForms] = useState(forms?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledForm, setDisabledForm] = useState(true);

  const [itemsEntries, setItemsEntries] = useState(entries?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledEntry, setDisabledEntry] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchForms());
    dispatch(fetchEntries());
    if (editMode) {
      dispatch(fetchActiveShadowEntry(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeShadowEntry && editMode) {
      setGroupId(activeShadowEntry?.groupId);
      setClientId(activeShadowEntry?.clientId);
      setFormId(activeShadowEntry?.formId);
      setEntryId(activeShadowEntry?.entryId);
      setData(activeShadowEntry?.data);
    }
  }, [activeShadowEntry]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    setItemsForms(forms?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [forms]);

  useEffect(() => {
    setItemsEntries(entries?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [entries]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SHADOW_ENTRY);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createShadowEntry(groupId, clientId, formId, entryId, data));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateShadowEntry(activeId, groupId, clientId, formId, entryId, data));
    }
  };


  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(clientData => clientData.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
    setDisabledForm(false);
    setItemsForms(forms?.data.filter(dataF => dataF.clientId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
  };
  
  const onFormSelect = (obj: any) => {
    setFormId(obj.value);
    setDisabledEntry(false);
    setItemsEntries(entries?.data.filter(dataE => dataE.formId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
  };


  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };


  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} ShadowEntry</h4>
    {postingError && (
      <Alert className="mt-3" variant="danger">
        {postingError}
      </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="groupId"
                placeholder="Please select a Group"
                required label="Group"
                items={itemsGroups}
                value={groupId}
                disabled={isPosting || isFetching}
                onSelect={onGroupSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="clientId"
                placeholder="Please select a Client"
                required label="Client"
                items={itemsClients}
                value={clientId}
                disabled={isPosting || isFetching || disabledClient}
                onSelect={onClientSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="formId"
                placeholder="Please select a Form"
                required label="Form"
                items={itemsForms}
                value={formId}
                disabled={isPosting || isFetching || disabledForm}
                onSelect={onFormSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="entryId"
                placeholder="Please select a Entry"
                required label="Entry"
                items={itemsEntries}
                value={entryId}
                disabled={isPosting || isFetching || disabledEntry}
                onSelect={onEntrySelect}
                type="default"
      />
      <Input v={v} err={err}
             name="data"
             id="data"
             label="Data"
             value={data}
             onChange={setData}
             disabled={isPosting || isFetching}
             onBlur={setData}
             placeholder="Insert a data"
      />
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
            onClick={() => editMode ? onSave() : onSubmit()}
            title={editMode ? 'SAVE' : 'CREATE'} />
        </div>
      </div>
    </form>
  </View>;
};

export default ShadowEntryForm;
