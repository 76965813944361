
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_RESPONSE,
  FETCH_TAGS_FAILURE,
  FETCH_TAG_REQUEST,
  FETCH_TAG_RESPONSE,
  FETCH_TAG_FAILURE,
  POST_TAG_FAILURE,
  POST_TAG_REQUEST,
  POST_TAG_RESPONSE,
  TagActionTypes, TagDataModel, Tag,
} from '../types/Tag';

import { RootState } from '../store/configureStore';

import { archiveTag, getTag, getTags, postTag, postTags, putTag } from '../api/Tag';
import { DataTableParamsModel } from '../types/Common';

export function fetchTagsRequest(): TagActionTypes {
  return {
    type: FETCH_TAGS_REQUEST,
    payload: null,
  };
}

export function fetchTagsResponse(
  tags: TagDataModel,
): TagActionTypes {
  return {
    type: FETCH_TAGS_RESPONSE,
    payload: tags,
  };
}

export function fetchTagsFailure(): TagActionTypes {
  return {
    type: FETCH_TAGS_FAILURE,
    payload: null,
  };
}

export function fetchTagRequest(): TagActionTypes {
  return {
    type: FETCH_TAG_REQUEST,
    payload: null,
  };
}

export function fetchTagResponse(
  tag: Tag,
): TagActionTypes {
  return {
    type: FETCH_TAG_RESPONSE,
    payload: tag,
  };
}

export function fetchTagFailure(): TagActionTypes {
  return {
    type: FETCH_TAG_FAILURE,
    payload: null,
  };
}


export function postTagRequest(): TagActionTypes {
  return {
    type: POST_TAG_REQUEST,
    payload: null,
  };
}

export function postTagResponse(): TagActionTypes {
  return {
    type: POST_TAG_RESPONSE,
    payload: null,
  };
}

export function postTagFailure(error: string, validationErrors: any): TagActionTypes {
  return {
    type: POST_TAG_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveTag = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchTagRequest());
    const asyncResp: any = await getTag(id);
    if (asyncResp?.success) {
      await dispatch(fetchTagResponse(asyncResp.data));
    } else {
      await dispatch(fetchTagFailure());
    }
  };

export const fetchTags = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchTagsRequest());
    const asyncResp: any = await getTags(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchTagsResponse(asyncResp));
    } else {
      await dispatch(fetchTagsFailure());
    }
  };

export const storeTag = async (id: string) => {
  try {
    await archiveTag(id);
  } catch (error) {
    console.error('Error archiving tag:', error);
    return error;
  }
};

export const createTag = (
  value: string, type: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postTagRequest());
    const asyncResp: any = await postTag(value, type, active);
    if (asyncResp?.success) {
      await dispatch(postTagResponse());
    } else {
      await dispatch(postTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


export const createTags = (
  value: string[], type: string[], active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postTagRequest());
    const asyncResp: any = await postTags(value, type, active);
    if (asyncResp?.success) {
      await dispatch(postTagResponse());
      await dispatch(fetchTags());
    } else {
      await dispatch(postTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
export const updateTag = (
  id: string,
  value: string,
  type: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postTagRequest());
    const asyncResp: any = await putTag(id, value, type, active);
    if (asyncResp?.success) {
      await dispatch(postTagResponse());
    } else {
      await dispatch(postTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


