import { RootState } from '../store/configureStore';

export const getWizards = (state: RootState) => state?.wizard?.wizards;

export const getActiveWizard = (state: RootState) => state?.wizard?.activeWizard;

export const wizardGetInfoImage = (state: RootState) => state?.wizard?.imageInfo;

export const wizardGetInfoText = (state: RootState) => state?.wizard?.textInfo;

export const getValuationInfo = (state: RootState) => state?.wizard?.valuationInfo;

export const isFetchingWizards = (state: RootState) => state?.wizard?.isFetchingWizards;

export const isPostingWizard = (state: RootState) => state?.wizard?.isPostingWizard;
export const postingWizardFailed = (state: RootState) => state?.wizard?.postingWizardFailed;
export const postingWizardError = (state: RootState) => state?.wizard?.postingWizardError;
export const postingWizardValidationErrors = (state: RootState) => state?.wizard?.postingWizardValidationErrors;
export const fetchingWizardsFailed = (state: RootState) => state?.wizard?.fetchingWizardsFailed;