import React from 'react';
import './Pill.scss';
import assetGallery from '../AssetGallery/AssetGallery';

type PillProps = {
  text: string;
  type: 'white' | 'black';
  icon?: 'tag' | 'search' | string;
  cancellable?: boolean;
  onClick?: () => void;
  hoverable?:boolean
};

const Pill: React.FC<PillProps> = ({ text, type, icon, cancellable = false, hoverable = false, onClick = () => {} }) => {
  const handlePillClick = () => {
    onClick?.();
  };

  let iconSource = '';
  if (icon === 'tag') {
    iconSource = assetGallery.tagImg;
  } else if (icon === 'search') {
    iconSource = assetGallery.searchesTag;
  } else if (typeof icon === 'string') {
    iconSource = icon; // Use the custom icon URL
  }


  return (
      <div className={`pills pills__${type} ${cancellable ? 'cancellable' : ''} ${hoverable ? 'pills__hover' : ''}`} onClick={handlePillClick}>
        {(icon && !cancellable) && <img className={`pills__${type}__icon`} src={iconSource} alt='pill-icon'/>}
        {cancellable && <img className={`pills__${type}__icon pills__${type}__icon__cancel`} src={assetGallery.xIcon} alt='cancel-icon'/>}
        <div>{text}</div>
      </div>
  );
};

export default Pill;
