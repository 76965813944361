import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getGroups(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.GROUP,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getGroup(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.GROUP}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postGroup(title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.GROUP,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putGroup(
  id: string,
  title: string,
  description: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.GROUP}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveGroup(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.GROUP}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
