import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getFormFieldRoles, isFetchingFormFieldRoles } from '../../../selectors/FormFieldRole';
import { destroyFormFieldRole, fetchFormFieldRoles } from '../../../actions/FormFieldRole';
import { FormFieldRole } from '../../../types/FormFieldRole';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const FormFieldRoleDataTable = () => {

  const formFieldRoles = useSelector(getFormFieldRoles);
  const isLoading = useSelector(isFetchingFormFieldRoles);
  const [toggle, setToggle] = useState(false);
  const [formFieldRole, setActiveFormFieldRole] = useState<FormFieldRole | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FORM_FIELD_ROLE_CREATE);
  };


  const onDelete = (row: FormFieldRole) => {
    setActiveFormFieldRole(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (formFieldRole?.id) {
      await destroyFormFieldRole(formFieldRole?.id);
      setRefetch(!refetch);
    }
    setActiveFormFieldRole(null);
  };


  const config = {
    columns: [
      { key: 'formFieldTitle', label: 'Form Field', mobileFriendly: true },
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return (
      <View title="FormField/Role" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchFormFieldRoles} paging={formFieldRoles?.paging || []}
                       isLoading={isLoading} data={formFieldRoles?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Delete FormField/Role" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this FormField/Role?
            </Modal>

        </View>
  );
};

export default FormFieldRoleDataTable;