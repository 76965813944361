import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getMatches, isFetchingMatches } from '../../../selectors/Match';
import { fetchMatches, storeMatch } from '../../../actions/Match';
import { Match } from '../../../types/Match';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const MatchDataTable = () => {

  const matches = useSelector(getMatches);
  const isLoading = useSelector(isFetchingMatches);
  const [toggle, setToggle] = useState(false);
  const [match, setActiveMatch] = useState<Match | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.MATCH_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.MATCH_UPDATE, row.id));
  };
  

  const onArchive = (row: Match) => {
    setActiveMatch(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (match?.id) {
      await storeMatch(match?.id);
      setRefetch(!refetch);
    }
    setActiveMatch(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'solutionTitle', label: 'Solution', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'status', label: 'Status', mobileFriendly: true },
      { key: 'impact', label: 'Impact', mobileFriendly: true },
      { key: 'significance', label: 'Significance', mobileFriendly: true },
      { key: 'reuseHierarchy', label: 'Reuse Hierarchy', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="Matches" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchMatches} paging={matches?.paging || []}
                 isLoading={isLoading} data={matches?.data || []} config={config} dependencies={refetch}/>
      <Modal show={toggle} title="Archive Match" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this match?
      </Modal>
    </View>
  );
};

export default MatchDataTable;

