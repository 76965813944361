import { RootState } from '../store/configureStore';

export const getFormFieldRoles = (state: RootState) => state?.formFieldRole?.formFieldRoles;

export const isFetchingFormFieldRoles = (state: RootState) =>
  state?.formFieldRole?.isFetchingFormFieldRoles;

export const isPostingFormFieldRole = (state: RootState) =>
  state?.formFieldRole?.isPostingFormFieldRole;
export const postingFormFieldRoleFailed = (state: RootState) =>
  state?.formFieldRole?.postingFormFieldRoleFailed;
export const postingFormFieldRoleError = (state: RootState) =>
  state?.formFieldRole?.postingFormFieldRoleError;
export const postingFormFieldRoleValidationErrors = (state: RootState) =>
  state?.formFieldRole?.postingFormFieldRoleValidationErrors;

export const fetchingFormFieldRolesFailed = (state: RootState) =>
  state?.formFieldRole?.fetchingFormFieldRolesFailed;
