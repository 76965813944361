import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_RESPONSE,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_RESPONSE,
  FETCH_CATEGORY_FAILURE,
  POST_CATEGORY_FAILURE,
  POST_CATEGORY_REQUEST,
  POST_CATEGORY_RESPONSE,
  CategoryActionTypes, CategoryDataModel, Category,
} from '../types/Category';

import { RootState } from '../store/configureStore';

import { archiveCategory, getCategory, getCategories, getCategoriesByShortcodes, postCategory, putCategory } from '../api/Category';
import { DataTableParamsModel } from '../types/Common';

export function fetchCategoriesRequest(): CategoryActionTypes {
  return {
    type: FETCH_CATEGORIES_REQUEST,
    payload: null,
  };
}

export function fetchCategoriesResponse(
  categories: CategoryDataModel,
): CategoryActionTypes {
  return {
    type: FETCH_CATEGORIES_RESPONSE,
    payload: categories,
  };
}

export function fetchCategoriesFailure(): CategoryActionTypes {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: null,
  };
}

export function fetchCategoryRequest(): CategoryActionTypes {
  return {
    type: FETCH_CATEGORY_REQUEST,
    payload: null,
  };
}

export function fetchCategoryResponse(
  categories: Category,
): CategoryActionTypes {
  return {
    type: FETCH_CATEGORY_RESPONSE,
    payload: categories,
  };
}

export function fetchCategoryFailure(): CategoryActionTypes {
  return {
    type: FETCH_CATEGORY_FAILURE,
    payload: null,
  };
}


export function postCategoryRequest(): CategoryActionTypes {
  return {
    type: POST_CATEGORY_REQUEST,
    payload: null,
  };
}

export function postCategoryResponse(): CategoryActionTypes {
  return {
    type: POST_CATEGORY_RESPONSE,
    payload: null,
  };
}

export function postCategoryFailure(error: string, validationErrors: any): CategoryActionTypes {
  return {
    type: POST_CATEGORY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveCategory = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCategoryRequest());
    const asyncResp: any = await getCategory(id);
    if (asyncResp?.success) {
      await dispatch(fetchCategoryResponse(asyncResp.data));
    } else {
      await dispatch(fetchCategoryFailure());
    }
  };

export const fetchCategories = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCategoriesRequest());
    const asyncResp: any = await getCategories(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchCategoriesResponse(asyncResp));
    } else {
      await dispatch(fetchCategoriesFailure());
    }
  };

export const fetchCategoriesGuest = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCategoriesRequest());
    const asyncResp: any = await getCategories(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchCategoriesResponse(asyncResp));
    } else {
      await dispatch(fetchCategoriesFailure());
    }
  };

export const fetchCategoriesByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCategoriesRequest());
    const asyncResp: any = await getCategoriesByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchCategoriesResponse(asyncResp));
    } else {
      await dispatch(fetchCategoriesFailure());
    }
  };

export const storeCategory = async (id: string) => {
  try {
    await archiveCategory(id);
  } catch (error) {
    throw error;
  }
};

export const createCategory = (
  groupId: string, clientId: string, order: number, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCategoryRequest());
    const asyncResp: any = await postCategory(groupId, clientId, order, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postCategoryResponse());
    } else {
      await dispatch(postCategoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateCategory = (
  id: string,
  groupId: string,
  clientId: string,
  order: number,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCategoryRequest());
    const asyncResp: any = await putCategory(id, groupId, clientId, order, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postCategoryResponse());
    } else {
      await dispatch(postCategoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


