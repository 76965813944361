import React, { useEffect } from 'react';
import './AccountInformation.scss';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions, isFetchingSubscriptions } from '../../../selectors/Subscription';
import { fetchSubscriptions } from '../../../actions/Subscription';
import { getActiveClient, getActiveUserSubscription } from '../../../helpers/functions';
import { Badge } from 'react-bootstrap';
import c from '../../../helpers/constants';
import Loading from '../../../components/Loading/Loading';
import { getLoggedInUser } from '../../../selectors/Auth';
import { useHistory } from 'react-router-dom';

const Subscriptions = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(getSubscriptions);
  const isLoading = useSelector(isFetchingSubscriptions);
  const history = useHistory();
  const activeUser = useSelector(getLoggedInUser);
  const client = getActiveClient();
  const userHasActiveSubscription = getActiveUserSubscription(activeUser, client.id);

  useEffect(() => {
    if (client?.id) {
      dispatch(fetchSubscriptions({ baseSearch: { clientId: client.id }, colKey: 'created', sortDirection: 'desc' }));
    }
  }, [client?.id, dispatch]);

  // Get active, pending, and attempted transactions
  // @ts-ignore
  const activeSubscription = subscriptions?.data?.find(sub => (sub.id === userHasActiveSubscription.activeSubscription));
  const pendingSubscription = subscriptions?.data?.find(sub => sub.transactionStatus === 'pending');
  // @ts-ignore
  const attemptedTransactions = subscriptions?.data;

  const onUpgrade = () => history.push(c.APP_ROUTES.SUBSCRIPTION);
  const toInvoice = (transactionId: string) => {
    window.open(c.APP_ROUTES.RETURN + '?transaction_id=' + transactionId, '_blank');
  };

  if (isLoading || !subscriptions) return <Loading />;

  return (
      <div className="container p-4">
        {(pendingSubscription && !activeSubscription) ? (
            <div className="row">
              <div className="col">
                <h3 className="text-warning">Pending Subscription</h3>
                <p>Your subscription is currently pending. Please wait for confirmation.</p>
              </div>
            </div>
        ) : (
            <>
              {activeSubscription ? (
                  <div className="row mb-3">
                    <div className="col">
                      <h3>
                        Active Subscription:
                        <Badge bg={activeSubscription?.planTitle === 'Free' ? 'primary' : 'warning'} className="ml-2">
                          {activeSubscription?.planTitle}
                        </Badge>
                      </h3>
                      {activeSubscription?.planTitle === 'Free' && (
                          <Button onClick={onUpgrade} title="Upgrade Subscription" />
                      )}
                    </div>
                  </div>
              ) : (
                  <div className="row mb-3">
                    <div className="col">
                      <h4>You have not selected a subscription plan!</h4>
                    </div>
                    <div className="col">
                      <Button onClick={onUpgrade} title="Upgrade Subscription" />
                    </div>
                  </div>
              )}

              {attemptedTransactions && attemptedTransactions.length > 0 && (
                  <div className="row">
                    <div className="col">
                      <h4>Transactions</h4>
                      <table className="table">
                        <thead>
                        <tr>
                          <th>Plan</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Invoice</th>
                        </tr>
                        </thead>
                        <tbody>
                        {attemptedTransactions.map(transaction => {
                          const isClickable = transaction.transactionStatus === 'completed' && transaction.planTitle != 'Free';

                          return (
                              <tr key={transaction.id}>
                                <td>{transaction.planTitle}</td>
                                <td>{Number(transaction.transactionAmount) / 100}</td>
                                <td>
                                    <span className={`text-${transaction.transactionStatus === 'failed' ? 'danger' : 'secondary'}`}>
                                      {transaction.transactionStatus}
                                    </span>
                                </td>
                                <td>{transaction.created}</td>
                                <td
                                    className={isClickable ? 'user-profile__link' : ''}
                                    onClick={() => isClickable && toInvoice(transaction.transactionId)}
                                >
                                  {isClickable ? transaction.transactionId : '-'}
                                </td>
                              </tr>
                          );
                        })}

                        </tbody>
                      </table>
                    </div>
                  </div>
              )}
            </>
        )}
      </div>
  );
};

export default Subscriptions;
