import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAuctions, isFetchingAuctions } from '../../../selectors/Auction';
import { fetchAuctions, storeAuction } from '../../../actions/Auction';
import { Auction } from '../../../types/Auction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const AuctionDataTable = () => {

  const auctions = useSelector(getAuctions);
  const isLoading = useSelector(isFetchingAuctions);
  const [toggle, setToggle] = useState(false);
  const [auction, setActiveAuction] = useState<Auction | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.AUCTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.AUCTION_UPDATE, row.id));
  };

  const onArchive = (row: Auction) => {
    setActiveAuction(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (auction?.id) {
      await storeAuction(auction?.id);
      setRefetch(!refetch);
    }
    setActiveAuction(null);
  };


  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },

    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Auctions" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchAuctions} paging={auctions?.paging || []}
                   isLoading={isLoading}  data={auctions?.data || []} config={config} dependencies={refetch} />
            <Modal show={toggle} title="Archive Auction" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this auction?
            </Modal>
        </View>
  );
};

export default AuctionDataTable;

