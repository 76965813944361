import React, { useEffect, useState } from 'react';
import FileSelector from '../../../components/FileSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from '../../../selectors/User';
import { fetchActiveUser, updateUser } from '../../../actions/User';
import { getUserToken } from '../../../helpers/functions';
import Button from '../../../components/Button';
import { getActiveConfiguration } from '../../../selectors/Configuration';


const ProfilePicture = () => {
  const activeUser = useSelector(getActiveUser);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const dispatch = useDispatch();
  const [asset, setAsset] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
  }, [dispatch, hasSubmitted]);

  useEffect(() => {
    if (activeUser && activeUser?.avatar){
      setAsset(activeUser?.avatar);
    }
  }, [activeUser, hasSubmitted]);

  const onSubmit = () => {
    if (asset && activeUser){
      dispatch(updateUser(activeUser.id, activeUser.roleId, activeUser.email, '', activeUser.username, activeUser.firstName, activeUser.lastName, '', '', '', asset, true, true ));
      setHasSubmitted(true);
      setShowSuccess(true);
    }
  };

  const handleFilePick = (id: any) => {
    setAsset(id);
    setShowSuccess(false);
  };
  const handleFileUnPick = () => {
    setAsset('');
    setShowSuccess(false);
  };

  return <div className='user-profile__section--grey'>
      <FileSelector
        groupId={activeConfiguration?.groupId}
        clientId={activeConfiguration?.clientId}
        type="images"
        dynamicId = 'primary'
        picked={asset}
        onPick={handleFilePick}
        onUnPick={handleFileUnPick}
      />
    {showSuccess && (
        <div style={{ color: 'green', marginTop: '10px' }}>Profile image updated successfully!</div>
    )}
    <div className={'user-profile__position-button-form user-profile__position--margin-extra'}>
      <Button onClick={() => onSubmit()}
              title={'Submit'}/>
    </div>
  </div>;
};

export default ProfilePicture;



