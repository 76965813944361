import {
  FETCH_AUCTION_BID_FAILURE,
  FETCH_AUCTION_BID_REQUEST,
  FETCH_AUCTION_BID_RESPONSE,
  FETCH_AUCTION_BIDS_FAILURE,
  FETCH_AUCTION_BIDS_REQUEST,
  FETCH_AUCTION_BIDS_RESPONSE,
  POST_AUCTION_BID_FAILURE,
  POST_AUCTION_BID_RESPONSE,
  AuctionBidActionTypes,
  AuctionBidState,
  POST_AUCTION_BID_REQUEST,
} from '../types/AuctionBid';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AuctionBidState = {
  auctionBids: null,
  activeAuctionBid: null,
  isFetchingAuctionBids: false,
  fetchingAuctionBidsFailed: false,
  isPostingAuctionBid: false,
  postingAuctionBidFailed: false,
  postingAuctionBidError: null,
  postingAuctionBidValidationErrors: null,
};

const auctionBidsReducer = (
  state = initialState,
  action: AuctionBidActionTypes,
): AuctionBidState => {
  switch (action.type) {
    case FETCH_AUCTION_BIDS_REQUEST:
      return {
        ...state,
        auctionBids: null,
        isFetchingAuctionBids: true,
        fetchingAuctionBidsFailed: false,
      };
    case FETCH_AUCTION_BIDS_RESPONSE:
      return {
        ...state,
        auctionBids: action.payload || [],
        isFetchingAuctionBids: false,
        fetchingAuctionBidsFailed: false,
      };
    case FETCH_AUCTION_BIDS_FAILURE:
      return {
        ...state,
        auctionBids: null,
        isFetchingAuctionBids: false,
        fetchingAuctionBidsFailed: true,
      };
    case FETCH_AUCTION_BID_REQUEST:
      return {
        ...state,
        auctionBids: null,
        isFetchingAuctionBids: true,
        fetchingAuctionBidsFailed: false,
      };
    case FETCH_AUCTION_BID_RESPONSE:
      return {
        ...state,
        activeAuctionBid: action.payload,
        isFetchingAuctionBids: false,
        fetchingAuctionBidsFailed: false,
      };
    case FETCH_AUCTION_BID_FAILURE:
      return {
        ...state,
        auctionBids: null,
        isFetchingAuctionBids: false,
        fetchingAuctionBidsFailed: true,
      };
    case POST_AUCTION_BID_REQUEST:
      return {
        ...state,
        isPostingAuctionBid: true,
        postingAuctionBidError: null,
        postingAuctionBidValidationErrors: null,
        postingAuctionBidFailed: false,
      };
    case POST_AUCTION_BID_RESPONSE:
      return {
        ...state,
        isPostingAuctionBid: false,
        postingAuctionBidError: null,
        postingAuctionBidValidationErrors: null,
        postingAuctionBidFailed: false,
      };
    case POST_AUCTION_BID_FAILURE:
      return {
        ...state,
        isPostingAuctionBid: false,
        postingAuctionBidError: action.payload.error || 'An error occurred',
        postingAuctionBidValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAuctionBidFailed: true,
      };
    default:
      return state;
  }
};

export default auctionBidsReducer;
