import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_RESPONSE,
  FETCH_OFFERS_FAILURE,
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_RESPONSE,
  FETCH_OFFER_FAILURE,
  POST_OFFER_FAILURE,
  POST_OFFER_REQUEST,
  POST_OFFER_RESPONSE,
  OfferActionTypes, OfferDataModel, Offer,
} from '../types/Offer';

import { RootState } from '../store/configureStore';

import {
  storeOffer,
  getOffer,
  getOffers,
  postOffer,
  putOffer,
} from '../api/Offer';

import { DataTableParamsModel } from '../types/Common';
import { Row } from '../types/Common';



export function fetchOffersRequest(): OfferActionTypes {
  return {
    type: FETCH_OFFERS_REQUEST,
    payload: null,
  };
}

export function fetchOffersResponse(
  offers: OfferDataModel,
): OfferActionTypes {
  return {
    type: FETCH_OFFERS_RESPONSE,
    payload: offers,
  };
}

export function fetchOffersFailure(): OfferActionTypes {
  return {
    type: FETCH_OFFERS_FAILURE,
    payload: null,
  };
}

export function fetchOfferRequest(): OfferActionTypes {
  return {
    type: FETCH_OFFER_REQUEST,
    payload: null,
  };
}

export function fetchOfferResponse(
  offer: Offer,
): OfferActionTypes {
  return {
    type: FETCH_OFFER_RESPONSE,
    payload: offer,
  };
}

export function fetchOfferFailure(): OfferActionTypes {
  return {
    type: FETCH_OFFER_FAILURE,
    payload: null,
  };
}


export function postOfferRequest(): OfferActionTypes {
  return {
    type: POST_OFFER_REQUEST,
    payload: null,
  };
}

export function postOfferResponse(): OfferActionTypes {
  return {
    type: POST_OFFER_RESPONSE,
    payload: null,
  };
}

export function postOfferFailure(error: string, validationErrors: any): OfferActionTypes {
  return {
    type: POST_OFFER_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveOffer = (id: string, groupShortcode?: string, clientShortcode?: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferRequest());
    const asyncResp: any = await getOffer(id, groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchOfferResponse(asyncResp.data));
    } else {
      await dispatch(fetchOfferFailure());
    }
  };

export const fetchOffers = (dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOffersRequest());
    const asyncResp: any = await getOffers(dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOffersResponse(asyncResp));
    } else {
      await dispatch(fetchOffersFailure());
    }
  };

export const archiveOffer = async (id: string) => {
  try {
    await storeOffer(id);
  } catch (error) {
    throw error;
  }
};


export const createOffer = (
  donorClientId: string,
  projectManagerUserIds: string | string[] | object | object[],
  salesRepUserId: string,
  shipmentContactId: string,
  solutionId: string,
  subject: string,
  details: string,
  date: string,
  clientNumber: string,
  currency: string,
  pickupLocation: string,
  collectionDate: string,
  notesToEME: string,
  notesToShippingProvider: string,
  statusCode?: string,
  offerItems?: Row[],
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    console.log(projectManagerUserIds, 'outter');
    dispatch(postOfferRequest());
    // @ts-ignore
    const asyncResp: any = await postOffer(donorClientId, projectManagerUserIds,
      salesRepUserId, shipmentContactId, solutionId, subject, details, date, clientNumber, currency,
      pickupLocation, collectionDate, notesToEME, notesToShippingProvider, statusCode, offerItems);
    if (asyncResp?.success) {
      await dispatch(postOfferResponse());
    } else {
      await dispatch(postOfferFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateOffer = (
  id: string,
  donorClientId: string,
  projectManagerUserIds: string | string[] | object | object[],
  salesRepUserId: string,
  shipmentContactId: string,
  solutionId: string,
  subject: string,
  details: string,
  date: string,
  clientNumber: string,
  currency: string,
  pickupLocation: string,
  collectionDate: string,
  notesToEME: string,
  notesToShippingProvider: string,
  statusCode?: string,
  reEstimationNotes?: string,
  offerItems?: Row[],
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferRequest());
    // @ts-ignore
    const asyncResp: any = await putOffer(id, donorClientId, projectManagerUserIds,
      salesRepUserId, shipmentContactId, solutionId, subject, details, date, clientNumber, currency,
      pickupLocation, collectionDate, notesToEME, notesToShippingProvider, statusCode, reEstimationNotes, offerItems);
    if (asyncResp?.success) {
      await dispatch(postOfferResponse());
    } else {
      await dispatch(postOfferFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


