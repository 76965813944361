import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_RESPONSE,
  FETCH_FIELDS_FAILURE,
  FETCH_FIELD_REQUEST,
  FETCH_FIELD_RESPONSE,
  FETCH_FIELD_FAILURE,
  POST_FIELD_FAILURE,
  POST_FIELD_REQUEST,
  POST_FIELD_RESPONSE,
  FieldActionTypes, FieldDataModel, Field,
} from '../types/Field';

import { RootState } from '../store/configureStore';

import { archiveField, getField, getFields, postField, putField } from '../api/Field';
import { DataTableParamsModel } from '../types/Common';

export function fetchFieldsRequest(): FieldActionTypes {
  return {
    type: FETCH_FIELDS_REQUEST,
    payload: null,
  };
}

export function fetchFieldsResponse(
  fields: FieldDataModel,
): FieldActionTypes {
  return {
    type: FETCH_FIELDS_RESPONSE,
    payload: fields,
  };
}

export function fetchFieldsFailure(): FieldActionTypes {
  return {
    type: FETCH_FIELDS_FAILURE,
    payload: null,
  };
}

export function fetchFieldRequest(): FieldActionTypes {
  return {
    type: FETCH_FIELD_REQUEST,
    payload: null,
  };
}

export function fetchFieldResponse(
  fields: Field,
): FieldActionTypes {
  return {
    type: FETCH_FIELD_RESPONSE,
    payload: fields,
  };
}

export function fetchFieldFailure(): FieldActionTypes {
  return {
    type: FETCH_FIELD_FAILURE,
    payload: null,
  };
}


export function postFieldRequest(): FieldActionTypes {
  return {
    type: POST_FIELD_REQUEST,
    payload: null,
  };
}

export function postFieldResponse(): FieldActionTypes {
  return {
    type: POST_FIELD_RESPONSE,
    payload: null,
  };
}

export function postFieldFailure(error: string, validationErrors: any): FieldActionTypes {
  return {
    type: POST_FIELD_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveField = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldRequest());
    const asyncResp: any = await getField(id);
    if (asyncResp?.success) {
      await dispatch(fetchFieldResponse(asyncResp.data));
    } else {
      await dispatch(fetchFieldFailure());
    }
  };

export const fetchFields = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldsRequest());
    const asyncResp: any = await getFields(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchFieldsFailure());
    }
  };

export const storeField = async (id: string) => {
  try {
    await archiveField(id);
  } catch (error) {
    throw error;
  }
};

export const createField = (
  groupId: string, clientId: string, categoryId: string, fieldTypeId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldRequest());
    const asyncResp: any = await postField(groupId, clientId, categoryId, fieldTypeId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldResponse());
    } else {
      await dispatch(postFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateField = (
  id: string,
  groupId: string,
  clientId: string,
  categoryId: string,
  fieldTypeId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldRequest());
    const asyncResp: any = await putField(id, groupId, clientId, categoryId, fieldTypeId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldResponse());
    } else {
      await dispatch(postFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


