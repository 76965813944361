import {
  FETCH_OPP_NOTIFICATIONS_REQUEST,
  FETCH_OPP_NOTIFICATIONS_RESPONSE,
  FETCH_OPP_NOTIFICATIONS_FAILURE,
  FETCH_OPP_NOTIFICATION_REQUEST,
  FETCH_OPP_NOTIFICATION_RESPONSE,
  FETCH_OPP_NOTIFICATION_FAILURE,
  POST_OPP_NOTIFICATION_REQUEST,
  POST_OPP_NOTIFICATION_RESPONSE,
  POST_OPP_NOTIFICATION_FAILURE,
  OPPNotificationActionTypes,
  OPPNotificationState,
} from '../types/OPPNotification';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OPPNotificationState = {
  oppNotifications: null,
  activeOPPNotification: null,
  isFetchingOPPNotifications: false,
  fetchingOPPNotificationsFailed: false,
  isPostingOPPNotification: false,
  postingOPPNotificationFailed: false,
  postingOPPNotificationError: null,
  postingOPPNotificationValidationErrors: null,
};

const OPPNotificationReducer = (
  state = initialState,
  action: OPPNotificationActionTypes,
): OPPNotificationState => {
  switch (action.type) {
    case FETCH_OPP_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        oppNotifications: null,
        isFetchingOPPNotifications: true,
        fetchingOPPNotificationsFailed: false,
      };
    case FETCH_OPP_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        oppNotifications: action.payload || [],
        isFetchingOPPNotifications: false,
        fetchingOPPNotificationsFailed: false,
      };
    case FETCH_OPP_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        oppNotifications: null,
        isFetchingOPPNotifications: false,
        fetchingOPPNotificationsFailed: true,
      };
    case FETCH_OPP_NOTIFICATION_REQUEST:
      return {
        ...state,
        activeOPPNotification: null,
        isFetchingOPPNotifications: true,
        fetchingOPPNotificationsFailed: false,
      };
    case FETCH_OPP_NOTIFICATION_RESPONSE:
      return {
        ...state,
        activeOPPNotification: action.payload,
        isFetchingOPPNotifications: false,
        fetchingOPPNotificationsFailed: false,
      };
    case FETCH_OPP_NOTIFICATION_FAILURE:
      return {
        ...state,
        activeOPPNotification: null,
        isFetchingOPPNotifications: false,
        fetchingOPPNotificationsFailed: true,
      };
    case POST_OPP_NOTIFICATION_REQUEST:
      return {
        ...state,
        isPostingOPPNotification: true,
        postingOPPNotificationError: null,
        postingOPPNotificationValidationErrors: null,
        postingOPPNotificationFailed: false,
      };
    case POST_OPP_NOTIFICATION_RESPONSE:
      return {
        ...state,
        isPostingOPPNotification: false,
        postingOPPNotificationError: null,
        postingOPPNotificationValidationErrors: null,
        postingOPPNotificationFailed: false,
      };
    case POST_OPP_NOTIFICATION_FAILURE:
      return {
        ...state,
        isPostingOPPNotification: false,
        postingOPPNotificationError: action.payload.error || 'An error occurred',
        postingOPPNotificationValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOPPNotificationFailed: true,
      };
    default:
      return state;
  }
};

export default OPPNotificationReducer;
