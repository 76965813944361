import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getClient(id: string, dataTableParams?: DataTableParamsModel) {
  const baseUrl = `${c.API_ENDPOINTS.CLIENT}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;
    
  return (
    axios({
      url: `${url}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getClients(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.CLIENT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postClient(groupId: string, title: string, description: string, email: string, phone: string, website: string, billingContact: string, active: boolean, addSelfToClient?: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.CLIENT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        title,
        description,
        email,
        phone,
        website,
        billingContact,
        active,
        addSelfToClient,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postDuplicateClient(clientId: string, title: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/duplicate`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        clientId,
        title,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putClient(
  id: string,
  groupId: string,
  title: string, description: string,
  email: string, phone: string, website: string,
  billingContact: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        title,
        description,
        email,
        phone,
        website,
        billingContact,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveClient(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

