import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_AUCTION_ENTRIES_RESPONSE,
  FETCH_AUCTION_ENTRIES_FAILURE,
  FETCH_AUCTION_ENTRY_REQUEST,
  FETCH_AUCTION_ENTRY_RESPONSE,
  FETCH_AUCTION_ENTRY_FAILURE,
  POST_AUCTION_ENTRY_FAILURE,
  POST_AUCTION_ENTRY_REQUEST,
  POST_AUCTION_ENTRY_RESPONSE,
  AuctionEntryActionTypes, AuctionEntryDataModel, AuctionEntry, FETCH_AUCTION_ENTRIES_REQUEST,
} from '../types/AuctionEntry';

import { RootState } from '../store/configureStore';

import {
  archiveAuctionEntry,
  getAuctionEntry,
  getAuctionEntries,
  postAuctionEntry,
  putAuctionEntry,
  getAuctionEntriesByAuctionId, postBatchAuctionEntries, postEditBatchAuctionEntries,
} from '../api/AuctionEntry';
import { DataTableParamsModel } from '../types/Common';

export function fetchAuctionEntriesRequest(): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchAuctionEntriesResponse(
  auctionEntries: AuctionEntryDataModel,
): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRIES_RESPONSE,
    payload: auctionEntries,
  };
}

export function fetchAuctionEntriesFailure(): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchAuctionEntryRequest(): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRY_REQUEST,
    payload: null,
  };
}

export function fetchAuctionEntryResponse(
  auctionEntry: AuctionEntry,
): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRY_RESPONSE,
    payload: auctionEntry,
  };
}

export function fetchAuctionEntryFailure(): AuctionEntryActionTypes {
  return {
    type: FETCH_AUCTION_ENTRY_FAILURE,
    payload: null,
  };
}


export function postAuctionEntryRequest(): AuctionEntryActionTypes {
  return {
    type: POST_AUCTION_ENTRY_REQUEST,
    payload: null,
  };
}

export function postAuctionEntryResponse(): AuctionEntryActionTypes {
  return {
    type: POST_AUCTION_ENTRY_RESPONSE,
    payload: null,
  };
}

export function postAuctionEntryFailure(error: string, validationErrors: any): AuctionEntryActionTypes {
  return {
    type: POST_AUCTION_ENTRY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveAuctionEntry = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionEntryRequest());
    const asyncResp: any = await getAuctionEntry(id);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionEntryResponse(asyncResp.data));
    } else {
      await dispatch(fetchAuctionEntryFailure());
    }
  };

export const fetchAuctionEntriesByAuctionId = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionEntriesRequest());
    const asyncResp: any = await getAuctionEntriesByAuctionId(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchAuctionEntriesFailure());
    }
  };


export const fetchAuctionEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionEntriesRequest());
    const asyncResp: any = await getAuctionEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchAuctionEntriesFailure());
    }
  };

export const storeAuctionEntry = async (id: string) => {
  try {
    await archiveAuctionEntry(id);
  } catch (error) {
    throw error;
  }
};

export const createAuctionEntry = (
  auctionId: string,
  entryId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionEntryRequest());
    const asyncResp: any = await postAuctionEntry(auctionId, entryId, startingBid, startDateTime, endDateTime, bidIncrement);
    if (asyncResp?.success) {
      await dispatch(postAuctionEntryResponse());
    } else {
      await dispatch(postAuctionEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createBatchAuctionEntries = (
  auctionId: string,
  entryIds: string[],
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionEntryRequest());
    const asyncResp: any = await postBatchAuctionEntries(auctionId, entryIds, startingBid, startDateTime, endDateTime, bidIncrement);
    if (asyncResp?.success) {
      await dispatch(postAuctionEntryResponse());
      // await dispatch(fetchAuctionEntries());
    } else {
      await dispatch(postAuctionEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const editBatchAuctionEntries = (
  batch :AuctionEntry[],
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionEntryRequest());
    const asyncResp: any = await postEditBatchAuctionEntries(batch);
    if (asyncResp?.success) {
      await dispatch(postAuctionEntryResponse());
      await dispatch(fetchAuctionEntries());
    } else {
      await dispatch(postAuctionEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateAuctionEntry = (
  id: string,
  auctionId: string,
  entryId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionEntryRequest());
    const asyncResp: any = await putAuctionEntry(id, auctionId, entryId, startingBid, startDateTime, endDateTime, bidIncrement);
    if (asyncResp?.success) {
      await dispatch(postAuctionEntryResponse());
    } else {
      await dispatch(postAuctionEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


