import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getMaterialsFunctions, isFetchingMaterialsFunctions } from '../../../selectors/MaterialsFunction';
import { fetchMaterialsFunctions, storeMaterialsFunction } from '../../../actions/MaterialsFunction';
import { MaterialsFunction } from '../../../types/MaterialsFunction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const MaterialsFunctionDataTable = () => {

  const materialsFunctions = useSelector(getMaterialsFunctions);
  const isLoading = useSelector(isFetchingMaterialsFunctions);
  const [toggle, setToggle] = useState(false);
  const [materialsFunction, setActiveMaterialsFunction] = useState<MaterialsFunction | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.MATERIALSFUNCTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.MATERIALSFUNCTION_UPDATE, row.id));
  };

  const onArchive = (row: MaterialsFunction) => {
    setActiveMaterialsFunction(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (materialsFunction?.id) {
      await storeMaterialsFunction(materialsFunction?.id);
      setRefetch(!refetch);
    }
    setActiveMaterialsFunction(null);
  };

  const config = {
    columns: [
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="MaterialsFunctions" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchMaterialsFunctions} paging={materialsFunctions?.paging || []}
                   isLoading={isLoading} data={materialsFunctions?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle} title="Archive MaterialsFunction" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this materialsFunction?
        </Modal>
      </View>
  );
};

export default MaterialsFunctionDataTable;

