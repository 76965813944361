import { RootState } from '../store/configureStore';

export const getFieldTypes = (state: RootState) => state?.fieldType?.fieldTypes;

export const getActiveFieldType = (state: RootState) => state?.fieldType?.activeFieldType;

export const isFetchingFieldTypes = (state: RootState) => state?.fieldType?.isFetchingFieldTypes;

export const isPostingFieldType = (state: RootState) => state?.fieldType?.isPostingFieldType;
export const postingFieldTypeFailed = (state: RootState) => state?.fieldType?.postingFieldTypeFailed;
export const postingFieldTypeError = (state: RootState) => state?.fieldType?.postingFieldTypeError;
export const postingFieldTypeValidationErrors = (state: RootState) => state?.fieldType?.postingFieldTypeValidationErrors;
export const fetchingFieldTypesFailed = (state: RootState) => state?.fieldType?.fetchingFieldTypesFailed;