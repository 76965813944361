import { RootState } from '../store/configureStore';

export const getEntries = (state: RootState) => state?.entry?.entries;
export const getRecentlyAddedEntries = (state: RootState) => state?.entry?.recentlyAddedEntries;
export const getRecentlyReusedEntries = (state: RootState) => state?.entry?.recentlyReusedEntries;

export const getPopularEntries = (state: RootState) => state?.entry?.popularEntries;

export const getActiveEntry = (state: RootState) => state?.entry?.activeEntry;

export const getActiveEntryMatches = (state: RootState) => state?.entry?.matches;

export const isFetchingEntries = (state: RootState) => state?.entry?.isFetchingEntries;
export const isFetchingRecentlyAddedEntries = (state: RootState) => state?.entry?.isFetchingRecentlyAddedEntries;
export const isFetchingRecentlyReusedEntries = (state: RootState) => state?.entry?.isFetchingRecentlyReusedEntries;
export const isFetchingPopularEntries = (state: RootState) => state?.entry?.isFetchingPopularEntries;

export const isPostingEntry = (state: RootState) => state?.entry?.isPostingEntry;
export const postingEntryFailed = (state: RootState) => state?.entry?.postingEntryFailed;
export const postingEntryError = (state: RootState) => state?.entry?.postingEntryError;
export const postingEntryValidationErrors = (state: RootState) => state?.entry?.postingEntryValidationErrors;
export const fetchingEntriesFailed = (state: RootState) => state?.entry?.fetchingEntriesFailed;

