import { RootState } from '../store/configureStore';

export const getForms = (state: RootState) => state?.form?.forms;

export const getActiveForm = (state: RootState) => state?.form?.activeForm;

export const isFetchingForms = (state: RootState) => state?.form?.isFetchingForms;

export const isPostingForm = (state: RootState) => state?.form?.isPostingForm;
export const postingFormFailed = (state: RootState) => state?.form?.postingFormFailed;
export const postingFormError = (state: RootState) => state?.form?.postingFormError;
export const postingFormValidationErrors = (state: RootState) => state?.form?.postingFormValidationErrors;
export const fetchingFormsFailed = (state: RootState) => state?.form?.fetchingFormsFailed;

