import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../components/View/View.scss';
import { formatUrl, getToken, getUserToken } from '../../helpers/functions';
import { getLoggedInRole } from '../../selectors/Auth';
import DataTable from '../../components/DataTable';
import { fetchingOffersFailed, getOffers, isFetchingOffers } from '../../selectors/Offer';
import { fetchOffers } from '../../actions/Offer';
import { fetchRoleClients } from '../../actions/RoleClient';
import ProposalCard from '../../components/Proposal/ProposalCard';
import { useHistory } from 'react-router-dom';
import c, { StatusCodesEnum } from '../../helpers/constants';
import { Offer } from '../../types/Offer';
import OfferRedirect from './OfferRedirect';


function MyProposalRenderer() {
  const offers = useSelector(getOffers);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchFailed = useSelector(fetchingOffersFailed);
  const isLoading = useSelector(isFetchingOffers);
  const userRole = useSelector(getLoggedInRole);
  const [redirectOfferId, setRedirectOfferId] = useState('');

  useEffect(() => {
    if (getUserToken() && getToken()) {
      dispatch(fetchRoleClients({ searchField: 'roleId', searchWord: userRole?.id }));
    }
  }, [dispatch]);

  // const onNavigate = (entryId: string) => {
  //   let route;
  //
  //   history.push(route);
  // };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ADD_NEW_OFFER);
  };

  const config = {
    columns: [ { key: 'subject', label: 'Subject', mobileFriendly: true },
      { key: 'details', label: 'Details', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
  };

  const decideReviewURL = (row: Offer) => () => {
    setRedirectOfferId(row.id);
  };

  if (redirectOfferId) {
    // Render OfferRedirect with the selected offerId, which will handle redirection internally
    return <OfferRedirect offerId={redirectOfferId} />;
  }

  return (
        <View title='MY PROPOSALS' onAddNew={onCreate} >
            <DataTable paging={offers?.paging || []} data={offers?.data || []} config={config}
                       fetchFunction={fetchOffers}
                       isTable={false}
                       isLoading={isLoading}
                       lineItemTemplate={(row: Offer) => {
                         // Handle missing clientTitle or solutionTitle
                         const clientTitle = row.clientTitle ? row.clientTitle.toUpperCase() : null;
                         const solutionTitle = row.solutionTitle ? row.solutionTitle.toUpperCase() : null;
                         const statusCode = row.statusCodes[0]?.value;

                         let description;
                         if (!clientTitle && !solutionTitle) {
                           description = 'BOTH CLIENT TITLE AND SOLUTION TITLE MISSING';
                         } else if (!clientTitle) {
                           description = `FROM MISSING CLIENT TITLE TO ${solutionTitle}`;
                         } else if (!solutionTitle) {
                           description = `FROM ${clientTitle}`;
                         } else {
                           description = `FROM ${clientTitle} TO ${solutionTitle}`;
                         }

                         return (
                               <div key={row.id} className="col-md-12 col-sm-12">
                                   <ProposalCard
                                       title={row.subject}
                                       minRate={row.totalMinRate}
                                       maxRate={row.totalMaxRate}
                                       actualRate={row.totalActualRate}
                                       currency={row.currency}
                                       description={description}
                                       statusCode={statusCode === StatusCodesEnum.PENDING_OFFER ? StatusCodesEnum.PENDING_OFFER_ACCEPTANCE
                                         : statusCode === StatusCodesEnum.PENDING_ESTIMATION ? StatusCodesEnum.PENDING_QUOTATION : statusCode}
                                       onReviewClick={decideReviewURL(row)}
                                       proposalURL={formatUrl(c.APP_ROUTES.REVIEW_ESTIMATION, row.id)}
                                       //TODO Update these with the actual links down the line
                                       offerId={row.id}
                                       offerDate={row.date}
                                       pickupLocation={row.pickupLocation}
                                       solutionLocation={row.solutionLocation}
                                       greenDownloadReportURL={'something'}
                                       showGreenReport={row.statusCodes[0]?.value == 'Completed'}
                                   />
                               </div>
                         );
                       }}
            />
            {fetchFailed && (
                <p>Failed to fetch offer items.</p>
            )}
        </View>
  );


}

export default MyProposalRenderer;
