import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AuctionData {
  auctionDetails: Record<string, any>;
  selectedItems: Record<string, any>;
}

interface MarketplaceData {
  marketplaceDetails: Record<string, any>;
  selectedItems: Record<string, any>; 
}

interface ContextType {
  auctionData: AuctionData;
  marketplaceData: MarketplaceData;
  updateAuctionData: (newData: Partial<AuctionData>) => void;
  updateMarketplaceData: (newData: Partial<MarketplaceData>) => void;
}

const Context = createContext<ContextType | undefined>(undefined);

export const useContextProvider = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useContextProvider must be used within a ContextProvider');
  }
  return context;
};

interface AuctionProviderProps {
  children: ReactNode;
}

export const ContextProvider: React.FC<AuctionProviderProps> = ({ children }) => {
  const [auctionData, setAuctionData] = useState<AuctionData>({
    auctionDetails: {},
    selectedItems: {},
  });

  const [marketplaceData, setMarketplaceData] = useState<MarketplaceData>({
    marketplaceDetails: {},
    selectedItems: {},
  });

  const updateAuctionData = (newData: Partial<AuctionData>) => {
    setAuctionData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const updateMarketplaceData = (newData: Partial<MarketplaceData>) => {
    setMarketplaceData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
        <Context.Provider value={{ auctionData, updateAuctionData, marketplaceData, updateMarketplaceData }}>
            {children}
        </Context.Provider>
  );
};
