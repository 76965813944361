import React, { useEffect, useState } from 'react';
import './Tabs.scss';

export type Tab = {
  label: string;
  icon?: string;
  content: React.ReactNode;
};

type Props = {
  tabs: Tab[];
  activeTabIndex?: number;
  onTabChange?: (index: number) => void; // Optional callback to inform parent of tab change
};

const Tabs: React.FC<Props> = ({ tabs, activeTabIndex, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<number>(activeTabIndex || 0);

  // Update local active tab when the activeTabIndex prop changes
  useEffect(() => {
    if (activeTabIndex !== undefined) {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex]);

  // Handle tab click and notify parent component if onTabChange is provided
  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (onTabChange) {
      onTabChange(index);
    }
  };

  if (!tabs || tabs.length === 0 || activeTab < 0 || activeTab >= tabs.length) {
    return null; // Render nothing if tabs are invalid
  }

  return (
      <div className="tabs">
        <div className="tabs__header">
          {tabs.map((tab, index) => (
              <div
                  key={index}
                  className={`tab ${activeTab === index ? 'active' : ''}`}
                  onClick={() => handleTabClick(index)}
              >
                {tab.icon && <img src={tab.icon} className="tabs__icon" alt="" />}
                {tab.label}
              </div>
          ))}
        </div>
        {tabs[activeTab].content && (
            <div className="tabs__content">{tabs[activeTab].content}</div>
        )}
      </div>
  );
};

export default Tabs;
