import { RootState } from '../store/configureStore';
import { View } from '../types/View';

export const getViews = (state: RootState) => state?.view?.views;
export const getDuplicateViews = (state: RootState) => state?.view?.duplicateViews;
export const getViewsData = (state: RootState) => state?.view?.viewsData;

export const getActiveView = (state: RootState) => state?.view?.activeView;

export const isFetchingViews = (state: RootState) => state?.view?.isFetchingViews;

export const isPostingView = (state: RootState) => state?.view?.isPostingView;
export const postingViewFailed = (state: RootState) => state?.view?.postingViewFailed;
export const postingViewError = (state: RootState) => state?.view?.postingViewError;
export const postingViewValidationErrors = (state: RootState) => state?.view?.postingViewValidationErrors;
export const fetchingViewsFailed = (state: RootState) => state?.view?.fetchingViewsFailed;

export const getInformationViews = (state: RootState): View[] => {
  const views = getViews(state); // Assuming getViews returns an array of ViewType
  return views?.data.filter((v: View) => v.contents) || [];
};

export const getInventoryViews = (state: RootState): View[] => {
  const views = getViews(state); // Assuming getViews returns an array of ViewType
  return views?.data.filter((v: View) => !v.contents) || [];
};