import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import gallery from '../AssetGallery/AssetGallery';
import './SearchBar.scss';
import Input from '../Input/Input';
import Pill from '../Pill';

type Item = {
  value: string;
  label?: string;
};

type Props = {
  placeholder: string;
  onSearch: (term: string, filterField?: string | [], sortField?: string | [], direction?: boolean ) => void;
  fields: Item[];
  defaultFilterField?: string | [];
  defaultSortField?: string;
  orderBy?: boolean;
  defaultSearch?: string;
};

const SearchBar = (props: Props) => {
  const {
    placeholder, onSearch = () => {
    }, fields = [], defaultFilterField = 'All', defaultSortField, defaultSearch = '', orderBy = true,
  } = props;
  const [searchText, setSearchText] = useState(defaultSearch || '');
  const [filterField, setFilterField] = useState(defaultFilterField);
  const [sortField, setSortField] = useState(defaultSortField);
  const [order, setDirection] = useState(orderBy);


  const onInnerSearch = () => {
    onSearch(searchText, filterField, sortField, order);
  };


  const onChange = (value:string) => {
    setSearchText(value);
  };
  const removeFilter = () => {
    setFilterField(defaultFilterField);
  };

  const removeSortBy = () => {
    setSortField(defaultSortField);
  };

  const changeDirection = () => {
    setDirection(!order);
  };

  const onBlur = (value:string) => {
    setSearchText(value);
  };

  useEffect(() => {
    onSearch(searchText, filterField, sortField, order);
  }, [sortField]);

  useEffect(()=>{
    if (searchText === ''){
      onSearch(searchText, filterField, sortField, order);
    }
  }, [searchText]);

  useEffect(() => {
    if (sortField){
      onSearch(searchText, filterField, sortField, order);
    }
  }, [order]);

  const filterBy = fields?.find(f => f.value === filterField)?.label;
  const sortBy = fields?.find(f => f.value === sortField)?.label;
  const sortList = fields.filter((f) => f.label !== 'All');

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onInnerSearch();
    }
  };
  //TODO: Implement Pill component
  const filterByPill = () =>{
    if (!filterBy || filterBy === 'All' || filterBy === defaultFilterField){
      return;
    }
    return (
        <div className='filter-by'>
            <Pill text={`Filter By: ${fields?.find(f => f.value === filterField)?.label || ''}`} type={'black'} cancellable onClick={removeFilter}/>
        </div>
    );
  };

  const sortByPill = () =>{
    if (!sortBy  || sortBy === defaultSortField){
      return;
    }
    return (
      <div className='filter-by'>
          <Pill text={`Sort By: ${fields?.find(f => f.value === sortField)?.label || ''}`} type={'black'} cancellable onClick={removeSortBy}/>
      </div>
    );
  };

  return (
        <>
            <div className="search-bar">
                <div className="input-group">
                    <Input
                        value={searchText} onChange={onChange}
                        onBlur={onBlur} placeholder={placeholder} onKeyDown={onKeyDown}
                    />
                    <span className="input-group-btn">
                        <div className="btn btn-search" onClick={onInnerSearch}>
                            <img className="search-icon" src={gallery.searchImg} alt='search-icon'/>
                        </div>
                    </span>
                </div>
                <div className="search-bar__controls">
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <div className={`search-bar__controls__icon-group ${filterByPill() && 'search-bar__controls__icon-group--active'}`}>
                                <img className="search-bar__controls__icon-group__icon" src={gallery.filterImg} alt='filter-icon'/>
                                <div className="search-bar__controls__icon-group__text d-none d-xl-block">Filter</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                {fields.map((f, i) =>
                                  (<Dropdown.Item key={i} onClick={() => setFilterField(f.value)}>{f.label || f.value}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="success">
                            <div className={`search-bar__controls__icon-group ${sortByPill() && 'search-bar__controls__icon-group--active'}`}>
                                <img className="search-bar__controls__icon-group__icon" src={gallery.sortByImg} alt='sort-by-icon'/>
                                <div className="search-bar__controls__icon-group__text d-none d-xl-block">Sort By</div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            {sortList.map((f, i) =>
                              (<Dropdown.Item key={i} onClick={() => setSortField(f.value)}>{f.label || f.value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="search-bar__controls__direction">
                        <div className="search-bar__controls__icon-group" onClick={changeDirection}>
                            <img className={`search-bar__controls__icon-group__icon ${ order && 'search-bar__controls__icon-group__icon--asc'}`} src={gallery.arrowAscImg} alt='direction-icon'/>
                            <div className={`search-bar__controls__icon-group__text d-none d-xl-block ${ order && 'search-bar__controls__icon-group__text--asc'}`}>
                            {order ? 'ASC' : 'DESC'}
                            </div>
                        </div>
                    </div>
                    {(filterByPill() || sortByPill()) &&
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="search-bar__controls__divider">
                          <div className="search-bar__controls__vl"/>
                      </div>
                          <div className="search-bar__controls__pills">
                            {filterByPill()}
                            {sortByPill()}
                          </div>
                        </div>
                    }
                </div>
            </div>
        </>
  );
};

export default SearchBar;

