import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getViewRole(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.VIEW_ROLE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getViewRoles(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.VIEW_ROLE,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteViewRole(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.VIEW_ROLE}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postViewRole(viewId: string, roleId: string ) {
  return (
    axios({
      url: c.API_ENDPOINTS.VIEW_ROLE,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        viewId,
        roleId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

