import React from 'react';
import { View as ViewType } from '../../../types/View';

type Props = {
  view: ViewType;
};

function StaticView(props: Props) {
  const { view: { contents } } = props;
  return (
            <div className="row">
                <div dangerouslySetInnerHTML={{ __html: contents || '' }}/>
            </div>
  );
}

export default StaticView;
