import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getFormFields, isFetchingFormFields } from '../../../selectors/FormField';
import { fetchFormFields, storeFormField } from '../../../actions/FormField';
import { FormField } from '../../../types/FormField';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const FormFieldDataTable = () => {

  const formFields = useSelector(getFormFields);
  const isLoading = useSelector(isFetchingFormFields);
  const [toggle, setToggle] = useState(false);
  const [formField, setActiveFormField] = useState<FormField | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FORM_FIELD_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FORM_FIELD_UPDATE, row.id));
  };

  const onArchive = (row: FormField) => {
    setActiveFormField(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (formField?.id) {
      await storeFormField(formField?.id);
      setRefetch(!refetch);
    }
    setActiveFormField(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'formTitle', label: 'Form', mobileFriendly: true },
      { key: 'categoryTitle', label: 'Category', mobileFriendly: true },
      { key: 'fieldTitle', label: 'Field', mobileFriendly: true },
      { key: 'order', label: 'Order', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Form Fields" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchFormFields} paging={formFields?.paging || []}
                 isLoading={isLoading}  data={formFields?.data || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Form Field" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this formField?
      </Modal>
    </View>
  );
};

export default FormFieldDataTable;

