import { RootState } from '../store/configureStore';

export const getSolutions = (state: RootState) => state?.solution?.solutions;

export const getActiveSolution = (state: RootState) => state?.solution?.activeSolution;

export const isFetchingSolutions = (state: RootState) => state?.solution?.isFetchingSolutions;

export const isPostingSolution = (state: RootState) => state?.solution?.isPostingSolution;
export const postingSolutionFailed = (state: RootState) => state?.solution?.postingSolutionFailed;
export const postingSolutionError = (state: RootState) => state?.solution?.postingSolutionError;
export const postingSolutionValidationErrors = (state: RootState) => state?.solution?.postingSolutionValidationErrors;
export const fetchingSolutionsFailed = (state: RootState) => state?.solution?.fetchingSolutionsFailed;