import React from 'react';
import View from '../components/View';
import { useSelector } from 'react-redux';
import { getActiveConfiguration, isFetchingConfigurations } from '../selectors/Configuration';
import Loading from '../components/Loading';

function Vision() {

  const configuration = useSelector(getActiveConfiguration);
  const isFetching = useSelector(isFetchingConfigurations);

  let content;
  if (configuration && !isFetching){
    if (configuration?.welcomeContent && configuration.welcomeContent.length > 0 && configuration?.welcomeContent !== 'Default') {
      content = (
              <>
                  <div className="container">
                      <div className="row">
                          <div dangerouslySetInnerHTML={{ __html: configuration.welcomeContent }} />
                      </div>
                  </div>
              </>
      );
    } else {
      content = (
              <>
                  <h1 className="mb-4">Welcome</h1>
                  <hr className="my-3" />
                  <div className="container">
                      <div className="row">
                          <p>
                              Excess Materials Exchange (EME) is a young and innovative technology company. On our
                              digital matching platform, we find new high-value reuse options for materials or
                              (waste) products for companies. Far too many valuable resources and materials are
                              wasted or ill-designed in the current paradigm, for which the planet must pay a heavy
                              price. Isn’t that a waste?
                          </p>
                          <p>
                              EME is determined to accelerate the global transition to a circular economy – and play
                              a part in creating a more viable planet. By showing the financial and ecological value
                              of materials. By challenging companies to design and produce their goods in a more
                              efficient and circular manner. And by making matches. A whole lot of matches.
                          </p>
                      </div>
                  </div>
              </>
      );
    }
  } else {
    content = (
          <Loading/>
    );
  }

  return (
        <View>
            <div className="page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">{content}</div>
                    </div>
                </div>
            </div>
        </View>
  );
}

export default Vision;
