import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_LEADS_REQUEST,
  FETCH_LEADS_RESPONSE,
  FETCH_LEADS_FAILURE,
  FETCH_LEAD_REQUEST,
  FETCH_LEAD_RESPONSE,
  FETCH_LEAD_FAILURE,
  POST_LEAD_FAILURE,
  POST_LEAD_REQUEST,
  POST_LEAD_RESPONSE,
  LeadActionTypes, LeadDataModel, Lead,
} from '../types/Lead';

import { RootState } from '../store/configureStore';

import { archiveLead, getLead, getLeads, postConvertLead, postLead, putLead } from '../api/Lead';
import { DataTableParamsModel } from '../types/Common';
import { LeadStatus } from '../helpers/constants';

export function fetchLeadsRequest(): LeadActionTypes {
  return {
    type: FETCH_LEADS_REQUEST,
    payload: null,
  };
}

export function fetchLeadsResponse(
  leads: LeadDataModel,
): LeadActionTypes {
  return {
    type: FETCH_LEADS_RESPONSE,
    payload: leads,
  };
}

export function fetchLeadsFailure(): LeadActionTypes {
  return {
    type: FETCH_LEADS_FAILURE,
    payload: null,
  };
}

export function fetchLeadRequest(): LeadActionTypes {
  return {
    type: FETCH_LEAD_REQUEST,
    payload: null,
  };
}

export function fetchLeadResponse(
  lead: Lead,
): LeadActionTypes {
  return {
    type: FETCH_LEAD_RESPONSE,
    payload: lead,
  };
}

export function fetchLeadFailure(): LeadActionTypes {
  return {
    type: FETCH_LEAD_FAILURE,
    payload: null,
  };
}

export function postLeadRequest(): LeadActionTypes {
  return {
    type: POST_LEAD_REQUEST,
    payload: null,
  };
}

export function postLeadResponse(): LeadActionTypes {
  return {
    type: POST_LEAD_RESPONSE,
    payload: null,
  };
}

export function postLeadFailure(error: string, validationErrors: any): LeadActionTypes {
  return {
    type: POST_LEAD_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveLead = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLeadRequest());
    const asyncResp: any = await getLead(id, dataTableParams);
    if (asyncResp?.success) {
      dispatch(fetchLeadResponse(asyncResp.data));
    } else {
      dispatch(fetchLeadFailure());
    }
  };

export const fetchLeads = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLeadsRequest());
    const asyncResp: any = await getLeads(datatableParams);
    if (asyncResp?.success) {
      dispatch(fetchLeadsResponse(asyncResp));
    } else {
      dispatch(fetchLeadsFailure());
    }
  };

export const storeLead = async (id: string) => {
  try {
    await archiveLead(id);
  } catch (error) {
    throw error;
  }
};

export const createLead = (
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string,
  companyNumber: string,
  companyPhone: string,
  companyEmail: string,
  companyWebsite: string,
  roleInCompany: string,
  city: string,
  country: string,
  termsAccepted: boolean,
  verification: LeadStatus | undefined,
  transaction: LeadStatus | undefined,
  agreement: LeadStatus | undefined,
  onboarded: LeadStatus | undefined,
): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLeadRequest());
    const asyncResp: any = await postLead(
      firstName,
      lastName,
      email,
      phoneNumber,
      companyName,
      companyNumber,
      companyPhone,
      companyEmail,
      companyWebsite,
      roleInCompany,
      city,
      country,
      termsAccepted,
      verification,
      transaction,
      agreement,
      onboarded,
    );
    if (asyncResp?.success) {
      dispatch(postLeadResponse());
    } else {
      dispatch(postLeadFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateLead = (
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string,
  companyNumber: string,
  companyPhone: string,
  companyEmail: string,
  companyWebsite: string,
  roleInCompany: string,
  city: string,
  country: string,
  termsAccepted: boolean,
  verification: LeadStatus,
  transaction: LeadStatus,
  agreement: LeadStatus,
  onboarded: LeadStatus,
): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLeadRequest());
    const asyncResp: any = await putLead(
      id,
      firstName,
      lastName,
      email,
      phoneNumber,
      companyName,
      companyNumber,
      companyPhone,
      companyEmail,
      companyWebsite,
      roleInCompany,
      city,
      country,
      termsAccepted,
      verification,
      transaction,
      agreement,
      onboarded,
    );
    if (asyncResp?.success) {
      dispatch(postLeadResponse());
    } else {
      dispatch(postLeadFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const convertLead = (
  leadId: string,
  groupId: string,
  clientId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLeadRequest());
    const asyncResp: any = await postConvertLead(leadId, groupId, clientId);
    if (asyncResp?.success) {
      dispatch(postLeadResponse());
    } else {
      dispatch(postLeadFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
