import { RootState } from '../store/configureStore';

export const getSolutionOutputTags = (state: RootState) => state?.solutionOutputTag?.solutionOutputTags;

export const getActiveSolutionOutputTag = (state: RootState) => state?.solutionOutputTag?.activeSolutionOutputTag;

export const isFetchingSolutionOutputTags = (state: RootState) => state?.solutionOutputTag?.isFetchingSolutionOutputTags;

export const isPostingSolutionOutputTag = (state: RootState) => state?.solutionOutputTag?.isPostingSolutionOutputTag;
export const postingSolutionOutputTagFailed = (state: RootState) => state?.solutionOutputTag?.postingSolutionOutputTagFailed;
export const postingSolutionOutputTagError = (state: RootState) => state?.solutionOutputTag?.postingSolutionOutputTagError;
export const postingSolutionOutputTagValidationErrors = (state: RootState) => state?.solutionOutputTag?.postingSolutionOutputTagValidationErrors;
export const fetchingSolutionOutputTagsFailed = (state: RootState) => state?.solutionOutputTag?.fetchingSolutionOutputTagsFailed;