import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../components/View/View.scss';
import { formatUrl, getUserToken, latLngCalculation } from '../../helpers/functions';
import c, { EntryStatus, SearchType } from '../../helpers/constants';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { getAuctionEntries } from '../../selectors/AuctionEntry';
import { fetchAuctionEntriesByAuctionId } from '../../actions/AuctionEntry';
import { fetchingAuctionEntriesFailed, isFetchingAuctionEntries } from '../../selectors/AuctionEntry';
import { fetchActiveAuction } from '../../actions/Auction';
import { getActiveAuction, isFetchingAuctions } from '../../selectors/Auction';
import DataTable from '../../components/DataTable';
import MaterialMap from '../../components/MaterialMap';
import { getAllEntryFields } from '../../selectors/EntryField';
import { fetchAllEntryFields } from '../../actions/EntryField';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import ButtonToggler from '../../components/ButtonToggler';
import assetGallery from '../../components/AssetGallery';


function Auction() {
  const dispatch = useDispatch();
  const auctionEntriesByAuctionId = useSelector(getAuctionEntries);
  const auction = useSelector(getActiveAuction);
  const fetchFailed = useSelector(fetchingAuctionEntriesFailed);
  const isLoadingEntries = useSelector(isFetchingAuctionEntries);
  const isLoadingAuctions = useSelector(isFetchingAuctions);
  const history = useHistory();
  let matchUpdate = useRouteMatch(c.APP_ROUTES.VIEW_AUCTION);
  const [gridView, setGridView] = useState(true);
  const [privateZoom, setPrivateZoom] = useState(5);
  const [publicZoom, setPublicZoom] = useState(5);
  const [privateLat, setPrivateLat] = useState(52.1312863);
  const [publicLat, setPublicLat] = useState(52.1312863);
  const [privateLng, setPrivateLng] = useState(-0.0544277);
  const [publicLng, setPublicLng] = useState(-0.0544277);
  const entryFields = useSelector(getAllEntryFields);
  const userRole = useSelector(getLoggedInRole);
  const userId = useSelector(getLoggedInUser);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const searchTerm = params.get('search');

  // @ts-ignore
  const auctionId =  matchUpdate?.params?.id;
  const isLoading =  isLoadingAuctions || auctionId !== auction?.id;

  useEffect(() => {
    if (getUserToken()) {
      dispatch(fetchActiveAuction(auctionId));
      dispatch(fetchAllEntryFields());
    }
  }, [dispatch]);

  const onView = (auctionEntryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION_ENTRY, auctionEntryId));
  };

  const publicStatuses = [
    EntryStatus.PUBLIC,
    EntryStatus.REUSED,
    EntryStatus.EXPIRED,
    EntryStatus.SOLD,
  ];

  const mapPublicItems = entryFields?.data
    .filter(x => (
      x.fieldType === 'map' &&
            (publicStatuses.includes(x.entryStatus as EntryStatus) || x.createdBy === userId?.email) &&
            x.archived === null &&
            auctionEntriesByAuctionId?.data.some(entry => entry.entryId === x.entryId)
    ))
    .map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
      'description': e.entryDescription,
      'assetId': e.entryAssetId,
    }));


  const mapPrivateItems = entryFields?.data
    .filter(x => (
      x.fieldType === 'map' &&
            x.archived === null &&
      auctionEntriesByAuctionId?.data.some(entry => entry.entryId === x.entryId)
    ))
    .map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
      'description': e.entryDescription,
      'assetId': e.entryAssetId,
    }));


  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  useEffect(() => {
    if (mapPublicItems && mapPrivateItems){

      const mapCalc = latLngCalculation(mapPrivateItems, mapPublicItems);
      setPublicLat(mapCalc.publicLat);
      setPrivateLat(mapCalc.privateLat);
      setPublicLng(mapCalc.publicLng);
      setPrivateLng(mapCalc.privateLng);
    }
    setPublicZoom(5);
    setPrivateZoom(5);
  }, [gridView, auction]);


  return (
        <View isLoading={isLoading} imgBanner={auction?.assetId} bannerType={SearchType.AUCTION} bannerSearchTerm = {searchTerm} bannerTitle={auction?.title}>
            <div className="row my-3">
              {gridView && <div className='col-12'>
                    <DataTable
                        paging={auctionEntriesByAuctionId?.paging || []} data={auctionEntriesByAuctionId?.data || []} config={config}
                        fetchFunction={fetchAuctionEntriesByAuctionId}
                        fetchParams={{ 'id': auctionId }}
                        isLoading={isLoadingEntries}
                        isTable={false}
                        gridView={gridView}
                        togglerFunc={() => setGridView((prev) => !prev)} lineItemTemplate={(row: any) =>
                        <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                                <Card
                                    title={row.entryTitle}
                                    cardId={row.index}
                                    description={row.entryDescription}
                                    imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.entryAsset}`}
                                    onClick={() => onView(row.id)}
                                    highestBid={row.highestBidAmount ?? row.startingBid}
                                    startDateTime={new Date(row.startDateTime)}
                                    endDateTime={new Date(row.endDateTime)}
                                />
                        </div>
                    }
                    />
                </div>}

                {!gridView &&
                    <div className="col-12">
                        <div className="view_map">
                          <div className="d-flex flex-column align-items-end mb-3">
                            <div className='view__map-grid--size'>
                              <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'} img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView} img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={()=>setGridView(!gridView)}/>
                            </div>
                            </div>
                            <MaterialMap
                                lat={(userRole && userRole?.accessLevel >= 2000 ) ? publicLat : privateLat}
                                lng={(userRole && userRole?.accessLevel >= 2000 ) ? publicLng : privateLng}
                                view={'viewRenderer'}
                                zoom={(userRole && userRole?.accessLevel >= 2000 ) ? publicZoom : privateZoom}
                                mapList={(userRole && userRole?.accessLevel >= 2000 ) ? mapPublicItems : mapPrivateItems}
                                enableInfoWindow={true}
                            />
                        </div>
                    </div>
                }
                {fetchFailed && (
                    <p>Failed to fetch auctions.</p>
                )}
            </div>
        </View>
  );


}

export default Auction;
