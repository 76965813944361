import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  RoleGroup,
  RoleGroupActionTypes,
  RoleGroupDataModel, FETCH_ROLE_GROUP_FAILURE,
  FETCH_ROLE_GROUP_REQUEST,
  FETCH_ROLE_GROUP_RESPONSE,
  FETCH_ROLE_GROUPS_FAILURE,
  FETCH_ROLE_GROUPS_REQUEST,
  FETCH_ROLE_GROUPS_RESPONSE,
  POST_ROLE_GROUP_FAILURE,
  POST_ROLE_GROUP_REQUEST,
  POST_ROLE_GROUP_RESPONSE,
} from '../types/RoleGroup';

import { RootState } from '../store/configureStore';

import { deleteRoleGroup, getRoleGroups, postRoleGroup } from '../api/RoleGroup';
import { DataTableParamsModel } from '../types/Common';


export function fetchRoleGroupsRequest(): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUPS_REQUEST,
    payload: null,
  };
}

export function fetchRoleGroupsResponse(
  roleGroups: RoleGroupDataModel,
): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUPS_RESPONSE,
    payload: roleGroups,
  };
}

export function fetchRoleGroupsFailure(): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUPS_FAILURE,
    payload: null,
  };
}

export function fetchRoleGroupRequest(): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUP_REQUEST,
    payload: null,
  };
}

export function fetchRoleGroupResponse(
  user: RoleGroup,
): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUP_RESPONSE,
    payload: user,
  };
}

export function fetchRoleGroupFailure(): RoleGroupActionTypes {
  return {
    type: FETCH_ROLE_GROUP_FAILURE,
    payload: null,
  };
}

export function postRoleGroupRequest(): RoleGroupActionTypes {
  return {
    type: POST_ROLE_GROUP_REQUEST,
    payload: null,
  };
}

export function postRoleGroupResponse(): RoleGroupActionTypes {
  return {
    type: POST_ROLE_GROUP_RESPONSE,
    payload: null,
  };
}

export function postRoleGroupFailure(error: string, validationErrors: any): RoleGroupActionTypes {
  return {
    type: POST_ROLE_GROUP_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchRoleGroups = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRoleGroupsRequest());
    const asyncResp: any = await getRoleGroups(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchRoleGroupsResponse(asyncResp));
    } else {
      await dispatch(fetchRoleGroupsFailure());
    }
  };

export const destroyRoleGroup = async (id: string) => {
  try {
    await deleteRoleGroup(id);
  } catch (error) {
    throw error;
  }
};


export const createRoleGroup = (
  roleId: string, groupId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postRoleGroupRequest());
    const asyncResp: any = await postRoleGroup(roleId, groupId);
    if (asyncResp?.success) {
      await dispatch(postRoleGroupResponse());
    } else {
      await dispatch(postRoleGroupFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
