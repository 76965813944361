import { RootState } from '../store/configureStore';

export const getCategories = (state: RootState) => state?.category?.categories;

export const getActiveCategory = (state: RootState) => state?.category?.activeCategory;

export const isFetchingCategories = (state: RootState) => state?.category?.isFetchingCategories;

export const isPostingCategory = (state: RootState) => state?.category?.isPostingCategory;
export const postingCategoryFailed = (state: RootState) => state?.category?.postingCategoryFailed;
export const postingCategoryError = (state: RootState) => state?.category?.postingCategoryError;
export const postingCategoryValidationErrors = (state: RootState) => state?.category?.postingCategoryValidationErrors;
export const fetchingCategoriesFailed = (state: RootState) => state?.category?.fetchingCategoriesFailed;
