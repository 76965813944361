import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SOLUTION_INPUT_TAGS_REQUEST,
  FETCH_SOLUTION_INPUT_TAGS_RESPONSE,
  FETCH_SOLUTION_INPUT_TAGS_FAILURE,
  FETCH_SOLUTION_INPUT_TAG_REQUEST,
  FETCH_SOLUTION_INPUT_TAG_RESPONSE,
  FETCH_SOLUTION_INPUT_TAG_FAILURE,
  POST_SOLUTION_INPUT_TAG_FAILURE,
  POST_SOLUTION_INPUT_TAG_REQUEST,
  POST_SOLUTION_INPUT_TAG_RESPONSE,
  SolutionInputTagActionTypes, SolutionInputTagDataModel, SolutionInputTag,
} from '../types/SolutionInputTag';

import { RootState } from '../store/configureStore';

import { archiveSolutionInputTag, getSolutionInputTag, getSolutionInputTags, postSolutionInputTag, putSolutionInputTag } from '../api/SolutionInputTag';
import { DataTableParamsModel } from '../types/Common';

export function fetchSolutionInputTagsRequest(): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAGS_REQUEST,
    payload: null,
  };
}

export function fetchSolutionInputTagsResponse(
  solutionInputTags: SolutionInputTagDataModel,
): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAGS_RESPONSE,
    payload: solutionInputTags,
  };
}

export function fetchSolutionInputTagsFailure(): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAGS_FAILURE,
    payload: null,
  };
}

export function fetchSolutionInputTagRequest(): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAG_REQUEST,
    payload: null,
  };
}

export function fetchSolutionInputTagResponse(
  solutionInputTag: SolutionInputTag,
): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAG_RESPONSE,
    payload: solutionInputTag,
  };
}

export function fetchSolutionInputTagFailure(): SolutionInputTagActionTypes {
  return {
    type: FETCH_SOLUTION_INPUT_TAG_FAILURE,
    payload: null,
  };
}


export function postSolutionInputTagRequest(): SolutionInputTagActionTypes {
  return {
    type: POST_SOLUTION_INPUT_TAG_REQUEST,
    payload: null,
  };
}

export function postSolutionInputTagResponse(): SolutionInputTagActionTypes {
  return {
    type: POST_SOLUTION_INPUT_TAG_RESPONSE,
    payload: null,
  };
}

export function postSolutionInputTagFailure(error: string, validationErrors: any): SolutionInputTagActionTypes {
  return {
    type: POST_SOLUTION_INPUT_TAG_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveSolutionInputTag = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionInputTagRequest());
    const asyncResp: any = await getSolutionInputTag(id);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionInputTagResponse(asyncResp.data));
    } else {
      await dispatch(fetchSolutionInputTagFailure());
    }
  };

export const fetchSolutionInputTags = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionInputTagsRequest());
    const asyncResp: any = await getSolutionInputTags(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionInputTagsResponse(asyncResp));
    } else {
      await dispatch(fetchSolutionInputTagsFailure());
    }
  };

export const storeSolutionInputTag = async (id: string) => {
  try {
    await archiveSolutionInputTag(id);
  } catch (error) {
    throw error;
  }
};

export const createSolutionInputTag = (
  solutionId: string, tagId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionInputTagRequest());
    const asyncResp: any = await postSolutionInputTag(solutionId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionInputTagResponse());
    } else {
      await dispatch(postSolutionInputTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSolutionInputTag = (
  id: string,
  solutionId: string,
  tagId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionInputTagRequest());
    const asyncResp: any = await putSolutionInputTag(id, solutionId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionInputTagResponse());
    } else {
      await dispatch(postSolutionInputTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


