import { RootState } from '../store/configureStore';

export const getAuctionEntries = (state: RootState) =>
  state?.auctionEntry?.auctionEntries;

export const getActiveAuctionEntry = (state: RootState) =>
  state?.auctionEntry?.activeAuctionEntry;

export const isFetchingAuctionEntries = (state: RootState) =>
  state?.auctionEntry?.isFetchingAuctionEntries;

export const isPostingAuctionEntry = (state: RootState) =>
  state?.auctionEntry?.isPostingAuctionEntry;

export const postingAuctionEntryFailed = (state: RootState) =>
  state?.auctionEntry?.postingAuctionEntryFailed;

export const postingAuctionEntryError = (state: RootState) =>
  state?.auctionEntry?.postingAuctionEntryError;

export const postingAuctionEntryValidationErrors = (state: RootState) =>
  state?.auctionEntry?.postingAuctionEntryValidationErrors;

export const fetchingAuctionEntriesFailed = (state: RootState) =>
  state?.auctionEntry?.fetchingAuctionEntriesFailed;
