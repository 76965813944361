import {
  FETCH_SHADOW_ENTRY_FAILURE,
  FETCH_SHADOW_ENTRY_REQUEST,
  FETCH_SHADOW_ENTRY_RESPONSE,
  FETCH_SHADOW_ENTRIES_FAILURE,
  FETCH_SHADOW_ENTRIES_REQUEST,
  FETCH_SHADOW_ENTRIES_RESPONSE,
  POST_SHADOW_ENTRY_FAILURE,
  POST_SHADOW_ENTRY_RESPONSE,
  ShadowEntryActionTypes,
  ShadowEntryState,
  POST_SHADOW_ENTRY_REQUEST,
} from '../types/ShadowEntry';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ShadowEntryState = {
  shadowEntries: null,
  activeShadowEntry: null,
  isFetchingShadowEntries: false,
  fetchingShadowEntriesFailed: false,
  isPostingShadowEntry: false,
  postingShadowEntryFailed: false,
  postingShadowEntryError: null,
  postingShadowEntryValidationErrors: null,
};

const shadowEntriesReducer = (
  state = initialState,
  action: ShadowEntryActionTypes,
): ShadowEntryState => {
  switch (action.type) {
    case FETCH_SHADOW_ENTRIES_REQUEST:
      return {
        ...state,
        shadowEntries: null,
        isFetchingShadowEntries: true,
        fetchingShadowEntriesFailed: false,
      };
    case FETCH_SHADOW_ENTRIES_RESPONSE:
      return {
        ...state,
        shadowEntries: action.payload || [],
        isFetchingShadowEntries: false,
        fetchingShadowEntriesFailed: false,
      };
    case FETCH_SHADOW_ENTRIES_FAILURE:
      return {
        ...state,
        shadowEntries: null,
        isFetchingShadowEntries: false,
        fetchingShadowEntriesFailed: true,
      };
    case FETCH_SHADOW_ENTRY_REQUEST:
      return {
        ...state,
        shadowEntries: null,
        isFetchingShadowEntries: true,
        fetchingShadowEntriesFailed: false,
      };
    case FETCH_SHADOW_ENTRY_RESPONSE:
      return {
        ...state,
        activeShadowEntry: action.payload,
        isFetchingShadowEntries: false,
        fetchingShadowEntriesFailed: false,
      };
    case FETCH_SHADOW_ENTRY_FAILURE:
      return {
        ...state,
        shadowEntries: null,
        isFetchingShadowEntries: false,
        fetchingShadowEntriesFailed: true,
      };
    case POST_SHADOW_ENTRY_REQUEST:
      return {
        ...state,
        isPostingShadowEntry: true,
        postingShadowEntryError: null,
        postingShadowEntryValidationErrors: null,
        postingShadowEntryFailed: false,
      };
    case POST_SHADOW_ENTRY_RESPONSE:
      return {
        ...state,
        isPostingShadowEntry: false,
        postingShadowEntryError: null,
        postingShadowEntryValidationErrors: null,
        postingShadowEntryFailed: false,
      };
    case POST_SHADOW_ENTRY_FAILURE:
      return {
        ...state,
        isPostingShadowEntry: false,
        postingShadowEntryError: action.payload.error || 'An error occurred',
        postingShadowEntryValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingShadowEntryFailed: true,
      };
    default:
      return state;
  }
};

export default shadowEntriesReducer;
