import React from 'react';
import './ImageButton.scss';

type Props = {
  iconSrc: string;
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  circularIcon?: boolean;
};

const IconButton = (props: Props) => {
  const { iconSrc, label, onClick, isLoading = false, disabled, circularIcon = false } = props;

  const onClicked = () => {
    if (onClick && typeof onClick === 'function' && !isLoading && !disabled) {
      onClick();
    }
  };

  return (
        <div className={`image-button ${disabled && 'opaque'}`} onClick={onClicked}>
            <img src={iconSrc} className={circularIcon ? 'image-button__circular-icon' : 'image-button__icon'} alt={'button-icon'}/>
            <span className="image-button__label d-none d-xl-block">{label}</span>
        </div>
  );
};

export default IconButton;