import { RootState } from '../store/configureStore';

export const getShadowEntries = (state: RootState) => state?.shadowEntry?.shadowEntries;

export const getActiveShadowEntry = (state: RootState) => state?.shadowEntry?.activeShadowEntry;

export const isFetchingShadowEntries = (state: RootState) => state?.shadowEntry?.isFetchingShadowEntries;

export const isPostingShadowEntry = (state: RootState) => state?.shadowEntry?.isPostingShadowEntry;
export const postingShadowEntryFailed = (state: RootState) => state?.shadowEntry?.postingShadowEntryFailed;
export const postingShadowEntryError = (state: RootState) => state?.shadowEntry?.postingShadowEntryError;
export const postingShadowEntryValidationErrors = (state: RootState) => state?.shadowEntry?.postingShadowEntryValidationErrors;
export const fetchingShadowEntriesFailed = (state: RootState) => state?.shadowEntry?.fetchingShadowEntriesFailed;