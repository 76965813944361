import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SOLUTION_OUTPUT_TAGS_REQUEST,
  FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE,
  FETCH_SOLUTION_OUTPUT_TAGS_FAILURE,
  FETCH_SOLUTION_OUTPUT_TAG_REQUEST,
  FETCH_SOLUTION_OUTPUT_TAG_RESPONSE,
  FETCH_SOLUTION_OUTPUT_TAG_FAILURE,
  POST_SOLUTION_OUTPUT_TAG_FAILURE,
  POST_SOLUTION_OUTPUT_TAG_REQUEST,
  POST_SOLUTION_OUTPUT_TAG_RESPONSE,
  SolutionOutputTagActionTypes, SolutionOutputTagDataModel, SolutionOutputTag,
} from '../types/SolutionOutputTag';

import { RootState } from '../store/configureStore';

import { archiveSolutionOutputTag, getSolutionOutputTag, getSolutionOutputTags, postSolutionOutputTag, putSolutionOutputTag } from '../api/SolutionOutputTag';
import { DataTableParamsModel } from '../types/Common';

export function fetchSolutionOutputTagsRequest(): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAGS_REQUEST,
    payload: null,
  };
}

export function fetchSolutionOutputTagsResponse(
  solutionOutputTags: SolutionOutputTagDataModel,
): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE,
    payload: solutionOutputTags,
  };
}

export function fetchSolutionOutputTagsFailure(): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAGS_FAILURE,
    payload: null,
  };
}

export function fetchSolutionOutputTagRequest(): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAG_REQUEST,
    payload: null,
  };
}

export function fetchSolutionOutputTagResponse(
  solutionOutputTag: SolutionOutputTag,
): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAG_RESPONSE,
    payload: solutionOutputTag,
  };
}

export function fetchSolutionOutputTagFailure(): SolutionOutputTagActionTypes {
  return {
    type: FETCH_SOLUTION_OUTPUT_TAG_FAILURE,
    payload: null,
  };
}


export function postSolutionOutputTagRequest(): SolutionOutputTagActionTypes {
  return {
    type: POST_SOLUTION_OUTPUT_TAG_REQUEST,
    payload: null,
  };
}

export function postSolutionOutputTagResponse(): SolutionOutputTagActionTypes {
  return {
    type: POST_SOLUTION_OUTPUT_TAG_RESPONSE,
    payload: null,
  };
}

export function postSolutionOutputTagFailure(error: string, validationErrors: any): SolutionOutputTagActionTypes {
  return {
    type: POST_SOLUTION_OUTPUT_TAG_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveSolutionOutputTag = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionOutputTagRequest());
    const asyncResp: any = await getSolutionOutputTag(id);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionOutputTagResponse(asyncResp.data));
    } else {
      await dispatch(fetchSolutionOutputTagFailure());
    }
  };

export const fetchSolutionOutputTags = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionOutputTagsRequest());
    const asyncResp: any = await getSolutionOutputTags(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionOutputTagsResponse(asyncResp));
    } else {
      await dispatch(fetchSolutionOutputTagsFailure());
    }
  };

export const storeSolutionOutputTag = async (id: string) => {
  try {
    await archiveSolutionOutputTag(id);
  } catch (error) {
    throw error;
  }
};

export const createSolutionOutputTag = (
  solutionId: string, tagId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionOutputTagRequest());
    const asyncResp: any = await postSolutionOutputTag(solutionId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionOutputTagResponse());
    } else {
      await dispatch(postSolutionOutputTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSolutionOutputTag = (
  id: string,
  solutionId: string,
  tagId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionOutputTagRequest());
    const asyncResp: any = await putSolutionOutputTag(id, solutionId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionOutputTagResponse());
    } else {
      await dispatch(postSolutionOutputTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


