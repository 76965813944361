import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AuthActionTypes, SET_AUTH, SET_LOGGED_IN_ROLE, SET_LOGGED_IN_USER } from '../types/Auth';
import { getUserToken, localStorageSetItem, setToken, setUserToken } from '../helpers/functions';

import { RootState } from '../store/configureStore';
import { getUser, postResetPassword, postUserLogin, putResetPassword, putUpdatePassword } from '../api/User';
import { fetchUsers, postUserFailure, postUserRequest, postUserResponse } from './User';
import { User } from '../types/User';
import { Role } from '../types/Role';
import { getRole } from '../api/Role';

export function setLoggedInUserAction(user: User | null): AuthActionTypes {
  return {
    type: SET_LOGGED_IN_USER,
    payload: user,
  };
}

export function setLoggedInRoleAction(role: Role | null): AuthActionTypes {
  return {
    type: SET_LOGGED_IN_ROLE,
    payload: role,
  };
}

export function setAuthAction(username: string, password: string): AuthActionTypes {
  return {
    type: SET_AUTH,
    payload: {
      username,
      password,
    },
  };
}

export const setAuth = (username: string, password: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    if (username === 'info@excessmaterialsexchange.com' && password === 'Excess2Materials!') {
      await localStorageSetItem('isAuthenticated', 'true');
      dispatch(setAuthAction(username, password));
    }
  };

export const logoutUser = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    setToken('');
    setUserToken('');
    localStorageSetItem('selectedGroupId', '');
    localStorageSetItem('selectedGroupTitle', '');
    localStorageSetItem('selectedClientId', '');
    localStorageSetItem('selectedClientTitle', '');
    await dispatch(setLoggedInUserAction(null));
    await dispatch(setLoggedInRoleAction(null));
  };

export const fetchLoggedInUser = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await getUser(getUserToken());
    if (asyncResp?.success) {
      await dispatch(setLoggedInUserAction(asyncResp.data));
      const asyncResp2: any = await getRole(asyncResp.data.roleId);
      if (asyncResp2?.success) {
        await dispatch(setLoggedInRoleAction(asyncResp2.data));
      }
    }
  };

export const loginUser = (
  email: string, password: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await postUserLogin(email, password);
    if (asyncResp?.success) {
      localStorageSetItem('selectedGroupId', '');
      localStorageSetItem('selectedGroupTitle', '');
      localStorageSetItem('selectedClientId', '');
      localStorageSetItem('selectedClientTitle', '');
      setToken(asyncResp?.token);
      setUserToken(asyncResp?.user.id);
      await dispatch(postUserResponse());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const requestResetPassword = (
  email: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await postResetPassword(email);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const resetPassword = (
  id: string,
  password: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await putResetPassword(id, password);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updatePassword = (
  id: string,
  oldPassword: string,
  newPassword: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await putUpdatePassword(id, oldPassword, newPassword);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

/*
export const setAuthenticatedUser = (user: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await localStorageSetItem('user', fetchActiveUser(user));
    dispatch(setAuthAction(username, password));
  };
*/
