import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getSolutionFunctions, isFetchingSolutionFunctions } from '../../../selectors/SolutionFunction';
import { fetchSolutionFunctions, storeSolutionFunction } from '../../../actions/SolutionFunction';
import { SolutionFunction } from '../../../types/SolutionFunction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const SolutionFunctionDataTable = () => {

  const solutionFunctions = useSelector(getSolutionFunctions);
  const isLoading = useSelector(isFetchingSolutionFunctions);
  const [toggle, setToggle] = useState(false);
  const [solutionFunction, setActiveSolutionFunction] = useState<SolutionFunction | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SOLUTION_FUNCTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SOLUTION_FUNCTION_UPDATE, row.id));
  };

  const onArchive = (row: SolutionFunction) => {
    setActiveSolutionFunction(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (solutionFunction?.id) {
      await storeSolutionFunction(solutionFunction?.id);
      setRefetch(!refetch);
    }
    setActiveSolutionFunction(null);
  };

  const config = {
    columns: [
      { key: 'functionValue', label: 'Function', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="SolutionFunctions" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchSolutionFunctions} paging={solutionFunctions?.paging || []}
                   isLoading={isLoading} data={solutionFunctions?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle} title="Archive SolutionFunction" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this solutionFunction?
        </Modal>
      </View>
  );
};

export default SolutionFunctionDataTable;

