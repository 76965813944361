import { RootState } from '../store/configureStore';

export const getMatches = (state: RootState) => state?.match?.matches;

export const getActiveMatch = (state: RootState) => state?.match?.activeMatch;

export const isFetchingMatches = (state: RootState) => state?.match?.isFetchingMatches;

export const isPostingMatch = (state: RootState) => state?.match?.isPostingMatch;
export const postingMatchFailed = (state: RootState) => state?.match?.postingMatchFailed;
export const postingMatchError = (state: RootState) => state?.match?.postingMatchError;
export const postingMatchValidationErrors = (state: RootState) => state?.match?.postingMatchValidationErrors;
export const fetchingMatchesFailed = (state: RootState) => state?.match?.fetchingMatchesFailed;