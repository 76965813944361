import { RootState } from '../store/configureStore';

export const getSolutionFunctions = (state: RootState) => state?.solutionFunction?.solutionFunctions;

export const getActiveSolutionFunction = (state: RootState) => state?.solutionFunction?.activeSolutionFunction;

export const isFetchingSolutionFunctions = (state: RootState) => state?.solutionFunction?.isFetchingSolutionFunctions;

export const isPostingSolutionFunction = (state: RootState) => state?.solutionFunction?.isPostingSolutionFunction;
export const postingSolutionFunctionFailed = (state: RootState) => state?.solutionFunction?.postingSolutionFunctionFailed;
export const postingSolutionFunctionError = (state: RootState) => state?.solutionFunction?.postingSolutionFunctionError;
export const postingSolutionFunctionValidationErrors = (state: RootState) => state?.solutionFunction?.postingSolutionFunctionValidationErrors;
export const fetchingSolutionFunctionsFailed = (state: RootState) => state?.solutionFunction?.fetchingSolutionFunctionsFailed;