import { RootState } from '../store/configureStore';

export const getEntryEdges = (state: RootState) => state?.entryEdge?.entryEdges;

export const getActiveEntryEdge = (state: RootState) => state?.entryEdge?.activeEntryEdge;

export const isFetchingEntryEdges = (state: RootState) => state?.entryEdge?.isFetchingEntryEdges;

export const isPostingEntryEdge = (state: RootState) => state?.entryEdge?.isPostingEntryEdge;
export const postingEntryEdgeFailed = (state: RootState) => state?.entryEdge?.postingEntryEdgeFailed;
export const postingEntryEdgeError = (state: RootState) => state?.entryEdge?.postingEntryEdgeError;
export const postingEntryEdgeValidationErrors = (state: RootState) => state?.entryEdge?.postingEntryEdgeValidationErrors;
export const fetchingEntryEdgesFailed = (state: RootState) => state?.entryEdge?.fetchingEntryEdgesFailed;
