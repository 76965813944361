import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser, getLoggedInRole } from '../../selectors/Auth';
import DropDown from '../../components/DropDown';
import './ClientSelector.scss';
import { useHistory } from 'react-router-dom';
import c from '../../helpers/constants';
import { getActiveClient, getActiveGroup, localStorageSetItem } from '../../helpers/functions';
import Button from '../Button';

type Item = {
  value: string | number;
  label?: string;
  type?: string;
};

interface ClientSelectorProps {
  onClientSelect?: (newTitle: string) => void;
}

const ClientSelector: React.FC<ClientSelectorProps> = ({ onClientSelect }) => {
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInRole = useSelector(getLoggedInRole);
  const history = useHistory();

  const [clientId, setClientId] = useState(getActiveClient().id);
  const [groupId, setGroupId] = useState(getActiveGroup().id);
  const [clientTitle, setClientTitle] = useState(getActiveClient().title);
  const [groupTitle, setGroupTitle] = useState(getActiveGroup().title);
  const [loading, setLoading] = useState(false);

  const [itemsClients, setItemsClients] = useState<Item[]>(
    loggedInUser?.clients.map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
  );

  const itemsGroups: Item[] = loggedInUser?.groups.map(group => ({
    label: group.groupTitle,
    value: group.groupId,
  })) || [];

  useEffect(() => {
    if (loggedInUser && loggedInUser.clients && groupId) {
      setItemsClients(
        loggedInUser?.clients
          ?.filter(data => data.clientGroupId.includes(groupId))
          .map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
      );
    }
  }, [loggedInUser, clientId, groupId]);

  const confirmClientSwitch = () => {
    if (groupId && clientId){
      // Set localStorage values for both client and group when client is selected
      localStorageSetItem('selectedClientId', clientId);
      localStorageSetItem('selectedClientTitle', clientTitle);
      localStorageSetItem('selectedGroupId', groupId);
      localStorageSetItem('selectedGroupTitle', groupTitle);
      setLoading(true);

      if (onClientSelect) {
        onClientSelect(clientTitle);
      }
      history.push(c.APP_ROUTES.PROFILE);
      window.location.reload();
    }
  };

  const handleGroupSelect = (selectedGroup: Item) => {
    if (groupId !== selectedGroup.value.toString()){
      setGroupId(selectedGroup.value.toString());
      setClientId('');
    }
    setGroupTitle(selectedGroup.label ?? '');
    setItemsClients(
      loggedInUser?.clients
        ?.filter(data => data.clientGroupId.includes(selectedGroup.value.toString()))
        .map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
    );
  };

  const handleClientSelect = (selectedClient: Item) => {
    setClientId(selectedClient.value.toString());
    setClientTitle(selectedClient.label ?? '');
  };

  return (
      <div>
        {loggedInUser && loggedInRole && loggedInUser?.clients.length > 1 && loggedInRole.accessLevel <= 1000 && (
            <div className="client-selector">
              {loggedInUser?.groups.length > 1 && (
                  <DropDown
                      id="groupId"
                      placeholder="Please select a Group"
                      label="Group"
                      items={itemsGroups}
                      value={groupId}
                      onSelect={(e) => handleGroupSelect(e)}
                      type="default"
                  />
              )}
              <DropDown
                  id="clientId"
                  placeholder="Please select a Client"
                  label="Client"
                  items={itemsClients}
                  value={clientId}
                  onSelect={(e) => handleClientSelect(e)}
                  type="default"
              />
              <Button title={'Confirm'} disabled={!clientId || !groupId} isLoading={loading} onClick={confirmClientSwitch}/>
            </div>
        )}
      </div>
  );
};

export default ClientSelector;
