import { RootState } from '../store/configureStore';

export const getAssets = (state: RootState) => state?.asset?.assets;

export const getAssetsData = (state: RootState) => state?.asset?.assetsData;

export const getDynamicAssets = (state: RootState) => state?.asset?.dynamicAssets;
export const getUploadedAssets = (state: RootState) => state?.asset?.uploadedAssets;
export const getUploadedAssetsIdentifier = (state: RootState) => state?.asset?.identifier;

export const getActiveAsset = (state: RootState) => state?.asset?.activeAsset;

export const isPostingAsset = (state: RootState) =>
  state?.asset?.isPostingAsset;

export const isFetchingAssets = (state: RootState) =>
  state?.asset?.isFetchingAssets;

export const fetchingAssetsFailed = (state: RootState) =>
  state?.asset?.fetchingAssetsFailed;
