import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../styles/formRenderer.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import { getActiveForm, isFetchingForms } from '../../selectors/Form';
import { getFormFields, isFetchingFormFields } from '../../selectors/FormField';
import { fetchActiveForm } from '../../actions/Form';
import { fetchFormFields } from '../../actions/FormField';
import { fetchCategories } from '../../actions/Category';
import { FormField } from '../../types/FormField';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useValidation } from '../../hooks/ValidationHook';
import { isRequired } from '../../helpers/validators';
import { createEntryWithFields, fetchActiveEntry, fetchEntries, updateEntryWithFields } from '../../actions/Entry';
import {
  getActiveEntry,
  getEntries,
  isFetchingEntries,
  isPostingEntry,
  postingEntryError,
  postingEntryFailed,
  postingEntryValidationErrors,
} from '../../selectors/Entry';
import { Alert } from 'react-bootstrap';
import MaterialMap from '../../components/MaterialMap';
import DropDown from '../../components/DropDown';
import CheckBox from '../../components/CheckBox';
import { getEntryFields } from '../../selectors/EntryField';
import { fetchEntryFields } from '../../actions/EntryField';
import { fetchEntryEdges } from '../../actions/EntryEdge';
import { getEntryEdges } from '../../selectors/EntryEdge';
import { getTags } from '../../selectors/Tag';
import { fetchTags } from '../../actions/Tag';
import { fetchActiveEntryTagsByEntryId } from '../../actions/EntryTag';
import { getEntryTags } from '../../selectors/EntryTag';
import Modal from '../../components/Modal';
import { formatUrl } from '../../helpers/functions';
import { getFieldProperties, isFetchingFieldProperties } from '../../selectors/FieldProperty';
import { fetchFieldProperties } from '../../actions/FieldProperty';
import { getActiveConfiguration } from '../../selectors/Configuration';
import Stepper from '../../components/Stepper';
import { fetchInfoByImage, fetchInfoByText } from '../../actions/Wizard';
import { isFetchingWizards, wizardGetInfoImage, wizardGetInfoText } from '../../selectors/Wizard';
import { Category } from '../../types/Category';
import { getCategories } from '../../selectors/Category';
import { isFetchingEntryFields } from '../../selectors/EntryField';
import FileSelector from '../../components/FileSelector/FileSelector';
import Pill from '../../components/Pill/Pill';
import assetGallery from '../../components/AssetGallery/AssetGallery';




function FormRenderer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const matchCreate = useRouteMatch(c.APP_ROUTES.FORM_RENDERER);
  const matchEdit = useRouteMatch(c.APP_ROUTES.FORM_RENDERER_EDIT);
  const activeForm = useSelector(getActiveForm);
  const formFields = useSelector(getFormFields);
  const isLoadingFormFields = useSelector(isFetchingFormFields);
  const entries = useSelector(getEntries);
  const tags = useSelector(getTags);
  const activeEntry = useSelector(getActiveEntry);
  const activeEntryTags = useSelector(getEntryTags);
  const entryFields = useSelector(getEntryFields);
  const entryEdges = useSelector(getEntryEdges);
  const isPosting = useSelector(isPostingEntry);
  const isFetching = useSelector(isFetchingEntries);
  const isLoadingFieldProperties = useSelector(isFetchingFieldProperties);
  const isFetchingForm = useSelector(isFetchingForms);
  const isLoadingEntryFields = useSelector(isFetchingEntryFields);
  const postingFailed = useSelector(postingEntryFailed);
  const postingError = useSelector(postingEntryError);
  const postingValidationErrors = useSelector(postingEntryValidationErrors);
  const fieldProperties = useSelector(getFieldProperties);
  const activeConfig = useSelector(getActiveConfiguration);
  const wizardImage = useSelector(wizardGetInfoImage);
  const wizardText = useSelector(wizardGetInfoText);
  const isWizardFetching = useSelector(isFetchingWizards);
  const categories = useSelector(getCategories);

  //@ts-ignore
  const [formId, setFormId] = useState(matchCreate?.params?.id);
  //@ts-ignore
  const [entryId, setEntryId] = useState(matchEdit?.params?.id);
  const [fieldValues, setFieldValues] = useState<any[]>([]);
  const [tagsId, setTagsId] = useState<any[]>([]);
  const [itemsTags, setItemsTags] = useState<any[]>([]);
  const [fromEntryIds, setFromEntryIds] = useState<any[]>([]);
  const [toEntryIds, setToEntryIds] = useState<any[]>([]);
  const [itemsEntries, setItemsEntries] = useState<any[]>([]);

  const [tagId, setTagId] = useState('');

  const [title, setTitle] = useState('');
  const [asset, setAsset] = useState('');
  const [description, setDescription] = useState('');
  const [fromEntryId, setFromEntryId] = useState('');
  const [toEntryId, setToEntryId] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hideMap, setHideMap] = useState(true);
  const [wizardModal, setWizardModal] = useState(false);


  const configAllowsRetrigger = activeConfig?.enableAI && activeConfig?.executeAIWizardOnUpdate;

  const [activeStep, setActiveStep] = useState(0);
  const [tagArray, setTagArray] = useState<any[]>([]);
  const isDataFetching = (
    (activeForm === null ) ||
      formFields === null ||
      isFetching ||
      isLoadingEntryFields ||
      isFetchingForm ||
    isLoadingFormFields
  );




  let activeCategories: Category[] = [];
  // @ts-ignore
  formFields?.data?.forEach(fp => {
    const found = activeCategories.find(ccc => ccc.id === fp.categoryId);
    if (!found) {
      const cat = categories?.data.find(cc => cc.id === fp.categoryId);
      if (cat) {
        activeCategories.push(cat);
      }
    }
  });

  activeCategories = activeCategories.sort((a, b) => a.order < b.order ? -1 : 1);



  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const steps = ['Usages',
    'Functionalities ',
    'Materials',
    'Components',
    'Computer Vision',
    'Tags',
  ];

  const { v, triggerValidation, isFormValid, err, updateFields } = useValidation(formConfig, postingValidationErrors);

  const [updateFieldsCalled, setUpdateFieldsCalled] = useState(false);

  useEffect(() => {
    if (fieldProperties && ((matchCreate?.isExact && activeForm?.id === formId) || (matchEdit?.isExact && activeEntry?.id === entryId)) && !isLoadingFieldProperties) {
      // Extract the current form ID based on whether it's a create or edit scenario
      const currentFormId = matchCreate?.isExact ? activeForm?.id : activeEntry?.formId;

      // Filter required fields based on field properties and form ID
      const requiredFields = fieldProperties?.data
        .filter(aa => {
          // Split formId by commas into an array
          const formIds = aa.formId.split(',');

          // Check if the field is required and the current form ID is included in the formIds
          return aa.fieldTypeOption === 'isRequired' && currentFormId  && aa.value === 'true' && formIds.includes(currentFormId);
        })
        .map(aa => aa.fieldTitle);

      console.log(requiredFields, fieldProperties, currentFormId, ' required fields');

      // If requiredFields are found and updateFields hasn't been called yet, proceed
      if (requiredFields && !updateFieldsCalled && (matchCreate?.isExact || matchEdit?.isExact)) {
        const newFieldsToAdd: any[] = [];
        requiredFields.forEach(fieldTitle => {
          const newField = { field: fieldTitle.toLowerCase(), validators: [isRequired] };
          if (!formConfig.some(field => field.field === newField.field)) {
            newFieldsToAdd.push(newField);
          }
        });

        // Add the new fields to the formConfig and call updateFields
        if (newFieldsToAdd.length > 0) {
          formConfig.push(...newFieldsToAdd);
          updateFields(newFieldsToAdd);
          setUpdateFieldsCalled(true); // Set the flag to prevent further calls
        }
      }
    }
  }, [fieldProperties, updateFieldsCalled, matchCreate?.isExact, matchEdit?.isExact, activeEntry, formConfig]);




  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchEntries());
    dispatch(fetchTags());
    if (formId && matchCreate?.isExact  ) {
      dispatch(fetchActiveForm(formId));
      dispatch(fetchFieldProperties({ searchField: 'formId', searchWord: formId }));

    }
    if (matchEdit?.isExact && entryId ){
      dispatch(fetchActiveEntry(entryId));
      dispatch(fetchEntryFields({ searchField:'entryId', searchWord: entryId }));
      dispatch(fetchActiveEntryTagsByEntryId(entryId));
      dispatch(fetchEntryEdges());
    }

  }, [dispatch]);

  //this useffect is fetching the form based on the entry
  useEffect(() => {
    if (entryId && matchEdit?.isExact && activeEntry && activeEntry.id === entryId) {
      dispatch(fetchActiveForm(activeEntry?.formId));
      dispatch(fetchFieldProperties({ searchField: 'formId', searchWord: activeEntry?.formId }));
    }
  }, [activeEntry]);

  useEffect(() => {
    if (formId && formId !== activeForm?.id && activeForm && matchCreate?.isExact) {
      dispatch(fetchActiveForm(formId));
    }
  }, [formId]);

  useEffect(() => {
    // @ts-ignore
    if (matchCreate?.params?.id !== formId) {
      // @ts-ignore
      setFormId(matchCreate?.params?.id);
    }
    // @ts-ignore
  }, [matchCreate?.params?.id]);

  // edit functionality
  useEffect(() => {
    setFieldValues([]);
    setTagsId([]);
    // @ts-ignore
    if (matchEdit?.params?.id !== entryId) {
      // @ts-ignore
      setEntryId(matchEdit?.params?.id);
    }

    // @ts-ignore
  }, [matchEdit?.params?.id]);


  useEffect(() => {
    if (formId && activeForm?.id ) {
      dispatch(fetchFormFields({ searchField:'formId', searchWord: activeForm?.id }));
    }
  }, [activeForm]);
  // Fill form values with entry values

  useEffect(() => {
    if (wizardModal) {
      dispatch(fetchInfoByText(title, description));
      dispatch(fetchInfoByImage(asset));
    }
  }, [wizardModal, dispatch]);


  const setValue = (formField: FormField, value: string) => {
    const index = fieldValues.findIndex(val => val.id === formField.id);
    const newArray = [...fieldValues];
    if (index === -1) {
      newArray.push({
        ...formField,
        value,
      });
    } else {
      newArray[index].value = value;
    }
    setFieldValues(newArray);
  };

  const getValue = (formField: FormField) => {
    const index = fieldValues.findIndex(val => val.id === formField.id);
    return fieldValues[index]?.value;
  };


  const getValueEdit = (formField: FormField, startValue: string ) => {
    const innerValue = getValue(formField);
    if (innerValue === undefined && startValue ){
      // add here the entryFieldId
      setValue(formField, startValue);
      return startValue;
    } else {
      return innerValue;
    }
  };

  const onAddAsset = (formField: FormField, newId: string) => {
    const val = getValue(formField) || '';
    const finalArray = val?.split(',') || [];
    finalArray.push(newId);
    const finalVal = finalArray.filter((value: string) => value).join(',');
    setValue(formField, finalVal);
  };
  const onRemoveAsset = (formField: FormField, oldId: string) => {
    const val = getValue(formField);
    const strArray = val.split(',');
    const index =  strArray.findIndex((str: string) => str === oldId);
    if (index > -1) {
      strArray.splice(index, 1);
      const finalVal = strArray.join(',');
      setValue(formField, finalVal);
    }
  };

  const onDismiss = () => {
    setHideMap(false);
    setWizardModal(false);
  };

  const onToggleMapInput = () => {
    setHideMap(!hideMap);
  };

  const handleFilePick = (id: any) => {
    setAsset(id);
  };

  const handleFileUnPick = () => {
    setAsset('');
  };
  const renderEntryAssetInput = () => {
    return <div>
      <div className="primary-image-container__label">Primary Image*</div>
          <>
            <FileSelector
                type="images"
                dynamicId = 'primary'
                picked={asset}
                groupId={activeForm?.groupId || ''}
                clientId={activeForm?.clientId || ''}
                onPick={handleFilePick}
                onUnPick={handleFileUnPick}
            />
          </>
      </div>;
  };


  const renderCarouselInput = (formField: FormField, value = '') => {
    if (matchEdit?.isExact){
      getValueEdit(formField, value);
    }
    return <div>
      <div className="category__bg--grey">
      <div className="category__sub-label-file-picker">{formField.fieldTitle}</div>
      <FileSelector
            type="images"
            dynamicId = {formField.id}
            groupId={activeForm?.groupId || ''}
            clientId={activeForm?.clientId || ''}
            picked={matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value)}
            onPick={(id) => onAddAsset(formField, id)}
            onUnPick={(id) => onRemoveAsset(formField, id)}
        />
      </div>
        </div>;
  };

  const renderFileInput = (formField: FormField, value = '') => {
    if (matchEdit?.isExact){
      getValueEdit(formField, value);
    }
    return <div>
      <div className="category__bg--grey">
        <div className="category__sub-label-file-picker">{formField.fieldTitle}</div>
        <FileSelector
            type="files"
            dynamicId= {formField.id}
            groupId={activeForm?.groupId || ''}
            clientId={activeForm?.clientId || ''}
            picked={matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value)}
            onPick={(id) => onAddAsset(formField, id)}
            onUnPick={(id) => onRemoveAsset(formField, id)}
        />
      </div>
        </div>;
  };

  const renderCustomFileInput = (formField: FormField, value = '') => {
    if (matchEdit?.isExact) {
      getValueEdit(formField, value);
    }
    let customFileTypes: string[] = [];
    if (fieldProperties && fieldProperties.data) {
      const fieldTypeOption = fieldProperties.data.find(e => (e.fieldTypeOption === 'allowedFileTypes' && e.fieldId === formField.fieldId));
      if (fieldTypeOption) {
        customFileTypes = fieldTypeOption.value.split(',');
      }
    }

    return (
        <div>
          <div className="category__sub-label">{formField.fieldTitle}</div>
          <FileSelector
              type="files"
              dynamicId = {formField.id}
              groupId={activeForm?.groupId || ''}
              clientId={activeForm?.clientId || ''}
              picked={matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value)}
              onPick={(id) => onAddAsset(formField, id)}
              onUnPick={(id) => onRemoveAsset(formField, id)}
              fileTypes={customFileTypes}
          />
        </div>
    );
  };

  const renderMapInput = (formField: FormField, value = '') => {
    const coords = matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value);
    let lat = 48.1312863;
    let lng = 4.1659645;
    let zoom = 4;
    let address = 'Europe';
    if (coords?.length > 0) {
      const parts = coords.split('~');
      lat = parseFloat(parts[0]);
      lng = parseFloat(parts[1]);
      address = parts[3];
      zoom = 14;
    }
    return <div>
      <div className="category__sub-label">{formField.fieldTitle}</div>
    <div className="d-flex flex-wrap justify-content-start align-content-center ms-1">
      <span className="location-text my-2 me-2">
              <img src={assetGallery.pinSmallImg} alt='pin' style={{ marginRight: '5px', marginBottom: '3px' }}/>
        {address}
      </span>
      <Button title={address ? 'Edit Location' : 'Select Location'} onClick={() => onToggleMapInput()}/>
    </div>
      <MaterialMap
          lat={lat}
          lng={lng}
          zoom={zoom}
          address={address}
          isEntryRenderer={true}
          onChange={(la, ln, zm, adr) =>
            setValue(formField, `${la}~${ln}~${zm}~${adr}`)}
          showOnlyAddress={hideMap}
          editMode={true}
      />
    </div>;
  };

  const renderTextInput = (formField: FormField, value = '') => {
    let isRequiredField = false;
    let customPlaceholder = '';
    let maxCharLength = undefined;
    if (fieldProperties && fieldProperties.data) {
      const requiredFieldOption = fieldProperties.data.find(e => (e.fieldTypeOption === 'isRequired' && e.fieldId === formField.fieldId));
      if (requiredFieldOption) {
        isRequiredField = requiredFieldOption.value === 'true';
      }
      const customPlaceholderOption = fieldProperties.data.find(e => (e.fieldTypeOption === 'customPlaceholder' && e.fieldId === formField.fieldId));
      if (customPlaceholderOption) {
        customPlaceholder = customPlaceholderOption.value;
      }
      const maxLengthOption = fieldProperties.data.find(e => (e.fieldTypeOption === 'maxCharLength' && e.fieldId === formField.fieldId));
      if (maxLengthOption){
        maxCharLength = parseInt(maxLengthOption.value, 10);
      }
    }

    return <div>
            <Input
                v={v} err={err}
                onBlur={(val) => setValue(formField, val)}
                onChange={(val) => setValue(formField, val)}
                value={matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value)}
                disabled={isPosting || isFetching}
                subtitle={formField.fieldDescription}
                placeholder={customPlaceholder ? customPlaceholder : formField.fieldTypeDescription}
                label={formField.fieldTitle}
                name={formField.fieldTitle.toLowerCase()}
                id={formField.fieldTitle.toLowerCase()}
                required={isRequiredField}
                maxLength={maxCharLength}/>
        </div>;
  };

  //Dropdown Item
  const onDropEntrySelect = (formField: FormField, obj: any) => {
    setValue(formField, obj.value);
  };
  const renderDropdownInput = (formField: FormField, value = '') => {
    let dropdownItems;
    let isRequiredField = false;
    if (fieldProperties && fieldProperties.data){
      const requiredFieldOption = fieldProperties.data.find(e => (e.fieldTypeOption === 'isRequired' && e.fieldId === formField.fieldId));
      if (requiredFieldOption) {
        isRequiredField = requiredFieldOption.value === 'true';
      }
    }
    let fieldPropertyValue = fieldProperties?.data.find(e=> (e.fieldTypeOption === 'items' && e.fieldId === formField.fieldId));
    try {
      if (fieldPropertyValue?.value){
        dropdownItems = fieldPropertyValue.value.split(',').map(item => {
          const parts = item.split('~');
          if (parts.length !== 2) {
            throw new Error(`Invalid format: ${item}`);
          }
        });
        dropdownItems = fieldPropertyValue.value.split(',').map(item => {
          const parts = item.split('~');
          return { label: parts[1], value: parts[0] };
        });
      }
    } catch (error){
      dropdownItems = [
        { label: 'Invalid items', value: '' },
      ];
    }

    return <div>
      <DropDown v={v} err={err}
                id={formField.fieldTitle.toLowerCase()}
                placeholder={formField.fieldTypeDescription}
                label={formField.fieldTitle}
                required={isRequiredField}
                items={dropdownItems}
                disabled={isPosting || isFetching}
                value={matchCreate?.isExact ? getValue(formField) : getValueEdit(formField, value)}
                onSelect={(val) => onDropEntrySelect(formField, val)}
      />
    </div>;
  };

  const decideField = (formField: FormField, value = '') => {

    switch (formField.fieldTypeTitle) {
      case 'text':
        return matchCreate?.isExact ? renderTextInput(formField) : renderTextInput(formField, value);
      case 'epc':
        return matchCreate?.isExact ? renderTextInput(formField) : renderTextInput(formField, value);
      case 'sku':
        return matchCreate?.isExact ? renderTextInput(formField) : renderTextInput(formField, value);
      case 'requiredEnergyInJoule':
        return matchCreate?.isExact ? renderTextInput(formField) : renderTextInput(formField, value);
      case 'dropdown':
        return matchCreate?.isExact ? renderDropdownInput(formField) : renderDropdownInput(formField, value);
      case 'map':
        return matchCreate?.isExact ? renderMapInput(formField) : renderMapInput(formField, value);
      case 'file':
        return matchCreate?.isExact ? renderFileInput(formField) : renderFileInput(formField, value);
      case 'decompositionImage':
        return matchCreate?.isExact ? renderCustomFileInput(formField) : renderCustomFileInput(formField, value);
      case 'carousel':
        return matchCreate?.isExact ? renderCarouselInput(formField) : renderCarouselInput(formField, value);
      default:
        return;
    }
  };

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      if (activeForm?.viewId){
        history.push(formatUrl(c.APP_ROUTES.VIEW_RENDERER, activeForm?.viewId));
      } else {
        history.push(c.APP_ROUTES.DASHBOARD);
      }
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation() && activeForm && !hasSubmitted) {
      setHasSubmitted(true);
      await dispatch(createEntryWithFields(activeForm.groupId, activeForm.clientId, activeForm.id, asset, title, description, true, fieldValues, fromEntryIds, toEntryIds, tagsId, tagArray));
    }
  };



  const onUpdate = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      await dispatch(updateEntryWithFields(entryId, title, description, asset, true, fieldValues,  fromEntryIds, toEntryIds,  tagsId, tagArray));
    }
  };

  const onCancel = async () => {
    if (activeForm){
      history.push(formatUrl(c.APP_ROUTES.VIEW_RENDERER, activeForm?.viewId));
    }
  };

  //Parent
  const onFromEntrySelect = (obj: any) => {
    setFromEntryId(obj.value);
    setItemsEntries(current => current.filter(t => t.label !== obj.label));
    setFromEntryIds([
      ...fromEntryIds,
      { id: obj.value, label: obj.label },
    ]);
  };

  const onFromEntryUnselect = (id: any, label: any) => {
    setItemsEntries([...itemsEntries, { label: label, value: id },
    ] );
    setFromEntryIds([
      ...fromEntryIds.filter(e => e.id != id),
    ]);

  };

  //Child
  const onToEntrySelect = (obj: any) => {
    setToEntryId(obj.value);
    setItemsEntries(current => current.filter(t => t.label !== obj.label));
    setToEntryIds([
      ...toEntryIds,
      { id: obj.value, label: obj.label },
    ]);
  };

  const onToEntryUnselect = (id: any, label: any) => {
    setItemsEntries([...itemsEntries, { label: label, value: id },
    ] );
    setToEntryIds([
      ...toEntryIds.filter(e => e.id != id),
    ]);

  };
  //Tag
  const onTagsSelect = (obj: any) => {
    setTagId(obj.value);
    setItemsTags(current => current.filter(t => t.label !== obj.label));
    setTagsId([
      ...tagsId,
      { id: obj.value, name: obj.label, type: obj.type },
    ]);
  };
  const onTagsUnselect = (id: any, name: any) => {
    setItemsTags([...itemsTags, { label: name, value: id },
    ] );
    setTagsId([
      ...tagsId.filter(e => e.id != id),
    ]);
  };

  useEffect(() => {
    if (entries && matchCreate?.isExact){
      setItemsEntries(entries?.data.map(r => ({ label: r.title, value: r.id })));
    }
    if (entries && matchEdit?.isExact && entryEdges){
      const data = entries.data.filter(entry => entry.id != entryId );
      const filteredItems = data.filter(entry => !entryEdges.data.some(edge => (edge.fromEntryId === entryId && edge.toEntryId === entry.id) || (edge.toEntryId === entryId && edge.fromEntryId === entry.id) ) );
      setItemsEntries(filteredItems.map(r => ({ label: r.title, value: r.id })));
    }
    if (tags && matchEdit?.isExact && activeEntryTags){
      setItemsTags(tags.data.filter(tag => !activeEntryTags.data.some(entryTag => (entryTag.tagValue === tag.value && entryId === entryTag.entryId))).map(r => ({ label: r.value, value: r.id, type: r.type })));
    } else if (tags && matchEdit?.isExact && !activeEntryTags){
      setItemsTags(tags?.data.map(r => ({ label: r.value, value: r.id, type: r.type })));
    }
    if (tags && matchCreate?.isExact){
      setItemsTags(tags?.data.map(r => ({ label: r.value, value: r.id, type: r.type })));
    }
    if (activeEntry && matchEdit?.isExact && entryFields ) {
      if (activeEntry.id === entryId) {
        setTitle(activeEntry?.title);
        setDescription(activeEntry?.description);
        setAsset(activeEntry?.assetId);
      }
      //Update the connections
      if (entryEdges?.data?.find(ee => ee.toEntryId === entryId)){
        setFromEntryIds(entryEdges?.data?.filter(ee => ee.toEntryId === entryId).map(e =>( { id: e.fromEntryId, label: e.fromEntryTitle })));
      }
      if (entryEdges?.data?.find(ee => ee.fromEntryId === entryId)){
        setToEntryIds(entryEdges?.data?.filter(ee => ee.fromEntryId === entryId).map(e =>( { id: e.toEntryId, label: e.toEntryTitle })));
      }

    }
  }, [activeEntry, entryFields, tags, entries]);

  useEffect(() => {
    if (!activeEntryTags){
      setTagsId([]);
    } else if  ( matchEdit?.isExact && activeEntryTags ){
      setTagsId(
        activeEntryTags?.data.map(r => {return { id: r.tagId, name: r.tagValue, type: r.tagType };}),
      );
    }
  }, [activeEntryTags]);

  const initAIWizard = () => {
    if (triggerValidation()){
      setTagArray([]);
      setWizardModal(true);
    }
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };
  const wideColumnTypes = ['file', 'carousel', 'decompositionImage', 'map'];

  const handleCheckBoxChange = (value: any, type: any) => () => {
    const newTag = { value: value, type };
    const isTagAlreadySelected = tagArray.some(tag => tag.value.trim() === value.trim() && tag.type === type);
    if (isTagAlreadySelected) {
      setTagArray(tagArray.filter(tag => tag.value.trim() !== value.trim() || tag.type !== type));
    } else {
      setTagArray([...tagArray, newTag]);
    }
  };


  useEffect(() => {
    if (activeStep === 5){
      setTagArray(tagArray.filter(tag => !tagsId.some(({ name }) => name.trim().toLowerCase() === tag.value.trim().toLowerCase())));
    }
  }, [activeStep]);


  return (
        <View title={!isDataFetching ? matchEdit?.isExact ? `Update ${activeForm?.title}` : activeForm?.title : ''}
        isLoading={isDataFetching || isPosting}>
            <div className="page">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                <form>
                      <Modal show={wizardModal && !isPosting} onHide={onDismiss} >
                        <div className='wizard__header'>
                          <Stepper steps={steps} activeStep={activeStep} onStepClick={handleStepClick}/>
                        </div>
                          <div className='wizard__body'>
                        <div className='wizard__title'>EME AI Engine</div>
                            <div className='mb-4'>Here is a list of potential tags that our AI Engine has detected for your proposed item.
                              Please, select the options that you deem relevant to the item.</div>
                        {isWizardFetching && <span>Generating response, please wait.</span>}
                        {!isWizardFetching && <>
                        {activeStep === 0 ? <ul className='list-group'>
                          {wizardText?.usages && wizardText?.usages.length > 0 ? wizardText?.usages.map((usage, index) => (
                              <div key={index} className='wizard__text-container'>
                                  <div className='row'>
                                    <div className='col-9'>
                                  {usage}
                                    </div>
                                    <div className='col-3'>
                                    <CheckBox checked={
                                        tagArray.some((tag: { value: string; }) => tag.value === usage) ||
                                        tagsId.some((tag: { name: string; }) => tag.name === usage)
                                    }
                                              key={index} id={`${index}`} onChange={handleCheckBoxChange(usage, 'usage')}></CheckBox>
                                    </div>
                              </div>
                              </div>
                          )) : <span>No usages found, please either modify your Title and Description fields and try again, or move to the next step.</span>}
                            </ul> : //functionalities
                          activeStep === 1 ? <ul className='list-group'>
                              {wizardText?.functionalities && wizardText?.functionalities.length > 0 ? wizardText?.functionalities.map((functionality, index) => (
                                  <div key={index} className='wizard__text-container'>
                                      <div className='row'>
                                        <div className='col-9'>
                                          {functionality}
                                        </div>
                                        <div className='col-3'>
                                          <CheckBox checked={
                                              tagArray.some((tag: { value: string; }) => tag.value === functionality) ||
                                              tagsId.some((tag: { name: string; }) => tag.name === functionality)
                                          }
                                                    key={index + wizardText?.usages.length}
                                                    id={`${index + wizardText?.usages.length}`}  onChange={handleCheckBoxChange(functionality, 'functionality')}></CheckBox>
                                        </div>
                                      </div>
                                  </div>
                              )) : <span>No functionalities found, please either modify your Title and Description fields and try again, or move to the next step.</span>}
                              </ul> : //materials
                            activeStep === 2 ? <ul className='list-group'>
                          {wizardText?.materials && wizardText?.materials.length > 0 ? wizardText?.materials.map((material, index) => (
                              <div key={index} className='wizard__text-container'>
                                  <div className='row'>
                                    <div className='col-9'>
                                      {material}
                                    </div>
                                    <div className='col-3'>
                                      <CheckBox checked={
                                          tagArray.some((tag: { value: string; }) => tag.value === material) ||
                                          tagsId.some((tag: { name: string; }) => tag.name === material)
                                      }
                                                key={index + wizardText?.functionalities.length}
                                                id={`${index + wizardText?.functionalities.length}`}  onChange={handleCheckBoxChange(material, 'material')}></CheckBox>
                                    </div>
                                  </div>
                              </div>
                          ))  : <span>No materials found, please either modify your Title and Description fields and try again, or move to the next step.</span>}
                                </ul> : //components
                              activeStep === 3 ? <ul className='list-group'>
                                  {wizardText?.components && wizardText?.components.length > 0 ? wizardText?.components.map((component, index) => (
                                      <div key={index} className='wizard__text-container'>
                                          <div className='row'>
                                            <div className='col-9'>
                                              {component}
                                            </div>
                                            <div className='col-3'>
                                              <CheckBox checked={
                                                  tagArray.some((tag: { value: string; }) => tag.value === component) ||
                                                  tagsId.some((tag: { name: string; }) => tag.name === component)
                                              }
                                                        key={index + wizardText?.materials.length}
                                                        id={`${index + wizardText?.materials.length}`}  onChange={handleCheckBoxChange(component, 'component')}></CheckBox>
                                            </div>
                                          </div>
                                      </div>
                                  )) : <span>No components found, please either modify your Title and Description fields and try again, or move to the next step.</span>}
                        </ul> : //computer vision
                                activeStep === 4 ? <ul className='list-group'>
                          {wizardImage && wizardImage.length > 0 ? wizardImage?.map((material, index) => (
                              <div key={index} className='wizard__text-container'>
                                  <div className='row'>
                                    <div className='col-9'>
                                      {material[0]}
                                    </div>
                                    <div className='col-3'>
                                      <CheckBox checked={
                                          tagArray.some((tag: { value: string; }) => tag.value === material[0]) ||
                                          tagsId.some((tag: { name: string; }) => tag.name === material[0])
                                      }
                                                onChange={handleCheckBoxChange(material[0], 'generic')}></CheckBox>
                                    </div>
                                  </div>
                              </div>
                          )) : <span>No materials could be found from your image, please either select a different Primary Image and try again, or move to the next step.</span>}
                        </ul> :
                                <div className="row">
                                  {tagsId.length > 0 || tagArray.length > 0 ? <span className="mb-2">Selected tags:</span> : <span className="mb-2"> No tags selected. </span>}
                                {tagsId.map(e =>
                                    <div key={e.id} className='col-auto'>
                                    <Pill  text={e.name} type='white'/>
                                    </div>)}

                                  {tagArray.map((e) =>
                                      <div key={e.id} className='col-auto'>
                                      <Pill key={e.id} text={e.value} type='white'/>
                                        </div>)}
                </div>}
                        </>}
                        {activeStep === 0 && <div className='wizard__skip-prompt' onClick={matchCreate?.isExact ? onSubmit : onUpdate}>Skip this process.</div>}
                        <div className="wizard__button-container">
                          <Button title='BACK' onClick={handleBack} disabled={activeStep === 0 || (isPosting ?? false)}  ></Button>
                          <Button title={ activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'} onClick={ activeStep === steps.length - 1 && matchEdit?.isExact ? onUpdate :
                            activeStep === steps.length - 1 && matchCreate?.isExact ? onSubmit : handleNext } disabled={isPosting ?? false} />
                        </div>
                        </div>
                      </Modal>
                  <div className="row">
                    <div className="category__label">Main Information</div>
                    <div className="col-md-12 category__container">
                    <div className="row">
                      <div className="col-md-6">
                        <Input v={v} err={err}
                               name="title"
                               id="title"
                               label="Title"
                               required
                               disabled={isPosting || isFetching}
                               value={title}
                               onChange={setTitle}
                               onBlur={setTitle}
                               placeholder="Insert a title for the new entry"
                        />
                      </div>
                      <div className="col-md-6">
                      <Input v={v} err={err}
                             required
                             disabled={isPosting || isFetching}
                             name="description"
                             id="description"
                             label="Description"
                             value={description}
                             onChange={setDescription}
                             onBlur={setDescription}
                             placeholder="Insert a description for the new entry"
                      />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 primary-image-container">
                      {renderEntryAssetInput()}
                    {formFields?.data.some(item => item.fieldTypeTitle === 'carousel') && <small>*Add additional images in the gallery below.</small>}
                  </div>
                  {activeCategories.map(category => (
                      <>
                        <div className="category__label">{category.title}</div>
                      <div key={category.id} className='category__container'>
                        <div className="row">
                            {formFields?.data
                              .filter(field => field.categoryId === category.id)
                              .sort((a, b) => a.order < b.order ? -1 : 1)
                              .map(e => (
                                    <div key={e.id} className={wideColumnTypes.includes(e.fieldTypeTitle) ? 'col-md-12' : 'col-md-6'}>
                                      {matchCreate?.isExact && decideField(e)}
                                      {matchEdit?.isExact && entryFields && decideField(e, entryFields?.data?.find(x => x.fieldId === e.fieldId)?.value)}
                                    </div>
                              ))}
                          </div>
                      </div>
                      </>
                  ))}
                </div>
                  <div className="row">
                    <div className="category__label">Connections</div>
                    <div className="col category__container">
                      <DropDown id="fromEntryId"
                                placeholder="Please select an parent entry"
                                label="Parent Connection"
                                items={itemsEntries}
                                disabled={isPosting || isFetching}
                                value={fromEntryId}
                                onSelect={onFromEntrySelect}
                                type="default"
                      />
                      {matchCreate?.isExact && fromEntryIds.map(e =>
                        <Pill key={e.id} text={e.label} type='white' cancellable onClick={() => onFromEntryUnselect(e.id, e.label)}/>)}
                      {matchEdit?.isExact && entryEdges && fromEntryIds.map(e =>
                          <Pill key={e.id} text={e.label} type='white' cancellable onClick={() => onFromEntryUnselect(e.id, e.label)}/>)}
                    </div>
                    <div className="col category__container">
                    <DropDown id="toEntryId"
                              placeholder="Please select a child entry"
                              label="Child Connection"
                              items={itemsEntries}
                              disabled={isPosting || isFetching}
                              value={toEntryId}
                              onSelect={onToEntrySelect}
                              type="default"
                    />
                      {matchCreate?.isExact && toEntryIds.map(e =>
                          <Pill key={e.id} text={e.label} type='white' cancellable onClick={() => onToEntryUnselect(e.id, e.label)}/>)}
                      {matchEdit?.isExact && entryEdges && toEntryIds.map(e =>
                          <Pill key={e.id} text={e.label} type='white' cancellable onClick={() => onToEntryUnselect(e.id, e.label)}/>)}
                  </div>
                  </div>
                  <div className='row'>
                    <div className="category__label">Tags</div>
                    <div className='col category__container'>
                  <DropDown id="tagsId"
                            placeholder="Please select tags for your product"
                            label="Select Tags"
                            items={itemsTags}
                            disabled={isPosting || isFetching}
                            value={tagId}
                            onSelect={onTagsSelect}
                            type="default"
                            searchable ={true}
                  />
                      <div>
                  {matchCreate?.isExact && tagsId.map(e =>
                      <Pill key={e.id} text={e.name} type='white' cancellable onClick={() => onTagsUnselect(e.id, e.name)}/>)}
                  {matchEdit?.isExact && tags && tagsId?.map(e =>
                    <Pill key={e.id} text={e.name} type='white' cancellable onClick={() => onTagsUnselect(e.id, e.name)}/>)}
                </div>
                  {(!formFields || !formFields?.data || formFields?.data?.length === 0) &&
                  <div className="mb-5">No data available.</div>}
                  </div>
              </div>
                {formFields?.data && formFields?.data?.length > 0 && <div className="my-1 form-renderer-button-container">
                  <Button title="Cancel" disabled={hasSubmitted} onClick={onCancel}/>
                  {matchCreate?.isExact && <Button title="Submit" disabled={(asset === ''  || !isFormValid())} onClick={activeConfig?.enableAI ? initAIWizard : onSubmit}/>}
                  {matchEdit?.isExact && <Button title="Update" disabled={asset === '' || !isFormValid()} onClick={configAllowsRetrigger ? initAIWizard : onUpdate}/>}
                </div>}
                </form>
            </div>
        </View>
  );
}

export default FormRenderer;
