import {
  FETCH_POPULAR_SEARCH_FAILURE,
  FETCH_POPULAR_SEARCH_REQUEST,
  FETCH_POPULAR_SEARCH_RESPONSE,
  FETCH_POPULAR_SEARCHES_FAILURE,
  FETCH_POPULAR_SEARCHES_REQUEST,
  FETCH_POPULAR_SEARCHES_RESPONSE,
  POST_POPULAR_SEARCH_FAILURE,
  POST_POPULAR_SEARCH_RESPONSE,
  PopularSearchActionTypes,
  PopularSearchState,
  POST_POPULAR_SEARCH_REQUEST,
  CLEAR_POPULAR_SEARCH,
} from '../types/PopularSearch';
import { parseValidationErrors } from '../helpers/functions';

const initialState: PopularSearchState = {
  popularSearches: null,
  activePopularSearch: null,
  isFetchingPopularSearches: false,
  fetchingPopularSearchesFailed: false,
  isPostingPopularSearch: false,
  postingPopularSearchFailed: false,
  postingPopularSearchError: null,
  postingPopularSearchValidationErrors: null,
};

const popularSearchReducer = (
  state = initialState,
  action: PopularSearchActionTypes,
): PopularSearchState => {
  switch (action.type) {
    case FETCH_POPULAR_SEARCHES_REQUEST:
      return {
        ...state,
        popularSearches: null,
        isFetchingPopularSearches: true,
        fetchingPopularSearchesFailed: false,
      };
    case FETCH_POPULAR_SEARCHES_RESPONSE:
      return {
        ...state,
        popularSearches: action.payload || [],
        isFetchingPopularSearches: false,
        fetchingPopularSearchesFailed: false,
      };
    case FETCH_POPULAR_SEARCHES_FAILURE:
      return {
        ...state,
        popularSearches: null,
        isFetchingPopularSearches: false,
        fetchingPopularSearchesFailed: true,
      };
    case FETCH_POPULAR_SEARCH_REQUEST:
      return {
        ...state,
        popularSearches: null,
        isFetchingPopularSearches: true,
        fetchingPopularSearchesFailed: false,
      };
    case FETCH_POPULAR_SEARCH_RESPONSE:
      return {
        ...state,
        activePopularSearch: action.payload,
        isFetchingPopularSearches: false,
        fetchingPopularSearchesFailed: false,
      };
    case FETCH_POPULAR_SEARCH_FAILURE:
      return {
        ...state,
        popularSearches: null,
        isFetchingPopularSearches: false,
        fetchingPopularSearchesFailed: true,
      };
    case POST_POPULAR_SEARCH_REQUEST:
      return {
        ...state,
        isPostingPopularSearch: true,
        postingPopularSearchError: null,
        postingPopularSearchValidationErrors: null,
        postingPopularSearchFailed: false,
      };
    case POST_POPULAR_SEARCH_RESPONSE:
      return {
        ...state,
        isPostingPopularSearch: false,
        postingPopularSearchError: null,
        postingPopularSearchValidationErrors: null,
        postingPopularSearchFailed: false,
      };
    case POST_POPULAR_SEARCH_FAILURE:
      return {
        ...state,
        isPostingPopularSearch: false,
        postingPopularSearchError: action.payload.error || 'An error occurred',
        postingPopularSearchValidationErrors: parseValidationErrors(
          action.payload.validationErrors,
        ),
        postingPopularSearchFailed: true,
      };
    case CLEAR_POPULAR_SEARCH:
      return {
        popularSearches: null,
        activePopularSearch: null,
        isFetchingPopularSearches: false,
        fetchingPopularSearchesFailed: false,
        isPostingPopularSearch: false,
        postingPopularSearchFailed: false,
        postingPopularSearchError: null,
        postingPopularSearchValidationErrors: null,
      };

    default:
      return state;
  }
};

export default popularSearchReducer;
