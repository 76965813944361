import axios from 'axios';

import c from '../helpers/constants';

import { objectToQueryString } from '../helpers/functions';
import { DataTableParamsModel } from '../types/Common';

export function getEntry(id: string, visibilityPublic?: boolean) {
  let url = '';
  if (visibilityPublic){
    url = `${c.API_ENDPOINTS.ENTRY}-public/${id}`;
  } else {
    url = `${c.API_ENDPOINTS.ENTRY}/${id}`;
  }
  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        'publicEntryId': id,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function matchEntry(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MATCH_ENTRY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntriesByMarketplace(dataTableParams?: DataTableParamsModel) {
  const baseUrl = `${c.API_ENDPOINTS.ENTRIES_BY_MARKETPLACE}`;
  const fetchParams = dataTableParams?.fetchParams;
  // Conditionally append "-shortcode" to the URL if both groupShortcode and clientShortcode are provided
  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode/${fetchParams?.id}`
    : `${baseUrl}/${fetchParams?.id}`;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryByShortcode(groupShortcode: string, clientShortcode: string, id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY}/${groupShortcode}/${clientShortcode}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getGuestEntryByShortcode(id: string, groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY}-guest/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        id,
        groupShortcode,
        clientShortcode,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntries(dataTableParams?: DataTableParamsModel) {
    
  let url = '';

  if (dataTableParams?.fetchParams?.visibilityPublic) {
    url = `${c.API_ENDPOINTS.ENTRY_PUBLIC}`;
  } else {
    const baseUrl = `${c.API_ENDPOINTS.ENTRY}`;
    const fetchParams = dataTableParams?.fetchParams;

    url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
      ? `${baseUrl}-shortcode`
      : baseUrl;
  }


  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getReusedEntires(dataTableParams?: DataTableParamsModel) {

  let url = `${c.API_ENDPOINTS.ENTRY_REUSED_ITEMS}`;


  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getPublicEntries(dataTableParams?: DataTableParamsModel) {

  const baseUrl = `${c.API_ENDPOINTS.ENTRY_PUBLIC}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;
  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntriesByShortcodes(groupShortcode: string, clientShortcode: string, formId: string | null = null) {
  let payload = '';
  if (formId) {
    payload = objectToQueryString({ searching: { key: 'formId', value: formId } });
  }
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRIES_SHORTCODE}/${groupShortcode}/${clientShortcode}?${payload}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntry(groupId: string, clientId: string, formId: string, assetId: string, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        assetId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postDuplicateEntry(entryId: string, numberOfDuplicates: number, startingNumber?: number) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY}/duplicate`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        entryId,
        numberOfDuplicates,
        startingNumber,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntryWithFields(groupId: string, clientId: string, formId: string, assetId: string,
  title: string, description: string, active: boolean, fields: any[],
  fromEntryIds: any[], toEntryIds: any[], tags?:any[], newTags?: { value: string, type: string }[],
) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_WITH_FIELDS,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        assetId,
        title,
        description,
        active,
        fields,
        fromEntryIds,
        toEntryIds,
        tags,
        newTags,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryWithFields(
  entryId: string,
  title: string,
  description: string | null,
  assetId: string,
  active: boolean,
  fields: any[],
  fromEntryIds: any[],
  toEntryIds: any[],
  tagsId: any[],
  newTags?: { value: string, type: string }[],
  dataTableParams?: DataTableParamsModel,
) {

  const fetchParams = dataTableParams?.fetchParams;

  let apiUrl = `${c.API_ENDPOINTS.ENTRY_WITH_FIELDS}/${entryId}`;
  if (fetchParams?.groupShortcode && fetchParams?.clientShortcode) {
    apiUrl = `${c.API_ENDPOINTS.ENTRY_WITH_FIELDS}-shortcode/${entryId}`;
  }
  return (
    axios({
      url: apiUrl,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        assetId,
        active,
        fields,
        fromEntryIds,
        toEntryIds,
        tagsId,
        newTags,
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntry(
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  assetId: string,
  title: string,
  description: string | null,
  active: boolean,
  notes?: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        formId,
        assetId,
        title,
        description,
        active,
        notes,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryStatus(
  id: string | undefined,
  status: string,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_STATUS}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        status,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntry(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

