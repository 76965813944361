import { ApiDataModel, FormError } from './Common';

export const FETCH_MARKETPLACE_ENTRIES_REQUEST = 'FETCH_MARKETPLACE_ENTRIES_REQUEST';
export const FETCH_MARKETPLACE_ENTRIES_RESPONSE = 'FETCH_MARKETPLACE_ENTRIES_RESPONSE';
export const FETCH_MARKETPLACE_ENTRIES_FAILURE = 'FETCH_MARKETPLACE_ENTRIES_FAILURE';

export const FETCH_MARKETPLACE_ENTRY_REQUEST = 'FETCH_MARKETPLACE_ENTRY_REQUEST';
export const FETCH_MARKETPLACE_ENTRY_RESPONSE = 'FETCH_MARKETPLACE_ENTRY_RESPONSE';
export const FETCH_MARKETPLACE_ENTRY_FAILURE = 'FETCH_MARKETPLACE_ENTRY_FAILURE';

export const POST_MARKETPLACE_ENTRY_REQUEST = 'POST_MARKETPLACE_ENTRY_REQUEST';
export const POST_MARKETPLACE_ENTRY_RESPONSE = 'POST_MARKETPLACE_ENTRY_RESPONSE';
export const POST_MARKETPLACE_ENTRY_FAILURE = 'POST_MARKETPLACE_ENTRY_FAILURE';

export interface MarketplaceEntry {
  id: string;
  marketplaceId: string;
  entryId: string;
  entryTitle: string;
  entryDescription: string;
  entryAssetId: string;
  entryGroupId: string;
  entryGroupShortcode: string;
  entryClientId: string;
  entryClientShortcode: string;
}

export interface MarketplaceEntryDataModel extends Omit<ApiDataModel, 'data'> {
  data: MarketplaceEntry[];
}

export interface MarketplaceEntryState {
  marketplaceEntries: MarketplaceEntryDataModel | null,
  activeMarketplaceEntry: MarketplaceEntry | null,
  isFetchingMarketplaceEntries: boolean;
  fetchingMarketplaceEntriesFailed: boolean;
  postingMarketplaceEntryFailed: boolean;
  isPostingMarketplaceEntry: boolean;
  postingMarketplaceEntryValidationErrors: FormError[] | null;
  postingMarketplaceEntryError: string | null;
}

interface FetchMarketplaceEntriesRequestAction {
  type: typeof FETCH_MARKETPLACE_ENTRIES_REQUEST;
  payload: null;
}

interface FetchMarketplaceEntriesResponseAction {
  type: typeof FETCH_MARKETPLACE_ENTRIES_RESPONSE;
  payload: MarketplaceEntryDataModel;
}

interface FetchMarketplaceEntriesFailureAction {
  type: typeof FETCH_MARKETPLACE_ENTRIES_FAILURE;
  payload: null;
}

interface FetchMarketplaceEntryRequestAction {
  type: typeof FETCH_MARKETPLACE_ENTRY_REQUEST;
  payload: null;
}

interface FetchMarketplaceEntryResponseAction {
  type: typeof FETCH_MARKETPLACE_ENTRY_RESPONSE;
  payload: MarketplaceEntry;
}

interface FetchMarketplaceEntryFailureAction {
  type: typeof FETCH_MARKETPLACE_ENTRY_FAILURE;
  payload: null;
}

interface PostMarketplaceEntryRequestAction {
  type: typeof POST_MARKETPLACE_ENTRY_REQUEST;
  payload: null;
}

interface PostMarketplaceEntryResponseAction {
  type: typeof POST_MARKETPLACE_ENTRY_RESPONSE;
  payload: null;
}

interface PostMarketplaceEntryFailureAction {
  type: typeof POST_MARKETPLACE_ENTRY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type MarketplaceEntryActionTypes =
    | FetchMarketplaceEntriesRequestAction
    | FetchMarketplaceEntriesResponseAction
    | FetchMarketplaceEntriesFailureAction
    | PostMarketplaceEntryRequestAction
    | PostMarketplaceEntryResponseAction
    | PostMarketplaceEntryFailureAction
    | FetchMarketplaceEntryRequestAction
    | FetchMarketplaceEntryResponseAction
    | FetchMarketplaceEntryFailureAction;
