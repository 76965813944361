import axios from 'axios';
import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getMarketplaceEntry(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.MARKETPLACE_ENTRY}/${id}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function getMarketplaceEntries(
  dataTableParams?: DataTableParamsModel,
) {
  const baseUrl = `${c.API_ENDPOINTS.MARKETPLACE_ENTRY}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return axios({
    url,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params: {
      dataTableParams,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}


export function deleteMarketplaceEntry(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.MARKETPLACE_ENTRY}/${id}`,
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function postMarketplaceEntry(marketplaceId: string, entryId: string) {
  return axios({
    url: c.API_ENDPOINTS.MARKETPLACE_ENTRY,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      marketplaceId,
      entryId,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function createOrUpdateMarketplaceEntries(marketplaceId: string, entryIds: string[]) {
  return axios({
    url: c.API_ENDPOINTS.CREATE_MARKETPLACE_ENTRIES_BATCH,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      marketplaceId,
      entryIds,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}
