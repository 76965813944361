import { ApiDataModel, FormError } from './Common';

export const FETCH_ROLE_CLIENTS_REQUEST = 'FETCH_ROLE_CLIENTS_REQUEST';
export const FETCH_ROLE_CLIENTS_RESPONSE = 'FETCH_ROLE_CLIENTS_RESPONSE';
export const FETCH_ROLE_CLIENTS_FAILURE = 'FETCH_ROLE_CLIENTS_FAILURE';

export const FETCH_ROLE_CLIENT_REQUEST = 'FETCH_ROLE_CLIENT_REQUEST';
export const FETCH_ROLE_CLIENT_RESPONSE = 'FETCH_ROLE_CLIENT_RESPONSE';
export const FETCH_ROLE_CLIENT_FAILURE = 'FETCH_ROLE_CLIENT_FAILURE';

export const POST_ROLE_CLIENT_REQUEST = 'POST_ROLE_CLIENT_REQUEST';
export const POST_ROLE_CLIENT_RESPONSE = 'POST_ROLE_CLIENT_RESPONSE';
export const POST_ROLE_CLIENT_FAILURE = 'POST_ROLE_CLIENT_FAILURE';

export interface RoleClient {
  id: string;
  clientId: string;
  clientTitle: string;
  roleId: string;
  roleTitle: string;
  roleAccessLevel: number;
}

export interface RoleClientDataModel extends Omit<ApiDataModel, 'data'> {
  data: RoleClient[];
}

export interface RoleClientState {
  roleClients: RoleClientDataModel | null,
  activeRoleClient: RoleClient | null,
  isFetchingRoleClients: boolean;
  fetchingRoleClientsFailed: boolean;
  postingRoleClientFailed: boolean;
  isPostingRoleClient: boolean;
  postingRoleClientValidationErrors: FormError[] | null;
  postingRoleClientError: string | null;
}

interface FetchRoleClientsRequestAction {
  type: typeof FETCH_ROLE_CLIENTS_REQUEST;
  payload: null;
}

interface FetchRoleClientsResponseAction {
  type: typeof FETCH_ROLE_CLIENTS_RESPONSE;
  payload: RoleClientDataModel;
}

interface FetchRoleClientsFailureAction {
  type: typeof FETCH_ROLE_CLIENTS_FAILURE;
  payload: null;
}

interface FetchRoleClientRequestAction {
  type: typeof FETCH_ROLE_CLIENT_REQUEST;
  payload: null;
}

interface FetchRoleClientResponseAction {
  type: typeof FETCH_ROLE_CLIENT_RESPONSE;
  payload: RoleClient;
}

interface FetchRoleClientFailureAction {
  type: typeof FETCH_ROLE_CLIENT_FAILURE;
  payload: null;
}

interface PostRoleClientRequestAction {
  type: typeof POST_ROLE_CLIENT_REQUEST;
  payload: null;
}

interface PostRoleClientResponseAction {
  type: typeof POST_ROLE_CLIENT_RESPONSE;
  payload: null;
}

interface PostRoleClientFailureAction {
  type: typeof POST_ROLE_CLIENT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type RoleClientActionTypes =
    | FetchRoleClientsRequestAction
    | FetchRoleClientsResponseAction
    | FetchRoleClientsFailureAction
    | PostRoleClientRequestAction
    | PostRoleClientResponseAction
    | PostRoleClientFailureAction
    | FetchRoleClientRequestAction
    | FetchRoleClientResponseAction
    | FetchRoleClientFailureAction;
