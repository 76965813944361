import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import View from '../../components/View';
import './ValuationDashboard.scss';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import { fetchActiveMatch } from '../../actions/Match';
import { getActiveMatch, isFetchingMatches } from '../../selectors/Match';
import { fetchValuationInfo } from '../../actions/Wizard';
import { getValuationInfo, isFetchingWizards } from '../../selectors/Wizard';
import MaterialMap from '../../components/MaterialMap';
import assetGallery from '../../components/AssetGallery';
import Pill from '../../components/Pill';
import { getEntryTags } from '../../selectors/EntryTag';
import { fetchEntryTags } from '../../actions/EntryTag';
import Geocode from 'react-geocode';
import useShortcodes from '../../hooks/ShortcodeHook';
import Button from '../../components/Button';
import { getLoggedInRole } from '../../selectors/Auth';

const ValuationDashboard = () => {
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.VALUATION_DASHBOARD_SHORTCODE, c.APP_ROUTES.VALUATION_DASHBOARD);
  const dispatch = useDispatch();
  const match = useSelector(getActiveMatch);
  const valuationInfo = useSelector(getValuationInfo);
  const isWizardFetching = useSelector(isFetchingWizards);
  const isLoadingMatches = useSelector(isFetchingMatches);
  const loggedInRole = useSelector(getLoggedInRole);
  const tags = useSelector(getEntryTags);
  const matchUpdate = isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.VALUATION_DASHBOARD_SHORTCODE) : useRouteMatch(c.APP_ROUTES.VALUATION_DASHBOARD);
  // @ts-ignore
  const matchId = matchUpdate?.params?.id;
  const solutionLocationParts = match?.solutionLocation?.split('~');
  const [latitude, setLat] = useState(0);
  const [longitude, setLng] = useState(0);
  const API_KEY = 'AIzaSyBAbdGe9ZZQbuGEAUmbsnAp4g-P4zVm44k';
  Geocode.setApiKey(API_KEY);
  Geocode.enableDebug();
  const isExactMatch = matchId === match?.id;
  const [toggle, setToggle] = useState(false);


  const mapListItems = match
    ? [
      {
        geolocation: match.entryLocation || '',
        id: match.entryId || '',
        title: match.entryTitle || '',
      },
      {
        geolocation: latitude && longitude ? `${latitude}~${longitude}` : match.solutionLocation || '',
        id: match.solutionId || '',
        title: match.solutionTitle || '',
      },
    ]
    : [];

  useEffect(() => {
    dispatch(fetchActiveMatch(matchId, { fetchParams: isUsingShortcodes ? { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } : {} }));
  }, [dispatch, toggle]);

  useEffect(() => {
    if (match && match.entryId){
      dispatch(fetchEntryTags({ baseSearch: { 'entryId': match.entryId }, fetchParams: isUsingShortcodes ? { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } : {} }));
    }
  }, [match]);

  const hasResponses = Boolean(
    match?.environmentalResponse &&
        match?.financialResponse &&
        match?.socialResponse &&
        match?.legalResponse,
  );

  const fetchValuation = () => {
    if (match){
      dispatch(
        fetchValuationInfo(
          match?.id,
          match?.solutionTitle,
          match?.solutionDescription,
          match?.entryTitle,
          match?.entryDescription,
          match?.solutionLocation,
          match?.entryLocation,
        ),
      );
    }
  };

  const onClick = () => {
    setToggle(!toggle);
    fetchValuation();
  };

  useEffect(() => {
    if (match && !hasResponses && isExactMatch) {
      fetchValuation();
    }
  }, [match]);

  const extractLocation = (location: string) => {
    const parts = location?.split('~');
    return parts ? parts[parts.length - 1].trim() : '';
  };

  const renderResponse = (response: string | undefined, valuationInfoResponse: string | undefined) => {
    if (response) {
      return <span>{response}</span>;
    } else if (!isWizardFetching && valuationInfoResponse) {
      return <span>{valuationInfoResponse}</span>;
    } else {
      return <span>Generating response, please wait.</span>;
    }
  };


  const renderLegalResponses = () => {
    if (!isWizardFetching && match?.legalResponse && match?.legalResponse !== '[]') {
      return match.legalResponse.replace(/[\[\],"]/g, '')
        .split('~~')
        .map((item) => item.trim())
        .filter((trimmedItem) => trimmedItem !== '')
        .map((trimmedItem, index) => <li key={index}>{trimmedItem}</li>);
    } else if (!isWizardFetching && match?.legalResponse === '[]'){
      return <span>No legal information found.</span>;
    } else if (!isWizardFetching && !match?.legalResponse && valuationInfo?.legal && Array.isArray(valuationInfo?.legal)) {
      const legalItems = valuationInfo.legal
        .map((item) => item.trim())
        .filter((trimmedItem) => trimmedItem !== '');

      if (legalItems.length === 0) {
        return <span>No legal information found.</span>;
      }
      return legalItems.map((trimmedItem, index) => <li key={index}>{trimmedItem.replace(/[\[\],"~~]/g, '')}</li>);
    } else {
      return <span>Generating response, please wait.</span>;
    }
  };

  const renderEnvironmentalResponse = () => (
        <div className="relative__impact">
            <div className="relative__subtitle"><img src={assetGallery.environmentalImg} alt={'environmental'}/>Environmental</div>
            {renderResponse(match?.environmentalResponse, valuationInfo?.environmental)}
        </div>
  );

  const renderFinancialResponse = () => (
        <div className="relative__impact">
            <div className="relative__subtitle"><img src={assetGallery.financialImg} alt={'financial'}/>Financial</div>
            {renderResponse(match?.financialResponse, valuationInfo?.financial)}
        </div>
  );

  const renderSocialResponse = () => (
        <div className="relative__impact">
            <div className="relative__subtitle"><img src={assetGallery.socialImg} alt={'social'}/>Social</div>
            {renderResponse(match?.socialResponse, valuationInfo?.social)}
        </div>
  );

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        if (solutionLocationParts && solutionLocationParts[0] && solutionLocationParts.length == 1){
          const response = await Geocode.fromAddress(solutionLocationParts[0]);
          const { lat, lng } = response.results[0].geometry.location;
          setLat(lat);
          setLng(lng);
        }

      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    };
    geocodeAddress();
  }, [solutionLocationParts]);

  return (
        <View title="SOLUTION OUTLINE" isLoading={isWizardFetching || isLoadingMatches || !isExactMatch}>
            <div className="row justify-content-around">
                <div className="col-md-5 valuation__category__container">
                    {mapListItems.length >= 1 && <MaterialMap
                        lat={solutionLocationParts && solutionLocationParts?.length > 2 ? parseFloat(solutionLocationParts[0]) : latitude}
                        lng={solutionLocationParts && solutionLocationParts?.length > 2 ? parseFloat(solutionLocationParts[1]) : longitude}
                        zoom={1.8}
                        mapList={mapListItems}
                        historyMode={true}
                        isEntryRenderer={false}
                    />}
                </div>
                    <div className="col-md-6 valuation__category__container">
                        <div className="valuation__title">{match && match.entryTitle}</div>
                        {loggedInRole?.accessLevel === 0 && <Button title={'Refresh Responses'} onClick={onClick}/>}
                        {(match && match.entryLocation) && <div className="valuation__location"><img src={assetGallery.pinSmallImg} alt='pin'/> {extractLocation(match.entryLocation)}</div>}
                        <div className="valuation__description">{match && match.entryDescription}</div>
                        <div className="solution__container">
                            <div className="valuation__title">{match && match.solutionTitle}</div>
                            {(match && match.solutionLocation) && <div className="valuation__location"><img src={assetGallery.pinSmallImg} alt='pin'/> {extractLocation(match.solutionLocation)}</div>}
                            <div className="valuation__description">{match && match.solutionDescription}</div>
                        </div>
                        {tags && tags?.data
                          .filter(tag => match?.inputTagValues.includes(tag.tagValue))
                          .map((matchedTag, tagIndex) => (
                                <Pill key={tagIndex} text={matchedTag.tagValue} type='black' icon='tag'/>
                          ))
                        }
                    </div>
                </div>
            <div className="row mt-2 justify-content-around">
                <div className="col-md-5 valuation__category__container mb-3">
                    <div className="relative__title"><img src={assetGallery.legalImg} alt={'legal'}/>Legal Considerations</div>
                    <div className="relative__impact">
                        {renderLegalResponses()}
                    </div>
                </div>
                <div className="col-md-6 valuation__category__container mb-3">
                    <div className="relative__title">Relative Impacts</div>
                    <div className="relative__content">
                        {renderEnvironmentalResponse()}
                        {renderFinancialResponse()}
                        {renderSocialResponse()}
                    </div>
                </div>
            </div>
        </View>
  );
};

export default ValuationDashboard;
