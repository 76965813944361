import { RootState } from '../store/configureStore';

export const getOfferProjectManagers = (state: RootState) => state?.offerProjectManager?.offerProjectManagers;

export const isFetchingOfferProjectManagers = (state: RootState) =>
  state?.offerProjectManager?.isFetchingOfferProjectManagers;

export const isPostingOfferProjectManager = (state: RootState) =>
  state?.offerProjectManager?.isPostingOfferProjectManager;
export const isPostingOfferProjectManagers = (state: RootState) =>
  state?.offerProjectManager?.isPostingOfferProjectManagers;

export const postingOfferProjectManagerFailed = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagerFailed;
export const postingOfferProjectManagerError = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagerError;
export const postingOfferProjectManagerValidationErrors = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagerValidationErrors;

export const fetchingOfferProjectManagersFailed = (state: RootState) =>
  state?.offerProjectManager?.fetchingOfferProjectManagersFailed;

export const postingOfferProjectManagersFailed = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagersFailed;
export const postingOfferProjectManagersError = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagersError;
export const postingOfferProjectManagersValidationErrors = (state: RootState) =>
  state?.offerProjectManager?.postingOfferProjectManagersValidationErrors;