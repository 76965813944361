import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../components/View/View.scss';
import { formatUrl } from '../../helpers/functions';
import { getLoggedInUser } from '../../selectors/Auth';
import { getActiveOffer, isFetchingOffers } from '../../selectors/Offer';
import { fetchActiveOffer } from '../../actions/Offer';
import c from '../../helpers/constants';
import useUserRoles from '../../hooks/userRoleHook';
import { useHistory } from 'react-router-dom';

interface OfferRedirectProps {
  offerId: string;
}

const OfferRedirect: React.FC<OfferRedirectProps> = ({ offerId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isABBUser, isABBClientAdmin, isEMESuperAdmin, isEMEClientAdmin } = useUserRoles();
  const loggedInUser = useSelector(getLoggedInUser);
  const activeOffer = useSelector(getActiveOffer);
  const isFetching = useSelector(isFetchingOffers);

  useEffect(() => {
    if (offerId) {
      dispatch(fetchActiveOffer(offerId));
    }
  }, [offerId, dispatch]);

  useEffect(() => {
    if (loggedInUser && activeOffer && activeOffer?.id === offerId) {
      const statusCode = activeOffer?.statusCodes[0]?.value;

      if ((isEMESuperAdmin || isEMEClientAdmin) && statusCode === 'Pending Estimation') {
        history.push(formatUrl(c.APP_ROUTES.CREATE_ESTIMATION, offerId));
      } else if ((isEMESuperAdmin || isEMEClientAdmin) && statusCode === 'Changes Requested') {
        history.push(formatUrl(c.APP_ROUTES.CREATE_ESTIMATION, offerId));
      } else if ((isABBClientAdmin || isEMESuperAdmin) && statusCode === 'Pending Verification') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_OFFER, offerId));
      } else if ((isABBClientAdmin || isEMESuperAdmin) && statusCode === 'Pending Offer') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_ESTIMATION, offerId));
      } else if ((isABBUser || isEMESuperAdmin) && statusCode === 'Draft') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_OFFER, offerId));
      } else {
        history.push(c.APP_ROUTES.DEAL_HUB);
      }
    }
  }, [activeOffer, loggedInUser, isEMESuperAdmin, isEMEClientAdmin, isABBClientAdmin, isABBUser, offerId, history]);

  return (
      <View isLoading={isFetching}>
      </View>
  );
};

export default OfferRedirect;
