import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getOPPNotification(id: string, dataTableParams?: DataTableParamsModel) {
  const url = `${c.API_ENDPOINTS.OPP_NOTIFICATION}/${id}`;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: dataTableParams,
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

export function getOPPNotifications(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.OPP_NOTIFICATION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

// Create a new subscription
export function postOPPNotification(
  notificationId: string,
  type: string,
  objectType: string,
  objectUid: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.OPP_NOTIFICATION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        notificationId,
        type,
        objectType,
        objectUid,
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}
