import { ApiDataModel, FormError } from './Common';
import { StatusCodesEnum } from '../helpers/constants';

export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_RESPONSE = 'FETCH_OFFERS_RESPONSE';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';

export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_RESPONSE = 'FETCH_OFFER_RESPONSE';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';

export const POST_OFFER_REQUEST = 'POST_OFFER_REQUEST';
export const POST_OFFER_RESPONSE = 'POST_OFFER_RESPONSE';
export const POST_OFFER_FAILURE = 'POST_OFFER_FAILURE';

export interface Offer {
  id: string;
  donorClientId: string;
  clientTitle: string;
  clientEmail: string;
  clientPhone: string;
  clientWebsite: string;
  projectManagers:
  {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    companyName: string,
  }[]
  projectManagerEmail: string;
  projectManagerFirstName: string;
  projectManagerLastName: string;
  projectManagerClientTitles: string | string[];
  salesRepUserId: string;
  salesRepUserEmail: string;
  salesRepUserFirstName: string;
  salesRepUserLastName: string;
  salesRepUserClientTitles: string | string[];
  shipmentContactId: string;
  shipmentContactEmail: string;
  shipmentContactNumber: string;
  shipmentContactCompanyName: string;
  solutionId: string;
  solutionTitle: string;
  solutionContactEmail: string;
  solutionContactNumber: string;
  solutionLocation: string;
  subject: string;
  details: string;
  date: string;
  clientNumber: string;
  currency: string;
  pickupLocation: string;
  collectionDate: string;
  notesToEME: string;
  notesToShippingProvider: string;
  totalMinRate: number;
  totalMaxRate: number;
  totalActualRate: number;
  statusCodes: { value: StatusCodesEnum, notes: string }[];
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface OfferDataModel extends Omit<ApiDataModel, 'data'> {
  data: Offer[];
}

export interface OfferState {
  offers: OfferDataModel | null;
  activeOffer: Offer | null;
  isFetchingOffers: boolean;
  fetchingOffersFailed: boolean;
  isPostingOffer: boolean;
  postingOfferFailed?: boolean;
  postingOfferValidationErrors: FormError[] | null;
  postingOfferError: string | null;
}

interface FetchOffersRequestAction {
  type: typeof FETCH_OFFERS_REQUEST;
  payload: null;
}

interface FetchOffersResponseAction {
  type: typeof FETCH_OFFERS_RESPONSE;
  payload: OfferDataModel;
}

interface FetchOffersFailureAction {
  type: typeof FETCH_OFFERS_FAILURE;
  payload: null;
}

interface FetchOfferRequestAction {
  type: typeof FETCH_OFFER_REQUEST;
  payload: null;
}

interface FetchOfferResponseAction {
  type: typeof FETCH_OFFER_RESPONSE;
  payload: Offer;
}

interface FetchOfferFailureAction {
  type: typeof FETCH_OFFER_FAILURE;
  payload: null;
}

interface PostOfferRequestAction {
  type: typeof POST_OFFER_REQUEST;
  payload: null;
}

interface PostOfferResponseAction {
  type: typeof POST_OFFER_RESPONSE;
  payload: null;
}

interface PostOfferFailureAction {
  type: typeof POST_OFFER_FAILURE;
  payload: {
    error?: string;
    validationErrors?: any;
  };
}

export type OfferActionTypes =
    | FetchOffersRequestAction
    | FetchOffersResponseAction
    | FetchOffersFailureAction
    | FetchOfferRequestAction
    | FetchOfferResponseAction
    | FetchOfferFailureAction
    | PostOfferRequestAction
    | PostOfferResponseAction
    | PostOfferFailureAction;
