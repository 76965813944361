import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  AuctionBid,
  AuctionBidActionTypes,
  AuctionBidDataModel,
  FETCH_AUCTION_BID_FAILURE,
  FETCH_AUCTION_BID_REQUEST,
  FETCH_AUCTION_BID_RESPONSE,
  FETCH_AUCTION_BIDS_FAILURE,
  FETCH_AUCTION_BIDS_REQUEST,
  FETCH_AUCTION_BIDS_RESPONSE,
  POST_AUCTION_BID_FAILURE,
  POST_AUCTION_BID_REQUEST,
  POST_AUCTION_BID_RESPONSE,
} from '../types/AuctionBid';

import { RootState } from '../store/configureStore';

import {
  postAuctionBid,
  getAuctionBids,
  archiveAuctionBid, putAuctionBid, getAuctionBid, placePostAuctionBid,
} from '../api/AuctionBid';
import { DataTableParamsModel } from '../types/Common';

export function fetchAuctionBidsRequest(): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BIDS_REQUEST,
    payload: null,
  };
}

export function fetchAuctionBidsResponse(
  auctionEntries: AuctionBidDataModel,
): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BIDS_RESPONSE,
    payload: auctionEntries,
  };
}

export function fetchAuctionBidsFailure(): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BIDS_FAILURE,
    payload: null,
  };
}

export function fetchAuctionBidRequest(): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BID_REQUEST,
    payload: null,
  };
}

export function fetchAuctionBidResponse(
  entry: AuctionBid,
): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BID_RESPONSE,
    payload: entry,
  };
}

export function fetchAuctionBidFailure(): AuctionBidActionTypes {
  return {
    type: FETCH_AUCTION_BID_FAILURE,
    payload: null,
  };
}

export function postAuctionBidRequest(): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_REQUEST,
    payload: null,
  };
}

export function postAuctionBidResponse(): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_RESPONSE,
    payload: null,
  };
}



export function postAuctionBidFailure(
  error: string,
  validationErrors: any,
): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export function postAuctionBidsRequest(): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_REQUEST,
    payload: null,
  };
}

export function postAuctionBidsResponse(): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_RESPONSE,
    payload: null,
  };
}

export function postAuctionBidsFailure(
  error: string,
  validationErrors: any,
): AuctionBidActionTypes {
  return {
    type: POST_AUCTION_BID_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchAuctionBids = (
  datatableParams?: DataTableParamsModel,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(fetchAuctionBidsRequest());
  const asyncResp: any = await getAuctionBids(
    datatableParams,
  );
  if (asyncResp?.success) {
    await dispatch(fetchAuctionBidsResponse(asyncResp));
  } else {
    await dispatch(fetchAuctionBidsFailure());
  }
};

export const fetchActiveAuctionBid = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionBidRequest());
    const asyncResp: any = await getAuctionBid(id);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionBidResponse(asyncResp.data));
    } else {
      await dispatch(fetchAuctionBidFailure());
    }
  };

export const storeAuctionBid = async (id: string) => {
  try {
    await archiveAuctionBid(id);
  } catch (error) {
    throw error;
  }
};

export const createAuctionBid = (
  auctionId: string,
  entryId: string,
  userId: string,
  amount: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(postAuctionBidRequest());
  const asyncResp: any = await postAuctionBid(auctionId, entryId, userId, amount);
  if (asyncResp?.success) {
    await dispatch(postAuctionBidResponse());
  } else {
    await dispatch(
      postAuctionBidFailure(asyncResp?.error, asyncResp?.validationErrors),
    );
  }
};

export const placeAuctionBid = (
  auctionEntryId: string,
  userId: string,
  amount: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(postAuctionBidRequest());
  const asyncResp: any = await placePostAuctionBid(auctionEntryId, userId, amount);
  if (asyncResp?.success) {
    await dispatch(postAuctionBidResponse());
    //await dispatch(fetchAuctionBids());
  } else {
    await dispatch(
      postAuctionBidFailure(asyncResp?.error, asyncResp?.validationErrors),
    );
  }
  return asyncResp;
};

export const updateAuctionBid = (
  activeId: string,
  auctionId: string,
  entryId: string,
  userId: string,
  amount: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(postAuctionBidsRequest());
  const asyncResp: any = await putAuctionBid(activeId, auctionId, entryId, userId, amount);
  if (asyncResp?.success) {
    await dispatch(postAuctionBidResponse());
  } else {
    await dispatch(
      postAuctionBidsFailure(asyncResp?.error, asyncResp?.validationErrors),
    );
  }
};
