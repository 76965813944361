import {
  FETCH_OPP_NOTIFICATIONS_REQUEST,
  FETCH_OPP_NOTIFICATIONS_RESPONSE,
  FETCH_OPP_NOTIFICATIONS_FAILURE,
  FETCH_OPP_NOTIFICATION_REQUEST,
  FETCH_OPP_NOTIFICATION_RESPONSE,
  FETCH_OPP_NOTIFICATION_FAILURE,
  POST_OPP_NOTIFICATION_REQUEST,
  POST_OPP_NOTIFICATION_RESPONSE,
  POST_OPP_NOTIFICATION_FAILURE,
  OPPNotificationActionTypes,
  OPPNotificationDataModel,
  OPPNotification,
} from '../types/OPPNotification';
import { DataTableParamsModel } from '../types/Common';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store/configureStore';
import { Action } from 'redux';
import { getOPPNotification, getOPPNotifications, postOPPNotification } from '../api/OPPNotification';

export function fetchOPPNotificationsRequest(): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATIONS_REQUEST,
    payload: null,
  };
}

export function fetchOPPNotificationsResponse(
  oppNotifications: OPPNotificationDataModel,
): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATIONS_RESPONSE,
    payload: oppNotifications,
  };
}

export function fetchOPPNotificationsFailure(): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATIONS_FAILURE,
    payload: null,
  };
}

export function fetchOPPNotificationRequest(): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATION_REQUEST,
    payload: null,
  };
}

export function fetchOPPNotificationResponse(
  oppNotification: OPPNotification,
): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATION_RESPONSE,
    payload: oppNotification,
  };
}

export function fetchOPPNotificationFailure(): OPPNotificationActionTypes {
  return {
    type: FETCH_OPP_NOTIFICATION_FAILURE,
    payload: null,
  };
}

export function postOPPNotificationRequest(): OPPNotificationActionTypes {
  return {
    type: POST_OPP_NOTIFICATION_REQUEST,
    payload: null,
  };
}

export function postOPPNotificationResponse(): OPPNotificationActionTypes {
  return {
    type: POST_OPP_NOTIFICATION_RESPONSE,
    payload: null,
  };
}

export function postOPPNotificationFailure(error: string, validationErrors: any): OPPNotificationActionTypes {
  return {
    type: POST_OPP_NOTIFICATION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchOPPNotifications = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOPPNotificationsRequest());
    const asyncResp: any = await getOPPNotifications(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOPPNotificationsResponse(asyncResp));
    } else {
      await dispatch(fetchOPPNotificationsFailure());
    }
  };

export const fetchOPPNotification = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOPPNotificationRequest());
    const asyncResp: any = await getOPPNotification(id, dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOPPNotificationResponse(asyncResp.data));
    } else {
      await dispatch(fetchOPPNotificationFailure());
    }
  };

export const createOPPNotification = (
  notificationId: string,
  type: string,
  objectType: string,
  objectUid: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOPPNotificationRequest());
    const asyncResp: any = await postOPPNotification(notificationId, type, objectType, objectUid);
    if (asyncResp?.success) {
      await dispatch(postOPPNotificationResponse());
    } else {
      await dispatch(postOPPNotificationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
