import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FORM_FIELDS_REQUEST,
  FETCH_FORM_FIELDS_RESPONSE,
  FETCH_FORM_FIELDS_FAILURE,
  FETCH_FORM_FIELD_REQUEST,
  FETCH_FORM_FIELD_RESPONSE,
  FETCH_FORM_FIELD_FAILURE,
  POST_FORM_FIELD_FAILURE,
  POST_FORM_FIELD_REQUEST,
  POST_FORM_FIELD_RESPONSE,
  FormFieldActionTypes, FormFieldDataModel, FormField,
} from '../types/FormField';

import { RootState } from '../store/configureStore';

import {
  archiveFormField,
  getFormField,
  getFormFields,
  getFormFieldsByShortcodes,
  postFormField,
  putFormField,
} from '../api/FormField';
import { DataTableParamsModel } from '../types/Common';

export function fetchFormFieldsRequest(): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELDS_REQUEST,
    payload: null,
  };
}

export function fetchFormFieldsResponse(
  formFields: FormFieldDataModel,
): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELDS_RESPONSE,
    payload: formFields,
  };
}

export function fetchFormFieldsFailure(): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELDS_FAILURE,
    payload: null,
  };
}

export function fetchFormFieldRequest(): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELD_REQUEST,
    payload: null,
  };
}

export function fetchFormFieldResponse(
  formFields: FormField,
): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELD_RESPONSE,
    payload: formFields,
  };
}

export function fetchFormFieldFailure(): FormFieldActionTypes {
  return {
    type: FETCH_FORM_FIELD_FAILURE,
    payload: null,
  };
}


export function postFormFieldRequest(): FormFieldActionTypes {
  return {
    type: POST_FORM_FIELD_REQUEST,
    payload: null,
  };
}

export function postFormFieldResponse(): FormFieldActionTypes {
  return {
    type: POST_FORM_FIELD_RESPONSE,
    payload: null,
  };
}

export function postFormFieldFailure(error: string, validationErrors: any): FormFieldActionTypes {
  return {
    type: POST_FORM_FIELD_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveFormField = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormFieldRequest());
    const asyncResp: any = await getFormField(id);
    if (asyncResp?.success) {
      await dispatch(fetchFormFieldResponse(asyncResp.data));
    } else {
      await dispatch(fetchFormFieldFailure());
    }
  };

export const fetchFormFields = (datatableParams?: DataTableParamsModel, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormFieldsRequest());
    const asyncResp: any = await getFormFields(datatableParams, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchFormFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchFormFieldsFailure());
    }
  };

export const fetchFormFieldsByShortcodes = (groupShortcode: string, clientShortcode: string, formId: string | null = null):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFormFieldsRequest());
    const asyncResp: any = await getFormFieldsByShortcodes(groupShortcode, clientShortcode, formId);
    if (asyncResp?.success) {
      await dispatch(fetchFormFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchFormFieldsFailure());
    }
  };

export const storeFormField = async (id: string) => {
  try {
    await archiveFormField(id);
  } catch (error) {
    throw error;
  }
};

export const createFormField = (
  groupId: string, clientId: string, formId: string, fieldId: string, order: number, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFormFieldRequest());
    const asyncResp: any = await postFormField(groupId, clientId, formId, fieldId, order, active);
    if (asyncResp?.success) {
      await dispatch(postFormFieldResponse());
    } else {
      await dispatch(postFormFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateFormField = (
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  fieldId: string,
  order: number,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFormFieldRequest());
    const asyncResp: any = await putFormField(id, groupId, clientId, formId, fieldId, order, active);
    if (asyncResp?.success) {
      await dispatch(postFormFieldResponse());
    } else {
      await dispatch(postFormFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


