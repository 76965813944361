import React from 'react';
import './ProposalCard.scss';
import AssetGallery from '../../AssetGallery';
import c, { StatusCodesEnum } from '../../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { formatUrl } from '../../../helpers/functions';

type Props = {
  title: string;
  description?: string;
  minRate?: number;
  info?: string;
  maxRate?: number;
  actualRate?: number;
  currency?: string;
  proposalURL?: string;
  showGreenReport?: boolean;
  greenDownloadReportURL?: string;
  reviewURL?: string;
  onReviewClick?:() => void;
  icon?: string;
  statusCode: StatusCodesEnum;
  offerId: string;
  offerDate: string;
  pickupLocation: string;
  solutionLocation: string;
};

const ProposalCard = (props: Props) => {
  const {
    title,
    description,
    info,
    minRate,
    maxRate,
    actualRate,
    proposalURL,
    showGreenReport = false,
    statusCode,
    icon,
    reviewURL,
    offerId,
  } = props;

  const history = useHistory();


  const getColor = (code: string) => {
    switch (code) {
      case StatusCodesEnum.DRAFT:
        return '#8d8d8d';

      case StatusCodesEnum.CHANGES_REQUESTED:
        return '#dd7e12';

      case StatusCodesEnum.COMPLETED:
        return '#13c730';

      default:
        return '#2C2C2C';
    }
  };


  const reviewStatuses = [
    StatusCodesEnum.PENDING_QUOTATION,
    StatusCodesEnum.PENDING_VERIFICATION,
    StatusCodesEnum.PENDING_OFFER_ACCEPTANCE,
    StatusCodesEnum.CHANGES_REQUESTED,
    StatusCodesEnum.DRAFT,
  ];

  const handleNavigation = (url?: string) => {
    if (url) {
      history.push(url);
    }
  };
  const handleGreenReport = (id?: string) => {
    if (id) {
      history.push(formatUrl(c.APP_ROUTES.GREEN_REPORT, id));
    }
  };


  const hasLinks = proposalURL || showGreenReport;

  return (
        <div className="proposal-card">
            <div className="proposal-card-icon">
                <img src={icon ? icon : AssetGallery.iconProposal} alt={'Proposal Icon'}/>

            </div>
            <div className={`proposal-card-content ${!hasLinks ? 'no-links' : ''}`}>
                <>
                </>
                <h5 className="proposal-card-title proposal-card__info">
                    <div className="proposal-card__info info-box">
                      {info}
                    </div>
                  {title ?? 'Subject Pending'}
                    {actualRate !== undefined && actualRate !== null && actualRate != 0.00 && (
                        <>
                          {' | '}
                      <span>
                          {actualRate} {' '}
                        </span>
                            {props.currency}
                        </>
                    )}
                    {minRate !== undefined && maxRate !== undefined && actualRate == 0.00 && minRate !== maxRate && (
                        <>
                            {' '}
                          {' | '}
                            <span>{minRate}</span> to{' '}
                            <span>{maxRate}</span> {props.currency}
                        </>
                    )}
                    {minRate !== undefined && maxRate !== undefined && actualRate == 0.00 && minRate === maxRate && (
                        <>
                            {' '}
                            {' | '}
                            <span>{maxRate}</span> {props.currency}
                        </>
                    )}
                  {actualRate == null && minRate == null && maxRate == null && (
                      <>
                        {' '}
                        {' | '}
                        <span>Price Pending</span>
                      </>
                  )}
                </h5>
                <p className="proposal-card-description">{description}</p>
                {hasLinks && (
                    <div className="proposal-card-links">
                        {proposalURL && (
                            <button className="details-link" onClick={() => handleNavigation(proposalURL)}>Proposal
                                Details</button>
                        )}
                        {showGreenReport && (
                            <button className="green-report-link" onClick={() => handleGreenReport(offerId)}>Green
                                Report</button>
                        )}
                    </div>
                )}
            </div>
            <div className="proposal-card-button">
                {((reviewURL || props.onReviewClick) && reviewStatuses.includes(statusCode)) && (
                    <>
                        {reviewURL &&
                            <button className="proposal-card-review-button"
                                 onClick={() => handleNavigation(reviewURL)}>Review</button>}
                        {props.onReviewClick &&
                            <button className="proposal-card-review-button"
                                                        onClick={props.onReviewClick}>Review</button>}
                    </>
                )}
                {( statusCode == StatusCodesEnum.COMPLETED) && (
                    <img src={AssetGallery.greenReportDownload} className="proposal-card-download-button"
                         onClick={() => handleGreenReport(offerId)}  // Trigger the download PDF function
                         alt={'green'}/>
                )}
            </div>
            <div className="proposal-card-badge" style={{ color: getColor(statusCode) }}>{statusCode}</div>

        </div>
  );
};

export default ProposalCard;