import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_VIEWS_REQUEST,
  FETCH_VIEWS_RESPONSE,
  FETCH_VIEWS_FAILURE,
  FETCH_VIEWS_DATA_REQUEST,
  FETCH_VIEWS_DATA_RESPONSE,
  FETCH_VIEWS_DATA_FAILURE,
  FETCH_DUPLICATE_VIEWS_REQUEST,
  FETCH_DUPLICATE_VIEWS_RESPONSE,
  FETCH_DUPLICATE_VIEWS_FAILURE,
  FETCH_VIEW_REQUEST,
  FETCH_VIEW_RESPONSE,
  FETCH_VIEW_FAILURE,
  POST_VIEW_FAILURE,
  POST_VIEW_REQUEST,
  POST_VIEW_RESPONSE,
  ViewActionTypes, ViewDataModel, View,
} from '../types/View';

import { RootState } from '../store/configureStore';

import {
  archiveView, getDuplicateViews,
  getView,
  getViewByShortcodes,
  getViews,
  getViewsByShortcodes,
  postDuplicateView, postTransferEntries,
  postView,
  putView,
} from '../api/View';
import { fetchEntries, postEntryFailure, postEntryRequest, postEntryResponse } from './Entry';
import { DataTableParamsModel } from '../types/Common';

export function fetchViewsRequest(): ViewActionTypes {
  return {
    type: FETCH_VIEWS_REQUEST,
    payload: null,
  };
}

export function fetchViewsResponse(
  views: ViewDataModel,
): ViewActionTypes {
  return {
    type: FETCH_VIEWS_RESPONSE,
    payload: views,
  };
}

export function fetchViewsFailure(): ViewActionTypes {
  return {
    type: FETCH_VIEWS_FAILURE,
    payload: null,
  };
}

export function fetchViewsDataRequest(): ViewActionTypes {
  return {
    type: FETCH_VIEWS_DATA_REQUEST,
    payload: null,
  };
}

export function fetchViewsDataResponse(
  viewsData: ViewDataModel,
): ViewActionTypes {
  return {
    type: FETCH_VIEWS_DATA_RESPONSE,
    payload: viewsData,
  };
}

export function fetchViewsDataFailure(): ViewActionTypes {
  return {
    type: FETCH_VIEWS_DATA_FAILURE,
    payload: null,
  };
}

export function fetchDuplicateViewsRequest(): ViewActionTypes {
  return {
    type: FETCH_DUPLICATE_VIEWS_REQUEST,
    payload: null,
  };
}

export function fetchDuplicateViewsResponse(
  views: ViewDataModel,
): ViewActionTypes {
  return {
    type: FETCH_DUPLICATE_VIEWS_RESPONSE,
    payload: views,
  };
}

export function fetchDuplicateViewsFailure(): ViewActionTypes {
  return {
    type: FETCH_DUPLICATE_VIEWS_FAILURE,
    payload: null,
  };
}

export function fetchViewRequest(): ViewActionTypes {
  return {
    type: FETCH_VIEW_REQUEST,
    payload: null,
  };
}

export function fetchViewResponse(
  views: View,
): ViewActionTypes {
  return {
    type: FETCH_VIEW_RESPONSE,
    payload: views,
  };
}

export function fetchViewFailure(): ViewActionTypes {
  return {
    type: FETCH_VIEW_FAILURE,
    payload: null,
  };
}


export function postViewRequest(): ViewActionTypes {
  return {
    type: POST_VIEW_REQUEST,
    payload: null,
  };
}

export function postViewResponse(): ViewActionTypes {
  return {
    type: POST_VIEW_RESPONSE,
    payload: null,
  };
}

export function postViewFailure(error: string, validationErrors: any): ViewActionTypes {
  return {
    type: POST_VIEW_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveView = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewRequest());
    const asyncResp: any = await getView(id);
    if (asyncResp?.success) {
      await dispatch(fetchViewResponse(asyncResp.data));
    } else {
      await dispatch(fetchViewFailure());
    }
  };

export const fetchViews = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewsRequest());
    const asyncResp: any = await getViews(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchViewsResponse(asyncResp));
    } else {
      await dispatch(fetchViewsFailure());
    }
  };

export const fetchViewByShortcodes = (groupShortcode: string, clientShortcode: string, id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewRequest());
    const asyncResp: any = await getViewByShortcodes(groupShortcode, clientShortcode, id);
    if (asyncResp?.success) {
      await dispatch(fetchViewResponse(asyncResp.data));
    } else {
      await dispatch(fetchViewFailure());
    }
  };

export const fetchViewsByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewsRequest());
    const asyncResp: any = await getViewsByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchViewsResponse(asyncResp));
    } else {
      await dispatch(fetchViewsFailure());
    }
  };

export const storeView = async (id: string) => {
  try {
    await archiveView(id);
  } catch (error) {
    console.error('Error archiving tag:', error);
    throw error;
  }
};


export const createView = (
  groupId: string, clientId: string, formId: string, order:number, title: string, contents: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postViewRequest());
    const asyncResp: any = await postView(groupId, clientId, formId, order, title, contents, active);
    if (asyncResp?.success) {
      await dispatch(postViewResponse());
    } else {
      await dispatch(postViewFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchViewsData = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchViewsDataRequest());
    const asyncResp: any = await getViews(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchViewsDataResponse(asyncResp));
    } else {
      await dispatch(fetchViewsDataFailure());
    }
  };


export const fetchDuplicateViews = (formId = ''):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchDuplicateViewsRequest());
    const asyncResp: any = await getDuplicateViews(formId);
    if (asyncResp?.success) {
      await dispatch(fetchDuplicateViewsResponse(asyncResp));
    } else {
      await dispatch(fetchDuplicateViewsFailure());
    }
  };

export const createDuplicateView = (
  groupId: string, clientId: string, formId: string, title: string, description: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postViewRequest());
    const asyncResp: any = await postDuplicateView(groupId, clientId, formId, title, description);
    if (asyncResp?.success) {
      await dispatch(postViewResponse());
      await dispatch(fetchViews());
      await dispatch(fetchDuplicateViews(formId));
    } else {
      await dispatch(postViewFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const transferEntries = (
  entryIds: string[], formId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await postTransferEntries(entryIds, formId);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
      await dispatch(fetchEntries());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateView = (
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  order:number,
  title: string,
  contents: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postViewRequest());
    const asyncResp: any = await putView(id, groupId, clientId, formId, order, title, contents, active);
    if (asyncResp?.success) {
      await dispatch(postViewResponse());
    } else {
      await dispatch(postViewFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


