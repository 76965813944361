import React, { useEffect, useState } from 'react';
import DropDown from '../../../components/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { isFetchingEntries, isPostingEntry } from '../../../selectors/Entry';
import Pill from '../../../components/Pill';
import { useRouteMatch } from 'react-router-dom';
import c from '../../../helpers/constants';
import Button from '../../../components/Button';
import { getTags } from '../../../selectors/Tag';

import { fetchTags } from '../../../actions/Tag';




const FavouriteTags = () => {
  const [itemsTags, setItemsTags] = useState<any[]>([]);
  const [tagsId, setTagsId] = useState<any[]>([]);
  const [tagId, setTagId] = useState('');
  const tags = useSelector(getTags);
  const isPosting = useSelector(isPostingEntry);
  const isFetching = useSelector(isFetchingEntries);
  const matchCreate = useRouteMatch(c.APP_ROUTES.FORM_RENDERER);
  const dispatch = useDispatch();
  const underConstruction = true;

  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);
  useEffect(() => {
    if (tags){
      setItemsTags(tags?.data.map(r => ({ label: r.value, value: r.id, type: r.type })));
    }
  }, [tags]);

  const onTagsSelect = (obj: any) => {
    setTagId(obj.value);
    setItemsTags(current => current.filter(t => t.label !== obj.label));
    setTagsId([
      ...tagsId,
      { id: obj.value, name: obj.label, type: obj.type },
    ]);
  };
  const onTagsUnselect = (id: any, name: any) => {
    setItemsTags([...itemsTags, { label: name, value: id },
    ] );
    setTagsId([
      ...tagsId.filter(e => e.id != id),
    ]);
  };

  return <>
    {!underConstruction ?
        <>
        <div className='user-profile__section--grey'>

          <div className='col-md-4 col-12'>
            <DropDown id="tagsId"
                      placeholder="Select your favourite tags"
                      label="Favourite Tags"
                      items={itemsTags}
                      disabled={isPosting || isFetching}
                      value={tagId}
                      onSelect={onTagsSelect}
                      type="default"
                      searchable={true}
            />
          </div>
          <div>
            {matchCreate?.isExact && tagsId.map(e =>
                <Pill key={e.id} text={e.name} type='white' cancellable onClick={() => onTagsUnselect(e.id, e.name)}/>)}
          </div>
        </div>
      <div className='user-profile__button--extra-margin-top'>
      <Button
      title={'Edit'}/>
      </div>
          </> : <div>This page is currently under construction!</div>
          }
  </>;
};

export default FavouriteTags;



