import {
  FETCH_MARKETPLACE_ENTRY_FAILURE,
  FETCH_MARKETPLACE_ENTRY_REQUEST,
  FETCH_MARKETPLACE_ENTRY_RESPONSE,
  FETCH_MARKETPLACE_ENTRIES_FAILURE,
  FETCH_MARKETPLACE_ENTRIES_REQUEST,
  FETCH_MARKETPLACE_ENTRIES_RESPONSE,
  POST_MARKETPLACE_ENTRY_FAILURE,
  POST_MARKETPLACE_ENTRY_RESPONSE,
  MarketplaceEntryActionTypes,
  MarketplaceEntryState,
  POST_MARKETPLACE_ENTRY_REQUEST,
} from '../types/MarketplaceEntry';
import { parseValidationErrors } from '../helpers/functions';

const initialState: MarketplaceEntryState = {
  marketplaceEntries: null,
  activeMarketplaceEntry: null,
  isFetchingMarketplaceEntries: false,
  fetchingMarketplaceEntriesFailed: false,
  isPostingMarketplaceEntry: false,
  postingMarketplaceEntryFailed: false,
  postingMarketplaceEntryError: null,
  postingMarketplaceEntryValidationErrors: null,
};

const marketplaceEntryReducer = (
  state = initialState,
  action: MarketplaceEntryActionTypes,
): MarketplaceEntryState => {
  switch (action.type) {
    case FETCH_MARKETPLACE_ENTRIES_REQUEST:
      return {
        ...state,
        marketplaceEntries: null,
        isFetchingMarketplaceEntries: true,
        fetchingMarketplaceEntriesFailed: false,
      };
    case FETCH_MARKETPLACE_ENTRIES_RESPONSE:
      return {
        ...state,
        marketplaceEntries: action.payload || [],
        isFetchingMarketplaceEntries: false,
        fetchingMarketplaceEntriesFailed: false,
      };
    case FETCH_MARKETPLACE_ENTRIES_FAILURE:
      return {
        ...state,
        marketplaceEntries: null,
        isFetchingMarketplaceEntries: false,
        fetchingMarketplaceEntriesFailed: true,
      };
    case FETCH_MARKETPLACE_ENTRY_REQUEST:
      return {
        ...state,
        marketplaceEntries: null,
        isFetchingMarketplaceEntries: true,
        fetchingMarketplaceEntriesFailed: false,
      };
    case FETCH_MARKETPLACE_ENTRY_RESPONSE:
      return {
        ...state,
        activeMarketplaceEntry: action.payload,
        isFetchingMarketplaceEntries: false,
        fetchingMarketplaceEntriesFailed: false,
      };
    case FETCH_MARKETPLACE_ENTRY_FAILURE:
      return {
        ...state,
        marketplaceEntries: null,
        isFetchingMarketplaceEntries: false,
        fetchingMarketplaceEntriesFailed: true,
      };
    case POST_MARKETPLACE_ENTRY_REQUEST:
      return {
        ...state,
        isPostingMarketplaceEntry: true,
        postingMarketplaceEntryError: null,
        postingMarketplaceEntryValidationErrors: null,
        postingMarketplaceEntryFailed: false,
      };
    case POST_MARKETPLACE_ENTRY_RESPONSE:
      return {
        ...state,
        isPostingMarketplaceEntry: false,
        postingMarketplaceEntryError: null,
        postingMarketplaceEntryValidationErrors: null,
        postingMarketplaceEntryFailed: false,
      };
    case POST_MARKETPLACE_ENTRY_FAILURE:
      return {
        ...state,
        isPostingMarketplaceEntry: false,
        postingMarketplaceEntryError: action.payload.error || 'An error occurred',
        postingMarketplaceEntryValidationErrors: parseValidationErrors(
          action.payload.validationErrors,
        ),
        postingMarketplaceEntryFailed: true,
      };

    default:
      return state;
  }
};

export default marketplaceEntryReducer;
