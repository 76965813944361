import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getSolutionInputTags, isFetchingSolutionInputTags } from '../../../selectors/SolutionInputTag';
import { fetchSolutionInputTags, storeSolutionInputTag } from '../../../actions/SolutionInputTag';
import { SolutionInputTag } from '../../../types/SolutionInputTag';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const SolutionInputTagDataTable = () => {

  const solutionInputTags = useSelector(getSolutionInputTags);
  const isLoading = useSelector(isFetchingSolutionInputTags);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [solutionInputTag, setActiveSolutionInputTag] = useState<SolutionInputTag | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SOLUTION_INPUT_TAG_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SOLUTION_INPUT_TAG_UPDATE, row.id));
  };

  const onArchive = (row: SolutionInputTag) => {
    setActiveSolutionInputTag(row);
    setToggle(true);
  };

  const onConfirmArchive = () => {
    setToggle(false);
    if (solutionInputTag?.id) {
      dispatch(storeSolutionInputTag(solutionInputTag?.id));
      setRefetch(!refetch);
    }
    setActiveSolutionInputTag(null);
  };

  const config = {
    columns: [
      { key: 'solutionTitle', label: 'Solution', mobileFriendly: true },
      { key: 'tagValue', label: 'Tag', mobileFriendly: true },
      { key: 'tagType', label: 'Tag Type', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="SolutionInputTags" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchSolutionInputTags} paging={solutionInputTags?.paging || []}
                   isLoading={isLoading}  data={solutionInputTags?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle} title="Archive SolutionInputTag" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this solutionInputTag?
        </Modal>
      </View>
  );
};

export default SolutionInputTagDataTable;

