import { RootState } from '../store/configureStore';

export const getTags = (state: RootState) => state?.tag?.tags;

export const getActiveTag = (state: RootState) => state?.tag?.activeTag;

export const isFetchingTags = (state: RootState) => state?.tag?.isFetchingTags;

export const isPostingTag = (state: RootState) => state?.tag?.isPostingTag;
export const postingTagFailed = (state: RootState) => state?.tag?.postingTagFailed;
export const postingTagError = (state: RootState) => state?.tag?.postingTagError;
export const postingTagValidationErrors = (state: RootState) => state?.tag?.postingTagValidationErrors;
export const fetchingTagsFailed = (state: RootState) => state?.tag?.fetchingTagsFailed;