import { RootState } from '../store/configureStore';

export const getBadges = (state: RootState) => state?.badge?.badges;

export const getActiveBadge = (state: RootState) => state?.badge?.activeBadge;

export const isFetchingBadges = (state: RootState) => state?.badge?.isFetchingBadges;

export const isPostingBadge = (state: RootState) => state?.badge?.isPostingBadge;
export const postingBadgeFailed = (state: RootState) => state?.badge?.postingBadgeFailed;
export const postingBadgeError = (state: RootState) => state?.badge?.postingBadgeError;
export const postingBadgeValidationErrors = (state: RootState) => state?.badge?.postingBadgeValidationErrors;
export const fetchingBadgesFailed = (state: RootState) => state?.badge?.fetchingBadgesFailed;