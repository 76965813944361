import React from 'react';
import View from '../../../components/View/View';
import c, { marketplaceCreateSteps, marketplaceEditSteps } from '../../../helpers/constants';
import { formatUrl } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import Stepper from '../../../components/Stepper';
import { useEditMode } from '../../../hooks/EditModeHook';
import Button from '../../../components/Button';
import TickMark from '../../../assets/images/svgs/tick_mark.svg';
import './MarketplaceWizard.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';


const StepThree = () => {
  const history = useHistory();

  const [createMode] = useEditMode(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_THREE);
  const [editMode, editActiveId ] = useEditMode(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_THREE);
  const activeAuctionId = useSelector((state: RootState) => state.marketplace.createdMarketplace);

  const steps = createMode ? marketplaceCreateSteps : marketplaceEditSteps;

  const onNavigate = () => {
    if (activeAuctionId && createMode){
      history.push(formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE, activeAuctionId));
    } else if (editMode) {
      history.push(formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE, editActiveId));
    }
  };

  return (
        <View>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-8">
                        <Stepper steps={steps} activeStep={createMode ? 2 : 1} hideMobileLabels={true} />
                    </div>
                </div>

                <div className="row p-4 justify-content-center align-items-center">
                    <div className="row justify-content-center align-items-center marketplace-wizard__box">
                        <div className="row justify-content-center align-items-center  p-md-5 p-sm-0">
                        <div className='row mt-5 justify-content-center align-items-center'>
                            <div className='col-12 text-center'><img className="marketplace-wizard__circle" src={TickMark} alt='checkmark'/></div>
                        </div>
                        <div className='row my-1'>
                            <div className='col-12 marketplace-wizard__success-title text-center'>Completion Confirmation: Your Marketplace is Ready!</div>
                        </div>
                        <div className='row my-1'>
                            <div className='col-12 text-center'>
                                All done! Your marketplace setup is complete.
                            </div>
                        </div>
                        <div className='row my-1'>
                            <div className='col-12 text-center'>
                                <Button onClick={() => onNavigate()} title={'Go To Marketplace'} />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </View>

  );
};

export default StepThree;

