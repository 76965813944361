import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getFieldProperties, isFetchingFieldProperties } from '../../../selectors/FieldProperty';
import { fetchFieldProperties, storeFieldProperty } from '../../../actions/FieldProperty';
import { FieldProperty } from '../../../types/FieldProperty';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const FieldPropertyDataTable = () => {

  const fieldProperties = useSelector(getFieldProperties);
  const isLoading = useSelector(isFetchingFieldProperties);
  const [toggle, setToggle] = useState(false);
  const [fieldProperty, setActiveFieldProperty] = useState<FieldProperty | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FIELD_PROPERTY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FIELD_PROPERTY_UPDATE, row.id));
  };

  const onArchive = (row: FieldProperty) => {
    setActiveFieldProperty(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (fieldProperty?.id) {
      await storeFieldProperty(fieldProperty?.id);
      setRefetch(!refetch);
    }
    setActiveFieldProperty(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'fieldTypeTitle', label: 'Field Type', mobileFriendly: true },
      { key: 'fieldTitle', label: 'Field', mobileFriendly: false },
      { key: 'fieldTypeOption', label: 'Field Type Option', mobileFriendly: false },
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (
      <View title="Field Properties" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchFieldProperties} paging={fieldProperties?.paging || []}
                 isLoading={isLoading}  data={fieldProperties?.data || []} config={config} dependencies={refetch}/>
      <Modal show={toggle} title="Archive FieldProperty" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this fieldProperty?
      </Modal>
    </View>
  );
};

export default FieldPropertyDataTable;

