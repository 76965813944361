import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropDown.scss';

export type Item = {
  value: string | number;
  label?: string;
  type?: string;
};

type Props = {
  items: Item[] | undefined;
  name?: string;
  id?: string;
  placeholder?: string;
  value: string | number | null | undefined;
  label?: string;
  required?: boolean;
  v?: any;
  err?: (id: string) => string[];
  onSelect: (item: Item) => void;
  errors?: string[];
  disabled?: boolean;
  validate?: (field: string, value: string | number | null | undefined | boolean) => void;
  type?: 'default' | 'red';
  searchable?: boolean;
  className?: string;
  radius?: boolean;
};

const DropDown = (props: Props) => {
  const {
    items,
    v,
    onSelect = () => {},
    value,
    disabled = false,
    type = 'default',
    placeholder = 'Please select',
    label,
    err = () => [],
    required,
    id = '',
    className,
    searchable = undefined,
    radius = false,
  } = props;
  const selected = items?.find((i) => i.value === value);

  const [touched, setTouched] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [updated, setUpdated] = useState(false);

  const onInnerSelect = (item: Item) => {
    setTouched(true);
    if (v && typeof v === 'function') {
      v(id, item?.value);
    }
    onSelect(item);
  };

  useEffect(() => {
    if (!touched && value && !updated) {
      if (v && typeof v === 'function') {
        v(id, value);
        setUpdated(true);
      }
    }
  }, [value, items]);

  const errors = err(id);

  const filteredItems =
      items && Array.isArray(items) && items.length > 0
        ? items.some((item) => typeof item.value !== 'number')
          ? items
            .filter((item) => typeof item.value !== 'number')
            .filter(
              (item) =>
                item.label?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.value?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.type?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .sort((a, b) => {
              const aText = (a.label || a.value).toString().toLowerCase();
              const bText = (b.label || b.value).toString().toLowerCase();
              return aText.localeCompare(bText);
            })
          : items
        : [];

  return (
      <div className="dropdown my-1" id={type}>
        <div className="mb-3">
          {label && (
              <label className={'form-label'}>
                {label}
                <span className={`form-label ${errors.length > 0 && 'error-lbl'}`}>
            {required && '*'}
          </span>
              </label>
          )}
          <Dropdown id={id}>
            <Dropdown.Toggle
                className={`${className || ''} ${radius ? 'table-dropdown' : ''} ${
                  touched ? 'touched' : 'untouched'
                }`}
                disabled={disabled}
                variant="success"
            >
              {selected?.label || selected?.value || placeholder}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {(searchable || (searchable === undefined && items && items?.length > 5)) && (
                  <div className="px-3 mb-2 dropdown__search">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </div>
              )}
              <div className="dropdown__options">
                {filteredItems?.map((item, index) => (
                    <Dropdown.Item key={index} onClick={() => onInnerSelect(item)}>
                      {item.label || item.value}
                    </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>


        </div>
        <div className='row'>
        {errors.map((e: string, i: number) => (
            <div key={i} className='col-auto error-container'>
              {e}
            </div>
        ))}
        </div>
      </div>
  );
};

export default DropDown;
