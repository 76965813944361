import { useEffect, useState } from 'react';
import { FormError } from '../types/Common';

export type Value = string | number | null | undefined | boolean;

export type Validator = (value: Value) => boolean | string;

type Field = {
  field: string;
  validators: Validator[];
  value?: Value;
  isValid?: boolean;
  touched?: boolean;
  errors?: string[];
};

export const useValidation = (initialConfig: Field[], serverValidationErrors: FormError[] | null) => {
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [config, setConfig] = useState<Field[]>(initialConfig);
  const [fields, setFields] = useState<Field[]>(initialConfig);


  useEffect(() => {
    if (serverValidationErrors && serverValidationErrors?.length > 0) {
      serverValidationErrors.forEach(e => {
        const localFields = [...fields];
        const index = localFields.findIndex((fie: Field) => fie.field === e.field);
        if (index > -1) {
          localFields[index].errors = e.errors;
        } else {
          console.error(`${e.field} is missing from the validators`);
        }
        setFields(localFields);
      });
    }
  }, [serverValidationErrors]);

  const validate = (field: string, value: Value) => {
    if (!fields || fields?.length === 0) {
      return;
    }

    if (!formTouched) {
      setFormTouched(true);
    }

    const localErrors: string[] = [];

    const localFields = [...fields];

    const index = localFields.findIndex((fie: Field) => fie.field === field);
    const f = localFields[index];
    if (index > -1) {
      localFields[index].value = value;
      f?.validators.forEach(validator => {
        const test = validator(value);
        if (typeof test === 'string') {
          localErrors.push(test);
        }
      });

      localFields[index].errors = localErrors;
    } else {
      console.error(`${field} is missing from the validators`);
    }

    setFields(localFields);
  };

  const err = (field: string) => {
    const f = fields.find(fie => fie.field === field);
    return f?.errors || [];
  };

  const v = (field: string, value: Value) => {
    validate(field, value);
  };

  const clearErrors = () => {
    const localFields = fields.map(f => {
      const { errors, ...rest } = f;
      return rest; // Return field without errors property
    });
    setFields(localFields);
    setFormTouched(true); // Reset the formTouched state
  };

  const isFormValid = () => {
    let isValid = true;
    fields.forEach(f => {
      if (f.errors && f.errors?.length > 0) {
        isValid = false;
      }
    });
    return isValid && formTouched;
  };

  const triggerValidation = () => {
    fields.forEach(f => {
      validate(f.field, f.value);
    });
    return isFormValid();
  };

  const updateFields = (newConfig: Field[]) => {
    const updatedFields = config.map(existingField => {
      const matchingField = newConfig.find(newField => newField.field === existingField.field);

      if (matchingField) {
        return {
          ...existingField,
          validators: matchingField.validators, // Update the validators
        };
      }

      return existingField;
    });

    newConfig.forEach(newField => {
      const exists = updatedFields.some(existingField => existingField.field === newField.field);
      if (!exists) {
        updatedFields.push(newField);
      }
    });

    setFields(updatedFields);
    setConfig(updatedFields);
  };

  const resetFields = (newConfig: Field[]) => {
    setFields(newConfig);
    setConfig(newConfig);
  };


  return {
    v,
    triggerValidation,
    isFormValid,
    err,
    updateFields,
    resetFields,
    clearErrors,
  };
};
