import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRY_TAGS_REQUEST,
  FETCH_ENTRY_TAGS_RESPONSE,
  FETCH_ENTRY_TAGS_FAILURE,
  FETCH_ENTRY_TAG_REQUEST,
  FETCH_ENTRY_TAG_RESPONSE,
  FETCH_ENTRY_TAG_FAILURE,
  POST_ENTRY_TAG_FAILURE,
  POST_ENTRY_TAG_REQUEST,
  POST_ENTRY_TAG_RESPONSE,
  EntryTagActionTypes, EntryTagDataModel, EntryTag,
} from '../types/EntryTag';

import { RootState } from '../store/configureStore';

import {
  archiveEntryTag,
  getEntryTag,
  getEntryTagsByEntryId,
  getEntryTags,
  postEntryTag,
  putEntryTag,
} from '../api/EntryTag';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntryTagsRequest(): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAGS_REQUEST,
    payload: null,
  };
}

export function fetchEntryTagsResponse(
  entryTags: EntryTagDataModel,
): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAGS_RESPONSE,
    payload: entryTags,
  };
}

export function fetchEntryTagsFailure(): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAGS_FAILURE,
    payload: null,
  };
}

export function fetchEntryTagRequest(): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAG_REQUEST,
    payload: null,
  };
}

export function fetchEntryTagResponse(
  entryTag: EntryTag,
): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAG_RESPONSE,
    payload: entryTag,
  };
}

export function fetchEntryTagFailure(): EntryTagActionTypes {
  return {
    type: FETCH_ENTRY_TAG_FAILURE,
    payload: null,
  };
}


export function postEntryTagRequest(): EntryTagActionTypes {
  return {
    type: POST_ENTRY_TAG_REQUEST,
    payload: null,
  };
}

export function postEntryTagResponse(): EntryTagActionTypes {
  return {
    type: POST_ENTRY_TAG_RESPONSE,
    payload: null,
  };
}

export function postEntryTagFailure(error: string, validationErrors: any): EntryTagActionTypes {
  return {
    type: POST_ENTRY_TAG_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveEntryTag = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryTagRequest());
    const asyncResp: any = await getEntryTag(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryTagResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryTagFailure());
    }
  };

export const fetchActiveEntryTagsByEntryId = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryTagsRequest());
    const asyncResp: any = await getEntryTagsByEntryId(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryTagsResponse(asyncResp));
    } else {
      await dispatch(fetchEntryTagsFailure());
    }
  };

export const fetchEntryTags = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryTagsRequest());
    const asyncResp: any = await getEntryTags(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntryTagsResponse(asyncResp));
    } else {
      await dispatch(fetchEntryTagsFailure());
    }
  };

export const storeEntryTag = async (id: string) => {
  try {
    await archiveEntryTag(id);
  } catch (error) {
    throw error;
  }
};

export const createEntryTag = (
  groupId: string, clientId: string, entryId: string, tagId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryTagRequest());
    const asyncResp: any = await postEntryTag(groupId, clientId, entryId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postEntryTagResponse());
    } else {
      await dispatch(postEntryTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryTag = (
  id: string,
  groupId: string,
  clientId: string,
  entryId: string,
  tagId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryTagRequest());
    const asyncResp: any = await putEntryTag(id, groupId, clientId, entryId, tagId, active);
    if (asyncResp?.success) {
      await dispatch(postEntryTagResponse());
    } else {
      await dispatch(postEntryTagFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


